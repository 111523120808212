<table class="table table-bordered table-striped">
  <thead>
    <tr>
      <th>Deductions/Contributions</th>
      <th>Employer</th>
      <th>Employee</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <th scope="row">Amount</th>
      <td>{{ employerContributions.amount }}</td>
      <td>{{ employeeDeductions.amount }}</td>
    </tr>
    <tr>
      <th scope="row">Contributions</th>
      <td>{{ employerContributions.contribution_percentage }}</td>
      <td>{{ employeeDeductions.contribution_percentage }}</td>
    </tr>
  </tbody>
  <tfoot>
    <tr>
      <th scope="row">Pay Cycle</th>
      <td colspan="2">{{ payCycle }}</td>
    </tr>
  </tfoot>
</table>
<p class="note">
  NOTE: These are estimates only. Every effort has been made to provide accurate amounts, however the true value will be
  confirmed by the insurance carrier.
</p>
