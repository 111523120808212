<div class="row justify-content-center">
  <div class="col" *ngIf="plan$ | async as plan">
    <h2 class="title">Employee Census</h2>
    <nb-card *ngIf="planMedia$ | async as planMedia">
      <nb-card-body class="">
        <pip-media-uploader
          class="mx-auto mb-5"
          [existingMedia]="planMedia[mediaType.Census]"
          [mediaType]="mediaType.Census"
          [modelId]="plan.id"
          [mediaModel]="mediaModel.Plan"
          [warning]="
            planMedia[mediaType.Census]
              ? 'Uploading a new census will destroy and overwrite any current employees'
              : null
          "
          [downloadTemplate]="censusTemplateUrl"
        ></pip-media-uploader>

        <ng-container *ngIf="planMedia[mediaType.Census]">
          <hr />
          <pip-employees-off-work></pip-employees-off-work>
        </ng-container>
      </nb-card-body>
      <hr />
      <pip-employees-table
        [showEnroll]="true"
        [showEmployeeId]="false"
        [employees]="employees$ | async"
        (getInfo)="onInfo($event, employeeInfo)"
        (delete)="onDelete($event, deleteDialog)"
      ></pip-employees-table>
      <nb-card-footer class="d-flex justify-content-between">
        <a nbButton status="primary" [routerLink]="['../']">Back to tasks</a>

        <pip-employee-pagination
          [paginatedLinks]="(plan$ | async).relationships.employees.links"
          [planId]="(plan$ | async).id"
        ></pip-employee-pagination>
      </nb-card-footer>
    </nb-card>
  </div>
</div>

<ng-template #deleteDialog let-data let-ref="dialogRef">
  <nb-card status="basic">
    <nb-card-header>Delete employee</nb-card-header>
    <nb-card-body class="p-5">
      Are you sure you want to delete <strong>{{ data }}</strong
      >? This action cannot be undone.
    </nb-card-body>
    <nb-card-footer class="d-flex justify-content-between">
      <button nbButton status="danger" (click)="ref.close(true)">Delete {{ data }}</button>
      <button nbButton status="primary" ghost (click)="ref.close(false)">Cancel</button>
    </nb-card-footer>
  </nb-card>
</ng-template>

<ng-template #employeeInfo let-data let-ref="dialogRef">
  <nb-card status="basic">
    <nb-card-header>Employee info</nb-card-header>
    <nb-card-body>
      <nb-alert accent="warning" *ngIf="!data.email">
        <h2 class="h5">Missing Email!</h2>
        <p>Employee email is missing/incomplete.</p>
      </nb-alert>
      <ng-container *ngIf="data.length; else AllComplete">
        <pip-field-status-alert *ngFor="let item of data.fs" [fieldStatus]="item"></pip-field-status-alert>
      </ng-container>
      <ng-template #AllComplete>
        <nb-alert accent="success">Employee is ready for enrollment</nb-alert>
      </ng-template>
    </nb-card-body>
    <nb-card-footer class="d-flex justify-content-end">
      <button nbButton status="primary" ghost (click)="ref.close(false)">Close</button>
    </nb-card-footer>
  </nb-card>
</ng-template>
