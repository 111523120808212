import { HttpParams } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { GetPlan, PlansState, RatesState } from '@app/data';
import { InvitationsService } from '@app/data/services';
import { toHttpParams } from '@app/utils/to-http-params';
import { NbToastrService } from '@nebular/theme';
import { Store } from '@ngxs/store';
import { BehaviorSubject, catchError, map, of, take } from 'rxjs';

@Component({
  selector: 'pip-client-plan-members',
  templateUrl: './client-plan-members.component.html',
  styleUrls: ['./client-plan-members.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class ClientPlanMembersComponent implements OnInit {
  public plan$ = this.store.select(PlansState.activePlan);
  public employees$ = this.store.select(PlansState.activePlanEmployees);
  public rates$ = this.store.select(PlansState.activePlanRates);

  public selectedEmployees = [];
  public isLoading$ = new BehaviorSubject(false);

  public employeeDownload$ = this.plan$.pipe(map((plan) => plan.links?.employees));

  constructor(public store: Store, private invitationService: InvitationsService, private toastr: NbToastrService) {}

  public ngOnInit(): void {}

  public onSelectedEmployeesChange(ids: string[]): void {
    this.selectedEmployees = ids;
  }

  public inviteSelectedEmployees(planId: string): void {
    this.isLoading$.next(true);

    this.invitationService
      .invitePlanEmployees(planId, this.selectedEmployees)
      .pipe(
        take(1),
        map((res) => res?.data?.length || 0),
        catchError(() => {
          return of(0);
        }),
      )
      .subscribe((count) => {
        this.isLoading$.next(false);

        this.toastr.success(`Invitation emails have been sent to ${count} plan member(s).`, 'Invitations Sent');

        if (count) {
          this.store.dispatch(new GetPlan({ planId }));
        }
      });
  }
}
