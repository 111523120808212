<div class="legal">
  <p>We apply to {{ carrierName }} for the group coverage described in this application.</p>
  <p>
    We agree to the terms and conditions of the Business pre-authroized debit (PAD) agreement and the Plan administration access agreement
    attached and acknowledge and agree that the attached quotation forms a part of this application. Any rate guarantee is a guarantee as to
    the rate only and does not apply to expenses or any other amounts payable in relation to the Group policy. Rate guarantees are subject
    to the other provisions of the contract. For greater certainty, rate guarantees do not prohibit
    {{ carrierName }} from exercising its rights to terminate or not renew coverage as provided in a contract.
  </p>
  <p>We agree that our group coverage will take effect once all of the following conditions have been met:</p>
  <ol>
    <li>This application must be accepted and the effective date approved by {{ carrierName }};</li>
    <li>A binder premium must be paid; and</li>
    <li>The minimum participation requirements must be met.</li>
  </ol>
  <p>
    WE DECLARE that all statements, representations and answers made in this application are consideration for and a basis of the
    contract(s) of insurance between us and {{ carrierName }}. We declare these statements, representations and answers to be true, full and
    complete. We agree that no other statement, representation or information will be binding upon or affect the rights of
    {{ carrierName }}. We agree to give {{ carrierName }}, on request, full information on each employee insured or eligible for insurance,
    including information required for assessment of claims.
  </p>
</div>
