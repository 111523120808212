<fieldset [formGroup]="controlContainer.control">
  <legend class="subtitle">Employees</legend>
  <div class="form-group row">
    <label for="work_week_hours" class="col col-form-label label">
      How many hours must an employee work each week to qualify for benefits?
    </label>
    <div class="col-sm-6">
      <input type="number" nbInput fullWidth id="work_week_hours" formControlName="work_week_hours" />
    </div>
  </div>
  <div class="form-group row">
    <label for="probation_months" class="col col-form-label label">
      How many months must an employee work to qualify for benefits? <small>(ie. waiting period)</small>
    </label>
    <div class="col-sm-6">
      <input type="number" nbInput fullWidth id="probation_months" formControlName="probation_months" />
    </div>
  </div>
  <div class="form-group row">
    <label for="waiting_period" class="col col-form-label label">Apply waiting period to existing employees?</label>
    <div class="col-sm-6">
      <nb-radio-group class="d-flex" formControlName="waiting_period" name="waiting_period">
        <nb-radio [value]="true">Yes</nb-radio>
        <nb-radio [value]="false">No</nb-radio>
      </nb-radio-group>
    </div>
  </div>

  <div class="form-group row">
    <label for="overtime_earnings" class="col col-form-label label"
      >Are overtime and bonuses included in the employee's earnings?</label
    >
    <div class="col-sm-6">
      <nb-radio-group class="d-flex" formControlName="overtime_earnings" name="overtime_earnings">
        <nb-radio [value]="true">Yes</nb-radio>
        <nb-radio [value]="false">No</nb-radio>
      </nb-radio-group>
    </div>
  </div>
  <div class="form-group row" *ngIf="plan.attributes.ltd">
    <label for="ltd_employee_paid" class="col col-form-label label">Is long term disability 100% employee paid?</label>
    <div class="col-sm-6">
      <nb-radio-group class="d-flex" formControlName="ltd_employee_paid" name="ltd_employee_paid">
        <nb-radio [value]="true">Yes</nb-radio>
        <nb-radio [value]="false">No</nb-radio>
      </nb-radio-group>
    </div>
  </div>
  <div class="form-group row" *ngIf="plan.attributes.std">
    <label for="std_employee_paid" class="col col-form-label label">Is short term disability 100% employee paid?</label>
    <div class="col-sm-6">
      <nb-radio-group class="d-flex" formControlName="std_employee_paid" name="std_employee_paid">
        <nb-radio [value]="true">Yes</nb-radio>
        <nb-radio [value]="false">No</nb-radio>
      </nb-radio-group>
    </div>
  </div>
  <div class="form-group row" *ngIf="plan.attributes.ltd || plan.attributes.std">
    <label for="workers_compensation" class="col col-form-label label">
      Are employees covered by Worker's Compensation or a similar plan?
    </label>
    <div class="col-sm-6">
      <nb-radio-group class="d-flex" formControlName="workers_compensation" name="workers_compensation">
        <nb-radio [value]="true">Yes</nb-radio>
        <nb-radio [value]="false">No</nb-radio>
      </nb-radio-group>
    </div>
  </div>
  <div class="form-group row">
    <label for="pay_cycle" class="col col-form-label label">Confirm current pay cycle</label>
    <div class="col-sm-6">
      <nb-radio-group class="" formControlName="pay_cycle" name="pay_cycle">
        <nb-radio *ngFor="let item of payCycleDictionary | keyvalue" [value]="item.key">{{ item.value }}</nb-radio>
      </nb-radio-group>
    </div>
  </div>
  <div class="form-group row">
    <label for="workers_compensation" class="col col-form-label label">
      For what duration of time will benefits be continued for a plan member who is temporarily laid off?
    </label>
    <div class="col-sm-6">
      <nb-select formControlName="benefits_continuation">
        <nb-option [value]="null">Select</nb-option>
        <nb-option *ngFor="let item of benefitsContinuations" [value]="item.value">{{ item.label }}</nb-option>
      </nb-select>
    </div>
  </div>
</fieldset>
<fieldset [formGroup]="controlContainer.control">
  <legend class="subtitle">Contributions</legend>

  <pip-contributions-formgroup
    [formGroup]="controlContainer.control.get('employer_contribution')"
  ></pip-contributions-formgroup>
</fieldset>
