import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'pip-advisor-signoff-content',
  templateUrl: './advisor-signoff-content.component.html',
  styleUrls: ['./advisor-signoff-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdvisorSignoffContentComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
