<button
  nbButton
  status="primary"
  type="button"
  appearance="outline"
  size="tiny"
  [disabled]="!plan?.attributes?.administrator?.email"
  (click)="onInvite(confirmInviteDialog)"
>
  {{ label }}
</button>

<ng-template #confirmInviteDialog let-data let-ref="dialogRef">
  <nb-card status="basic">
    <nb-card-header>Confirm invite</nb-card-header>
    <nb-card-body>
      <p>Are you sure you want to send an invitation to {{ plan?.attributes?.administrator?.email }}?</p>
    </nb-card-body>
    <nb-card-footer>
      <button nbButton status="primary" type="button" (click)="ref.close(true)">Yes, send invitation</button>
      <button nbButton status="primary" ghost type="button" (click)="ref.close()">No, do not send invitation</button>
    </nb-card-footer>
  </nb-card>
</ng-template>
