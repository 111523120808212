import { ChangeDetectionStrategy, Component, OnInit, TemplateRef } from '@angular/core';
import { DeleteEmployee, IFieldStatuses, MediaModel, MediaType, PlansState } from '@app/data';
import { environment } from '@env/environment';
import { NbDialogService } from '@nebular/theme';
import { Store } from '@ngxs/store';
import { filter, take, tap } from 'rxjs/operators';

@Component({
  selector: 'pip-quote-task-census',
  templateUrl: './quote-task-census.component.html',
  styleUrls: ['./quote-task-census.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuoteTaskCensusComponent implements OnInit {
  public plan$ = this.store.select(PlansState.activePlan);
  public planMedia$ = this.store.select(PlansState.activePlanMedia);
  public employees$ = this.store.select(PlansState.activePlanEmployees);
  public rates$ = this.store.select(PlansState.activePlanRates);

  public mediaType = MediaType;
  public mediaModel = MediaModel;
  public censusTemplateUrl = `${environment.url.api}/assets/census-template.xlsx`;

  constructor(private store: Store, private dialog: NbDialogService) {}

  public ngOnInit(): void {}

  public onDelete({ id, first_name, last_name }, dialog: TemplateRef<any>): void {
    this.dialog
      .open(dialog, { context: `${first_name} ${last_name}` })
      .onClose.pipe(
        take(1),
        filter((shouldDelete) => shouldDelete),
      )
      .subscribe(() => this.store.dispatch([new DeleteEmployee(id)]));
  }

  public onInfo({ fs, email }: { fs: IFieldStatuses; email: string }, dialog: TemplateRef<any>): void {
    this.dialog.open(dialog, { context: { fs: Object.values(fs).filter((fs) => !fs.complete), email } });
  }
}
