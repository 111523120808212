import { ChangeDetectionStrategy, Component, OnChanges, TemplateRef } from '@angular/core';
import { DeleteEmployee, PlansState, PlanStatus } from '@app/data';
import { NbDialogService } from '@nebular/theme';
import { Store } from '@ngxs/store';
import { filter, map, take } from 'rxjs/operators';

@Component({
  selector: 'pip-employee-list',
  templateUrl: './employee-list.component.html',
  styleUrls: ['./employee-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmployeeListComponent implements OnChanges {
  public plan$ = this.store.select(PlansState.activePlan);
  public rates$ = this.store.select(PlansState.activePlanRates);
  public employees$ = this.store.select(PlansState.activePlanEmployees);

  public showEnrollEmployee$ = this.plan$.pipe(
    map(plan => {
      if (plan?.relationships?.predecessor?.data) {
        return plan?.attributes?.status === PlanStatus.EnrollClient;
      }

      return plan?.attributes?.status !== PlanStatus.Setup;
    }),
  );

  public planStatus = PlanStatus;

  constructor(private store: Store, private dialog: NbDialogService) {}

  public ngOnChanges(): void {}

  public onDelete({ id, first_name, last_name }, dialog: TemplateRef<any>): void {
    this.dialog
      .open(dialog, { context: `${first_name} ${last_name}` })
      .onClose.pipe(
        take(1),
        filter(shouldDelete => shouldDelete),
      )
      .subscribe(() => this.store.dispatch([new DeleteEmployee(id)]));
  }
}
