<nb-button-group>
  <button
    nbButton
    type="button"
    *ngFor="let link of paginatedLinks; trackBy: trackPaginate"
    [disabled]="!link.url"
    [ngClass]="{
      disabled: !link.url || link.active
    }"
    [status]="linkStatus(link.active)"
    (click)="link.url && !link.active && onPaginate(link.url)"
    [innerHtml]="link.label"
  ></button>
</nb-button-group>
