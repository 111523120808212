import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { RateGuaranteeOpts } from '@app/data/enums/rate-guarantee-opts';

@Component({
  selector: 'pip-common-rate-properties-form-group',
  templateUrl: './common-rate-properties-form-group.component.html',
  styleUrls: ['./common-rate-properties-form-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommonRatePropertiesFormGroupComponent {
  @Input() public showRateGuarantee = true;
  @Input() public showRenewalCaps = true;

  public rateGuaranteeOpts = RateGuaranteeOpts;

  constructor(public controlContainer: ControlContainer) {}
}
