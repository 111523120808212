import { ChangeDetectionStrategy, Component, TemplateRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  CreateRate,
  DataType,
  IRate,
  IRateAttributes,
  PlansState,
  PlanStandards,
  Rate,
  Role,
  UsersState,
} from '@app/data';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { combineLatest } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Component({
  selector: 'pip-client-rates',
  templateUrl: './client-rates.component.html',
  styleUrls: ['./client-rates.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientRatesComponent {
  public plan$ = this.store.select(PlansState.activePlan);
  public rates$ = this.store.select(PlansState.activePlanRates);
  public userRoles$ = this.store.select(UsersState.activeUserRoles);

  public firstRenewal$ = this.plan$.pipe(map((plan) => plan?.meta?.succession?.length === 1));
  public isAnalyst$ = this.userRoles$.pipe(map((roles) => roles?.includes(Role.Analyst)));
  public isAdvisor$ = this.userRoles$.pipe(map((roles) => roles?.includes(Role.Advisor)));
  public isAdvisorOrAnalyst$ = combineLatest([this.isAnalyst$, this.isAdvisor$]).pipe(
    map(([isAnalyst, isAdvisor]) => isAdvisor || isAnalyst),
  );

  private dialogRef: NbDialogRef<any>;

  constructor(private store: Store, private actions$: Actions, public dialog: NbDialogService) {}

  public handleCreate(templateRef: TemplateRef<any>): void {
    const {
      name,
      add,
      eap,
      ltd,
      std,
      life,
      healthcare_single,
      healthcare_single_parent,
      healthcare_couple,
      healthcare_family,
      dental_single,
      dental_single_parent,
      dental_couple,
      dental_family,
      dependent_life,
      critical_illness,
      critical_illness_single,
      life_max,
      std_max,
      ltd_max,
      critical_illness_max,
      life_volume_type,
      life_volume,
      critical_illness_volume_type,
      critical_illness_volume,
      virtual_care,
      standards,
      url_video,
    } = Rate.createAttributes();

    const {
      employer_contribution,
      overtime_earnings,
      probation_months,
      waiting_period,
      work_week_hours,
      std_employee_paid,
      ltd_employee_paid,
      workers_compensation,
      allocations,
    } = standards || PlanStandards.create();

    const form = new FormGroup({
      name: new FormControl(name),
      url_video: new FormControl(url_video),
      add: new FormControl(add),
      eap: new FormControl(eap),
      ltd: new FormControl(ltd),
      std: new FormControl(std),
      life: new FormControl(life),
      healthcare_single: new FormControl(healthcare_single),
      healthcare_single_parent: new FormControl(healthcare_single_parent),
      healthcare_couple: new FormControl(healthcare_couple),
      healthcare_family: new FormControl(healthcare_family),
      dental_single: new FormControl(dental_single),
      dental_single_parent: new FormControl(dental_single_parent),
      dental_couple: new FormControl(dental_couple),
      dental_family: new FormControl(dental_family),
      dependent_life: new FormControl(dependent_life),
      critical_illness: new FormControl(critical_illness),
      critical_illness_single: new FormControl(critical_illness_single),
      life_max: new FormControl(life_max),
      std_max: new FormControl(std_max),
      ltd_max: new FormControl(ltd_max),
      critical_illness_max: new FormControl(critical_illness_max),
      life_volume_type: new FormControl(life_volume_type),
      life_volume: new FormControl(life_volume, [Validators.max(1_000_000)]),
      critical_illness_volume_type: new FormControl(critical_illness_volume_type),
      critical_illness_volume: new FormControl(critical_illness_volume),
      virtual_care: new FormControl(virtual_care),
      standards: new FormGroup({
        allocations: new FormControl(allocations),
        employer_contribution: new FormGroup({
          life: new FormControl(employer_contribution.life, []),
          add: new FormControl(employer_contribution.add, []),
          dependentLife: new FormControl(employer_contribution.dependentLife, []),
          criticalIllness: new FormControl(employer_contribution.criticalIllness, []),
          ltd: new FormControl(employer_contribution.ltd, []),
          std: new FormControl(employer_contribution.std, []),
          healthcare: new FormControl(employer_contribution.healthcare, []),
          dentalcare: new FormControl(employer_contribution.dentalcare, []),
          virtualCare: new FormControl(employer_contribution.virtualCare, []),
        }),
        overtime_earnings: new FormControl(overtime_earnings),
        probation_months: new FormControl(probation_months),
        waiting_period: new FormControl(waiting_period),
        work_week_hours: new FormControl(work_week_hours),
        std_employee_paid: new FormControl(std_employee_paid),
        ltd_employee_paid: new FormControl(ltd_employee_paid),
        workers_compensation: new FormControl(workers_compensation),
      }),
    });

    this.dialogRef = this.dialog.open(templateRef, { context: { form }, hasScroll: false });
  }

  public handleSubmit(formValues: IRateAttributes, planId: string): void {
    const rate: IRate = {
      id: null,
      type: DataType.Rates,
      attributes: {
        ...formValues,
      },
    };

    this.actions$.pipe(ofActionSuccessful(CreateRate), take(1)).subscribe(() => this.dialogRef.close());

    this.store.dispatch(new CreateRate({ planId, rate }));
  }
}
