<div class="d-flex justify-content-center align-items-center pip-pagination">
  <div class="pip-pagination__size">
    <label for="pageSize" class="label">
      Items per page: &nbsp;
    </label>
    <nb-select id="pageSize" [ngModel]="pageSizeSelected" (ngModelChange)="onChange($event, true)">
      <nb-option *ngFor="let opt of pageSizeOpts" [value]="opt">{{opt}}</nb-option>
    </nb-select>
  </div>
  <pagination-controls
    id="pagination"
    responsive="true"
    nextLabel=""
    previousLabel=""
    (pageChange)="onChange($event)">
  </pagination-controls>
</div>
