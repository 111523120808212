import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { IMedia, MediaTemplateDictionary, MediaUploadDictionary } from '@app/data';

@Component({
  selector: 'pip-file-upload-form-group',
  templateUrl: './file-upload-form-group.component.html',
  styleUrls: ['./file-upload-form-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileUploadFormGroupComponent implements OnInit {
  @Input() public downloadTemplate: string;
  @Input() public existingMedia: IMedia | IMedia[];
  @Input() public fileTypeLabel: string;
  @Input() public formCtrlName: string;
  @Input() public isLoading: boolean;
  @Input() public mediaModel: string;
  @Input() public mediaType: string;
  @Input() public modelId: string;
  @Input() public requireExpiry: boolean;
  @Input() public warning: string;
  @Input() public required: boolean;
  @Input() public uploadLabelOverride: string;
  @Input() public downloadLabelOverride: string;

  @ViewChild('input', { static: true }) public fileInput: ElementRef<HTMLInputElement>;

  public expiry: Date;
  public templateDictionary = MediaTemplateDictionary;
  public uploadDictionary = MediaUploadDictionary;

  public get isArray(): boolean {
    return Array.isArray(this.existingMedia);
  }

  public get items(): IMedia[] {
    if (!this.existingMedia) {
      return;
    }

    return this.isArray ? (this.existingMedia as IMedia[]) : new Array(this.existingMedia as IMedia);
  }

  @Output() private upload = new EventEmitter<FormData>();
  @Output() private delete = new EventEmitter<string>();
  @Output() private announceWarning = new EventEmitter<void>();

  constructor() {}

  public ngOnInit(): void {
    this.expiry = this.requireExpiry
      ? this.existingMedia && !this.isArray && new Date((this.existingMedia as IMedia).attributes.expire_at)
      : null;
  }

  public onChange({ target }: { target: HTMLInputElement }) {
    const file: File = target.files[0];

    const formData = new FormData();

    formData.append('file', file);
    formData.append('type', this.mediaType);
    formData.append('model', this.mediaModel);
    formData.append('model_id', this.modelId);

    if (this.expiry) {
      formData.append('expire_at', this.expiry.toUTCString());
    }

    this.upload.emit(formData);
    target.value = null;
  }

  public deleteMedia(mediaId: string): void {
    this.delete.emit(mediaId);
  }

  public onClick(): void {
    if (!this.warning) {
      this.fileInput.nativeElement.click();
    } else {
      this.announceWarning.emit();
    }
  }
}
