<div class="row justify-content-center">
  <div class="col-xl-6">
    <h2>Sign Documents</h2>
    <nb-card status="basic">
      <nb-card-body>
        <pip-media-uploader
          *ngIf="(planMedia$ | async) && (plan$ | async)"
          [downloadTemplate]="(carrierMediaLinks$ | async)?.SecureOnlineAccess"
          [existingMedia]="(planMedia$ | async)[mediaType.SecureOnlineAccess]"
          [mediaType]="mediaType.SecureOnlineAccess"
          [modelId]="(plan$ | async).id"
          [mediaModel]="mediaModel.Plan"
        ></pip-media-uploader>

        <pip-media-uploader
          [downloadTemplate]="(carrierMediaLinks$ | async)?.MasterApplication"
          [existingMedia]="(planMedia$ | async)[mediaType.MasterApplication]"
          [mediaType]="mediaType.MasterApplication"
          [modelId]="(plan$ | async).id"
          [mediaModel]="mediaModel.Plan"
        ></pip-media-uploader>

        <pip-media-uploader
          [downloadTemplate]="(carrierMediaLinks$ | async)?.PayorAuthorization"
          [existingMedia]="(planMedia$ | async)[mediaType.PayorAuthorization]"
          [mediaType]="mediaType.PayorAuthorization"
          [modelId]="(plan$ | async).id"
          [mediaModel]="mediaModel.Plan"
        ></pip-media-uploader>

        <pip-media-uploader
          [downloadTemplate]="(carrierMediaLinks$ | async)?.SalesTaxForm"
          [existingMedia]="(planMedia$ | async)[mediaType.SalesTaxForm]"
          [mediaType]="mediaType.SalesTaxForm"
          [modelId]="(plan$ | async).id"
          [mediaModel]="mediaModel.Plan"
        ></pip-media-uploader>
      </nb-card-body>
      <nb-card-footer class="d-flex justify-content-between">
        <button nbButton status="primary" ghost type="button" (click)="onCancel()">Cancel</button>
        <ng-container *ngIf="(isSubmittable$ | async) && (plan$ | async) as plan; else Warning">
          <button nbButton status="primary" type="button" (click)="onUpdatePlan(plan)">Confirm</button>
        </ng-container>
        <ng-template #Warning>
          <nb-alert accent="primary" class="text-center mb-0">
            You must provide all documents above before you are able to confirm.
          </nb-alert>
        </ng-template>
      </nb-card-footer>
    </nb-card>
  </div>
</div>
