import { Pipe, PipeTransform } from '@angular/core';
import { plansStateDefaults, PlanStatus, PlanStatusDictionary } from '@app/data';

@Pipe({
  name: 'planStatus',
})
export class PlanStatusPipe implements PipeTransform {
  public transform(key: PlanStatus): string {
    if (!PlanStatusDictionary[key]) {
      console.warn(`PlanStatusDictionary doesn't contain:`, key);
      return key;
    }

    return PlanStatusDictionary[key];
  }
}
