<ng-container [formGroup]="controlContainer.control">
  <fieldset>
    <div class="form-group row">
      <label for="name" class="col col-form-label label">Class Name</label>
      <div class="col-sm-9">
        <input type="text" nbInput fullWidth id="name" formControlName="name" />
      </div>
    </div>
    <div class="form-group row">
      <label for="url_video" class="col col-form-label label">YouTube video ID</label>
      <div class="col-sm-9">
        <input type="text" nbInput fullWidth id="url_video" formControlName="url_video" />
      </div>
    </div>
  </fieldset>
  <div class="row">
    <div class="col">
      <!-- Life -->
      <fieldset>
        <legend class="subtitle">Life</legend>
        <div class="form-group row">
          <label for="life" class="col col-form-label label">Rate</label>
          <div class="col-sm-9">
            <input type="text" nbInput fullWidth id="life" formControlName="life" mask="separator" prefix="$" />
          </div>
        </div>
        <div class="form-group row">
          <label for="life_max" class="col col-form-label label">Max</label>
          <div class="col-sm-9">
            <input type="text" nbInput fullWidth id="life_max" formControlName="life_max" mask="separator" prefix="$" />
          </div>
        </div>
        <div class="form-group row">
          <label for="life_volume_type" class="col col-form-label label">Volume Type</label>
          <div class="col-sm-9">
            <nb-select
              fullWidth
              id="life_volume_type"
              formControlName="life_volume_type"
              (selectedChange)="onSelectChange('life_volume', $event)"
            >
              <nb-option *ngFor="let item of lifeVolumeTypes" [value]="item[0]">{{ item[1] }}</nb-option>
            </nb-select>
          </div>
        </div>
        <div class="form-group row">
          <label for="life_volume" class="col col-form-label label">Volume</label>
          <div class="col-sm-9">
            <input
              type="text"
              nbInput
              fullWidth
              id="life_volume"
              formControlName="life_volume"
              mask="separator"
              prefix="$"
            />
          </div>
        </div>
      </fieldset>
      <!-- AD&D -->
      <fieldset>
        <legend class="subtitle">AD&D</legend>
        <div class="form-group row">
          <label for="add" class="col col-form-label label">Rate</label>
          <div class="col-sm-9">
            <input type="text" nbInput fullWidth id="add" formControlName="add" mask="separator" prefix="$" />
          </div>
        </div>
      </fieldset>
      <!-- Dep. Life -->
      <fieldset>
        <legend class="subtitle">Dep. Life</legend>
        <div class="form-group row">
          <label for="dependent_life" class="col col-form-label label">Rate</label>
          <div class="col-sm-9">
            <input
              type="text"
              nbInput
              fullWidth
              id="dependent_life"
              formControlName="dependent_life"
              mask="separator"
              prefix="$"
            />
          </div>
        </div>
      </fieldset>
      <!-- Critical Illness -->
      <fieldset>
        <legend class="subtitle">Critical Illness</legend>
        <div class="form-group row">
          <label for="critical_illness" class="col col-form-label label">Rate Single</label>
          <div class="col-sm-9">
            <input
              type="text"
              nbInput
              fullWidth
              id="critical_illness_single"
              formControlName="critical_illness_single"
              mask="separator"
              prefix="$"
            />
          </div>
        </div>
        <div class="form-group row">
          <label for="critical_illness" class="col col-form-label label">Rate Family</label>
          <div class="col-sm-9">
            <input
              type="text"
              nbInput
              fullWidth
              id="critical_illness"
              formControlName="critical_illness"
              mask="separator"
              prefix="$"
            />
          </div>
        </div>

        <div class="form-group row">
          <label for="critical_illness_max" class="col col-form-label label">Max</label>
          <div class="col-sm-9">
            <input
              type="text"
              nbInput
              fullWidth
              id="critical_illness_max"
              formControlName="critical_illness_max"
              mask="separator"
              prefix="$"
            />
          </div>
        </div>
        <div class="form-group row">
          <label for="critical_illness_volume_type" class="col col-form-label label">Volume Type</label>
          <div class="col-sm-9">
            <nb-select
              fullWidth
              id="critical_illness_volume_type"
              formControlName="critical_illness_volume_type"
              (selectedChange)="onSelectChange('critical_illness_volume', $event)"
            >
              <nb-option value="SalaryX1">Salary x1</nb-option>
              <nb-option value="SalaryX2">Salary x2</nb-option>
              <nb-option value="SalaryX3">Salary x3</nb-option>
              <nb-option value="Fixed">Fixed</nb-option>
            </nb-select>
          </div>
        </div>
        <div class="form-group row">
          <label for="critical_illness_volume" class="col col-form-label label">Volume</label>
          <div class="col-sm-9">
            <input
              type="text"
              nbInput
              fullWidth
              id="critical_illness_volume"
              formControlName="critical_illness_volume"
              mask="separator"
              prefix="$"
            />
          </div>
        </div>
      </fieldset>
    </div>
    <div class="col">
      <!-- STD -->
      <fieldset>
        <legend class="subtitle">STD</legend>
        <div class="form-group row">
          <label for="std" class="col col-form-label label">Rate</label>
          <div class="col-sm-9">
            <input type="text" nbInput fullWidth id="std" formControlName="std" mask="separator" prefix="$" />
          </div>
        </div>
        <div class="form-group row">
          <label for="std_max" class="col col-form-label label">Max</label>
          <div class="col-sm-9">
            <input type="text" nbInput fullWidth id="std_max" formControlName="std_max" mask="separator" prefix="$" />
          </div>
        </div>
      </fieldset>
      <!-- LTD -->
      <fieldset>
        <legend class="subtitle">LTD</legend>
        <div class="form-group row">
          <label for="ltd" class="col col-form-label label">Rate</label>
          <div class="col-sm-9">
            <input type="text" nbInput fullWidth id="ltd" formControlName="ltd" mask="separator" prefix="$" />
          </div>
        </div>
        <div class="form-group row">
          <label for="ltd_max" class="col col-form-label label">Max</label>
          <div class="col-sm-9">
            <input type="text" nbInput fullWidth id="ltd_max" formControlName="ltd_max" mask="separator" prefix="$" />
          </div>
        </div>
      </fieldset>
      <!-- Health -->
      <fieldset>
        <legend class="subtitle">Health</legend>
        <div class="form-group row">
          <label for="healthcare_single" class="col col-form-label label">Single Rate</label>
          <div class="col-sm-9">
            <input
              type="text"
              nbInput
              fullWidth
              id="healthcare_single"
              formControlName="healthcare_single"
              mask="separator"
              prefix="$"
            />
          </div>
        </div>
        <div class="form-group row">
          <label for="healthcare_single" class="col col-form-label label">Single Parent Rate</label>
          <div class="col-sm-9">
            <input
              type="text"
              nbInput
              fullWidth
              id="healthcare_single_parent"
              formControlName="healthcare_single_parent"
              mask="separator"
              prefix="$"
            />
          </div>
        </div>
        <div class="form-group row">
          <label for="healthcare_single" class="col col-form-label label">Couple Rate</label>
          <div class="col-sm-9">
            <input
              type="text"
              nbInput
              fullWidth
              id="healthcare_couple"
              formControlName="healthcare_couple"
              mask="separator"
              prefix="$"
            />
          </div>
        </div>
        <div class="form-group row">
          <label for="healthcare_family" class="col col-form-label label">Family Rate</label>
          <div class="col-sm-9">
            <input
              type="text"
              nbInput
              fullWidth
              id="healthcare_family"
              formControlName="healthcare_family"
              mask="separator"
              prefix="$"
            />
          </div>
        </div>
      </fieldset>

      <!-- Dental -->
      <fieldset>
        <legend class="subtitle">Dental</legend>
        <div class="form-group row">
          <label for="dental_single" class="col col-form-label label">Single Rate</label>
          <div class="col-sm-9">
            <input
              type="text"
              nbInput
              fullWidth
              id="dental_single"
              formControlName="dental_single"
              mask="separator"
              prefix="$"
            />
          </div>
        </div>
        <div class="form-group row">
          <label for="dental_single" class="col col-form-label label">Single Parent Rate</label>
          <div class="col-sm-9">
            <input
              type="text"
              nbInput
              fullWidth
              id="dental_single_parent"
              formControlName="dental_single_parent"
              mask="separator"
              prefix="$"
            />
          </div>
        </div>
        <div class="form-group row">
          <label for="dental_single" class="col col-form-label label">Couple Rate</label>
          <div class="col-sm-9">
            <input
              type="text"
              nbInput
              fullWidth
              id="dental_couple"
              formControlName="dental_couple"
              mask="separator"
              prefix="$"
            />
          </div>
        </div>
        <div class="form-group row">
          <label for="dental_family" class="col col-form-label label">Family Rate</label>
          <div class="col-sm-9">
            <input
              type="text"
              nbInput
              fullWidth
              id="dental_family"
              formControlName="dental_family"
              mask="separator"
              prefix="$"
            />
          </div>
        </div>
      </fieldset>
      <!-- EAP -->
      <fieldset>
        <legend class="subtitle">EAP</legend>
        <div class="form-group row">
          <label for="eap" class="col col-form-label label">Rate</label>
          <div class="col-sm-9">
            <input type="text" nbInput fullWidth id="eap" formControlName="eap" mask="separator" prefix="$" />
          </div>
        </div>
      </fieldset>
      <!-- Virtual Care -->
      <fieldset>
        <legend class="subtitle">Virtual Care</legend>
        <div class="form-group row">
          <label for="virtual_care" class="col col-form-label label">Rate</label>
          <div class="col-sm-9">
            <input
              type="text"
              nbInput
              fullWidth
              id="virtual_care"
              formControlName="virtual_care"
              mask="separator"
              prefix="$"
            />
          </div>
        </div>
      </fieldset>
    </div>
  </div>

  <ng-container>
    <nb-accordion>
      <nb-accordion-item>
        <nb-accordion-item-header>Rate Standards (Optional)</nb-accordion-item-header>
        <nb-accordion-item-body>
          <fieldset formGroupName="standards">
            <legend class="subtitle">Employees</legend>
            <div class="form-group row">
              <label for="work_week_hours" class="col col-form-label label">
                How many hours must an employee work each week to qualify for benefits?
              </label>
              <div class="col-sm-6">
                <input type="number" nbInput fullWidth id="work_week_hours" formControlName="work_week_hours" />
              </div>
            </div>
            <div class="form-group row">
              <label for="probation_months" class="col col-form-label label">
                How many months must an employee work to qualify for benefits? <small>(ie. waiting period)</small>
              </label>
              <div class="col-sm-6">
                <input type="number" nbInput fullWidth id="probation_months" formControlName="probation_months" />
              </div>
            </div>
            <div class="form-group row">
              <label for="waiting_period" class="col col-form-label label"
                >Apply waiting period to existing employees?</label
              >
              <div class="col-sm-6">
                <nb-radio-group class="d-flex" formControlName="waiting_period" name="waiting_period">
                  <nb-radio [value]="true">Yes</nb-radio>
                  <nb-radio [value]="false">No</nb-radio>
                </nb-radio-group>
              </div>
            </div>

            <div class="form-group row">
              <label for="overtime_earnings" class="col col-form-label label"
                >Are overtime and bonuses included in the employee's earnings?</label
              >
              <div class="col-sm-6">
                <nb-radio-group class="d-flex" formControlName="overtime_earnings" name="overtime_earnings">
                  <nb-radio [value]="true">Yes</nb-radio>
                  <nb-radio [value]="false">No</nb-radio>
                </nb-radio-group>
              </div>
            </div>
            <div class="form-group row" *ngIf="plan.attributes.ltd">
              <label for="ltd_employee_paid" class="col col-form-label label"
                >Is long term disability 100% employee paid?</label
              >
              <div class="col-sm-6">
                <nb-radio-group class="d-flex" formControlName="ltd_employee_paid" name="ltd_employee_paid">
                  <nb-radio [value]="true">Yes</nb-radio>
                  <nb-radio [value]="false">No</nb-radio>
                </nb-radio-group>
              </div>
            </div>
            <div class="form-group row" *ngIf="plan.attributes.std">
              <label for="std_employee_paid" class="col col-form-label label"
                >Is short term disability 100% employee paid?</label
              >
              <div class="col-sm-6">
                <nb-radio-group class="d-flex" formControlName="std_employee_paid" name="std_employee_paid">
                  <nb-radio [value]="true">Yes</nb-radio>
                  <nb-radio [value]="false">No</nb-radio>
                </nb-radio-group>
              </div>
            </div>
            <div class="form-group row" *ngIf="plan.attributes.ltd || plan.attributes.std">
              <label for="workers_compensation" class="col col-form-label label">
                Are employees covered by Worker's Compensation or a similar plan?
              </label>
              <div class="col-sm-6">
                <nb-radio-group class="d-flex" formControlName="workers_compensation" name="workers_compensation">
                  <nb-radio [value]="true">Yes</nb-radio>
                  <nb-radio [value]="false">No</nb-radio>
                </nb-radio-group>
              </div>
            </div>
            <div class="form-group row">
              <label for="workers_compensation" class="col col-form-label label">
                For what duration of time will benefits be continued for a plan member who is temporarily laid off?
              </label>
              <div class="col-sm-6">
                <nb-select formControlName="benefits_continuation">
                  <nb-option [value]="null">Select</nb-option>
                  <nb-option *ngFor="let item of benefitsContinuations" [value]="item.value">{{
                    item.label
                  }}</nb-option>
                </nb-select>
              </div>
            </div>
          </fieldset>
          <fieldset>
            <legend class="subtitle">Contributions</legend>
            <pip-contributions-formgroup [formGroup]="employerContributionsGroup"></pip-contributions-formgroup>
          </fieldset>
        </nb-accordion-item-body>
      </nb-accordion-item>
    </nb-accordion>
  </ng-container>
</ng-container>
