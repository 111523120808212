<div class="d-flex align-items-center">
  <h2>Plan Standards</h2>
  <button *ngIf="canEdit" nbButton status="primary" size="tiny" outline class="ml-2" (click)="handleEdit(EditDialog)">
    Edit
  </button>
</div>

<div>
  <div class="form-group row">
    <dt class="col col-form-label label">How many hours must an employee work each week to quality for benefits?</dt>
    <dd class="col-5">{{ planStandards?.work_week_hours }}</dd>
  </div>
  <div class="form-group row">
    <dt class="col col-form-label label">How many months must an employee work to quality for benefits?</dt>
    <dd class="col-5">{{ planStandards?.probation_months }}</dd>
  </div>
  <div class="form-group row">
    <dt class="col col-form-label label">Apply waiting period to existing employees?</dt>
    <dd class="col-5">{{ planStandards?.waiting_period | truthyStatus }}</dd>
  </div>
  <div class="form-group row">
    <dt class="col col-form-label label">Are overtime and bonuses included in the employee's earnings?</dt>
    <dd class="col-5">{{ planStandards?.overtime_earnings | truthyStatus }}</dd>
  </div>
  <div class="form-group row" *ngIf="plan.attributes.ltd">
    <dt class="col col-form-label label">Is long term disability 100% employee paid?</dt>
    <dd class="col-5">{{ planStandards?.ltd_employee_paid | truthyStatus }}</dd>
  </div>
  <div class="form-group row" *ngIf="plan.attributes.std">
    <dt class="col col-form-label label">Is short term disability 100% employee paid?</dt>
    <dd class="col-5">{{ planStandards?.std_employee_paid | truthyStatus }}</dd>
  </div>
  <div class="form-group row" *ngIf="plan.attributes.ltd || plan.attributes.std">
    <dt class="col col-form-label label">Are employees covered by Worker's Compensation or a similar plan?</dt>
    <dd class="col-5">{{ planStandards?.workers_compensation | truthyStatus }}</dd>
  </div>
  <div class="form-group row">
    <dt class="col col-form-label label">
      For what duration of time will benefits be continued for a plan member who is temporarily laid off?
    </dt>
    <dd class="col-5">{{ planStandards?.benefits_continuation }}</dd>
  </div>
  <div class="form-group row">
    <dt class="col col-form-label label">Pay cycle</dt>
    <dd class="col-5">{{ planStandards?.pay_cycle }}</dd>
  </div>
  <div>
    <dt class="">Employer's contribution</dt>

    <div class="form-group row">
      <dt class="col col-form-label label">Allocate benefit costs in defined order</dt>
      <dd class="col-5">{{ planStandards?.allocations | truthyStatus }}</dd>
    </div>
    <dd class="form-group row">
      <div class="col-2 col-form-label label">Life</div>
      <div class="col-2">{{ planStandards?.employer_contribution.life }}%</div>
      <div class="col-2 col-form-label label">AD&D</div>
      <div class="col-2">{{ planStandards?.employer_contribution.add }}%</div>
      <div class="col-2 col-form-label label">Dependent Life</div>
      <div class="col-2">{{ planStandards?.employer_contribution.dependentLife }}%</div>
      <div class="col-2 col-form-label label">Critical Illness</div>
      <div class="col-2">{{ planStandards?.employer_contribution.criticalIllness }}%</div>
      <div class="col-2 col-form-label label">LTD</div>
      <div class="col-2">{{ planStandards?.employer_contribution.ltd }}%</div>
      <div class="col-2 col-form-label label">STD</div>
      <div class="col-2">{{ planStandards?.employer_contribution.std }}%</div>
      <div class="col-2 col-form-label label">Healthcare</div>
      <div class="col-2">{{ planStandards?.employer_contribution.healthcare }}%</div>
      <div class="col-2 col-form-label label">Dentalcare</div>
      <div class="col-2">{{ planStandards?.employer_contribution.dentalcare }}%</div>
      <div class="col-2 col-form-label label">Virtual Care</div>
      <div class="col-2">{{ planStandards?.employer_contribution.virtualCare }}%</div>
    </dd>
  </div>
</div>

<!--  -->
<ng-template #EditDialog let-data let-ref="dialogRef">
  <form class="d-flex" [formGroup]="data.form" (ngSubmit)="handleSubmit(data.form.value)">
    <nb-card status="basic">
      <nb-card-body>
        <pip-plan-standards-form-group [plan]="plan" [formGroup]="data.form"></pip-plan-standards-form-group>
      </nb-card-body>
      <nb-card-footer class="d-flex justify-content-between">
        <button nbButton status="primary" ghost type="button" (click)="ref.close()">Close</button>
        <button nbButton status="primary" type="submit">Save</button>
      </nb-card-footer>
    </nb-card>
  </form>
</ng-template>
