<div class="d-flex align-items-center justify-content-end">
  <button *ngIf="allowEdit" nbButton status="primary" size="tiny" outline class="ml-2" (click)="handleEdit(EditDialog)">
    Edit
  </button>

  <button
    *ngIf="allowEdit"
    nbButton
    status="danger"
    size="tiny"
    outline
    class="ml-2"
    (click)="handleDelete(DeleteDialog)"
  >
    Delete
  </button>
</div>

<dl class="form-row">
  <div class="form-group col">
    <dt for="transit" class="label">Transit number</dt>
    <dd>{{ paymentInfo.attributes.transit }}</dd>
  </div>
  <div class="form-group col">
    <dt for="transit" class="label">Insitution number</dt>
    <dd>{{ paymentInfo.attributes.institution_id }}</dd>
  </div>
  <div class="form-group col">
    <dt for="transit" class="label">Bank account number</dt>
    <dd>{{ paymentInfo.attributes.account_number }}</dd>
  </div>
</dl>

<div class="d-flex align-items-start">
  <pip-media-uploader
    [existingMedia]="paymentInfo.media[mediaType.VoidCheque]"
    [mediaType]="mediaType.VoidCheque"
    [modelId]="paymentInfo.id"
    [mediaModel]="mediaModel.PaymentInfo"
  ></pip-media-uploader>
</div>

<ng-template #EditDialog let-data let-ref="dialogRef">
  <form class="d-flex" [formGroup]="data.form" (ngSubmit)="onEdit(data.form.value)">
    <nb-card status="basic">
      <nb-card-body>
        <pip-payment-information-form-group [plan]="plan" [formGroup]="data.form"></pip-payment-information-form-group>
      </nb-card-body>
      <nb-card-footer class="d-flex justify-content-between">
        <button nbButton status="primary" ghost type="button" (click)="ref.close()">Close</button>
        <button nbButton status="primary" type="submit">Save</button>
      </nb-card-footer>
    </nb-card>
  </form>
</ng-template>

<ng-template #DeleteDialog let-data let-ref="dialogRef">
  <nb-card status="basic">
    <nb-card-body> Are you sure you want to delete this payment info?</nb-card-body>
    <nb-card-footer class="d-flex justify-content-between">
      <button nbButton status="primary" ghost type="button" (click)="ref.close(false)">Close</button>
      <button nbButton status="primary" type="submit" (click)="ref.close(true)">Delete</button>
    </nb-card-footer>
  </nb-card>
</ng-template>
