<ng-container *ngIf="organization; else companySearch">
  <pip-organization-info-form
    [plan]="null"
    [quoteType]="'newQuote'"
    [activeUser]="activeUser"
    [isAnalyst]="isAnalyst"
    [isGroupRep]="isGroupRep"
    [existingOrganization]="organization"
    (save)="onCreatePlan($event)"
  ></pip-organization-info-form>
</ng-container>

<ng-template #companySearch>
  <form [formGroup]="form">
    <nb-card>
      <nb-card-body>
        <fieldset>
          <legend class="subtitle">Organization</legend>
          <div class="form-group">
            <label for="organization_name" class="label">Organization name</label>
            <div class="">
              <input
                type="text"
                nbInput
                fullWidth
                id="organization_name"
                placeholder="ACME Inc."
                formControlName="organization_name"
              />
            </div>
          </div>
        </fieldset>
        <ng-container *ngIf="(companies$ | async)?.length">
          <fieldset>
            <legend class="subtitle">We've found similar organizations...</legend>
            <nb-list>
              <nb-list-item
                *ngFor="let company of companies$ | async"
                nbTooltip="This company is currently active"
                nbTooltipStatus="danger"
                [nbTooltipDisabled]="!company.attributes.is_active"
              >
                <button [disabled]="company.attributes.is_active" nbButton size="small" (click)="setCompany(company)">
                  Use
                </button>
                <span class="ml-3">{{ company.attributes.name }}</span>
              </nb-list-item>
            </nb-list>
          </fieldset>
          <fieldset>
            <legend class="subtitle">Or you can use the name provided...</legend>
            <nb-list>
              <nb-list-item>
                <button
                  nbButton
                  size="small"
                  (click)="setCompany({ attributes: { name: form.value.organization_name }, id: null })"
                >
                  Use
                </button>
                <span class="ml-3">{{ form.value.organization_name }}</span>
              </nb-list-item>
            </nb-list>
          </fieldset>
        </ng-container>
        <ng-container #noMatches *ngIf="(companies$ | async)?.length === 0 && form.value.organization_name.length">
          <fieldset>
            <legend class="subtitle">This organization name is available</legend>
            <nb-list>
              <nb-list-item>
                <button
                  nbButton
                  size="small"
                  (click)="setCompany({ attributes: { name: form.value.organization_name }, id: null })"
                >
                  Use
                </button>
                <span class="ml-3">{{ form.value.organization_name }}</span>
              </nb-list-item>
            </nb-list>
          </fieldset>
        </ng-container>
      </nb-card-body>
    </nb-card>
  </form>
</ng-template>
