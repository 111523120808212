<div class="row" *ngIf="controlContainer.control">
  <div class="col">
    <nb-checkbox status="basic" [formControl]="allocationsCtrl">Optimize employee deductions</nb-checkbox>
  </div>
</div>
<div class="row" *ngIf="showOptimizedValue; else Contributions">
  <label for="contribution" class="col col-form-label label"
    >Of the total cost, what percentage represents the employer's contribution?</label
  >
  <div class="col-sm-6">
    <nb-form-field>
      <input
        type="text"
        nbInput
        fullWidth
        id="contribution"
        mask="separator"
        suffix="%"
        [formControl]="contributionValue"
      />
    </nb-form-field>
  </div>
</div>

<ng-template #Contributions>
  <fieldset class="p-0 mt-2" [formGroup]="controlContainer.control">
    <legend class="subtitle">Set employer contributions per line item</legend>
    <div class="form-group row">
      <label for="life" class="col col-form-label label">Life</label>
      <div class="col-sm-6">
        <input type="text" nbInput fullWidth id="life" formControlName="life" mask="separator" suffix="%" />
      </div>
    </div>
    <div class="form-group row">
      <label for="add" class="col col-form-label label">AD&D</label>
      <div class="col-sm-6">
        <input type="text" nbInput fullWidth id="add" formControlName="add" mask="separator" suffix="%" />
      </div>
    </div>
    <div class="form-group row">
      <label for="dependentLife" class="col col-form-label label">Dependent Life</label>
      <div class="col-sm-6">
        <input
          type="text"
          nbInput
          fullWidth
          id="dependentLife"
          formControlName="dependentLife"
          mask="separator"
          suffix="%"
        />
      </div>
    </div>
    <div class="form-group row">
      <label for="criticalIllness" class="col col-form-label label">Critical Illness</label>
      <div class="col-sm-6">
        <input
          type="text"
          nbInput
          fullWidth
          id="criticalIllness"
          formControlName="criticalIllness"
          mask="separator"
          suffix="%"
        />
      </div>
    </div>
    <div class="form-group row">
      <label for="ltd" class="col col-form-label label">LTD</label>
      <div class="col-sm-6">
        <input type="text" nbInput fullWidth id="ltd" formControlName="ltd" mask="separator" suffix="%" />
      </div>
    </div>

    <div class="form-group row">
      <label for="std" class="col col-form-label label">STD</label>
      <div class="col-sm-6">
        <input type="text" nbInput fullWidth id="std" formControlName="std" mask="separator" suffix="%" />
      </div>
    </div>
    <div class="form-group row">
      <label for="healthcare" class="col col-form-label label">Healthcare</label>
      <div class="col-sm-6">
        <input type="text" nbInput fullWidth id="healthcare" formControlName="healthcare" mask="separator" suffix="%" />
      </div>
    </div>

    <div class="form-group row">
      <label for="dentalcare" class="col col-form-label label">Dentalcare</label>
      <div class="col-sm-6">
        <input type="text" nbInput fullWidth id="dentalcare" formControlName="dentalcare" mask="separator" suffix="%" />
      </div>
    </div>
    <div class="form-group row">
      <label for="virtualCare" class="col col-form-label label">Virtual Care</label>
      <div class="col-sm-6">
        <input
          type="text"
          nbInput
          fullWidth
          id="virtualCare"
          formControlName="virtualCare"
          mask="separator"
          suffix="%"
        />
      </div>
    </div>
  </fieldset>
</ng-template>
