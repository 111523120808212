<form [formGroup]="form" (submit)="onSubmit()">
  <nb-card>
    <nb-card-body>
      <fieldset *ngIf="showEIPremiumReductionMessage">
        <div class="border p-3 rounded mb-3">
          Your group may qualify for the EI premium reduction program. Please visit this
          <a
            href="https://www.canada.ca/en/employment-social-development/programs/ei/ei-list/ei-employers/premium-reduction-program.html"
          >site</a
          >
          to find out more and download the application form.
        </div>
      </fieldset>
      <fieldset>
        <legend class="subtitle">Quote</legend>
        <div class="form-group row">
          <p for="deviations" class="col col-form-label label">Review the selected quote</p>

          <div class="col-sm-9">
            <a nbButton status="primary" outline [href]="quoteDownload" download="download">
              <nb-icon icon="cloud-download-outline"></nb-icon>
              Download selected quote
            </a>
          </div>
        </div>
      </fieldset>
      <fieldset>
        <legend class="subtitle">Final Changes</legend>
        <div class="form-group row">
          <label for="deviations" class="col col-form-label label"
            >Please enter any requested final changes to the plan:</label
          >
          <div class="col-sm-9">
            <textarea nbInput fullWidth id="deviations" formControlName="deviations"></textarea>
          </div>
        </div>
      </fieldset>
    </nb-card-body>
    <nb-card-footer class="d-flex justify-content-between">
      <button nbButton status="primary" ghost type="button" (click)="cancel.emit()">{{ cancelButton }}</button>
      <button nbButton status="primary" type="submit" [disabled]="!form.valid">{{ confirmButton }}</button>
    </nb-card-footer>
  </nb-card>
</form>
