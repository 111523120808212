import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { IFieldStatus } from '@app/data';

export type Task = {
  label: string;
  routerLink: string[];
  fieldStatus: Observable<IFieldStatus>;
};

@Component({
  selector: 'pip-tasks-table',
  templateUrl: './tasks-table.component.html',
  styleUrls: ['./tasks-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TasksTableComponent implements OnInit {
  @Input() public tasks: Task[];
  @Input() public property = 'complete';

  constructor() {}

  ngOnInit() {}
}
