<div class="d-flex align-items-center">
  <h2>Business Address</h2>
  <button nbButton status="primary" size="tiny" outline class="ml-2" (click)="handleEdit(EditDialog)">Edit</button>
</div>
<div>
  <span class="d-block">{{ companyInfo?.address1 }}</span>
  <span class="d-block">{{ companyInfo?.address2 }}</span>
  <span class="d-block">{{ companyInfo?.city }}, {{ companyInfo?.state }} {{ companyInfo?.country }}</span>
  <span class="d-block">{{ companyInfo?.postal_code }}</span>
</div>

<!--  -->
<ng-template #EditDialog let-data let-ref="dialogRef">
  <form [formGroup]="data.form" (ngSubmit)="handleSubmit(data.form.value)">
    <nb-card status="basic">
      <nb-card-body>
        <pip-business-address-formgroup [formGroup]="data.form"></pip-business-address-formgroup>
      </nb-card-body>
      <nb-card-footer class="d-flex justify-content-between">
        <button nbButton status="primary" ghost type="button" (click)="ref.close()">Close</button>
        <button nbButton status="primary" type="submit">Save</button>
      </nb-card-footer>
    </nb-card>
  </form>
</ng-template>
