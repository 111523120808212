import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ControlContainer, FormArrayName } from '@angular/forms';
import { IDefaultPlan } from '@app/data';

@Component({
  selector: 'pip-pick-a-plan-table',
  templateUrl: './pick-a-plan-table.component.html',
  styleUrls: ['./pick-a-plan-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PickAPlanTableComponent implements OnInit {
  @Input() public defaultPlans: IDefaultPlan[];
  @Input() public isForm = true;

  public get formArray(): FormArrayName {
    return this.controlContainer as FormArrayName;
  }

  constructor(public controlContainer: ControlContainer) {}

  public ngOnInit(): void {}
}
