import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AuthPaths } from '@app/auth';
import { DashboardPaths } from '@app/core/dashboard';
import { ICarrier, IPlan, IProfile, PlansState, QuotesState, UpdatePlan, UsersState } from '@app/data';
import { Navigate } from '@ngxs/router-plugin';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { PlansPaths } from '../../plans.paths';

@Component({
  selector: 'pip-pa-task-list',
  templateUrl: './pa-task-list.component.html',
  styleUrls: ['./pa-task-list.component.scss'],
})
export class PATaskListComponent implements OnInit {
  public plan$: Observable<IPlan> = this.store.select(PlansState.activePlan);
  public profile$: Observable<IProfile> = this.store.select(UsersState.activeUserProfile);
  public carrier$: Observable<ICarrier> = this.store.select(QuotesState.selectedCarrier);

  public isSubmittable$ = this.plan$.pipe(
    map(p => p && p.meta && p.meta.field_statuses && Object.values(p.meta.field_statuses).every(fs => fs.confirmed)),
  );

  public tasks$ = this.plan$.pipe(
    filter(plan=> !!plan),
    map(plan => {
      const tasks = [
        {
          label: 'Confirm Start Date',
          routerLink: ['./', PlansPaths.StartDate],
          fieldStatus: plan?.meta?.field_statuses.start_at,
        },
        {
          label: 'Confirm Company Info',
          routerLink: ['./', PlansPaths.CompanyInfo],
          fieldStatus: plan?.meta?.field_statuses.organization_info,
        },
        {
          label: 'Review Contact Info',
          routerLink: ['./', PlansPaths.Administration],
          fieldStatus: plan?.meta?.field_statuses.administrator,
        },
        {
          label: 'Confirm Plan Design',
          routerLink: ['./', PlansPaths.Benefits],
          fieldStatus: plan?.meta?.field_statuses.insurance_info,
        },
        {
          label: 'Set Standards',
          routerLink: ['./', PlansPaths.Standards],
          fieldStatus: plan?.meta?.field_statuses.standards,
        },
        {
          label: 'Payment Info',
          routerLink: ['./', PlansPaths.PaymentInfo],
          fieldStatus: plan?.meta?.field_statuses.payment_info,
        },
        {
          label: 'Enroll Employees',
          routerLink: ['./', PlansPaths.Employees],
          fieldStatus: plan?.meta?.field_statuses.employees,
        },
      ];

      return tasks;
    }),
  );

  public form: FormGroup;

  public routerLinks = {
    login: ['/', AuthPaths.Login],
  };

  constructor(private store: Store, private actions$: Actions) {}

  public ngOnInit(): void {}

  public onSubmit(plan: IPlan): void {
    this.actions$
      .pipe(ofActionSuccessful(UpdatePlan), take(1))
      .subscribe(() => this.store.dispatch(new Navigate([DashboardPaths.ROOT])));

    this.store.dispatch(new UpdatePlan(plan));
  }
}
