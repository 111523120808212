import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { IFieldStatus } from '@app/data';

@Component({
  selector: 'pip-field-status-alert',
  templateUrl: './field-status-alert.component.html',
  styleUrls: ['./field-status-alert.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FieldStatusAlertComponent implements OnInit {
  @Input() public fieldStatus: IFieldStatus;
  @Input() public key: keyof Pick<IFieldStatus, 'complete' | 'confirmed'> = 'complete';
  @Input() public showIfComplete = false;
  @Input() public message: string;

  public get isShown(): boolean {
    if (!this.fieldStatus) {
      return false;
    }

    return (this.showIfComplete && this.fieldStatus[this.key]) || !!this.fieldStatus.message;
  }

  constructor() {}

  public ngOnInit(): void {}
}
