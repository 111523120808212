import { Pipe, PipeTransform } from '@angular/core';
import { IFieldStatus, IFieldStatuses } from '@app/data';

@Pipe({
  name: 'fieldStatus',
})
export class FieldStatusPipe implements PipeTransform {
  transform(fieldStatuses: IFieldStatuses): 'success' | 'warning' {
    if (Object.values(fieldStatuses).some((field: IFieldStatus) => field.complete === false)) {
      return 'warning';
    }

    return 'success';
  }
}
