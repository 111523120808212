import { Injectable } from '@angular/core';
import { PaymentInfosService } from '@app/data/services';
import * as Utils from '@app/utils';
import { NbToastrService } from '@nebular/theme';
import { Action, State, StateContext } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { tap } from 'rxjs/operators';
import { DataType } from '../../enums';
import * as Data from '../../models';
import { IPaymentInformation, IPlan } from '../../models';
import { IncludeEmployees } from '../employees';
import { GetPlan, IncludePlans } from '../plans/plans.actions';
import {
  CreatePaymentInfo,
  CreatePaymentInfoSuccess,
  DeletePaymentInfo,
  GetPaymentInfo,
  IncludePaymentInfos,
  UpdatePaymentInfo,
} from './payment-info.actions';

export interface PaymentInfosStateModel {
  entities: EntityMap<Data.IPaymentInformation>;
}

export const paymentInfosStateDefaults: PaymentInfosStateModel = {
  entities: {},
};

@State<PaymentInfosStateModel>({
  name: 'paymentInfos',
  defaults: paymentInfosStateDefaults,
})
@Injectable()
export class PaymentInfosState {
  constructor(public paymentInfosService: PaymentInfosService, public toastr: NbToastrService) {}

  @Action(IncludePaymentInfos)
  public includeUsers(ctx: StateContext<PaymentInfosStateModel>, action: IncludePaymentInfos) {
    const paymentInfos = action.payload.map(this.paymentInfosService.transformIncomingPaymentInfo);

    ctx.setState(
      patch<PaymentInfosStateModel>({
        entities: patch(Utils.createEntities(paymentInfos, 'id')),
      }),
    );
  }

  @Action(GetPaymentInfo)
  public async getPaymentInfo(ctx: StateContext<PaymentInfosStateModel>, action: GetPaymentInfo) {
    const paymentInfo = await this.paymentInfosService.getPaymentInfo(action.payload.paymentInfoId).toPromise();
    const state = ctx.getState();

    ctx.setState({
      ...state,
      entities: { ...state.entities, ...Utils.createEntities([paymentInfo.data], 'id') },
    });

    const plans: IPlan[] = Array.isArray(paymentInfo.included)
      ? (paymentInfo.included.filter((i) => i.type === DataType.Plans) as IPlan[])
      : [];

    ctx.dispatch(new IncludePlans(plans));
  }

  @Action(CreatePaymentInfo)
  public createPaymentInfo(ctx: StateContext<PaymentInfosStateModel>, action: CreatePaymentInfo) {
    return this.paymentInfosService.createPaymentInfo(action.payload.paymentInfo, action.payload.planId).pipe(
      tap((res) => {
        const paymentInfo = res.data;

        ctx.setState(
          patch<PaymentInfosStateModel>({
            entities: patch({ [paymentInfo.id]: paymentInfo }),
          }),
        );

        ctx.dispatch([new CreatePaymentInfoSuccess(paymentInfo), new GetPlan({ planId: action.payload.planId })]);
      }),
    );
  }

  @Action(UpdatePaymentInfo)
  public updatePaymentInfo(ctx: StateContext<PaymentInfosStateModel>, action: UpdatePaymentInfo) {
    return this.paymentInfosService.updatePaymentInfo(action.payload).pipe(
      tap((res) => {
        const plan = res.data;
        const paymentInfos = res.included.filter(
          (included) => included.type === DataType.PaymentInformation,
        ) as IPaymentInformation[];

        ctx.dispatch(new IncludePlans([plan]));
        ctx.dispatch(new IncludePaymentInfos(paymentInfos));

        this.toastr.success(`${plan.attributes.name} was updated.`, 'Update successful');
      }),
    );
  }

  @Action(DeletePaymentInfo)
  public deletePaymentInfo(ctx: StateContext<PaymentInfosStateModel>, action: DeletePaymentInfo) {
    const id = action.payload;
    const rate = ctx.getState().entities[id];

    return this.paymentInfosService.deletePaymentInfo(id).pipe(
      tap((res) => {
        this.toastr.success(`${rate.attributes.name} was deleted.`, 'Delete successful');

        const { [id]: removed, ...entities } = ctx.getState().entities;

        ctx.patchState({
          entities,
        });
      }),
    );
  }
}
