import * as Data from '../../models';
import { EmployeeConfirmation } from '@app/data/enums/employee-confirmation.enum';

export class GetUserEmployees {
  public static readonly type = '[Employees] Get User Employees';
  constructor(public payload: { userId: string }) {}
}

export class GetAuthUserEmployees {
  public static readonly type = '[Employees] Get Auth User Employees';
  constructor() {}
}

export class GetUserEmployeesSuccess {
  public static readonly type = '[Employees] Get User Employees Success';
  constructor() {}
}

export class IncludeEmployees {
  public static readonly type = '[Employees] Include Employees';
  constructor(public payload: Data.IEmployee[]) {}
}

export class GetEmployee {
  public static readonly type = '[Employees] Get Employee';
  constructor(public payload: { employeeId: string; updatePlan?: boolean }) {}
}

export class CreateEmployee {
  public static readonly type = '[Employees] Create Employee';
  constructor(public payload: { employee: Data.IEmployee; planId: string }) {}
}

export class CreateEmployeeSuccess {
  public static readonly type = '[Employees] Create Employee Success';
  constructor(public payload: { id: string }) {}
}

export class UpdateEmployee {
  public static readonly type = '[Employees] Update Employee';
  constructor(public payload: Data.IEmployee) {}
}

export class DeleteEmployee {
  public static readonly type = '[Employees] Delete Employee';
  constructor(public payload: string) {}
}

export class EmployeeConfirmations {
  public static readonly type = '[Employees] Employee Confirmations';
  constructor(public payload: { employeeId: string; confirmation: EmployeeConfirmation }) {}
}
