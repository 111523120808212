import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { IPlan, ISummaries, Plan } from '@app/data';
import { ISummary } from '@app/data/models/summary';

@Component({
  selector: 'pip-quote-summary-selection-form',
  templateUrl: './quote-summary-selection-form.component.html',
  styleUrls: ['./quote-summary-selection-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuoteSummarySelectionFormComponent implements OnInit {
  @Input() public plan: IPlan;
  @Input() public summaries: ISummaries;
  @Input() public loading: boolean;

  @Output() public addDocument = new EventEmitter<IPlan>();
  @Output() public deleteMedia = new EventEmitter<string>();

  public form: FormGroup;

  public get hasSummaries(): boolean {
    return this.summaries && Object.keys(this.summaries).length > 0;
  }

  @Output() private save = new EventEmitter<IPlan>();

  constructor() {}

  public ngOnInit(): void {
    this.initForm();
  }

  public onSubmit(): void {
    const { id, type } = this.plan;
    const payload: IPlan = { id, type, attributes: { ...this.form.value } };

    this.save.emit(payload);
  }

  public summaryQuotes(summaries: ISummary[]): ISummary[] {
    return summaries.filter(s => s.is_quote);
  }

  public summaryDocs(summaries: ISummary[]): ISummary[] {
    return summaries.filter(s => s.is_quote === false);
  }

  private initForm(): void {
    const { option_selected } = this.plan.attributes || Plan.createAttributes();

    this.form = new FormGroup({
      option_selected: new FormControl(option_selected),
    });
  }
}
