import { MediaType } from '../enums';
import { IMedia } from './media';

export interface IPlanMedia {
  [MediaType.AdditionalDocument]: IMedia[];
  [MediaType.BenefitsBooklet]: IMedia[];
  [MediaType.BenefitsTeam]: IMedia;
  [MediaType.Census]: IMedia;
  [MediaType.Contract]: IMedia[];
  [MediaType.EP3]: IMedia;
  [MediaType.FinalBill]: IMedia;
  [MediaType.MasterApplication]: IMedia;
  [MediaType.Miscellaneous]: IMedia[];
  [MediaType.PlanOther]: IMedia[];
  [MediaType.PayorAuthorization]: IMedia;
  [MediaType.PlanHelp]: IMedia;
  [MediaType.Proposal]: IMedia;
  [MediaType.Quote]: IMedia[];
  [MediaType.Renewal]: IMedia[];
  [MediaType.SalesTaxForm]: IMedia;
  [MediaType.SecureOnlineAccess]: IMedia;
  [MediaType.TerminationLetter]: IMedia;
  [MediaType.VoidCheque]: IMedia;
}
