import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { Observable } from 'rxjs';
import * as Data from '../models';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class ProfilesService {
  constructor(private api: ApiService) {}

  public getProfile(profileId: string): Observable<Data.Response<Data.IProfile>> {
    const params: Params = { include: 'media' };

    return this.api.get(['profiles', profileId], { params });
  }

  public updateProfile(data: Partial<Data.IProfile>): Observable<Data.Response<Data.IProfile>> {
    return this.api.patch(['profiles', data.id], data);
  }
}
