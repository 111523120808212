import { Injectable } from '@angular/core';
import { DataType } from '@app/data/enums';
import { IOrganization } from '@app/data/models/organization';
import * as Utils from '@app/utils';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { IncludeOrganizations } from './organizations.action';

export interface OrganizationsStateModel {
  entities: EntityMap<IOrganization>;
}

export const OrganizationsStateDefaults: OrganizationsStateModel = {
  entities: {},
};

@State<OrganizationsStateModel>({
  name: DataType.Organizations,
  defaults: OrganizationsStateDefaults,
})
@Injectable()
export class OrganizationsState {
  constructor() {}

  @Selector()
  public static Organizations(state: OrganizationsStateModel) {
    return Utils.entitiesToArray(state.entities);
  }

  @Action(IncludeOrganizations)
  public includeOrganizations(ctx: StateContext<OrganizationsStateModel>, action: IncludeOrganizations): void {
    ctx.setState(
      patch<OrganizationsStateModel>({
        entities: patch(Utils.createEntities(action.payload, 'id')),
      }),
    );
  }
}
