import { dictionaryToOptionDataList } from '@app/utils/option-data';

export enum MediaType {
  AbsentFromWork = 'AbsentFromWork',
  AdditionalDocument = 'AdditionalDocument',
  AdvisorAgreement = 'AdvisorAgreement',
  AgentOfRecord = 'AgentOfRecordLetter',
  BenefitsBooklet = 'BenefitsBooklet',
  BenefitsTeam = 'BenefitsTeam',
  CarrierGuide = 'CarrierGuide',
  Census = 'Census',
  Contract = 'Contract',
  EmployeeApplication = 'EmployeeApplication',
  EmployeeHealth = 'EmployeeHealth',
  EmployeeIdCard = 'EmployeeIdCard',
  EP3 = 'EP3',
  ErrorsOmissions = 'ErrorsAndOmissions',
  EvidenceOfInsurability = 'EvidenceOfInsurability',
  FinalBill = 'FinalBill',
  LifeLicense = 'AdvisorLifeLicense',
  MasterApplication = 'MasterApplication',
  Miscellaneous = 'Miscellaneous',
  PlanOther = 'PlanOther',
  PayorAuthorization = 'PayorAuthorization',
  PlanHelp = 'PlanHelp',
  Proposal = 'Proposal',
  Quote = 'Quote',
  RateBenefitsBooklet = 'RateBenefitsBooklet',
  RateWelcomePackage = 'RateWelcomePackage',
  Renewal = 'Renewal',
  SalesTaxForm = 'SalesTaxForm',
  SecureOnlineAccess = 'SecureOnlineAccess',
  TerminationLetter = 'TerminationLetter',
  TrusteeAppointment = 'TrusteeAppointment',
  VoidCheque = 'VoidCheque',
}

export const MediaTypeDictionary: Record<MediaType, string> = {
  [MediaType.AbsentFromWork]: 'Absent from Work',
  [MediaType.AdditionalDocument]: 'Additional Document',
  [MediaType.AdvisorAgreement]: 'Advisor Agreement',
  [MediaType.AgentOfRecord]: 'Agent of Record Letter',
  [MediaType.BenefitsBooklet]: 'Benefits Booklet',
  [MediaType.BenefitsTeam]: 'Benefits Team',
  [MediaType.CarrierGuide]: 'Carrier Guide',
  [MediaType.Census]: 'Census',
  [MediaType.Contract]: 'Contract',
  [MediaType.EmployeeApplication]: 'Employee Application Form',
  [MediaType.EmployeeHealth]: 'Employee Health Form',
  [MediaType.EmployeeIdCard]: 'Employee ID Card',
  [MediaType.EP3]: 'EP3',
  [MediaType.ErrorsOmissions]: 'Errors and Omissions',
  [MediaType.EvidenceOfInsurability]: 'Evidence of Insurability',
  [MediaType.FinalBill]: 'Final Billd',
  [MediaType.LifeLicense]: 'Life License',
  [MediaType.MasterApplication]: 'Master Application',
  [MediaType.Miscellaneous]: 'Miscellaneous',
  [MediaType.PlanOther]: 'Other',
  [MediaType.PayorAuthorization]: 'Payor Authorization',
  [MediaType.PlanHelp]: 'Plan Help',
  [MediaType.Proposal]: 'Proposal',
  [MediaType.Quote]: 'Quote',
  [MediaType.RateBenefitsBooklet]: 'Benefits Booklet',
  [MediaType.RateWelcomePackage]: 'Welcome Package',
  [MediaType.Renewal]: 'Renewal',
  [MediaType.SalesTaxForm]: 'Sales Tax Form',
  [MediaType.SecureOnlineAccess]: 'Signed Secure Online Access Form',
  [MediaType.TerminationLetter]: 'Termination Letter',
  [MediaType.TrusteeAppointment]: 'Trustee Appointment',
  [MediaType.VoidCheque]: 'Void Cheque',
};

export const MediaTemplateDictionary: Record<MediaType, string> = {
  [MediaType.AbsentFromWork]: 'Download Absent from Work template',
  [MediaType.AdditionalDocument]: 'Download Additional Document',
  [MediaType.AdvisorAgreement]: 'Download Advisor Agreement template',
  [MediaType.AgentOfRecord]: 'Download Agent of Record Letter template',
  [MediaType.BenefitsBooklet]: 'Download Benefits Booklet template',
  [MediaType.BenefitsTeam]: 'Download Benefits Team template',
  [MediaType.CarrierGuide]: 'Download Carrier Guide template',
  [MediaType.Census]: 'Download blank Census',
  [MediaType.Contract]: 'Download Contract',
  [MediaType.EmployeeApplication]: 'Download Enrollment Application Form',
  [MediaType.EmployeeHealth]: 'Download Employee Health Form template',
  [MediaType.EmployeeIdCard]: 'Download Employee ID Card',
  [MediaType.EP3]: 'Download EP3',
  [MediaType.ErrorsOmissions]: 'Download Errors and Omissions template',
  [MediaType.EvidenceOfInsurability]: 'Download Evidence of Insurability',
  [MediaType.FinalBill]: 'Download Final Bill',
  [MediaType.LifeLicense]: 'Download Life License template',
  [MediaType.MasterApplication]: 'Download Master Application template',
  [MediaType.Miscellaneous]: 'Download Miscellaneous template',
  [MediaType.PlanOther]: 'Download file',
  [MediaType.PayorAuthorization]: 'Download Payor Authorization template',
  [MediaType.PlanHelp]: 'Download Plan Help template',
  [MediaType.Proposal]: 'Download Proposal template',
  [MediaType.Quote]: 'Download Quote template',
  [MediaType.RateBenefitsBooklet]: 'Download Benefits Booklet template',
  [MediaType.RateWelcomePackage]: 'Download Welcome Package template',
  [MediaType.Renewal]: 'Download Renewal template',
  [MediaType.SalesTaxForm]: 'Download Sales Tax Form',
  [MediaType.SecureOnlineAccess]: 'Download Signed Secure Online Access Form template',
  [MediaType.TerminationLetter]: 'Download Termination Letter template',
  [MediaType.TrusteeAppointment]: 'Download Trustee Appointment template',
  [MediaType.VoidCheque]: 'Download Void Cheque template',
};

export const MediaUploadDictionary: Record<MediaType, string> = {
  [MediaType.AbsentFromWork]: 'Upload Absent from Work',
  [MediaType.AdditionalDocument]: 'Upload Additional Document',
  [MediaType.AdvisorAgreement]: 'Upload Advisor Agreement',
  [MediaType.AgentOfRecord]: 'Upload Agent of Record Letter',
  [MediaType.BenefitsBooklet]: 'Upload Booklet',
  [MediaType.BenefitsTeam]: 'Upload Benefits Team',
  [MediaType.CarrierGuide]: 'Upload Carrier Guide',
  [MediaType.Census]: 'Upload completed Census',
  [MediaType.Contract]: 'Upload Contract',
  [MediaType.EmployeeApplication]: 'Upload Completed Enrollment Form',
  [MediaType.EmployeeHealth]: 'Upload Employee Health Form',
  [MediaType.EmployeeIdCard]: 'Upload Employee ID Card',
  [MediaType.EP3]: 'Upload EP3',
  [MediaType.ErrorsOmissions]: 'Upload Errors and Omissions',
  [MediaType.EvidenceOfInsurability]: 'Upload Completed Evidence of Insurability',
  [MediaType.FinalBill]: 'Upload Final Bill',
  [MediaType.LifeLicense]: 'Upload Life License',
  [MediaType.MasterApplication]: 'Upload Master Application',
  [MediaType.Miscellaneous]: 'Upload Additional Docs',
  [MediaType.PlanOther]: 'Upload other file',
  [MediaType.PayorAuthorization]: 'Upload Payor Authorization',
  [MediaType.PlanHelp]: 'Upload Plan Help',
  [MediaType.Proposal]: 'Upload Proposal',
  [MediaType.Quote]: 'Upload Quote',
  [MediaType.RateBenefitsBooklet]: 'Upload Benefits Booklet',
  [MediaType.RateWelcomePackage]: 'Upload Welcome Package',
  [MediaType.Renewal]: 'Upload Renewal',
  [MediaType.SalesTaxForm]: 'Upload Sales Tax Form',
  [MediaType.SecureOnlineAccess]: 'Upload Signed Secure Online Access Form',
  [MediaType.TerminationLetter]: 'Upload Termination Letter',
  [MediaType.TrusteeAppointment]: 'Upload Trustee Appointment',
  [MediaType.VoidCheque]: 'Upload Void Cheque',
};

export const MediaTypes = dictionaryToOptionDataList(MediaTypeDictionary);

const { Proposal, Miscellaneous, Quote } = MediaTypeDictionary;
export const SummaryDocTypes = dictionaryToOptionDataList({ Proposal, Miscellaneous, Quote });
