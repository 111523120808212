import { Role, RoleDictionary } from '@app/data/enums/role.enum';

import { User } from '../models/user';

export const DevUsers: User[] = [
  // { name: 'Joe Juststarted', email: 'joe.juststarted@nowhere.com', password: '12345678', role: UserRole.Prospect },
  // { name: 'Harry Halfdone', email: 'harry.halfdone@nowhere.com', password: '12345678', role: UserRole.Prospect },
  // { name: 'Mike Manulife', email: 'mike@manulife.com ', password: '12345678', role: RoleDictionary[Role.Carrier] },
  // { name: 'Sunlife Steve', email: 'steve@sunlife.com', password: '12345678', role: RoleDictionary[Role.Carrier] },
  {
    name: 'Andrew McDougall',
    email: 'andrew_mcdougall@manulife.ca ',
    password: '12345678',
    role: RoleDictionary[Role.Carrier],
  },
  {
    name: 'Marc Johnstone',
    email: 'marc.johnstone@sunlife.com',
    password: '12345678',
    role: RoleDictionary[Role.Carrier],
  },
  { name: 'Greg Grifters', email: 'greg@grifters.com', password: '12345678', role: RoleDictionary[Role.Employee] },
  { name: 'Gabe Grifters', email: 'gabe@grifters.com', password: '12345678', role: RoleDictionary[Role.Employee] },
  { name: 'Carl Consensus', email: 'carl@consensus.com', password: '12345678', role: RoleDictionary[Role.Employee] },
  {
    name: 'Penny Smith',
    email: 'penny.smith@marsworks.com',
    password: '12345678',
    role: RoleDictionary[Role.PlanAdmin],
  },
  {
    name: 'Paula Smith',
    email: 'paula.smith@marsworks.com',
    password: '12345678',
    role: RoleDictionary[Role.PlanAdmin],
  },
  {
    name: 'Peter Smith',
    email: 'peter.smith@marsworks.com',
    password: '12345678',
    role: RoleDictionary[Role.PlanAdmin],
  },
  { name: 'Wile Coyote', email: 'wile.coyote@acme.com', password: '12345678', role: RoleDictionary[Role.PlanAdmin] },
  {
    name: 'Simple Steve',
    email: 'simple.steve@simplecensus.com',
    password: '12345678',
    role: RoleDictionary[Role.PlanAdmin],
  },
  {
    name: 'Richard Branson',
    email: 'richard.branson@nonvirgingalactic.com',
    password: '12345678',
    role: RoleDictionary[Role.PlanAdmin],
  },
  {
    name: 'Eddie Smith',
    email: 'eddie.smith@marsworks.com',
    password: '12345678',
    role: RoleDictionary[Role.Employee],
  },
  {
    name: 'Allan Advisorson',
    email: 'allan.advisorson@nowhere.com',
    password: '12345678',
    role: RoleDictionary[Role.Advisor],
  },
  {
    name: 'Fred Firstimer',
    email: 'fred.firstimer@nowhere.com',
    password: '12345678',
    role: RoleDictionary[Role.Advisor],
  },
  {
    name: 'Allie Analyst',
    email: 'allie.analyst@ingeniousinsurance.com',
    password: '12345678',
    role: RoleDictionary[Role.Analyst],
  },
];
