import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { Params } from '@angular/router';

import { DataType } from '../enums';
import { IQuote, IQuoteStatus, Response } from '../models';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class QuotesService {
  private params: Params = { include: 'plans,advisors,carriers,media,predecessor-rates,quotes' };

  constructor(private apiService: ApiService) {}

  public getQuotes(status: IQuoteStatus): Observable<Response<IQuote[]>> {
    const params = { ...this.params, status };

    return this.apiService.get('quotes', { params });
  }

  public getQuote(quoteId: string): Observable<Response<IQuote>> {
    return this.apiService.get(['quotes', quoteId], { params: this.params });
  }

  public updateQuote(data: IQuote): Observable<Response<IQuote>> {
    return this.apiService.patch(['quotes', data.id], data, { params: this.params });
  }

  public createQuote(planId: string, carrierId: string, users?: string[]): Observable<Response<IQuote>> {
    const payload = {
      type: DataType.Quotes,
      relationships: {
        carriers: {
          data: { type: DataType.Carriers, id: carrierId },
        },
        ...(users?.length && {
          users: {
            data: users.map((id) => ({ id, type: 'users' })),
          },
        }),
      },
    };

    return this.apiService.post(['plans', planId, 'relationships', 'quotes'], payload, { params: this.params });
  }
}
