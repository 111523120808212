import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { CreatePlanRenewal, PlansState, UsersState } from '@app/data';
import { Store } from '@ngxs/store';

@Component({
  selector: 'pip-client-renewals',
  templateUrl: './client-renewals.component.html',
  styleUrls: ['./client-renewals.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientRenewalsComponent implements OnInit {
  public plan$ = this.store.select(PlansState.activePlan);

  constructor(private store: Store) {}

  public ngOnInit(): void {}

  public createRenewal(planId: string): void {
    this.store.dispatch(new CreatePlanRenewal({ planId }));
  }
}
