import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Country, IProfile, IUser, UpdateProfile } from '@app/data';
import { Store } from '@ngxs/store';

@Component({
  selector: 'pip-user-details-form',
  templateUrl: './user-details-form.component.html',
  styleUrls: ['./user-details-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserDetailsFormComponent implements OnInit, OnChanges {
  @Input() public user: IUser;
  @Input() public profile: IProfile;
  @Input() public isAdvisor: boolean;

  public form: FormGroup;

  constructor(private fb: FormBuilder, private store: Store) {
    this.createForm();
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    this.initForm();
  }

  public onSubmit() {
    const { id, type } = this.profile;
    const { first_name, last_name, email, phone, group_experience, company, address1, address2, city, postal_code, state } = this.form.get(
      'userDetails',
    ).value;

    const profile: IProfile = {
      id,
      type,
      attributes: {
        first_name,
        last_name,
        email,
        phone,
        group_experience,
        company,
        address1,
        address2,
        city,
        postal_code,
        state,
      },
    };

    this.store.dispatch(new UpdateProfile(profile));
  }

  private createForm() {
    this.form = this.fb.group({
      userDetails: this.fb.group({
        first_name: [null, Validators.required],
        last_name: [null, Validators.required],
        email: [{ value: null, disabled: true }, [Validators.required, Validators.email]],
        phone: [null, Validators.required],
        group_experience: [null, Validators.required],
        company: [null],
        address1: [null],
        address2: [null],
        city: [null],
        postal_code: [null],
        state: [null],
        country: [Country.Canada],
      }),
    });
  }

  private initForm(): void {
    if (this.user) {
      this.form.get('userDetails').patchValue(this.user.attributes);
    }
    if (this.profile) {
      this.form.get('userDetails').patchValue(this.profile.attributes);
    }
  }
}
