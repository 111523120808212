import { ChangeDetectionStrategy, Component, Input, TemplateRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DataType, ICompanyInfo, IPlan, UpdatePlan } from '@app/data';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { take } from 'rxjs/operators';

@Component({
  selector: 'pip-organization-info',
  templateUrl: './organization-info.component.html',
  styleUrls: ['./organization-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrganizationInfoComponent {
  @Input() public planId: string;
  @Input() public companyName: string;
  @Input() public companyInfo: ICompanyInfo;

  private dialogRef: NbDialogRef<any>;

  constructor(private store: Store, private actions$: Actions, public dialog: NbDialogService) {}

  public handleEdit(templateRef: TemplateRef<any>): void {
    const { industry, website, language } = this.companyInfo;

    const form = new FormGroup({
      organization_name: new FormControl(this.companyName, [Validators.required]),
      industry: new FormControl(industry, [Validators.required]),
      website: new FormControl(website, [Validators.required]),
      language: new FormControl(language, [Validators.required]),
    });

    this.dialogRef = this.dialog.open(templateRef, { context: { form } });
  }

  public handleSubmit(formValues): void {
    const plan: IPlan = {
      id: this.planId,
      type: DataType.Plans,
      attributes: {
        organization_name: formValues.organization_name,
        organization_info: {
          ...this.companyInfo,
          website: formValues.website,
          industry: formValues.industry,
          language: formValues.language,
        },
      },
    };

    this.actions$.pipe(ofActionSuccessful(UpdatePlan), take(1)).subscribe(() => this.dialogRef.close());

    this.store.dispatch(new UpdatePlan(plan));
  }
}
