import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ControlContainer } from '@angular/forms';

@Component({
  selector: 'pip-employee-payment-info-formgroup',
  templateUrl: './employee-payment-info-formgroup.component.html',
  styleUrls: ['./employee-payment-info-formgroup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmployeePaymentInfoFormgroupComponent implements OnInit {
  constructor(public controlContainer: ControlContainer) {}

  ngOnInit() {}
}
