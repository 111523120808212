export enum PayCycle {
  Weekly = 'Weekly',
  BiWeekly = 'BiWeekly',
  Monthly = 'Monthly',
  SemiMonthly = 'SemiMonthly',
}

export const PayCycleDictionary: Record<PayCycle, string> = {
  Weekly: 'Weekly',
  BiWeekly: 'Bi Weekly',
  Monthly: 'Monthly',
  SemiMonthly: 'Semi  Monthly',
};
