import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { IPlan } from '@app/data';

@Component({
  selector: 'pip-payment-information-form-group',
  templateUrl: './payment-information-form-group.component.html',
  styleUrls: ['./payment-information-form-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentInformationFormGroupComponent implements OnInit {
  @Input() public plan: IPlan;

  constructor(public controlContainer: ControlContainer) {}

  public ngOnInit(): void {}
}
