import { Location, ɵparseCookieValue } from '@angular/common';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
} from '@angular/router';
import { AuthPaths } from '@app/auth/auth.paths';
import { AuthState } from '@app/auth/store';
import { environment } from '@env/environment';
import { Store } from '@ngxs/store';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanLoad {
  constructor(private location: Location, private router: Router) {}

  public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.isAuthed();
  }

  public canLoad(route: Route, segments: UrlSegment[]): boolean {
    return this.isAuthed();
  }

  private isAuthed(): boolean {
    const redirect = this.location.path();
    const isLoggedIn = !!ɵparseCookieValue(document.cookie, 'XSRF-TOKEN');

    if (!isLoggedIn) {
      // HACK: Use window.location to keep params... Since neither Angular Router nor NGXS Navigate will...
      window.location.href = `${environment.url.api}/?redirect=${redirect}`;
      // TODO: Figure out why using either the Angular Router or the NGXS Navigate won't use the params?!?!?!?!?!
      // this.router.navigate(['/', AuthPaths.Login], { queryParams: { redirect }, queryParamsHandling: "preserve" });
      // this.store.dispatch(new Navigate(['/', AuthPaths.Login], { redirect }));
    }

    return isLoggedIn;
  }
}
