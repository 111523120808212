<pip-field-status-alert
  *ngIf="(plan$ | async)?.meta?.field_statuses?.standards as standards"
  [fieldStatus]="standards"
></pip-field-status-alert>

<nb-card>
  <nb-card-body>
    <div class="d-flex flex-wrap">
      <pip-media-uploader
        *ngIf="!(isVirgin$ | async)"
        [existingMedia]="terminationLetter$ | async"
        [mediaType]="mediaType.TerminationLetter"
        [mediaModel]="mediaModel.Plan"
        [modelId]="(plan$ | async).id"
      ></pip-media-uploader>
      <pip-media-uploader
        *ngIf="!(isVirgin$ | async)"
        [existingMedia]="EP3$ | async"
        [mediaType]="mediaType.EP3"
        [mediaModel]="mediaModel.Plan"
        [modelId]="(plan$ | async).id"
      ></pip-media-uploader>
      <pip-media-uploader
        *ngIf="!(isVirgin$ | async)"
        [existingMedia]="finalBill$ | async"
        [mediaType]="mediaType.FinalBill"
        [mediaModel]="mediaModel.Plan"
        [modelId]="(plan$ | async).id"
      ></pip-media-uploader>
      <pip-media-uploader
        [existingMedia]="other$ | async"
        [mediaType]="mediaType.PlanOther"
        [mediaModel]="mediaModel.Plan"
        [modelId]="(plan$ | async).id"
      ></pip-media-uploader>
    </div>
  </nb-card-body>
</nb-card>

<pip-quote-standards-form
  [plan]="plan$ | async"
  [locked]="locked$ | async"
  [loading]="loading$ | async"
  (save)="onSave($event)"
></pip-quote-standards-form>
