export interface IAdministrator {
  born_at: string;
  email: string;
  first_name: string;
  last_name: string;
  mothers_name: string;
  phone: string;
}

export class Administrator {
  public static create(): IAdministrator {
    return {
      born_at: null,
      email: null,
      first_name: null,
      last_name: null,
      mothers_name: null,
      phone: null,
    };
  }
}
