import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IPlanAttributes, Plan, PlanStandards } from '@app/data';
import { RateGuaranteeOpts } from '@app/data/enums/rate-guarantee-opts';

@Component({
  selector: 'pip-common-rate-properties-form',
  templateUrl: './common-rate-properties-form.component.html',
  styleUrls: ['./common-rate-properties-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommonRatePropertiesFormComponent implements OnInit {
  @Input() public attributes: IPlanAttributes;
  @Output() public update = new EventEmitter<Partial<IPlanAttributes>>();

  public form: FormGroup;

  private rateGuaranteeOpts = RateGuaranteeOpts;

  constructor() {}

  public ngOnInit(): void {
    this.initForm();
  }

  public onSubmit(): void {
    const { rate_guarantee_select, rate_guarantee_other, ltd_flat, std_flat, ...payload } = this.form.value;

    this.update.emit({
      ...payload,
      ...(payload.std_graded_scale === false && { std_flat }),
      ...(payload.ltd_graded_scale === false && { ltd_flat }),
      rate_guarantee: rate_guarantee_select === 'other' ? rate_guarantee_other : rate_guarantee_select,
    });
  }

  private initForm(): void {
    const {
      critical_illness_nem,
      critical_illness_termination_age,
      dentalcare_termination_age,
      healthcare_termination_age,
      life_nem,
      life_termination_age,
      ltd_flat,
      ltd_graded_scale,
      ltd_nem,
      pro_rated,
      rate_guarantee,
      renewal_caps,
      renewal_months,
      std_flat,
      std_graded_scale,
      std_nem,
      std_termination_age,
      standards,
    } = this.attributes || Plan.createAttributes();

    const rate_guarantee_select = this.rateGuaranteeOpts.includes(rate_guarantee) ? rate_guarantee : 'other';
    const rate_guarantee_other = rate_guarantee_select === 'other' ? rate_guarantee : undefined;

    this.form = new FormGroup({
      critical_illness_nem: new FormControl(critical_illness_nem, [Validators.min(10_000), Validators.max(150_000)]),
      critical_illness_termination_age: new FormControl(critical_illness_termination_age),
      dentalcare_termination_age: new FormControl(dentalcare_termination_age),
      healthcare_termination_age: new FormControl(healthcare_termination_age),
      life_nem: new FormControl(life_nem),
      life_termination_age: new FormControl(life_termination_age),
      ltd_flat: new FormControl(ltd_flat),
      ltd_graded_scale: new FormControl(ltd_graded_scale),
      ltd_nem: new FormControl(ltd_nem),
      pro_rated: new FormControl(pro_rated),
      rate_guarantee_other: new FormControl(rate_guarantee_other),
      rate_guarantee_select: new FormControl(rate_guarantee_select),
      renewal_caps: new FormControl(renewal_caps),
      renewal_months: new FormControl(renewal_months),
      std_flat: new FormControl(std_flat),
      std_graded_scale: new FormControl(std_graded_scale),
      std_nem: new FormControl(std_nem),
      std_termination_age: new FormControl(std_termination_age),
    });
  }
}
