import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { IFieldStatuses } from '@app/data/models';
import { FieldStatusPipe } from '@app/shared/pipes';

@Component({
  selector: 'pip-field-status-indicator',
  templateUrl: './field-status-indicator.component.html',
  styleUrls: ['./field-status-indicator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FieldStatusIndicatorComponent implements OnInit {
  @Input() public fieldStatuses: IFieldStatuses;
  @Input() public checkFields: string[] = [
    'beneficiaries',
    'coverage_info',
    'dependents',
    'direct_deposit',
    'forms',
    'identification',
    'occupation_and_earnings',
    'personal_info',
    'review',
    'signoffs_legal',
  ];

  public get isComplete(): boolean {
    return (
      this.fieldStatuses &&
      Object.entries(this.fieldStatuses)
        .filter(([key, _]) => this.checkFields.includes(key))
        .every(([_, fieldStatus]) => fieldStatus.complete)
    );
  }

  public get isHidden(): boolean {
    return this.isComplete;
  }

  constructor() {}

  public ngOnInit(): void {}

  public onSelect(): void {
    // TODO
  }
}
