import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IPlan, IRate, MediaModel, MediaType, PlanStandards, PlanStatus, Rate, Role } from '@app/data';
import { PayCycle } from '@app/data/enums/pay-cycle.enum';

@Component({
  selector: 'pip-rates-form',
  templateUrl: './rates-form.component.html',
  styleUrls: ['./rates-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RatesFormComponent implements OnInit {
  @Input() public planStatus: PlanStatus;
  @Input() public roles: Role[];
  @Input() public rate = Rate.create();
  @Input() public plan: IPlan;
  @Output() private update = new EventEmitter<IRate>();

  public mediaType = MediaType;
  public mediaModel = MediaModel;
  public payCycle = PayCycle;
  public form: FormGroup;

  public get showRateBenefitsBooklet(): boolean {
    return this.rate && this.isPlanSetupPhase && this.isAnalyst;
  }

  public get showRateWelcomePackage(): boolean {
    return this.rate && this.isPlanSetupPhase && this.isAnalyst;
  }

  private get isPlanSetupPhase(): boolean {
    return this.planStatus && this.planStatus === PlanStatus.Setup;
  }

  private get isAnalyst(): boolean {
    return Array.isArray(this.roles) && this.roles?.includes(Role.Analyst);
  }

  constructor() {}

  public ngOnInit(): void {
    this.initForm();
  }

  public onSubmit(): void {
    const { standards, ...attributes } = this.form.value;

    const rate: IRate = {
      ...this.rate,
      attributes: {
        ...attributes,
        // only append `standards` if any field has data
        ...(Object.values(standards).some((value) => !!value) && { standards }),
      },
    };

    this.update.emit(rate);
  }

  private initForm(): void {
    const { attributes } = this.rate;
    const {
      name,
      add,
      eap,
      ltd,
      std,
      life,
      healthcare_single,
      healthcare_single_parent,
      healthcare_couple,
      healthcare_family,
      dental_single,
      dental_single_parent,
      dental_couple,
      dental_family,
      dependent_life,
      critical_illness,
      critical_illness_single,
      life_max,
      std_max,
      ltd_max,
      critical_illness_max,
      life_volume_type,
      life_volume,
      critical_illness_volume_type,
      critical_illness_volume,
      virtual_care,
      standards,
      url_video,
    } = attributes || Rate.createAttributes();

    const {
      overtime_earnings,
      probation_months,
      waiting_period,
      work_week_hours,
      std_employee_paid,
      ltd_employee_paid,
      workers_compensation,
      allocations,
      benefits_continuation,
    } = standards || PlanStandards.create();

    const employer_contribution = standards?.employer_contribution || PlanStandards.createEmployerContribution();

    this.form = new FormGroup({
      name: new FormControl(name),
      url_video: new FormControl(url_video),
      add: new FormControl(add),
      eap: new FormControl(eap),
      ltd: new FormControl(ltd),
      std: new FormControl(std),
      life: new FormControl(life),
      healthcare_single: new FormControl(healthcare_single),
      healthcare_single_parent: new FormControl(healthcare_single_parent),
      healthcare_couple: new FormControl(healthcare_couple),
      healthcare_family: new FormControl(healthcare_family),
      dental_single: new FormControl(dental_single),
      dental_single_parent: new FormControl(dental_single_parent),
      dental_couple: new FormControl(dental_couple),
      dental_family: new FormControl(dental_family),
      dependent_life: new FormControl(dependent_life),
      critical_illness: new FormControl(critical_illness),
      critical_illness_single: new FormControl(critical_illness_single),
      life_max: new FormControl(life_max),
      std_max: new FormControl(std_max),
      ltd_max: new FormControl(ltd_max),
      critical_illness_max: new FormControl(critical_illness_max),
      life_volume_type: new FormControl(life_volume_type),
      life_volume: new FormControl(life_volume, [Validators.max(1_000_000)]),
      critical_illness_volume_type: new FormControl(critical_illness_volume_type),
      critical_illness_volume: new FormControl(critical_illness_volume),
      virtual_care: new FormControl(virtual_care),
      standards: new FormGroup({
        allocations: new FormControl(allocations, []),
        benefits_continuation: new FormControl(benefits_continuation),
        employer_contribution: new FormGroup({
          life: new FormControl(employer_contribution.life, []),
          add: new FormControl(employer_contribution.add, []),
          dependentLife: new FormControl(employer_contribution.dependentLife, []),
          criticalIllness: new FormControl(employer_contribution.criticalIllness, []),
          ltd: new FormControl(employer_contribution.ltd, []),
          std: new FormControl(employer_contribution.std, []),
          healthcare: new FormControl(employer_contribution.healthcare, []),
          dentalcare: new FormControl(employer_contribution.dentalcare, []),
          virtualCare: new FormControl(employer_contribution.virtualCare, []),
        }),
        overtime_earnings: new FormControl(overtime_earnings),
        probation_months: new FormControl(probation_months),
        waiting_period: new FormControl(waiting_period),
        work_week_hours: new FormControl(work_week_hours),
        std_employee_paid: new FormControl(std_employee_paid),
        ltd_employee_paid: new FormControl(ltd_employee_paid),
        workers_compensation: new FormControl(workers_compensation),
      }),
    });
  }
}
