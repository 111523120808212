import { ChangeDetectionStrategy, Component, Input, OnInit, ViewChild } from '@angular/core';
import { GetEmployee, IFieldStatus, IFieldStatuses } from '@app/data';
import { InvitationsService } from '@app/data/services';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { Store } from '@ngxs/store';
import { filter, take } from 'rxjs/operators';

@Component({
  selector: 'pip-invite-employee',
  templateUrl: './invite-employee.component.html',
  styleUrls: ['./invite-employee.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InviteEmployeeComponent implements OnInit {
  @Input() employee: { email: string; id: string; last_invited_at: string; signed_at: string; complete: boolean };
  @Input() fieldStatuses: IFieldStatuses;

  @ViewChild('employeeInfoDialog') public employeeInfoDialog;
  @ViewChild('confirmInviteDialog') public confirmInviteDialog;

  public get allComplete(): boolean {
    return !!this.employee.email && this.fieldStatuses.occupation_and_earnings.complete;
  }

  public get incompleteFs(): IFieldStatus[] {
    return this.fieldStatuses ? Object.values(this.fieldStatuses).filter((fs) => !fs.complete) : [];
  }

  public get showEnrollButton(): boolean {
    const hasNoIssues = this.incompleteFs.length === 0;
    const hasEmployeeSigned = !!this.employee.signed_at;

    return !(hasEmployeeSigned || hasNoIssues);
  }

  constructor(
    private invitations: InvitationsService,
    private dialog: NbDialogService,
    private toastr: NbToastrService,
    private store: Store,
  ) {}

  public ngOnInit(): void {}

  public onClick(): void {
    if (this.allComplete) {
      this.onInvite();
    } else {
      this.onInfo();
    }
  }

  public onInvite(): void {
    this.dialog
      .open(this.confirmInviteDialog)
      .onClose.pipe(
        take(1),
        filter((c) => c),
      )
      .subscribe(() => {
        this.invitations
          .inviteEmployee(this.employee.id)
          .toPromise()
          .then(() => {
            this.store.dispatch(new GetEmployee({ employeeId: this.employee.id }));
            this.toastr.success(`Successfully invited ${this.employee.email}`, 'Success');
          });
      });
  }

  public onInfo(): void {
    this.dialog.open(this.employeeInfoDialog);
  }
}
