import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IPlan, PlansState, QuotesState, UpdatePlan } from '@app/data';
import { Navigate } from '@ngxs/router-plugin';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Component({
  selector: 'pip-quote-task-payment-info',
  templateUrl: './quote-task-payment-info.component.html',
  styleUrls: ['./quote-task-payment-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuoteTaskPaymentInfoComponent implements OnInit {
  public plan$ = this.store.select(PlansState.activePlan);
  public planMedia$ = this.store.select(PlansState.activePlanMedia);
  public carrier$ = this.store.select(QuotesState.selectedCarrier);
  public carrierMedia$ = this.store.select(QuotesState.carrierMediaLinks);

  public get showSalesTaxForm$(): Observable<boolean> {
    return this.carrierMedia$.pipe(map((links) => !!links.SalesTaxForm));
  }

  constructor(private store: Store, private actions$: Actions, private route: ActivatedRoute) {}

  public ngOnInit(): void {}

  public onUpdatePlan(plan: IPlan): void {
    this.actions$
      .pipe(ofActionSuccessful(UpdatePlan), take(1))
      .subscribe(() => this.store.dispatch(new Navigate(['../'], null, { relativeTo: this.route })));

    this.store.dispatch(new UpdatePlan(plan));
  }

  public onCancel(): void {
    this.store.dispatch(new Navigate(['../'], null, { relativeTo: this.route }));
  }
}
