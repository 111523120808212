<form [formGroup]="form" class="legal" *ngIf="carrier" (ngSubmit)="onSubmit()">
  <iframe style="width: 100%" class="border mb-3" [src]="padURL" sandbox="allow-scripts"></iframe>

  <nb-checkbox formControlName="payment_signoff">
    I hereby consent to the disclosure of the information contained in my pre-authorized debit enrollment agreement to
    the financial institution, provided such information is directly related to and required for the smooth application
    of the rules governing pre-authorized debits.
  </nb-checkbox>

  <div class="d-flex mt-3">
    <button [disabled]="form.invalid || form.disabled" nbButton status="primary" type="submit">Submit</button>
  </div>
</form>
