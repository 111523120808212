import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CreatePlan, IPlan, PlansState, UpdatePlan, UsersState, Role } from '@app/data';
import { Navigate } from '@ngxs/router-plugin';
import { Actions, ofActionSuccessful, Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { take, map } from 'rxjs/operators';

@Component({
  selector: 'pip-quote-task-company-info',
  templateUrl: './quote-task-company-info.component.html',
  styleUrls: ['./quote-task-company-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuoteTaskCompanyInfoComponent {
  @Select(PlansState.activePlan) public plan$: Observable<IPlan>;

  public confirmButton$ = this.store.select(UsersState.activeUserRoles).pipe(
    map((roles) => {
      if (roles?.includes(Role.PlanAdmin)) {
        return 'Confirm';
      } else {
        return 'Save';
      }
    }),
  );

  constructor(private store: Store, private actions$: Actions, private route: ActivatedRoute) {}

  public onUpdatePlan(plan: IPlan): void {
    this.actions$
      .pipe(ofActionSuccessful(UpdatePlan), take(1))
      .subscribe(() => this.store.dispatch(new Navigate(['../'], null, { relativeTo: this.route })));

    this.store.dispatch(new UpdatePlan(plan));
  }

  public onCancel(): void {
    this.store.dispatch(new Navigate(['../'], null, { relativeTo: this.route }));
  }
}
