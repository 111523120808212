<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <nb-card>
    <nb-card-body>
      <ng-container *ngIf="addAssociateSelector">
        <fieldset>
          <legend class="subtitle">Client Rep</legend>
          <pip-associate-selector
            [user]="planRep"
            type="rep"
            [planId]="null"
            [canEdit]="isAnalyst"
            (userSelected)="onUserSelected($event)"
          ></pip-associate-selector>
        </fieldset>
      </ng-container>

      <pip-company-info-formgroup formGroupName="companyInfo"></pip-company-info-formgroup>
      <pip-business-address-formgroup formGroupName="businessAddress"></pip-business-address-formgroup>
    </nb-card-body>
    <nb-card-footer class="d-flex justify-content-between">
      <a nbButton status="primary" ghost [routerLink]="['../']">{{ cancelButton }}</a>
      <button nbButton status="primary" type="submit" [disabled]="!isValid()">{{ confirmButton }}</button>
    </nb-card-footer>
  </nb-card>
</form>
