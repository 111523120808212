import { AdditionalBenefitsComponent } from './additional-benefits/additional-benefits.component';
import { AssociateSelectorComponent } from './associate-selector/associate-selector.component';
import { AttachEmployeeIdComponent } from './attach-employee-id/attach-employee-id.component';
import { BusinessAddressComponent } from './business-address/business-address.component';
import { ClientAdministrationComponent } from './client-administration/client-administration.component';
import { ClientCompanyInfoComponent } from './client-company-info/client-company-info.component';
import { ClientListComponent } from './client-list/client-list.component';
import { ClientNotesComponent } from './client-notes/client-notes.component';
import { ClientPaymentInfoComponent } from './client-payment-info/client-payment-info.component';
import { ClientPlanMembersComponent } from './client-plan-members/client-plan-members.component';
import { ClientRatesComponent } from './client-rates/client-rates.component';
import { ClientRenewalDetailComponent } from './client-renewal-detail/client-renewal-detail.component';
import { ClientRenewalsComponent } from './client-renewals/client-renewals.component';
import { ClientComponent } from './client/client.component';
import { CommonRatePropertiesComponent } from './common-rate-properties/common-rate-properties.component';
import { CreateEmployeeComponent } from './create-employee/create-employee.component';
import { CreateNewPlanComponent } from './create-new-plan/create-new-plan.component';
import { CreateQuoteComponent } from './create-quote/create-quote.component';
import { EmployeeDetailComponent } from './employee-detail/employee-detail.component';
import { FieldStatusIndicatorComponent } from './field-status-indicator/field-status-indicator.component';
import { ImportEmployeeDataComponent } from './import-employee-data/import-employee-data.component';
import { InvitePlanAdminComponent } from './invite-plan-admin/invite-plan-admin.component';
import { MainContactComponent } from './main-contact/main-contact.component';
import { MediaUploaderComponent } from './media-uploader/media-uploader.component';
import { NewClientComponent } from './new-client/new-client.component';
import { OrganizationInfoComponent } from './organization-info/organization-info.component';
import { OverallDetailsComponent } from './overall-details/overall-details.component';
import { PaymentInformationPropertiesComponent } from './payment-information-properties/payment-information-properties.component';
import { PlanStandardsInfoComponent } from './plan-standards-info/plan-standards-info.component';
import { PlansListComponent } from './plans-list/plans-list.component';
import { RatePropertiesComponent } from './rate-properties/rate-properties.component';

export const containers: any[] = [
  AdditionalBenefitsComponent,
  AssociateSelectorComponent,
  AttachEmployeeIdComponent,
  BusinessAddressComponent,
  ClientAdministrationComponent,
  ClientCompanyInfoComponent,
  ClientComponent,
  ClientListComponent,
  ClientNotesComponent,
  ClientPaymentInfoComponent,
  ClientPlanMembersComponent,
  ClientRatesComponent,
  ClientRenewalDetailComponent,
  ClientRenewalsComponent,
  CommonRatePropertiesComponent,
  CreateEmployeeComponent,
  CreateNewPlanComponent,
  CreateQuoteComponent,
  EmployeeDetailComponent,
  FieldStatusIndicatorComponent,
  ImportEmployeeDataComponent,
  InvitePlanAdminComponent,
  MainContactComponent,
  MediaUploaderComponent,
  NewClientComponent,
  OrganizationInfoComponent,
  OverallDetailsComponent,
  PaymentInformationPropertiesComponent,
  PlansListComponent,
  PlanStandardsInfoComponent,
  RatePropertiesComponent,
];

export * from './attach-employee-id/attach-employee-id.component';
export * from './client-administration/client-administration.component';
export * from './client-company-info/client-company-info.component';
export * from './client-list/client-list.component';
export * from './client-payment-info/client-payment-info.component';
export * from './client-plan-members/client-plan-members.component';
export * from './client-rates/client-rates.component';
export * from './client-renewal-detail/client-renewal-detail.component';
export * from './client-renewals/client-renewals.component';
export * from './client/client.component';
export * from './create-employee/create-employee.component';
export * from './create-new-plan/create-new-plan.component';
export * from './create-quote/create-quote.component';
export * from './employee-detail/employee-detail.component';
export * from './field-status-indicator/field-status-indicator.component';
export * from './import-employee-data/import-employee-data.component';
export * from './media-uploader/media-uploader.component';
export * from './new-client/new-client.component';
