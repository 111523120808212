<pip-field-status-alert [fieldStatus]="(plan$ | async)?.meta?.field_statuses?.legal"></pip-field-status-alert>

<nb-card status="basic">
  <nb-card-header>Signoffs / Legal</nb-card-header>
  <nb-card-body>
    <p>
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Provident nisi, optio inventore exercitationem ut cupiditate quos, molestiae
      suscipit error porro accusamus, mollitia eaque voluptatem veritatis ipsum quibusdam. Rerum, nam sed!
    </p>
    <hr />
    <pip-media-uploader
      *ngIf="(planMedia$ | async) && (plan$ | async)"
      [downloadTemplate]="(carrierMediaLinks$ | async)?.SecureOnlineAccess"
      [existingMedia]="(planMedia$ | async)[mediaType.SecureOnlineAccess]"
      [mediaType]="mediaType.SecureOnlineAccess"
      [modelId]="(plan$ | async).id"
      [mediaModel]="mediaModel.Plan"
    ></pip-media-uploader>

    <pip-media-uploader
      [downloadTemplate]="(carrierMediaLinks$ | async)?.MasterApplication"
      [existingMedia]="(planMedia$ | async)[mediaType.MasterApplication]"
      [mediaType]="mediaType.MasterApplication"
      [modelId]="(plan$ | async).id"
      [mediaModel]="mediaModel.Plan"
    ></pip-media-uploader>

    <pip-media-uploader
      [downloadTemplate]="(carrierMediaLinks$ | async)?.PayorAuthorization"
      [existingMedia]="(planMedia$ | async)[mediaType.PayorAuthorization]"
      [mediaType]="mediaType.PayorAuthorization"
      [modelId]="(plan$ | async).id"
      [mediaModel]="mediaModel.Plan"
    ></pip-media-uploader>

    <pip-media-uploader
      [downloadTemplate]="(carrierMediaLinks$ | async)?.SalesTaxForm"
      [existingMedia]="(planMedia$ | async)[mediaType.SalesTaxForm]"
      [mediaType]="mediaType.SalesTaxForm"
      [modelId]="(plan$ | async).id"
      [mediaModel]="mediaModel.Plan"
    ></pip-media-uploader>
  </nb-card-body>
</nb-card>
