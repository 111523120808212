<table class="table">
  <thead>
    <tr>
      <th>Renewal</th>
      <th><span class="sr-only">Actions</span></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let renewal of renewals">
      <td>
        {{ renewal.id }}
      </td>
      <td>
        <a [routerLink]="['./', renewal.id]">View</a>
      </td>
    </tr>
  </tbody>
</table>
