import { Pipe, PipeTransform } from '@angular/core';
import { SegmentType, SegmentTypeDictionary } from '@app/data';

@Pipe({
  name: 'segmentType',
})
export class SegmentTypePipe implements PipeTransform {
  public transform(key: SegmentType): string {
    if (!SegmentTypeDictionary[key]) {
      console.warn(`SegmentTypeDictionary doesn't contain:`, key);
      return key;
    }

    return SegmentTypeDictionary[key];
  }
}
