<h1>New Client</h1>
<div class="row justify-content-center">
  <div class="col-lg-6">
    <pip-create-new-plan
      [quoteType]="'newQuote'"
      [activeUser]="activeUser$ | async"
      [isAnalyst]="isAnalyst$ | async"
      [isGroupRep]="isGroupRep$ | async"
      (save)="onCreatePlan($event)"
    ></pip-create-new-plan>
  </div>
</div>
