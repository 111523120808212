<form [formGroup]="form" (submit)="updatePlan()">
  <nb-stepper [linear]="true" [disableStepNavigation]="true">
    <!-- Profile -->
    <nb-step label="Profile">
      <nb-card status="basic">
        <nb-card-header>
          Client Profile
        </nb-card-header>
        <nb-card-body class="p-5">
          <div class="row">
            <div class="col">
              <pip-company-info-formgroup formGroupName="companyInfo"></pip-company-info-formgroup>
              <pip-administrator-formgroup formGroupName="administrator"></pip-administrator-formgroup>
            </div>
            <div class="col">
              <pip-business-address-formgroup formGroupName="businessAddress"></pip-business-address-formgroup>
            </div>
          </div>
        </nb-card-body>
      </nb-card>
      <div class="d-flex justify-content-end">
        <button
          type="button"
          nbButton
          status="primary"
          size="large"
          shape="semi-round"
          [disabled]="isClientProfileValid === false"
          (click)="updatePlan()"
        >
          Save & Continue
          <nb-icon icon="arrow-right"></nb-icon>
        </button>
      </div>
    </nb-step>

    <!-- Virgin Plan -->
    <nb-step label="Plan" *ngIf="isVirgin === true">
      <nb-card status="basic" *ngIf="planMedia">
        <nb-card-header>
          Plan
        </nb-card-header>
        <nb-card-body class="p-5">
          <p>Pick your plan(s):</p>
          <pip-pick-a-plan-table formGroupName="selectedPlans" [defaultPlans]="defaultPlans"></pip-pick-a-plan-table>
          <div class="form-group">
            <div class="form-group">
              <nb-checkbox id="std" formControlName="std">Include short-term disability</nb-checkbox>
            </div>
            <div class="form-group">
              <nb-checkbox id="ltd" formControlName="ltd">Include long-term disability</nb-checkbox>
            </div>
            <div class="form-group">
              <nb-checkbox id="eap" formControlName="eap">Include EAP</nb-checkbox>
            </div>
            <div class="form-group">
              <nb-checkbox id="virtual_care" formControlName="virtual_care">Include virtual care</nb-checkbox>
            </div>
            <div class="form-group">
              <label for="requests" class="form-label label">
                Want to see something else? Request it here:
              </label>
              <textarea
                nbInput
                fullWidth
                id="requests"
                placeholder="ex. Plan 1 – change Life and AD&D to 1 times salary and Dental to 90%, $2,000 annual max."
                formControlName="requests"
              >
              </textarea>
            </div>
          </div>
        </nb-card-body>
      </nb-card>
      <div class="d-flex justify-content-between">
        <button type="button" nbButton status="primary" size="large" shape="semi-round" outline (click)="stepper.previous()">
          <nb-icon icon="arrow-left"></nb-icon>
          Previous
        </button>

        <a [routerLink]="['../', plan?.id]" *ngIf="plan" nbButton status="primary" ghost>View summary</a>

        <button
          type="button"
          nbButton
          status="primary"
          size="large"
          shape="semi-round"
          [disabled]="!hasSelectedPlans"
          (click)="updatePlan()"
        >
          Save & Continue
          <nb-icon icon="arrow-right"></nb-icon>
        </button>
      </div>
    </nb-step>

    <!-- Established Plan -->
    <nb-step label="Plan" *ngIf="isVirgin === false">
      <nb-card status="basic" *ngIf="planMedia">
        <nb-card-header>
          Plan
        </nb-card-header>
        <nb-card-body class="p-5">
          <pip-media-uploader
            class="mx-auto mb-5"
            [existingMedia]="planMedia[mediaType.BenefitsBooklet]"
            [mediaType]="mediaType.BenefitsBooklet"
            [modelId]="plan.id"
            [mediaModel]="mediaModel.Plan"
          ></pip-media-uploader>
          <div class="form-group">
            <label for="requests" class="form-label label">
              Want to see something else? Request it here:
            </label>
            <div class="">
              <textarea nbInput fullWidth id="requests" formControlName="requests"></textarea>
            </div>
          </div>
        </nb-card-body>
      </nb-card>
      <div class="d-flex justify-content-between">
        <button type="button" nbButton status="primary" size="large" shape="semi-round" outline (click)="stepper.previous()">
          <nb-icon icon="arrow-left"></nb-icon>
          Previous
        </button>
        <a [routerLink]="['../', plan?.id]" *ngIf="plan" nbButton status="primary" ghost>View summary</a>

        <button
          type="button"
          nbButton
          status="primary"
          size="large"
          shape="semi-round"
          [disabled]="!hasBenefitsBooklet"
          (click)="updatePlan()"
        >
          Save & Continue
          <nb-icon icon="arrow-right"></nb-icon>
        </button>
      </div>
    </nb-step>
    <nb-step label="Recent Renewal" *ngIf="isVirgin === false">
      <nb-card status="basic" *ngIf="planMedia">
        <nb-card-header>
          Renewal
        </nb-card-header>
        <nb-card-body class="p-5">
          <pip-media-uploader
            class="mx-auto mb-5"
            [existingMedia]="planMedia[mediaType.Renewal]"
            [mediaType]="mediaType.Renewal"
            [modelId]="plan.id"
            [mediaModel]="mediaModel.Plan"
          ></pip-media-uploader>
        </nb-card-body>
      </nb-card>
      <div class="d-flex justify-content-between">
        <button type="button" nbButton status="primary" size="large" shape="semi-round" outline (click)="stepper.previous()">
          <nb-icon icon="arrow-left"></nb-icon>
          Previous
        </button>
        <a [routerLink]="['../', plan?.id]" *ngIf="plan" nbButton status="primary" ghost>View summary</a>
        <button type="button" nbButton status="primary" size="large" shape="semi-round" [disabled]="!hasRenewal" (click)="stepper.next()">
          Save & Continue
          <nb-icon icon="arrow-right"></nb-icon>
        </button>
      </div>
    </nb-step>
    <nb-step label="Census">
      <nb-card status="basic" *ngIf="planMedia">
        <nb-card-header>
          Census
        </nb-card-header>
        <nb-card-body class="p-5">
          <div class="text-center">
            <a [href]="censusTemplateUrl" target="_blank" nbButton status="primary" size="large" class="mb-5">
              <nb-icon icon="cloud-download-outline"></nb-icon>
              Download census template
            </a>

            <pip-media-uploader
              class="mx-auto mb-5"
              [existingMedia]="planMedia[mediaType.Census]"
              [mediaType]="mediaType.Census"
              [modelId]="plan.id"
              [mediaModel]="mediaModel.Plan"
              [warning]="planMedia[mediaType.Census] ? 'Uploading a new census will destroy and overwrite any current employees' : null"
            ></pip-media-uploader>
          </div>

          <ng-container *ngIf="planMedia[mediaType.Census]">
            <hr />
            <pip-employees-off-work></pip-employees-off-work>
          </ng-container>
        </nb-card-body>
      </nb-card>
      <div class="d-flex justify-content-between">
        <button type="button" nbButton status="primary" size="large" shape="semi-round" outline (click)="stepper.previous()">
          <nb-icon icon="arrow-left"></nb-icon>
          Previous
        </button>
        <a [routerLink]="['../', plan?.id]" *ngIf="plan" nbButton status="primary" ghost>View summary</a>
        <button type="button" nbButton status="primary" size="large" [disabled]="!hasCensus" (click)="submitPlan()">
          <nb-icon icon="cloud-upload"></nb-icon>
          Submit
        </button>
      </div>
    </nb-step>
    <nb-step label="Submit">
      <nb-card status="basic">
        <nb-card-header>
          Submitted
        </nb-card-header>
        <nb-card-body class="text-center p-5">
          <h1>Thank you!</h1>
          <p>Your quote request has been submitted.</p>
          <p>Please allow 7-10 business days for your proposal.</p>
        </nb-card-body>
      </nb-card>
      <div class="d-flex justify-content-start">
        <button type="button" nbButton status="primary" size="large" shape="semi-round" outline (click)="stepper.previous()">
          <nb-icon icon="arrow-left"></nb-icon>
          Previous
        </button>
      </div>
    </nb-step>
  </nb-stepper>
</form>
