import { CarrierDocumentComponent } from './carrier-document/carrier-document.component';
import { ClientDocumentsComponent } from './client-documents/client-documents.component';
import { CompanyInfoFormComponent } from './company-info-form/company-info-form.component';
import { DeletePlanComponent } from './delete-plan/delete-plan.component';
import { EmployeeListComponent } from './employee-list/employee-list.component';
import { EmployeesOffWorkComponent } from './employees-off-work/employees-off-work.component';
import { NewQuoteComponent } from './new-quote/new-quote.component';
import { PATaskListComponent } from './pa-task-list/pa-task-list.component';
import { PaTaskMainContactComponent } from './pa-task-main-contact/pa-task-main-contact.component';
import { PlanAdministrationComponent } from './plan-administration/plan-administration.component';
import { PlanDetailComponent } from './plan-detail/plan-detail.component';
import { PlanInsuranceStatusComponent } from './plan-insurance-status/plan-insurance-status.component';
import { PlanPaymentInfoComponent } from './plan-payment-info/plan-payment-info.component';
import { PlanRatesComponent } from './plan-rates/plan-rates.component';
import { PlanSecureAccessComponent } from './plan-secure-access/plan-secure-access.component';
import { PlanStandardsComponent } from './plan-standards/plan-standards.component';
import { PlanSummariesComponent } from './plan-summaries/plan-summaries.component';
import { PlanComponent } from './plan/plan.component';
import { QuoteTaskCensusComponent } from './quote-task-census/quote-task-census.component';
import { QuoteTaskCompanyInfoComponent } from './quote-task-company-info/quote-task-company-info.component';
import { QuoteTaskDocumentsComponent } from './quote-task-documents/quote-task-documents.component';
import { QuoteTaskEmployeesComponent } from './quote-task-employees/quote-task-employees.component';
import { QuoteTaskListComponent } from './quote-task-list/quote-task-list.component';
import { QuoteTaskPaymentInfoComponent } from './quote-task-payment-info/quote-task-payment-info.component';
import { QuoteTaskPlanAdministratorComponent } from './quote-task-plan-administrator/quote-task-plan-administrator.component';
import { QuoteTaskPlanDetailsComponent } from './quote-task-plan-details/quote-task-plan-details.component';
import { QuoteTaskPlanReviewComponent } from './quote-task-plan-review/quote-task-plan-review.component';
import { QuoteTaskReviewProposalComponent } from './quote-task-review-proposal/quote-task-review-proposal.component';
import { QuoteTaskStandardsComponent } from './quote-task-standards/quote-task-standards.component';
import { QuoteTaskStartDateComponent } from './quote-task-start-date/quote-task-start-date.component';
import { QuoteTasksComponent } from './quote-tasks/quote-tasks.component';
import { QuoteWizardComponent } from './quote-wizard/quote-wizard.component';

export const containers: any[] = [
  CarrierDocumentComponent,
  ClientDocumentsComponent,
  CompanyInfoFormComponent,
  DeletePlanComponent,
  EmployeeListComponent,
  EmployeesOffWorkComponent,
  NewQuoteComponent,
  PATaskListComponent,
  PaTaskMainContactComponent,
  PlanAdministrationComponent,
  PlanComponent,
  PlanDetailComponent,
  PlanInsuranceStatusComponent,
  PlanPaymentInfoComponent,
  PlanRatesComponent,
  PlanSecureAccessComponent,
  PlanStandardsComponent,
  PlanSummariesComponent,
  QuoteTaskCensusComponent,
  QuoteTaskCompanyInfoComponent,
  QuoteTaskDocumentsComponent,
  QuoteTaskEmployeesComponent,
  QuoteTaskListComponent,
  QuoteTaskPaymentInfoComponent,
  QuoteTaskPlanAdministratorComponent,
  QuoteTaskPlanDetailsComponent,
  QuoteTaskPlanReviewComponent,
  QuoteTasksComponent,
  QuoteTaskStandardsComponent,
  QuoteTaskStartDateComponent,
  QuoteWizardComponent,
  QuoteTaskReviewProposalComponent,
];

export * from '../../../shared/containers/invite-employee/invite-employee.component';
export * from '../../../shared/containers/plans-list/plans-list.component';
export * from './carrier-document/carrier-document.component';
export * from './client-documents/client-documents.component';
export * from './company-info-form/company-info-form.component';
export * from './delete-plan/delete-plan.component';
export * from './employee-list/employee-list.component';
export * from './employees-off-work/employees-off-work.component';
export * from './new-quote/new-quote.component';
export * from './pa-task-list/pa-task-list.component';
export * from './pa-task-main-contact/pa-task-main-contact.component';
export * from './plan-administration/plan-administration.component';
export * from './plan-detail/plan-detail.component';
export * from './plan-insurance-status/plan-insurance-status.component';
export * from './plan-payment-info/plan-payment-info.component';
export * from './plan-rates/plan-rates.component';
export * from './plan-secure-access/plan-secure-access.component';
export * from './plan-standards/plan-standards.component';
export * from './plan-summaries/plan-summaries.component';
export * from './plan/plan.component';
export * from './quote-task-census/quote-task-census.component';
export * from './quote-task-company-info/quote-task-company-info.component';
export * from './quote-task-documents/quote-task-documents.component';
export * from './quote-task-employees/quote-task-employees.component';
export * from './quote-task-list/quote-task-list.component';
export * from './quote-task-payment-info/quote-task-payment-info.component';
export * from './quote-task-plan-administrator/quote-task-plan-administrator.component';
export * from './quote-task-plan-details/quote-task-plan-details.component';
export * from './quote-task-plan-review/quote-task-plan-review.component';
export * from './quote-task-review-proposal/quote-task-review-proposal.component';
export * from './quote-task-standards/quote-task-standards.component';
export * from './quote-task-start-date/quote-task-start-date.component';
export * from './quote-tasks/quote-tasks.component';
export * from './quote-wizard/quote-wizard.component';
