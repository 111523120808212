import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'pip-access-agreement-content',
  templateUrl: './access-agreement-content.component.html',
  styleUrls: ['./access-agreement-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccessAgreementContentComponent implements OnInit {
  @Input() public carrierName: string;

  constructor() {}

  ngOnInit() {}
}
