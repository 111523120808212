<nb-card>
  <nb-card-header class="d-flex justify-content-between">
    <span>Notes</span>
    <button
      *ngIf="allowEdit"
      nbButton
      status="primary"
      size="tiny"
      outline
      class="ml-2"
      (click)="handleEdit(NoteDialog, null)"
    >
      Create
    </button>
  </nb-card-header>
  <nb-card-body>
    <ng-container *ngIf="notes$ | async">
      <div *ngFor="let note of notes$ | async">
        <div class="d-flex justify-content-between">
          <div class="label">
            <span>{{ note.user.attributes.first_name }} {{ note.user.attributes.last_name }} / </span>
            <span>
              {{ note.detail.attributes.updated_at | date }}
            </span>
          </div>
          <div *ngIf="allowEdit && note.canEdit">
            <button
              nbButton
              status="primary"
              size="tiny"
              outline
              class="ml-2"
              (click)="handleEdit(NoteDialog, note.detail)"
            >
              <nb-icon icon="edit"></nb-icon>
            </button>
            <button
              nbButton
              status="danger"
              size="tiny"
              outline
              class="ml-2"
              (click)="handleDelete(ConfirmDeleteDialog, note.detail)"
            >
              <nb-icon icon="trash"></nb-icon>
            </button>
          </div>
        </div>
        <p>{{ note.detail.attributes.info }}</p>
        <hr />
      </div>
    </ng-container>
  </nb-card-body>
</nb-card>

<!-- Edit -->
<ng-template #NoteDialog let-data let-ref="dialogRef">
  <form [formGroup]="data.form" (ngSubmit)="handleSubmit(data)">
    <nb-card status="basic" class="w-full">
      <nb-card-body>
        <div class="container">
          <div class="row">
            <div class="col">
              <label for="info" class="label">Note</label>
              <textarea
                type="text"
                nbInput
                fullWidth
                id="info"
                formControlName="info"
                rows="10"
                maxlength="2000"
              ></textarea>
            </div>
          </div>
        </div>
      </nb-card-body>
      <nb-card-footer class="d-flex justify-content-between">
        <button nbButton status="primary" ghost type="button" (click)="ref.close()">Close</button>
        <button nbButton status="primary" type="submit">Save</button>
      </nb-card-footer>
    </nb-card>
  </form>
</ng-template>

<!-- Delete -->
<ng-template #ConfirmDeleteDialog let-data let-ref="dialogRef">
  <nb-card status="basic" class="w-full">
    <nb-card-body>
      Are you sure you want to delete this note? This action cannot be undone.
    </nb-card-body>
    <nb-card-footer class="d-flex justify-content-between">
      <button nbButton status="primary" ghost type="button" (click)="ref.close()">Close</button>
      <button nbButton status="primary" type="button" (click)="ref.close(true)">Delete</button>
    </nb-card-footer>
  </nb-card>
</ng-template>
