import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ControlContainer, FormGroup } from '@angular/forms';
import { BenefitsContinuations, IPlan, LifeVolumeType, LifeVolumeTypes } from '@app/data';

@Component({
  selector: 'pip-rate-properties-form-group',
  templateUrl: './rate-properties-form-group.component.html',
  styleUrls: ['./rate-properties-form-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RatePropertiesFormGroupComponent implements OnInit {
  @Input() public plan: IPlan;

  public lifeVolumeTypes = LifeVolumeTypes;
  public benefitsContinuations = BenefitsContinuations;

  public get employerContributionsGroup(): FormGroup {
    return this.controlContainer?.control.get('standards').get('employer_contribution') as FormGroup;
  }

  constructor(public controlContainer: ControlContainer) {}

  public ngOnInit(): void {
    this.onSelectChange(
      'critical_illness_volume',
      this.controlContainer.control.get('critical_illness_volume_type').value,
    );

    this.onSelectChange('life_volume', this.controlContainer.control.get('life_volume_type').value);
  }

  public onSelectChange(target: string, value: LifeVolumeType): void {
    const ctrl = this.controlContainer.control.get(target);

    if (value !== 'Fixed') {
      ctrl.setValue(null);
      ctrl.disable();
    } else {
      ctrl.enable();
    }
  }
}
