import { ChangeDetectionStrategy, Component, Input, TemplateRef } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DataType, IPlan, IPlanStandards, Plan, UpdatePlan } from '@app/data';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { take } from 'rxjs/operators';

@Component({
  selector: 'pip-additional-benefits',
  templateUrl: './additional-benefits.component.html',
  styleUrls: ['./additional-benefits.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdditionalBenefitsComponent {
  @Input() public plan: IPlan;
  @Input() public canEdit: boolean = false;

  public dialogRef: NbDialogRef<any>;

  constructor(private store: Store, private actions$: Actions, private dialog: NbDialogService) {}

  public handleEdit(templateRef: TemplateRef<any>): void {
    const { std, ltd, eap, critical_illness, virtual_care, health_spending_account } = this.plan.attributes || Plan.createAttributes();

    const form = new FormGroup({
      std: new FormControl(std, []),
      ltd: new FormControl(ltd, []),
      eap: new FormControl(eap, []),
      critical_illness: new FormControl(critical_illness, []),
      virtual_care: new FormControl(virtual_care, []),
      health_spending_account: new FormControl(health_spending_account, []),
    });

    this.dialogRef = this.dialog.open(templateRef, { context: { form } });
  }

  public handleSubmit(formValues: IPlanStandards): void {
    const plan: IPlan = {
      id: this.plan.id,
      type: DataType.Plans,
      attributes: {
        ...formValues,
      },
    };

    this.actions$.pipe(ofActionSuccessful(UpdatePlan), take(1)).subscribe(() => this.dialogRef.close());

    this.store.dispatch(new UpdatePlan(plan));
  }
}
