import { ChangeDetectionStrategy, Component, Input, TemplateRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  CreateQuote,
  DataType,
  ICarrier,
  ICarrierMediaLinks,
  ICompanyInfo,
  IOrganization,
  IPlan,
  IPlanMedia,
  MediaModel,
  MediaType,
  Plan,
  UpdatePlan,
} from '@app/data';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { take } from 'rxjs/operators';

@Component({
  selector: 'pip-overall-details',
  templateUrl: './overall-details.component.html',
  styleUrls: ['./overall-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OverallDetailsComponent {
  @Input() public plan: IPlan;
  @Input() public planMedia: IPlanMedia;
  @Input() public carriers: ICarrier[];
  @Input() public carrier: ICarrier;
  @Input() public carrierMediaLinks: ICarrierMediaLinks;
  @Input() public canUploadDocs: boolean;
  @Input() public organization: IOrganization;
  @Input() public canEdit: boolean = false;

  public dialogRef: NbDialogRef<any>;
  public mediaType = MediaType;
  public mediaModel = MediaModel;

  constructor(private store: Store, private actions$: Actions, private dialog: NbDialogService) {}

  public handleEdit(templateRef: TemplateRef<any>): void {
    const { policy_number, effective_at, client_at, incepted_at, start_at } =
      this.plan.attributes || Plan.createAttributes();

    const form = new FormGroup({
      policy_number: new FormControl(policy_number, [Validators.required]),
      effective_at: new FormControl(effective_at, [Validators.required]),
      start_at: new FormControl(start_at, [Validators.required]),
      client_at: new FormControl(client_at),
      incepted_at: new FormControl(incepted_at),
    });

    this.dialogRef = this.dialog.open(templateRef, { context: { form } });
  }

  public handleSubmit(formValues: ICompanyInfo): void {
    const plan: IPlan = {
      id: this.plan.id,
      type: DataType.Plans,
      attributes: {
        ...formValues,
      },
    };

    this.actions$.pipe(ofActionSuccessful(UpdatePlan), take(1)).subscribe(() => this.dialogRef.close());

    this.store.dispatch(new UpdatePlan(plan));
  }

  public handleSelectCarrierDialog(templateRef: TemplateRef<any>): void {
    const form = new FormGroup({
      carrier: new FormControl(null, [Validators.required]),
    });

    this.dialogRef = this.dialog.open(templateRef, { context: { form } });
  }

  public handleSelectCarrier(carrier: ICarrier): void {
    this.actions$.pipe(ofActionSuccessful(CreateQuote), take(1)).subscribe(() => this.dialogRef.close());
    this.store.dispatch(new CreateQuote({ planId: this.plan.id, carrierId: carrier.id }));
  }
}
