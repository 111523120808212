/**
 * Create entities from an array of like objects
 *
 * @export
 * @template T Array object type
 */
export function createEntities<T>(array: T[], uniq: keyof T, updateObj?: { [id: string]: T }): EntityMap<T> {
  return array.reduce((ents: { [id: number]: T }, item: T) => {
    // If update object provided, use it as a base to update
    if (updateObj && updateObj[item[`${uniq}`]]) {
      return { ...ents, [item[`${uniq}`]]: updateObj[item[`${uniq}`]] };
    }

    return {
      ...ents,
      [item[`${uniq}`]]: item,
    };
  }, {});
}
