import { Pipe, PipeTransform } from '@angular/core';
import { Language, LanguageDictionary } from '@app/data/enums/language.enum';

@Pipe({
  name: 'languageDictionary',
})
export class LanguageDictionaryPipe implements PipeTransform {
  transform(value: Language) {
    return LanguageDictionary[value];
  }
}
