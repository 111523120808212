<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="form-group row">
    <div class="col">
      <nb-checkbox id="acknowledgement" formControlName="acknowledgement"
        >I confirm that the client agrees to the attached renewal agreement... etc. Need to confirm this
        text.</nb-checkbox
      >
    </div>
  </div>
  <div class="form-group row">
    <div class="col">
      <label for="signature" class="label d-block">
        Please enter <strong>{{ reqSignature }}</strong> to sign
      </label>
      <input nbInput fullWidth name="signature" id="signature" type="text" formControlName="signature" />
    </div>
  </div>
  <div class="form-group row">
    <div class="col">
      <button nbButton status="primary" type="submit" [disabled]="!form.valid">
        Submit
      </button>
    </div>
  </div>
</form>
