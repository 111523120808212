import { Injectable } from '@angular/core';
import * as Data from '@app/data/models';
import { IEmployee, IPaymentInformationMedia, ISegment, IUser, RelationshipData } from '@app/data/models';
import { IRateMedia } from '@app/data/models/rate-media';
import { createEntities } from '@app/utils';
import { NbToastrService } from '@nebular/theme';
import { RouterState, RouterStateModel } from '@ngxs/router-plugin';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { tap } from 'rxjs/operators';
import { SharedSelectors } from '../../../shared/store';
import { RouterStateParams } from '../../../store';
import { DataType, MediaType, PlanStatus } from '../../enums';
import { PlansService } from '../../services';
import { EmployeesState, EmployeesStateModel, IncludeEmployees } from '../employees';
import { MediaState, MediaStateModel } from '../media';
import { IncludeMedia } from '../media/media.actions';
import { IncludeOrganizations, OrganizationsState, OrganizationsStateModel } from '../organizations';
import { IncludePaymentInfos, PaymentInfosState, PaymentInfosStateModel } from '../payment-info';
import { IncludeQuotes } from '../quotes/quotes.actions';
import { IncludeRates } from '../rates/rates.actions';
import { RatesState, RatesStateModel } from '../rates/rates.state';
import { IncludeUsers } from '../users/users.action';
import { UsersState, UsersStateModel } from '../users/users.state';
import {
  AddPlanEmployees,
  CreatePlan,
  CreatePlanFromExisting,
  CreatePlanRenewal,
  CreatePlanSuccess,
  DeletePlan,
  GetCarriers,
  GetDefaultPlans,
  GetPlan,
  GetPlanRenewal,
  GetPlans,
  GetPlansSuccess,
  GetRenewalSegment,
  GetUserPlans,
  IncludePlanRenewals,
  IncludePlans,
  PlanConfirmations,
  RemovePlanAssociate,
  UpdatePlan,
  UpdatePlanAssociate,
  UpdatePlanRenewal,
  UpdatePlanSuccess,
  UpdateRenewalSegment,
} from './plans.actions';
import { PlanListKey, PlansStateModel } from './plans.models';

export const plansStateDefaults: PlansStateModel = {
  entities: {},
  planList: [],
  quotesList: [],
  enrollmentsList: [],
  clientsList: [],
  loading: false,
  defaultPlans: {},
  carriers: {},
  meta: null,
  renewals: {},
  segments: {},
  lastFetchedPlanId: null,
  lastFetchedQuery: null,
};

@State<PlansStateModel>({
  name: DataType.Plans,
  defaults: plansStateDefaults,
})
@Injectable()
export class PlansState {
  @Selector()
  public static plans(state: PlansStateModel): Data.IPlan[] {
    return SharedSelectors.getEntityList<Data.IPlan>(state);
  }

  @Selector([UsersState])
  public static planList(state: PlansStateModel, users: UsersStateModel): Data.IPlanListItem[] {
    return PlansState.plansToList(state, users, 'planList');
  }

  @Selector([UsersState])
  public static enrollmentsList(state: PlansStateModel, users: UsersStateModel): Data.IPlanListItem[] {
    return PlansState.plansToList(state, users, 'enrollmentsList');
  }

  @Selector([UsersState])
  public static clientsList(state: PlansStateModel, users: UsersStateModel): Data.IPlanListItem[] {
    return PlansState.plansToList(state, users, 'clientsList');
  }

  @Selector([UsersState])
  public static quotesList(state: PlansStateModel, users: UsersStateModel): Data.IPlanListItem[] {
    return PlansState.plansToList(state, users, 'quotesList');
  }

  @Selector()
  public static defaultPlans(state: PlansStateModel): Data.IDefaultPlan[] {
    return Object.values(state.defaultPlans);
  }

  @Selector()
  public static loading(state: PlansStateModel): boolean {
    return state.loading;
  }

  @Selector([RouterState])
  public static activePlanId(state: PlansStateModel, router: RouterStateModel<RouterStateParams>): string {
    return router.state.params.planId;
  }

  @Selector([PlansState.activePlanId])
  public static activePlan(state: PlansStateModel, planId: string): Data.IPlan {
    return state.entities[planId];
  }

  @Selector([PlansState.activePlanRep, UsersState.activeUserId])
  public static isActiveUserActivePlanRep(state: PlansStateModel, activePlanRep: IUser, activeUserId: string): boolean {
    return activePlanRep.id === activeUserId;
  }

  @Selector([PlansState.activePlanAssociate, UsersState.activeUserId])
  public static isActiveUserActivePlanAssociate(
    state: PlansStateModel,
    activePlanAssociate: IUser,
    activeUserId: string,
  ): boolean {
    return activePlanAssociate.id === activeUserId;
  }

  @Selector([PlansState.activePlan, MediaState])
  public static activePlanMedia(state: PlansStateModel, plan: Data.IPlan, media: MediaStateModel): Data.IPlanMedia {
    return PlansState.getPlanMedia(plan, media.entities);
  }

  @Selector([PlansState.activePlan, EmployeesState])
  public static activePlanEmployees(
    state: PlansStateModel,
    plan: Data.IPlan,
    employees: EmployeesStateModel,
  ): Data.IEmployee[] {
    return (
      plan.relationships.employees &&
      (plan.relationships.employees.data as Data.RelationshipData<Data.IEmployee>[])
        .filter((i) => !!employees.entities[i.id])
        .map((i) => employees.entities[i.id])
        .sort((a, b) => (a.attributes.last_name > b.attributes.last_name ? 1 : -1))
    );
  }

  @Selector([PlansState.activePlanMedia])
  public static activePlanSummaries(state: PlansStateModel, planMedia: Data.IPlanMedia): Data.ISummaries {
    const { Proposal, Miscellaneous, Quote } = planMedia;
    const summaries = [Proposal, ...Miscellaneous, ...Quote];

    return summaries.reduce((g, media) => {
      const label = media.relationships.carriers
        ? state.carriers[media.relationships.carriers.data.id].attributes.name
        : media.attributes.name;
      const option = state.defaultPlans[media.relationships.options.data.id].attributes.name;

      if (!g[option]) {
        g[option] = [];
      }

      g[option].push({
        is_quote: !!media.relationships.carriers,
        option_name: option,
        label,
        media,
      });

      return g;
    }, {});
  }

  @Selector()
  public static pagination(state: PlansStateModel): Data.IPagination {
    return state.meta;
  }

  @Selector()
  public static planCarriers(state: PlansStateModel): Data.ICarrier[] {
    return Object.values(state.carriers);
  }

  @Selector([PlansState.activePlan, RatesState, MediaState])
  public static activePlanRates(
    state: PlansStateModel,
    plan: Data.IPlan,
    ratesModel: RatesStateModel,
    mediaModel: MediaStateModel,
  ): Data.IRate[] {
    return (
      plan.relationships.rates &&
      (plan.relationships.rates.data as Data.RelationshipData<Data.IRate>[])
        .filter((i) => !!ratesModel.entities[i.id])
        .map((i) => {
          const rate = ratesModel.entities[i.id];
          const rateMedia = (rate.relationships.media as Data.Relationship<Data.IMedia[]>).data.map(
            (rel) => mediaModel.entities[rel.id],
          );

          const media: IRateMedia = {
            [MediaType.RateBenefitsBooklet]: rateMedia.find(
              (media) => media.attributes.type === MediaType.RateBenefitsBooklet,
            ),
            [MediaType.RateWelcomePackage]: rateMedia.filter(
              (media) => media.attributes.type === MediaType.RateWelcomePackage,
            ),
          };

          return { ...rate, media: media };
        })
    );
  }

  @Selector([PlansState.activePlan, PaymentInfosState, MediaState])
  public static activePlanPaymentInfos(
    state: PlansStateModel,
    plan: Data.IPlan,
    paymentInfosModel: PaymentInfosStateModel,
    mediaModel: MediaStateModel,
  ): Data.IPaymentInformation[] {
    return (plan?.relationships?.payment_infos.data as Data.RelationshipData<Data.IPaymentInformation>[])
      .filter((i) => !!paymentInfosModel.entities[i.id])
      .map((i) => {
        const paymentInfo = paymentInfosModel.entities[i.id];
        const mediaRels = (paymentInfo.relationships?.media as Data.Relationship<Data.IMedia[]>)?.data.map(
          (rel) => mediaModel.entities[rel.id],
        );

        const media: IPaymentInformationMedia = {
          [MediaType.VoidCheque]: mediaRels?.find((media) => media.attributes.type === MediaType.VoidCheque),
        };

        return { ...paymentInfo, media };
      });
  }

  @Selector([PlansState.activePlan, UsersState])
  public static activePlanRep(state: PlansStateModel, plan: Data.IPlan, usersState: UsersStateModel): Data.IUser {
    return usersState.entities[(plan.relationships.rep.data as Data.RelationshipData<Data.IUser>).id];
  }

  @Selector([PlansState.activePlan, UsersState])
  public static activePlanAssociate(state: PlansStateModel, plan: Data.IPlan, usersState: UsersStateModel): Data.IUser {
    return usersState.entities[(plan.relationships.associate.data as Data.RelationshipData<Data.IUser>).id];
  }

  @Selector([PlansState.activePlan])
  public static doesActivePlanHaveRenewal(state: PlansStateModel, plan: Data.IPlan): boolean {
    return !!plan.relationships?.renewals?.data?.length;
  }

  @Selector([PlansState.activePlan])
  public static activeRenewal(plans: PlansStateModel, plan: Data.IPlan): Data.IRenewal {
    return plans.renewals[plan.relationships.renewals.data[0].id];
  }

  @Selector([PlansState.activeRenewal, RatesState, MediaState])
  public static activeRenewalRates(
    plans: PlansStateModel,
    renewal: Data.IRenewal,
    ratesModel: RatesStateModel,
    mediaModel: MediaStateModel,
  ): Data.IRate[] {
    return (renewal?.relationships?.rates?.data as Data.RelationshipData<Data.IRate>[])
      .filter((i) => !!ratesModel.entities[i.id])
      .map((i) => {
        const rate = ratesModel.entities[i.id];
        const rateMedia = (rate.relationships.media as Data.Relationship<Data.IMedia[]>).data.map(
          (rel) => mediaModel.entities[rel.id],
        );

        const media: IRateMedia = {
          [MediaType.RateBenefitsBooklet]: rateMedia.find(
            (media) => media.attributes.type === MediaType.RateBenefitsBooklet,
          ),
          [MediaType.RateWelcomePackage]: rateMedia.filter(
            (media) => media.attributes.type === MediaType.RateWelcomePackage,
          ),
        };

        return { ...rate, media: media };
      });
  }

  @Selector([PlansState.activeRenewal])
  public static activeRenewalSegments(plans: PlansStateModel, renewal: Data.IRenewal): Data.ISegment[] {
    return renewal.relationships.segments.data.map((i) => plans.segments[i.id]).filter((i) => !!i);
  }

  @Selector([PlansState.activeRenewalSegments, MediaState])
  public static activeRenewalSegmentsMedia(
    plans: PlansStateModel,
    segments: Data.ISegment[],
    media: MediaStateModel,
  ): Data.IMedia[][] {
    return segments.map((segment) => segment.relationships.media.data.map((i) => media.entities[i.id]));
  }

  @Selector([PlansState.activePlan, OrganizationsState])
  public static activePlanOrganization(
    state: PlansStateModel,
    plan: Data.IPlan,
    organizations: OrganizationsStateModel,
  ): Data.IOrganization {
    return organizations.entities[plan.relationships.organizations.data.id];
  }

  @Selector([EmployeesState.activeEmployee, PlansState])
  public static activeEmployeePlan(
    ctx: StateContext<PlansStateModel>,
    employee: Data.IEmployee,
    plansState: PlansStateModel,
  ): Data.IPlan {
    const plan = plansState.entities[(employee.relationships.plans.data as Data.RelationshipData<Data.IPlan>).id];
    return plan;
  }

  @Selector([PlansState.activeEmployeePlan, MediaState])
  public static activeEmployeePlanMedia(
    ctx: StateContext<PlansStateModel>,
    plan: Data.IPlan,
    mediaState: MediaStateModel,
  ): Data.IPlanMedia {
    return PlansState.getPlanMedia(plan, mediaState.entities);
  }

  @Selector([PlansState.plans])
  public static newPlan(_: PlansStateModel, plans: Data.IPlan[]): Data.IPlan {
    // A new plan will be the newest plan that is not Aborted, Archived, or Confirmed
    return plans
      .filter(
        (plan) => ![PlanStatus.Aborted, PlanStatus.Confirmed, PlanStatus.Archived].includes(plan.attributes.status),
      )
      .sort((a, b) => (new Date(a.attributes.start_at) > new Date(b.attributes.start_at) ? -1 : 1))[0];
  }

  @Selector([PlansState.plans, PlansState.newPlan])
  public static currentPlan(state: PlansStateModel, plans: Data.IPlan[], newPlan: Data.IPlan | undefined): Data.IPlan {
    // A current plan will be the newest Confirmed plan (_should_ only have 1... But you never know!)
    return plans
      .filter((plan) => plan.attributes.status === PlanStatus.Confirmed)
      .sort((a, b) => (new Date(a.attributes.start_at) > new Date(b.attributes.start_at) ? -1 : 1))[0];
  }

  @Selector([PlansState.currentPlan, EmployeesState.employees])
  public static currentPlanEmployee(
    state: PlansStateModel,
    plan: Data.IPlan,
    employees: Data.IEmployee[],
  ): Data.IEmployee {
    return employees?.find((employee) => employee?.relationships?.plans?.data?.id === plan.id);
  }

  @Selector([PlansState.newPlan, EmployeesState.employees])
  public static newPlanEmployee(state: PlansStateModel, plan: Data.IPlan, employees: Data.IEmployee[]): Data.IEmployee {
    return employees?.find((employee) => employee?.relationships?.plans?.data?.id === plan.id);
  }

  constructor(private plans: PlansService, private toastr: NbToastrService) {}

  @Action(GetUserPlans)
  public async getUserPlans(ctx: StateContext<PlansStateModel>, action: GetUserPlans) {
    ctx.patchState({ loading: true });
    const plans = await this.plans.getUserPlans(action.payload.userId).toPromise();
    const state = ctx.getState();

    ctx.setState({
      ...state,
      loading: false,
      entities: createEntities(plans.data, 'id'),
      meta: plans.meta as Data.IPagination,
    });
  }

  @Action(GetPlans)
  public async getPlans(ctx: StateContext<PlansStateModel>, action: GetPlans) {
    ctx.patchState({ loading: true });

    const { onlyMine, search, statuses, pagination, sort, listKey } = action.payload;
    const response = await this.plans.getPlans(statuses, onlyMine, search, pagination, sort).toPromise();
    const state = ctx.getState();

    const key = listKey || 'entities';

    ctx.setState({
      ...state,
      loading: false,
      [key]: listKey ? response.data : createEntities(response.data, 'id'),
      meta: response.meta as Data.IPagination,
    });

    this.updateStores(ctx, response.included);

    ctx.dispatch(new GetPlansSuccess());
  }

  @Action(GetPlan)
  public async getPlan(ctx: StateContext<PlansStateModel>, action: GetPlan) {
    const state = ctx.getState();

    ctx.patchState({
      loading: true,
    });

    const query =
      !state.lastFetchedPlanId || (state.lastFetchedPlanId === action.payload.planId && !action.payload.query)
        ? state.lastFetchedQuery
        : action.payload.query;

    const plan = await this.plans.getPlan(action.payload.planId, query).toPromise();

    ctx.setState({
      ...state,
      loading: false,
      entities: { ...state.entities, ...createEntities([plan.data], 'id') },
      lastFetchedPlanId: action.payload.planId,
      lastFetchedQuery: action.payload.query,
    });

    this.updateStores(ctx, plan.included);
  }

  @Action(UpdatePlan)
  public async updatePlan(ctx: StateContext<PlansStateModel>, action: UpdatePlan) {
    ctx.patchState({ loading: true });
    const state = ctx.getState();

    try {
      const res = await this.plans.updatePlan(action.payload).toPromise();

      ctx.setState({
        ...state,
        loading: false,
        entities: { ...state.entities, ...createEntities(res ? [res.data] : [], 'id') },
      });

      this.updateStores(ctx, res.included);

      if (res && res.data) {
        ctx.dispatch(new UpdatePlanSuccess({ plan: res.data }));
      }
    } catch (err) {
      ctx.patchState({ loading: false });
    }
  }

  @Action(UpdatePlanSuccess)
  public async updatePlanSuccess(ctx: StateContext<PlansStateModel>, action: UpdatePlanSuccess) {
    this.toastr.success(
      `${action.payload.plan.attributes.organization_name}'s plan updated`,
      'Plan Successfully Updated',
    );
  }

  @Action(CreatePlan)
  public async createPlan(ctx: StateContext<PlansStateModel>, action: CreatePlan) {
    ctx.patchState({ loading: true });

    const state = ctx.getState();
    const response = await this.plans.createPlan(action.payload).toPromise();
    const plan = response.data;

    ctx.setState({
      ...state,
      loading: false,
      entities: { ...state.entities, ...createEntities([plan], 'id') },
    });

    ctx.dispatch(new CreatePlanSuccess(plan));
  }

  @Action(CreatePlanSuccess)
  public async createPlanSuccess(ctx: StateContext<PlansStateModel>, action: CreatePlanSuccess) {}

  @Action(CreatePlanFromExisting)
  public async createPlanFromExisting(ctx: StateContext<PlansStateModel>, action: CreatePlanFromExisting) {
    ctx.patchState({ loading: true });

    const payload: Data.IPlan = {
      id: null,
      type: DataType.Plans,
      attributes: null,
      meta: {
        rtq_id: action.payload.planId,
      },
    };

    const state = ctx.getState();
    const response = await this.plans.createPlan(payload).toPromise();
    const plan = response.data;

    ctx.setState({
      ...state,
      loading: false,
      entities: { ...state.entities, ...createEntities([plan], 'id') },
    });

    ctx.dispatch(new CreatePlanSuccess(plan));
  }

  @Action(GetDefaultPlans)
  public async getDefaultPlans(ctx: StateContext<PlansStateModel>, action: GetDefaultPlans) {
    const defaultPlans = await this.plans.getDefaultPlans().toPromise();

    ctx.setState(
      patch({
        defaultPlans: createEntities(defaultPlans.data, 'id'),
      }),
    );
  }

  @Action(GetCarriers)
  public async getCarriers(ctx: StateContext<PlansStateModel>) {
    const carriers = await this.plans.getCarriers().toPromise();

    ctx.setState({
      ...ctx.getState(),
      carriers: createEntities(carriers.data, 'id'),
    });

    this.updateStores(ctx, carriers.included);
  }

  @Action(DeletePlan)
  public deletePlan(ctx: StateContext<PlansStateModel>, action: DeletePlan) {
    const id = action.payload.planId;
    const plan = ctx.getState().entities[id];

    return this.plans.deletePlan(id).pipe(
      tap((res) => {
        this.toastr.success(`${plan.attributes.organization_name} quote was deleted.`, 'Delete successful');

        const { [id]: removed, ...entities } = ctx.getState().entities;

        ctx.patchState({
          entities,
        });
      }),
    );
  }

  @Action(UpdatePlanAssociate)
  public async updatePlanAssociate(ctx: StateContext<PlansStateModel>, action: UpdatePlanAssociate) {
    ctx.patchState({ loading: true });
    const state = ctx.getState();
    const res = await this.plans
      .updatePlanAssociate({
        planId: action.payload.planId,
        type: action.payload.type,
        userId: action.payload.user.id,
      })
      .toPromise();

    ctx.setState({
      ...state,
      loading: false,
      entities: { ...state.entities, ...createEntities(res ? [res.data] : [], 'id') },
    });

    this.updateStores(ctx, [action.payload.user, ...(res.included ? res.included : [])]);

    if (res && res.data) {
      this.toastr.success(`${res.data.attributes.organization_name}'s plan updated`, 'Plan Successfully Updated');
    }
  }

  @Action(RemovePlanAssociate)
  public async removePlanAssociate(ctx: StateContext<PlansStateModel>, action: RemovePlanAssociate) {
    ctx.patchState({ loading: true });
    const state = ctx.getState();
    const res = await this.plans.removePlanAssociate({ planId: action.payload.planId }).toPromise();

    ctx.setState({
      ...state,
      loading: false,
      entities: { ...state.entities, ...createEntities(res ? [res.data] : [], 'id') },
    });

    this.updateStores(ctx, [...(res.included ? res.included : [])]);

    if (res && res.data) {
      this.toastr.success(`${res.data.attributes.organization_name}'s plan updated`, 'Plan Successfully Updated');
    }
  }

  @Action(AddPlanEmployees)
  public addPlanEmployees(ctx: StateContext<PlansStateModel>, action: AddPlanEmployees) {
    const newEmployees = action.payload.employeeIds.map((id) => ({ type: DataType.Employees, id })) as IEmployee[];

    ctx.patchState({
      entities: {
        ...ctx.getState().entities,
        [action.payload.planId]: {
          ...ctx.getState().entities[action.payload.planId],
          relationships: {
            ...ctx.getState().entities[action.payload.planId].relationships,
            employees: {
              data: [...ctx.getState().entities[action.payload.planId].relationships.employees.data, ...newEmployees],
            },
          },
        },
      },
    });
  }

  @Action(CreatePlanRenewal)
  public async addPlanRenewal(ctx: StateContext<PlansStateModel>, action: CreatePlanRenewal) {
    ctx.patchState({ loading: true });

    const state = ctx.getState();
    const response = await this.plans.createPlanRenewal(action.payload.planId).toPromise();
    const renewal = response.data;

    const activePlan = state.entities[action.payload.planId];

    ctx.setState({
      ...state,
      loading: false,
      renewals: {
        ...state.renewals,
        ...createEntities([renewal], 'id'),
      },
      entities: {
        ...state.entities,
        [activePlan.id]: {
          ...activePlan,
          relationships: {
            ...activePlan.relationships,
            renewals: {
              data: [{ ...renewal }],
            },
          },
        },
      },
    });

    this.updateStores(ctx, [...(response.included ? response.included : [])]);
  }

  @Action(GetPlanRenewal)
  public async getPlanRenewal(ctx: StateContext<PlansStateModel>, action: GetPlanRenewal) {
    ctx.patchState({ loading: true });

    const state = ctx.getState();
    const response = await this.plans.getPlanRenewal(action.payload.renewalId).toPromise();
    const renewal = response.data;
    const segments = response.included.filter((i) => i.type === DataType.Segments) as ISegment[];

    ctx.setState({
      ...state,
      loading: false,
      renewals: {
        ...state.renewals,
        ...createEntities([renewal], 'id'),
      },
      segments: {
        ...state.segments,
        ...createEntities(segments, 'id'),
      },
    });

    this.updateStores(ctx, [...(response.included ? response.included : [])]);
  }

  @Action(UpdatePlanRenewal)
  public async updatePlanRenewal(ctx: StateContext<PlansStateModel>, action: UpdatePlanRenewal) {
    ctx.patchState({ loading: true });

    const state = ctx.getState();
    const response = await this.plans.updatePlanRenewal(action.payload.renewal).toPromise();
    const renewal = response.data;
    const segments = response.included.filter((i) => i.type === DataType.Segments) as ISegment[];

    ctx.setState({
      ...state,
      loading: false,
      renewals: {
        ...state.renewals,
        ...createEntities([renewal], 'id'),
      },
      segments: {
        ...state.segments,
        ...createEntities(segments, 'id'),
      },
    });

    this.updateStores(ctx, [...(response.included ? response.included : [])]);

    if (renewal) {
      this.toastr.success(`Renewal updated`, 'Plan Successfully Updated');
    }
  }

  @Action(GetRenewalSegment)
  public async getRenewalSegment(ctx: StateContext<PlansStateModel>, action: GetRenewalSegment) {
    ctx.patchState({ loading: true });

    const state = ctx.getState();
    const response = await this.plans.getRenewalSegment(action.payload.segmentId).toPromise();
    const segments = response.data;

    ctx.setState({
      ...state,
      loading: false,
      segments: {
        ...state.segments,
        ...createEntities([segments], 'id'),
      },
    });

    this.updateStores(ctx, [...(response.included ? response.included : [])]);
  }

  @Action(UpdateRenewalSegment)
  public async updateRenewalSegment(ctx: StateContext<PlansStateModel>, action: UpdateRenewalSegment) {
    ctx.patchState({ loading: true });

    const state = ctx.getState();
    const response = await this.plans.updateRenewalSegment(action.payload.segment).toPromise();
    const renewal = response.data;
    const segments = response.included.filter((i) => i.type === DataType.Segments) as ISegment[];

    ctx.setState({
      ...state,
      loading: false,
      renewals: {
        ...state.renewals,
        ...createEntities([renewal], 'id'),
      },
      segments: {
        ...state.segments,
        ...createEntities(segments, 'id'),
      },
    });

    this.updateStores(ctx, [...(response.included ? response.included : [])]);

    if (renewal) {
      this.toastr.success(`Renewal updated`, 'Plan Successfully Updated');
    }
  }

  @Action(IncludePlans)
  public includePlans(ctx: StateContext<PlansStateModel>, action: IncludePlans) {
    ctx.setState(
      patch<PlansStateModel>({
        entities: patch(createEntities(action.payload, 'id')),
      }),
    );
  }

  @Action(IncludePlanRenewals)
  public includePlanRenewals(ctx: StateContext<PlansStateModel>, action: IncludePlanRenewals) {
    ctx.setState(
      patch<PlansStateModel>({
        renewals: patch(createEntities(action.payload, 'id')),
      }),
    );
  }

  @Action(PlanConfirmations)
  public async confirmPlanEmployess(
    ctx: StateContext<PlansStateModel>,
    { payload: { confirmation, planId } }: PlanConfirmations,
  ) {
    ctx.patchState({ loading: true });
    const state = ctx.getState();
    const res = await this.plans.planConfirmation(planId, confirmation).toPromise();

    ctx.setState({
      ...state,
      loading: false,
      entities: { ...state.entities, ...createEntities(res ? [res.data] : [], 'id') },
    });

    this.updateStores(ctx, res.included);

    if (res && res.data) {
      this.toastr.success(`${res.data.attributes.organization_name}'s plan updated`, 'Plan Successfully Updated');
    }
  }

  // TODO Move/merge with other state's `updateStores` to a shared spot...
  // That just takes in any `included` and updates the appropriate stores
  private updateStores(ctx: StateContext<PlansStateModel>, included: Data.ResourceObject[]) {
    const plans = included.filter((i) => i.type === DataType.Plans) as Data.IPlan[];
    const employees = included.filter((i) => i.type === DataType.Employees) as Data.IEmployee[];
    const users = included.filter((i) => i.type === DataType.Users) as Data.IUser[];
    const media = included.filter((i) => i.type === DataType.Media) as Data.IMedia[];
    const rates = included.filter((i) => i.type === DataType.Rates) as Data.IRate[];
    const quotes = included.filter((i) => i.type === DataType.Quotes) as Data.IQuote[];
    const organizations = included.filter((i) => i.type === DataType.Organizations) as Data.IOrganization[];
    const renewals = included.filter((i) => i.type === DataType.Renewals) as Data.IRenewal[];
    const paymentInfos = included.filter((i) => i.type === DataType.PaymentInformation) as Data.IPaymentInformation[];

    if (plans.length) {
      ctx.dispatch(new IncludePlans(plans));
    }

    if (media.length) {
      ctx.dispatch(new IncludeMedia(media));
    }

    if (users.length) {
      ctx.dispatch(new IncludeUsers(users));
    }

    if (employees.length) {
      ctx.dispatch(new IncludeEmployees(employees));
    }

    if (rates.length) {
      ctx.dispatch(new IncludeRates(rates));
    }

    if (paymentInfos.length) {
      ctx.dispatch(new IncludePaymentInfos(paymentInfos));
    }

    if (quotes.length) {
      ctx.dispatch(new IncludeQuotes(quotes));
    }

    if (organizations.length) {
      ctx.dispatch(new IncludeOrganizations(organizations));
    }

    if (renewals.length) {
      ctx.dispatch(new IncludePlanRenewals(renewals));
    }
  }

  private static plansToList(state: PlansStateModel, users: UsersStateModel, listKey: PlanListKey) {
    return Object.values(state[listKey]).map((plan): Data.IPlanListItem => {
      const repId = (plan?.relationships?.rep?.data as RelationshipData<IUser>)?.id;
      const associateId = (plan?.relationships?.associate?.data as RelationshipData<IUser>)?.id;

      return {
        id: plan.id,
        rep:
          users.entities[repId] &&
          `${users.entities[repId].attributes.first_name} ${users.entities[repId].attributes.last_name}`,
        associate:
          users.entities[associateId] &&
          `${users.entities[associateId].attributes.first_name} ${users.entities[associateId].attributes.last_name}`,
        start_at: plan.attributes.start_at,
        organization_name: plan.attributes.organization_name,
        status: plan.attributes.status,
        expire_at: plan.attributes.expire_at,
        created_at: plan.attributes.created_at,
        updated_at: plan.attributes.updated_at,
        renewal_at: plan.relationships?.predecessor?.data ? plan.attributes.start_at : plan.attributes.expire_at,
      };
    });
  }

  private static getPlanMedia(plan: Data.IPlan, media: EntityMap<Data.IMedia>): Data.IPlanMedia {
    const planMedia =
      (plan.relationships.media.data as Data.RelationshipData<Data.IMedia>[])
        .filter((i) => media[i.id])
        .map((i) => media[i.id]) || [];

    return {
      [MediaType.AdditionalDocument]: planMedia.filter((i) => i.attributes.type === MediaType.AdditionalDocument),
      [MediaType.BenefitsBooklet]: planMedia.filter((i) => i.attributes.type === MediaType.BenefitsBooklet),
      [MediaType.BenefitsTeam]: planMedia.find((i) => i.attributes.type === MediaType.BenefitsTeam),
      [MediaType.Census]: planMedia.find((i) => i.attributes.type === MediaType.Census),
      [MediaType.Contract]: planMedia.filter((i) => i.attributes.type === MediaType.Contract),
      [MediaType.EP3]: planMedia.find((i) => i.attributes.type === MediaType.EP3),
      [MediaType.FinalBill]: planMedia.find((i) => i.attributes.type === MediaType.FinalBill),
      [MediaType.MasterApplication]: planMedia.find((i) => i.attributes.type === MediaType.MasterApplication),
      [MediaType.Miscellaneous]: planMedia.filter((i) => i.attributes.type === MediaType.Miscellaneous),
      [MediaType.PlanOther]: planMedia.filter((i) => i.attributes.type === MediaType.PlanOther),
      [MediaType.PayorAuthorization]: planMedia.find((i) => i.attributes.type === MediaType.PayorAuthorization),
      [MediaType.PlanHelp]: planMedia.find((i) => i.attributes.type === MediaType.PlanHelp),
      [MediaType.Proposal]: planMedia.find((i) => i.attributes.type === MediaType.Proposal),
      [MediaType.Quote]: planMedia.filter((i) => i.attributes.type === MediaType.Quote),
      [MediaType.Renewal]: planMedia.filter((i) => i.attributes.type === MediaType.Renewal),
      [MediaType.SalesTaxForm]: planMedia.find((i) => i.attributes.type === MediaType.SalesTaxForm),
      [MediaType.SecureOnlineAccess]: planMedia.find((i) => i.attributes.type === MediaType.SecureOnlineAccess),
      [MediaType.TerminationLetter]: planMedia.find((i) => i.attributes.type === MediaType.TerminationLetter),
      [MediaType.VoidCheque]: planMedia.find((i) => i.attributes.type === MediaType.VoidCheque),
    };
  }
}
