<div class="p-5" *ngIf="isLoading">
  <nb-spinner status="primary"></nb-spinner>
</div>

<table [nbTreeGrid]="dataSource" nbSort (sort)="changeSort($event)">
  <tr nbTreeGridHeaderRow *nbTreeGridHeaderRowDef="columns"></tr>
  <tr nbTreeGridRow *nbTreeGridRowDef="let row; columns: columns"></tr>

  <ng-container nbTreeGridColumnDef="company">
    <th nbTreeGridHeaderCell *nbTreeGridHeaderCellDef [nbSortHeader]="getDirection('company')">Company</th>
    <td nbTreeGridCell *nbTreeGridCellDef="let row">{{ row.data.company }}</td>
  </ng-container>

  <ng-container nbTreeGridColumnDef="advisors">
    <th nbTreeGridHeaderCell *nbTreeGridHeaderCellDef [nbSortHeader]="getDirection('advisor')">Advisors</th>
    <td nbTreeGridCell *nbTreeGridCellDef="let row">
      <p *ngFor="let advisor of row.data.advisors">{{ advisor }}</p>
    </td>
  </ng-container>

  <ng-container nbTreeGridColumnDef="status">
    <th nbTreeGridHeaderCell *nbTreeGridHeaderCellDef [nbSortHeader]="getDirection('status')">Status</th>
    <td nbTreeGridCell *nbTreeGridCellDef="let row">
      <span class="badge" [ngClass]="row.data.status | split: { split: ' ', replace: '' }">{{ row.data.status }}</span>
    </td>
  </ng-container>

  <ng-container nbTreeGridColumnDef="requestedDate">
    <th nbTreeGridHeaderCell *nbTreeGridHeaderCellDef [nbSortHeader]="getDirection('requestedDate')">Requested date</th>
    <td nbTreeGridCell *nbTreeGridCellDef="let row">{{ row.data.requestedDate | date }}</td>
  </ng-container>

  <ng-container nbTreeGridColumnDef="dueDate">
    <th nbTreeGridHeaderCell *nbTreeGridHeaderCellDef [nbSortHeader]="getDirection('dueDate')">Due date</th>
    <td nbTreeGridCell *nbTreeGridCellDef="let row">{{ row.data.dueDate | date }}</td>
  </ng-container>

  <ng-container nbTreeGridColumnDef="actions">
    <th nbTreeGridHeaderCell *nbTreeGridHeaderCellDef></th>
    <td nbTreeGridCell *nbTreeGridCellDef="let row">
      <a
        nbButton
        status="primary"
        size="tiny"
        ghost
        [routerLink]="['/', paths.ROOT, row.data.id, paths.Detail]"
        routerLinkActive="router-link-active"
      >
        <nb-icon icon="edit-outline"></nb-icon>
        View/Update
      </a>
    </td>
  </ng-container>
</table>
