<div class="row justify-content-center">
  <div class="col-xl-6">
    <h2>Standards</h2>
    <pip-field-status-alert
      *ngIf="(plan$ | async)?.meta?.field_statuses?.standards as standards"
      [fieldStatus]="standards"
      key="confirmed"
    ></pip-field-status-alert>
    <pip-quote-standards-form
      *ngIf="plan$ | async as plan"
      [plan]="plan"
      [showStartAt]="false"
      (save)="onUpdatePlan($event)"
      (cancel)="onCancel()"
    ></pip-quote-standards-form>
  </div>
</div>
