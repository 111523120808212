export enum ClaimType {
  LTD = 'LTD',
  STD = 'STD',
  EI = 'EI',
  Other = 'Other',
  ParentalMaintainAll = 'ParentalAll',
  ParentalMaintainAllWithoutLTD = 'ParentalMaintainAllWithoutLTD',
  ParentalDiscontinue = 'ParentalDiscontinue',
}

export const ClaimTypeDictionary: Record<ClaimType, string> = {
  [ClaimType.LTD]: 'LTD',
  [ClaimType.STD]: 'STD',
  [ClaimType.EI]: 'EI',
  [ClaimType.Other]: 'Other',
  [ClaimType.ParentalMaintainAll]: 'Parental',
  [ClaimType.ParentalMaintainAllWithoutLTD]: 'Parental',
  [ClaimType.ParentalDiscontinue]: 'Parental',
};
