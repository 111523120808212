import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'pip-status-indicator',
  templateUrl: './status-indicator.component.html',
  styleUrls: ['./status-indicator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusIndicatorComponent implements OnInit {
  @Input() isGood: boolean = null;

  public get icon(): string {
    switch (this.isGood) {
      case null: {
        return 'radio-button-off';
      }
      case true: {
        return 'checkmark-outline';
      }
      case false: {
        return 'alert-triangle-outline';
      }
    }
  }

  public get status(): string {
    switch (this.isGood) {
      case undefined: {
        return 'default';
      }
      case true: {
        return 'success';
      }
      case false: {
        return 'warning';
      }
    }
  }

  constructor() {}

  ngOnInit() {}
}
