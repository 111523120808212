import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { CanadianProvinces, ClaimType, IPaymentInformation, IRate, USStates } from '@app/data';
import { EarningsFrequency, EarningsFrequencyDictionary } from '@app/data/enums/earnings-frequency.enum';

@Component({
  selector: 'pip-employee-occupation-formgroup',
  templateUrl: './employee-occupation-formgroup.component.html',
  styleUrls: ['./employee-occupation-formgroup.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class EmployeeOccupationFormgroupComponent implements OnInit {
  @Input() public rates: IRate[];
  @Input() public billingDivisions: IPaymentInformation[];

  public canadianProvinces = CanadianProvinces;
  public usStates = USStates;
  public claimType = ClaimType;
  public earningsFrequency = EarningsFrequency;
  public earningsFrequencyDictionary = EarningsFrequencyDictionary;

  constructor(public controlContainer: ControlContainer) {}

  public ngOnInit(): void {}
}
