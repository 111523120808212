import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { GetPlan, IPlan, PlansState, PlanStatus, Role, UsersState } from '@app/data';
import { RouterState } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';

const Roles = [PlanStatus.InProgress, PlanStatus.Proposal, PlanStatus.RTQ, PlanStatus.Setup, PlanStatus.Signoff];

@Component({
  selector: 'pip-quote-tasks',
  templateUrl: './quote-tasks.component.html',
  styleUrls: ['./quote-tasks.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuoteTasksComponent implements OnInit {
  public plan$: Observable<IPlan> = this.store.select(PlansState.activePlan);

  public showDelete$: Observable<boolean> = this.plan$.pipe(
    withLatestFrom(this.store.select(UsersState.activeUserRoles)),
    map(([plan, roles]) => roles?.includes(Role.Advisor) && Roles.includes(plan?.attributes.status as PlanStatus)),
  );

  constructor(private store: Store) {}

  ngOnInit() {
    const planId = this.store.selectSnapshot(RouterState).state.params.planId;
    this.store.dispatch(new GetPlan({ planId }));
  }
}
