<nb-card status="basic">
  <nb-card-header>
    Client documents
  </nb-card-header>
  <nb-card-body>
    <ng-container *ngIf="(planMedia$ | async) && (plan$ | async)">
      <pip-media-uploader
        [existingMedia]="(planMedia$ | async)[mediaType.Census]"
        [mediaType]="mediaType.Census"
        [modelId]="(plan$ | async).id"
        [mediaModel]="mediaModel.Plan"
      ></pip-media-uploader>
    </ng-container>
  </nb-card-body>
</nb-card>
