import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import {
  CreateRate,
  CreateRateSuccess,
  IPlan,
  IPlanAttributes,
  IRate,
  PlansState,
  ResourceObject,
  Role,
  UpdatePlan,
  UpdateRate,
  UsersState,
} from '@app/data';
import { RatesFormComponent } from '@app/shared/components/forms/rates-form/rates-form.component';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { combineLatest } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Component({
  selector: 'pip-plan-rates',
  templateUrl: './plan-rates.component.html',
  styleUrls: ['./plan-rates.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class PlanRatesComponent implements OnInit {
  public plan$ = this.store.select(PlansState.activePlan);
  public rates$ = this.store.select(PlansState.activePlanRates);
  public roles$ = this.store.select(UsersState.activeUserRoles);
  public userRoles$ = this.store.select(UsersState.activeUserRoles);
  public isAnalyst$ = this.userRoles$.pipe(map((roles) => roles.includes(Role.Analyst)));
  public isAdvisor$ = this.userRoles$.pipe(map((roles) => roles.includes(Role.Advisor)));
  public isAdvisorOrAnalyst$ = combineLatest([this.isAnalyst$, this.isAdvisor$]).pipe(
    map(([isAnalyst, isAdvisor]) => isAdvisor || isAnalyst),
  );

  @ViewChild('CreateForm') private createForm: RatesFormComponent;

  constructor(private store: Store, private actions$: Actions) {}

  public ngOnInit(): void {}

  public onUpdate(plan: IRate): void {
    this.store.dispatch(new UpdateRate(plan));
  }

  public onCreate(rate: IRate, planId: string): void {
    this.actions$.pipe(ofActionSuccessful(CreateRateSuccess), take(1)).subscribe((_) => {
      this.createForm.form.reset();
    });

    this.store.dispatch(new CreateRate({ rate, planId }));
  }

  public onSalaryThresholdUpdate(maxes: Partial<IPlanAttributes>, plan: IPlan): void {
    const payload: IPlan = {
      id: plan.id,
      type: plan.type,
      attributes: { ...maxes },
    };

    this.store.dispatch(new UpdatePlan(payload));
  }

  public trackById(index: number, item: ResourceObject): string {
    return item.id;
  }
}
