import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { IDefaultPlan, IFieldStatus, IPlan, IPlanMedia, PlansState, PlanStatus } from '@app/data';
import { Select } from '@ngxs/store';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'pip-plan-detail',
  templateUrl: './plan-detail.component.html',
  styleUrls: ['./plan-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanDetailComponent implements OnInit {
  @Select(PlansState.activePlan) public plan$: Observable<IPlan>;
  @Select(PlansState.defaultPlans) public defaultPlans$: Observable<IDefaultPlan[]>;
  @Select(PlansState.loading) public loading$: Observable<boolean>;
  @Select(PlansState.activePlanMedia) public planMedia$: Observable<IPlanMedia>;

  public fieldStatus$: Observable<IFieldStatus>;
  public locked$: Observable<boolean>;

  constructor() {}

  public ngOnInit(): void {
    this.fieldStatus$ = this.plan$.pipe(map((plan) => plan.meta.field_statuses.organization_info));
    this.locked$ = this.plan$.pipe(
      map((plan) => plan?.attributes?.status === PlanStatus.Setup || plan?.attributes.status === PlanStatus.Confirmed),
    );
  }
}
