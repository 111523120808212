<div>
  <p>
    I hereby apply for coverage (&quot;Coverage&quot;) under the Group Benefits plan issued to my plan sponsor by the incumbent insurance
    provider. I understand that certain aspects of such Coverage may extend to my spouse and eligible dependants (collectively,
    &quot;Dependants&quot;). I certify that the information in this form is true and complete to the best of my knowledge. I understand that
    as the applicant, it is my responsibility to ensure that any further verbal or written statement provided by me, and/or my Dependants,
    in the future is true and complete to the best of our knowledge. I acknowledge and agree that this Coverage or any portion of this
    Coverage, and future claims thereunder may be denied or terminated as a result of the provision of false, incomplete, or misleading
    information. I authorize the incumbent insurance provider to collect, use, maintain and disclose personal information relevant to this
    application (&quot;Information&quot;) for the purposes of Group Benefits plan administration, audit, assessment, investigation, claim
    management, underwriting and for determining plan eligibility (&quot;Purposes&quot;). I authorize any person or organization with
    Information, including any medical and health professionals, facilities or providers, professional regulatory bodies, any employer,
    group plan administrator, insurer, investigative agency, and any administrators of other benefits programs to collect, use, maintain and
    exchange this information with each other and with the incumbent insurance provider, its reinsurers and/or its service providers, for
    the Purposes. I am authorized by my Dependants to consent to this Authorization, on their behalf as if they were signing it themselves,
    and to disclose and receive their Information, for the Purposes. I authorize my plan sponsor to make deductions from my pay for my Group
    Benefits plan, if applicable. I authorize the use of my Social Insurance Number (&quot;SIN&quot;) for the purposes of identification and
    administration, if my SIN is used as my plan member certificate number. I agree a photocopy or electronic version of this authorization
    is valid.
  </p>
  <p>
    If applicable, I authorize the incumbent insurance provider to deposit all payments (&quot;Payments&quot;) due to me from the above
    referenced Group Benefits policy (&quot;Policy&quot;), into the bank account (&quot;Account&quot;) that I have identified on this form.
    I confirm that this direct bank deposit authorization applies to the financial institution herein named by me and any other financial
    institution I choose to name in the future; and shall remain valid until revoked in writing by me, or my duly authorized representative.
    I understand and agree that upon the deposit of any Payment(s) into the Account, the incumbent insurance provider is fully discharged
    from any further liability with respect to such Payment(s). I also understand and agree that the incumbent insurance provider may, at
    any time and without prior notice, discontinue the direct deposit of Payment(s), as requested herein, and require my personal written
    endorsement relating to future Payment(s). I also hereby acknowledge and agree that any Payment(s) made by the incumbent insurance
    provider into the Account, to which I am not entitled, either by contract or by law, shall not form part of my property, and shall be
    immediately refunded to the incumbent insurance provider, either by me or by representatives of my estate.
  </p>
  <p>
    If applicable, I authorize the incumbent insurance provider to correspond with me through the email address identified on this form
    regarding my Coverage, for the Purposes. I understand such correspondence may contain Information; and that the Information is being
    sent in a manner that is not guaranteed as a secured means of communication. I agree that the incumbent insurance is not liable for
    damages which I may incur as a result of interception by a third party of an email transmission sent by the incumbent insurance provider
    or by me pursuant to this authorization. I agree should the email address identified on this form change that I am responsible for
    updating the email address maintained by he incumbent insurance provider. I understand that if I do not wish to receive emails from the
    incumbent insurance provider, I can remove my email address online or by contacting the Customer Service Center.
  </p>
  <p>
    I understand that any Information provided to or collected by the incumbent insurance provider in accordance with this authorization,
    will be kept in a Group Benefits life, health or disability file. Access to my Information will be limited to:
  </p>
  <ul>
    <li>
      The incumbent insurance provider’s employees, representatives, reinsurers, and service providers in the performance of their jobs;
    </li>
    <li>Persons to whom I have granted access; and</li>
    <li>Persons authorized by law.</li>
  </ul>
  <p>
    I have the right to request access to the personal information in my file, and, where appropriate, to have any inaccurate information
    corrected.
  </p>
</div>

<form [formGroup]="form" (ngSubmit)="onSubmit(employee, warningDialog)">
  <div class="form-group row">
    <div class="col">
      <nb-checkbox id="acknowledgement" formControlName="acknowledgement"
        >I acknowledge that more specific details regarding how and why the incumbent insurance provider collects, uses, maintains, and
        discloses my personal information can be found in the incumbent insurance provider&#39;s Privacy Policy and Privacy Information
        Package available from my Plan Sponsor or Administrator.</nb-checkbox
      >
    </div>
  </div>
  <div class="form-group row">
    <div class="col">
      <label for="signature" class="label d-block">
        Please enter <strong>{{ reqSignature }}</strong> to sign
      </label>
      <input nbInput fullWidth name="signature" id="signature" type="text" formControlName="signature" />
    </div>
  </div>
  <div class="form-group row">
    <div class="col">
      <button nbButton status="primary" type="submit" [disabled]="!form.valid">
        Submit
      </button>
    </div>
  </div>
</form>

<ng-template #warningDialog let-data let-ref="dialogRef">
  <nb-card status="basic">
    <nb-card-header>Please Confirm</nb-card-header>
    <nb-card-body>
      <p>After you click Confirm, you will no longer be able to make changes to your enrollment.</p>
    </nb-card-body>
    <nb-card-footer class="d-flex justify-content-between">
      <button nbButton status="primary" ghost (click)="ref.close(false)">Close</button>
      <button nbButton status="primary" (click)="ref.close(true)">Confirm</button>
    </nb-card-footer>
  </nb-card>
</ng-template>
