import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ControlContainer, FormControl } from '@angular/forms';
import { CanadianProvinces, USStates } from '@app/data';
import { MaritalStatus } from '@app/data/enums/marital-status.enum';

@Component({
  selector: 'pip-employee-detail-formgroup',
  templateUrl: './employee-detail-formgroup.component.html',
  styleUrls: ['./employee-detail-formgroup.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class EmployeeDetailFormgroupComponent implements OnInit {
  public canadianProvinces = CanadianProvinces;
  public usStates = USStates;
  public martialStatus = MaritalStatus;

  public get maritalStatusCtrl(): FormControl {
    return this.controlContainer.control.get('marital_status') as FormControl;
  }

  constructor(public controlContainer: ControlContainer) {}

  public ngOnInit(): void {}
}
