<div class="row justify-content-center">
  <div class="col">
    <h2>Payment Information</h2>
    <pip-field-status-alert
      *ngIf="(plan$ | async)?.meta?.field_statuses?.payment_info as payment_info"
      [fieldStatus]="payment_info"
      key="confirmed"
    ></pip-field-status-alert>
    <pip-plan-payment-info></pip-plan-payment-info>
  </div>
</div>
