import { map, Observable, tap } from 'rxjs';

import { Injectable } from '@angular/core';

import { IRate, Response } from '../models';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class RatesService {
  private params = { include: 'plans' };

  constructor(private api: ApiService) {}

  public getPlanRates(planId: string): Observable<Response<IRate[]>> {
    return this.api.get(['plans', planId, 'relationships', 'Rate'], { params: this.params }).pipe(
      map((res) => ({
        ...res,
        data: [...(res.data as IRate[]).map(this.transformIncomingRate)],
      })),
    );
  }

  public getRate(rateId: string): Observable<Response<IRate>> {
    return this.api
      .get(['rates', rateId], { params: this.params })
      .pipe(map((res) => ({ ...res, data: this.transformIncomingRate(res.data as IRate) })));
  }

  public createRate(rate: IRate, planId: string): Observable<Response<IRate>> {
    const data = this.transformOutgoingRate(rate);

    return this.api.post(['plans', planId, 'relationships', 'rates'], data, { params: this.params });
  }

  public updateRate(rate: IRate): Observable<Response<IRate>> {
    const data = this.transformOutgoingRate(rate);

    return this.api.patch(['rates', data.id], data, { params: this.params });
  }

  public deleteRate(id: string): Observable<Response<null>> {
    return this.api.delete(['rates', id], { params: this.params });
  }

  public transformIncomingRate(rate: IRate): IRate {
    /** HACK: Transform numbers to strings.
     * The current version of `ngx-mask` sets numbers to 0 when field is empty
     * but strings will be empty strings when empty
     */

    const transformed = {
      ...rate,
      attributes: {
        ...rate.attributes,
        add: rate.attributes.add?.toString(),
        eap: rate.attributes.eap?.toString(),
        ltd: rate.attributes.ltd?.toString(),
        std: rate.attributes.std?.toString(),
        life: rate.attributes.life?.toString(),
        healthcare_single: rate.attributes.healthcare_single?.toString(),
        healthcare_single_parent: rate.attributes.healthcare_single_parent?.toString(),
        healthcare_couple: rate.attributes.healthcare_couple?.toString(),
        healthcare_family: rate.attributes.healthcare_family?.toString(),
        dental_single: rate.attributes.dental_single?.toString(),
        dental_single_parent: rate.attributes.dental_single_parent?.toString(),
        dental_couple: rate.attributes.dental_couple?.toString(),
        dental_family: rate.attributes.dental_family?.toString(),
        dependent_life: rate.attributes.dependent_life?.toString(),
        critical_illness: rate.attributes.critical_illness?.toString(),
        life_max: rate.attributes.life_max?.toString(),
        std_max: rate.attributes.std_max?.toString(),
        ltd_max: rate.attributes.ltd_max?.toString(),
        critical_illness_max: rate.attributes.critical_illness_max?.toString(),
        life_volume: rate.attributes.life_volume?.toString(),
        critical_illness_volume: rate.attributes.critical_illness_volume?.toString(),
        virtual_care: rate.attributes.virtual_care?.toString(),
      },
    };

    return transformed;
  }

  private transformOutgoingRate(rate: IRate): IRate {
    return {
      ...rate,
      attributes: {
        ...rate.attributes,
        add: parseFloat(rate.attributes.add?.toString()),
        eap: parseFloat(rate.attributes.eap?.toString()),
        ltd: parseFloat(rate.attributes.ltd?.toString()),
        std: parseFloat(rate.attributes.std?.toString()),
        life: parseFloat(rate.attributes.life?.toString()),
        healthcare_single: parseFloat(rate.attributes.healthcare_single?.toString()),
        healthcare_single_parent: parseFloat(rate.attributes.healthcare_single_parent?.toString()),
        healthcare_couple: parseFloat(rate.attributes.healthcare_couple?.toString()),
        healthcare_family: parseFloat(rate.attributes.healthcare_family?.toString()),
        dental_single: parseFloat(rate.attributes.dental_single?.toString()),
        dental_single_parent: parseFloat(rate.attributes.dental_single_parent?.toString()),
        dental_couple: parseFloat(rate.attributes.dental_couple?.toString()),
        dental_family: parseFloat(rate.attributes.dental_family?.toString()),
        dependent_life: parseFloat(rate.attributes.dependent_life?.toString()),
        critical_illness: parseFloat(rate.attributes.critical_illness?.toString()),
        life_max: parseFloat(rate.attributes.life_max?.toString()),
        std_max: parseFloat(rate.attributes.std_max?.toString()),
        ltd_max: parseFloat(rate.attributes.ltd_max?.toString()),
        critical_illness_max: parseFloat(rate.attributes.critical_illness_max?.toString()),
        life_volume: parseFloat(rate.attributes.life_volume?.toString()),
        critical_illness_volume: parseFloat(rate.attributes.critical_illness_volume?.toString()),
        virtual_care: parseFloat(rate.attributes.virtual_care?.toString()),
      },
    };
  }
}
