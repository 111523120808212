import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import * as Data from '../models';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class MediaService {
  constructor(private api: ApiService) {}

  public uploadMedia(data: FormData): Observable<Data.Response<Data.IMedia>> {
    return this.api.post(`media`, data);
  }

  public importEmployeeData(data: FormData): Observable<Data.Response<Data.IEmployee[]>> {
    return this.api.post(`media`, data);
  }

  public deleteMedia(mediaId: string): Observable<Data.Response<Data.ResourceObjectOrObjects>> {
    return this.api.delete(['media', mediaId]);
  }
}
