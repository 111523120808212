<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="form-row">
    <div class="form-group col-md-6">
      <div class="form-row">
        <div class="form-group col">
          <label class="label">Employee</label>
          <nb-select fullWidth formControlName="id">
            <nb-option *ngFor="let employee of offwork" [value]="employee.id">
              {{ employee.attributes.last_name }}, {{ employee.attributes.first_name }}
              <span class="text-hint caption">&nbsp;({{ employee.attributes.born_at | date }})</span>
            </nb-option>
          </nb-select>
        </div>
        <div class="form-group col flex-grow-0">
          <label class="label">Claim type</label>
          <nb-select fullWidth formControlName="claim_type">
            <nb-option [value]="claimType.STD">STD</nb-option>
            <nb-option [value]="claimType.LTD">LTD</nb-option>
            <nb-option [value]="claimType.EI">EI</nb-option>
            <nb-option [value]="claimType.Other">Other</nb-option>
          </nb-select>
        </div>
        <div class="form-group col flex-grow-0">
          <label class="label">Waiver approved</label>
          <nb-select fullWidth formControlName="waiver_approved">
            <nb-option [value]="true">Yes</nb-option>
            <nb-option [value]="false">No</nb-option>
          </nb-select>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col">
          <label class="label">Cause of claim (condition)</label>
          <input type="text" nbInput fullWidth formControlName="claim_cause" />
        </div>
      </div>
    </div>
    <div class="form-group col">
      <div class="form-row">
        <div class="form-group col">
          <label for="start_date" class="label">Date claim began</label>
          <input
            type="text"
            nbInput
            fullWidth
            id="start_date"
            formControlName="claim_start"
            readonly
            [nbDatepicker]="claimStartDatepicker"
          />
          <nb-datepicker format="yyyy-MM-dd" #claimStartDatepicker></nb-datepicker>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col">
          <label for="end_date" class="label">Date expected to return</label>
          <input
            type="text"
            nbInput
            fullWidth
            id="end_date"
            formControlName="claim_end"
            readonly
            [nbDatepicker]="claimEndDatepicker"
          />
          <nb-datepicker format="yyyy-MM-dd" #claimEndDatepicker></nb-datepicker>
        </div>
        <div class="form-group col flex-grow-0 d-flex align-items-end">
          <button nbButton status="primary" type="submit" [disabled]="form.valid === false">Add</button>
        </div>
      </div>
    </div>
  </div>
</form>
