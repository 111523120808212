import { Injectable } from '@angular/core';
import { RatesService } from '@app/data/services/rates.service';
import * as Utils from '@app/utils';
import { NbToastrService } from '@nebular/theme';
import { Action, State, StateContext } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { tap } from 'rxjs/operators';
import { DataType } from '../../enums';
import * as Data from '../../models';
import { IPlan } from '../../models';
import { GetPlan, IncludePlans } from '../plans/plans.actions';
import { CreateRate, CreateRateSuccess, DeleteRate, GetRate, IncludeRates, UpdateRate } from './rates.actions';

export interface RatesStateModel {
  entities: EntityMap<Data.IRate>;
}

export const ratesStateDefaults: RatesStateModel = {
  entities: {},
};

@State<RatesStateModel>({
  name: DataType.Rates,
  defaults: ratesStateDefaults,
})
@Injectable()
export class RatesState {
  constructor(public ratesService: RatesService, public toastr: NbToastrService) {}

  @Action(IncludeRates)
  public includeUsers(ctx: StateContext<RatesStateModel>, action: IncludeRates) {
    const rates = action.payload.map(this.ratesService.transformIncomingRate);

    ctx.setState(
      patch<RatesStateModel>({
        entities: patch(Utils.createEntities(rates, 'id')),
      }),
    );
  }

  @Action(GetRate)
  public async getRate(ctx: StateContext<RatesStateModel>, action: GetRate) {
    const rates = await this.ratesService.getRate(action.payload.rateId).toPromise();
    const state = ctx.getState();

    ctx.setState({
      ...state,
      entities: { ...state.entities, ...Utils.createEntities([rates.data], 'id') },
    });

    const plans: IPlan[] = Array.isArray(rates.included)
      ? (rates.included.filter((i) => i.type === DataType.Plans) as IPlan[])
      : [];
    ctx.dispatch(new IncludePlans(plans));
  }

  @Action(CreateRate)
  public createRate(ctx: StateContext<RatesStateModel>, action: CreateRate) {
    return this.ratesService.createRate(action.payload.rate, action.payload.planId).pipe(
      tap((res) => {
        const rate = res.data;

        ctx.setState(
          patch<RatesStateModel>({
            entities: patch({ [rate.id]: rate }),
          }),
        );

        ctx.dispatch([new CreateRateSuccess(rate), new GetPlan({ planId: action.payload.planId })]);
      }),
    );
  }

  @Action(UpdateRate)
  public updateEmployee(ctx: StateContext<RatesStateModel>, action: UpdateRate) {
    return this.ratesService.updateRate(action.payload).pipe(
      tap((res) => {
        const rate = res.data;

        ctx.setState(
          patch<RatesStateModel>({
            entities: patch({ [rate.id]: rate }),
          }),
        );

        this.toastr.success(`${rate.attributes.name} was updated.`, 'Update successful');
      }),
    );
  }

  @Action(DeleteRate)
  public deleteEmployee(ctx: StateContext<RatesStateModel>, action: DeleteRate) {
    const id = action.payload;
    const rate = ctx.getState().entities[id];

    return this.ratesService.deleteRate(id).pipe(
      tap((res) => {
        this.toastr.success(`${rate.attributes.name} was deleted.`, 'Delete successful');

        const { [id]: removed, ...entities } = ctx.getState().entities;

        ctx.patchState({
          entities,
        });
      }),
    );
  }
}
