<form [formGroup]="form" (submit)="onSubmit()">
  <nb-card status="basic" [nbSpinner]="loading" nbSpinnerStatus="primary">
    <nb-card-body>
      <fieldset formGroupName="payment_information">
        <legend class="subtitle">Account</legend>
        <pip-media-uploader
          [existingMedia]="planMedia[mediaType.VoidCheque]"
          [mediaType]="mediaType.VoidCheque"
          [modelId]="plan.id"
          [mediaModel]="mediaModel.Plan"
        ></pip-media-uploader>

        <div class="form-row">
          <div class="form-group col">
            <label for="institution_id" class="label">Institution ID</label>
            <input type="text" nbInput fullWidth id="institution_id" formControlName="institution_id" />
          </div>
          <div class="form-group col">
            <label for="transit" class="label">Account Transit</label>
            <input type="text" nbInput fullWidth id="transit" formControlName="transit" />
          </div>
          <div class="form-group col">
            <label for="account_number" class="label">Account number</label>
            <input type="text" nbInput fullWidth id="account_number" formControlName="account_number" />
          </div>
        </div>
      </fieldset>
      <fieldset formGroupName="payment_information">
        <legend class="subtitle">Method</legend>
        <div class="form-row">
          <div class="form-group col">
            <label for="method" class="label">Method</label>
            <nb-radio-group class="d-flex" id="method" formControlName="method">
              <nb-radio [disabled]="form.disabled" value="automatic">Automatic monthly withdrawal</nb-radio>
              <nb-radio [disabled]="form.disabled" value="manual">Manual monthly mail-in (pay by cheque)</nb-radio>
            </nb-radio-group>
          </div>
        </div>
      </fieldset>
      <fieldset *ngIf="showSalesTaxFormUpload">
        <legend class="subtitle">Sales Tax Form</legend>
        <a [href]="carrierSalesTaxForm" nbButton status="primary" outline class="mb-3">
          <nb-icon icon="cloud-download-outline"></nb-icon>
          Download sales tax form
        </a>

        <pip-media-uploader
          [downloadTemplate]="carrierSalesTaxForm"
          [existingMedia]="planSalesTaxForm"
          [mediaType]="mediaType.SalesTaxForm"
          [modelId]="plan.id"
          [mediaModel]="mediaModel.Plan"
        ></pip-media-uploader>
      </fieldset>

      <fieldset class="legal" *ngIf="carrier">
        <iframe style="width: 100%" class="border" [src]="padURL" sandbox="allow-scripts"></iframe>

        <nb-checkbox formControlName="payment_signoff">
          I hereby consent to the disclosure of the information contained in my pre-authorized debit enrolment agreement
          to the financial institution, provided such information is directly related to and required for the smooth
          application of the rules governing pre-authorized debits.
        </nb-checkbox>
      </fieldset>
    </nb-card-body>
    <nb-card-footer class="d-flex justify-content-between">
      <button nbButton status="primary" ghost type="button" (click)="cancel.emit()">{{ cancelButton }}</button>
      <button [disabled]="form.invalid || form.disabled" nbButton status="primary" type="submit">
        {{ confirmButton }}
      </button>
    </nb-card-footer>
  </nb-card>
</form>
