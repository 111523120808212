<div class="row justify-content-center">
  <div class="col-xl-6">
    <h2>Confirm the Plan</h2>
    <pip-field-status-alert
      *ngIf="(plan$ | async)?.meta?.field_statuses?.insurance_info as insurance_info"
      [fieldStatus]="insurance_info"
      key="confirmed"
    ></pip-field-status-alert>
    <pip-confirm-plan-form
      *ngIf="plan$ | async as plan"
      [plan]="plan"
      [quoteDownload]="(selectedQuote$ | async)?.links?.self"
      (save)="onUpdatePlan($event)"
      (cancel)="onCancel()"
    ></pip-confirm-plan-form>
  </div>
</div>
