export enum QuoteStatus {
  Submitted = 'Submitted',
  Selected = 'Selected',
  Dead = 'Dead',
  Declined = 'Declined',
  NotStarted = 'Request to quote',
  InProgress = 'In Progress',
  Sold = 'Sold',
}

export const QuoteStatusDictionary: Record<QuoteStatus, string> = {
  [QuoteStatus.Submitted]: 'Submitted',
  [QuoteStatus.Selected]: 'Submitted',
  [QuoteStatus.Dead]: 'Submitted',
  [QuoteStatus.Declined]: 'Declined',
  [QuoteStatus.NotStarted]: 'Request to quote',
  [QuoteStatus.InProgress]: 'In Progress',
  [QuoteStatus.Sold]: 'Sold',
};
