<form [formGroup]="form" (submit)="onSubmit()">
  <nb-card status="basic" [nbSpinner]="loading" nbSpinnerStatus="primary">
    <nb-card-header class="d-flex justify-content-between align-items-center">
      Summaries
      <button type="button" nbButton status="primary" size="small" (click)="addDocument.emit(plan)">
        <nb-icon icon="plus-circle-outline"></nb-icon>
        Add document
      </button>
    </nb-card-header>
    <ng-container *ngIf="hasSummaries; then Summaries; else NoSummaries"></ng-container>

    <!-- Zero State -->
    <ng-template #NoSummaries>
      <nb-card-body>
        <nb-alert status="info">Currently awaiting summaries from Ingenious.</nb-alert>
      </nb-card-body>
    </ng-template>

    <!-- Summary groupings -->
    <ng-template #Summaries>
      <nb-card-body>
        <div class="row">
          <div class="col" *ngFor="let summary of summaries | keyvalue">
            <div class="h5 pb-1 mb-2">{{ summary.key }}</div>
            <ul class="list-unstyled">
              <li *ngFor="let item of summaryDocs(summary.value)" class="d-flex">
                <span class="mr-auto">{{ item.label }}</span>
                <a nbButton status="primary" size="tiny" ghost [href]="item.media.links['self']"
                  ><nb-icon icon="download-outline"></nb-icon
                ></a>
                <button nbButton status="danger" size="tiny" ghost type="button" class="caption" (click)="deleteMedia.emit(item.media.id)">
                  <nb-icon icon="trash-2-outline"></nb-icon>
                </button>
              </li>
            </ul>
            <div class="form-group">
              <div *ngFor="let item of summaryQuotes(summary.value)" class="d-flex">
                <nb-radio class="mr-auto" name="summaryQuotes" formControlName="option_selected" [value]="item.media.id">
                  {{ item.label }}
                </nb-radio>
                <a nbButton status="primary" size="tiny" ghost [href]="item.media.links['self']"
                  ><nb-icon icon="download-outline"></nb-icon
                ></a>
                <button nbButton status="danger" size="tiny" ghost type="button" class="caption" (click)="deleteMedia.emit(item.media.id)">
                  <nb-icon icon="trash-2-outline"></nb-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
      </nb-card-body>
    </ng-template>

    <nb-card-footer class="d-flex justify-content-end">
      <button nbButton status="primary" type="submit">Save</button>
    </nb-card-footer>
  </nb-card>
</form>
