import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IUser, RemovePlanAssociate, Role, UpdatePlanAssociate } from '@app/data';
import { UsersService } from '@app/data/services';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Component({
  selector: 'pip-associate-selector',
  templateUrl: './associate-selector.component.html',
  styleUrls: ['./associate-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssociateSelectorComponent {
  @Input() public type: 'associate' | 'rep' = 'associate';
  @Input() public planId: string;
  @Input() public user: IUser;
  @Input() public canEdit: boolean = false;

  @Output() public userSelected: EventEmitter<IUser> = new EventEmitter();

  public users$: Observable<IUser[]>;

  public get emptyUserName(): string {
    return `No client ${this.type}`;
  }

  public get emptyUserTitle(): string {
    return `This client currently has no ${this.type.toLowerCase()} selected.`;
  }

  private dialogRef: NbDialogRef<any>;

  constructor(
    private store: Store,
    private actions$: Actions,
    public dialog: NbDialogService,
    public usersService: UsersService,
  ) {}

  public handleEdit(templateRef: TemplateRef<any>): void {
    const form = new FormGroup({
      search: new FormControl(null, [Validators.required]),
    });

    this.dialogRef = this.dialog.open(templateRef, {
      context: {
        form,
        showRemoveBtn: this.type === 'associate' && !!this.user,
      },
    });
  }

  public handleSearch(formValues): void {
    this.users$ = this.usersService
      .getUsers({
        search: formValues.search,
        roles: [Role.Advisor],
        insurance_type: this.type === 'rep' ? 'Group' : null,
      })
      .pipe(map((res) => res.data));
  }

  public handleUserSelect(user: IUser): void {
    if (!this.planId) {
      this.userSelected.emit(user);
      this.dialogRef.close();
      return;
    }

    this.actions$
      .pipe(ofActionSuccessful(UpdatePlanAssociate))
      .pipe(take(1))
      .subscribe(() => this.dialogRef.close());

    this.store.dispatch(new UpdatePlanAssociate({ planId: this.planId, type: this.type, user }));
  }

  public handleRemove(user: IUser): void {
    this.actions$
      .pipe(ofActionSuccessful(RemovePlanAssociate))
      .pipe(take(1))
      .subscribe(() => this.dialogRef.close());

    this.store.dispatch(new RemovePlanAssociate({ planId: this.planId }));
  }
}
