import { NgModule } from '@angular/core';
import { NbDateFnsDateModule } from '@nebular/date-fns';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import {
  NbAccordionModule,
  NbActionsModule,
  NbAlertModule,
  NbBadgeModule,
  NbButtonGroupModule,
  NbButtonModule,
  NbCardModule,
  NbCheckboxModule,
  NbContextMenuModule,
  NbDatepickerModule,
  NbDialogModule,
  NbFormFieldModule,
  NbIconModule,
  NbInputModule,
  NbLayoutModule,
  NbListModule,
  NbMenuModule,
  NbRadioModule,
  NbSelectModule,
  NbSidebarModule,
  NbSpinnerModule,
  NbStepperModule,
  NbToastrModule,
  NbToggleModule,
  NbTooltipModule,
  NbTreeGridModule,
  NbUserModule,
} from '@nebular/theme';
import { dialogConfig, toastrConfig } from './config';

@NgModule({
  imports: [
    NbAccordionModule,
    NbActionsModule,
    NbAlertModule,
    NbBadgeModule,
    NbButtonModule,
    NbCardModule,
    NbCheckboxModule,
    NbContextMenuModule,
    NbDatepickerModule.forRoot(),
    NbDialogModule.forRoot(dialogConfig),
    NbEvaIconsModule,
    NbIconModule,
    NbInputModule,
    NbLayoutModule,
    NbListModule,
    NbMenuModule.forRoot(),
    // NbMomentDateModule,
    NbDateFnsDateModule.forRoot({
      format: 'yyyy-MM-dd',
      parseOptions: { useAdditionalWeekYearTokens: true, useAdditionalDayOfYearTokens: true },
      formatOptions: { useAdditionalWeekYearTokens: true, useAdditionalDayOfYearTokens: true },
    }),
    NbRadioModule,
    NbSelectModule,
    NbSidebarModule.forRoot(),
    NbSpinnerModule,
    NbStepperModule,
    NbToastrModule.forRoot(toastrConfig),
    NbToggleModule,
    NbTreeGridModule,
    NbUserModule,
    NbButtonGroupModule,
    NbFormFieldModule,
  ],
  exports: [
    NbAccordionModule,
    NbActionsModule,
    NbAlertModule,
    NbBadgeModule,
    NbButtonModule,
    NbCardModule,
    NbCheckboxModule,
    NbContextMenuModule,
    NbDatepickerModule,
    NbDialogModule,
    NbEvaIconsModule,
    NbIconModule,
    NbInputModule,
    NbLayoutModule,
    NbListModule,
    NbMenuModule,
    NbRadioModule,
    NbSelectModule,
    NbSidebarModule,
    NbSpinnerModule,
    NbStepperModule,
    NbToastrModule,
    NbToggleModule,
    NbTreeGridModule,
    NbUserModule,
    NbTooltipModule,
    NbButtonGroupModule,
    NbFormFieldModule,
  ],
})
export class NebularModule {}
