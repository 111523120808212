import { Pipe, PipeTransform } from '@angular/core';
import { PaAccess, PaAccessDictionary } from '@app/data/enums/pa-access.enum';

@Pipe({
  name: 'paAccess',
})
export class PaAccessPipe implements PipeTransform {
  public transform(key: PaAccess): string {
    if (!PaAccessDictionary[key]) {
      console.warn(`PaAccessDictionary doesn't contain:`, key);
      return key;
    }

    return PaAccessDictionary[key];
  }
}
