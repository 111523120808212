<div *ngFor="let menuItem of visibleMenu">
  <a
    nbButton
    status="basic"
    fullWidth
    ghost
    class="d-flex justify-content-start position-relative px-1"
    [routerLink]="['./', menuItem.link]"
    [class.status-primary]="isActivePage(menuItem.link)"
  >
    <span>{{ menuItem.label }}</span>

    <nb-badge
      class="position-relative ml-1"
      *ngIf="menuItem.badge"
      [status]="menuItem.badge.status"
      [text]="menuItem.badge.label"
    ></nb-badge>
  </a>
</div>
