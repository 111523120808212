<table class="table table-bordered bg-white">
  <thead>
    <tr>
      <th>Task</th>
      <th class="text-center">Status</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let task of tasks">
      <td class="align-middle">
        <a [routerLink]="task.routerLink">{{ task.label }}</a>
      </td>
      <td class="text-center">
        <pip-status-indicator *ngIf="task.fieldStatus" [isGood]="task.fieldStatus[property]"></pip-status-indicator>
      </td>
    </tr>
  </tbody>
</table>
