<fieldset [formGroup]="controlContainer.control">
  <legend class="subtitle">Company Information</legend>

  <div class="form-group row">
    <label for="organization_name" class="col col-form-label label">Organization name</label>
    <div class="col-sm-9">
      <input type="text" nbInput fullWidth id="organization_name" placeholder="ACME Inc." formControlName="organization_name" />
    </div>
  </div>

  <div class="form-group row">
    <label for="industry" class="col col-form-label label">Industry</label>
    <div class="col-sm-9">
      <nb-select fullWidth id="industry" formControlName="industry">
        <nb-option *ngFor="let opt of industryOpts" [value]="opt.value">{{ opt.label }}</nb-option>
      </nb-select>
    </div>
  </div>

  <div class="form-group row">
    <label for="website" class="col col-form-label label">Website <small class="text-hint">(optional)</small></label>
    <div class="col-sm-9">
      <input type="text" nbInput fullWidth id="website" formControlName="website"/>
    </div>
  </div>
  <div class="form-group row">
    <label for="language" class="col col-form-label label">Language</label>
    <div class="col-sm-9">
      <nb-select fullWidth id="language" formControlName="language">
        <nb-option *ngFor="let opt of languages" [value]="opt.value">{{ opt.label }}</nb-option>
      </nb-select>
    </div>
  </div>
</fieldset>
