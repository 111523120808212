import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  CreatePlan, CreatePlanSuccess, GetPlan, IDefaultPlan, IPlan, IPlanMedia, PlansState, UpdatePlan,
} from '@app/data';
import { Navigate, RouterState } from '@ngxs/router-plugin';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';

import { combineLatest, Observable, Subject } from 'rxjs';
import { filter, map, take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'pip-quote-wizard',
  templateUrl: './quote-wizard.component.html',
  styleUrls: ['./quote-wizard.component.scss'],
})
export class QuoteWizardComponent implements OnInit, OnDestroy {
  public instantiateWizard$: Observable<boolean>;
  public plan$: Observable<IPlan> = this.store.select(PlansState.activePlan);
  public planMedia$: Observable<IPlanMedia> = this.store.select(PlansState.activePlanMedia);
  public defaultPlans$: Observable<IDefaultPlan[]> = this.store.select(PlansState.defaultPlans);

  // Check queryparams for `virgin=true` and if we have a plan
  // Return queryparam or plan.attributes.virgin or default to false if nothing is present
  public isVirgin$: Observable<boolean> = combineLatest([
    this.store.select(RouterState).pipe(map(s => s.state.params.virgin === 'true')),
    this.plan$.pipe(map(p => p && p.attributes.virgin)),
  ]).pipe(map(([rs, plan]) => rs || plan || false));

  private unsub$ = new Subject<void>();
  private updateSub$;

  constructor(private store: Store, private actions: Actions, private route: ActivatedRoute) {}

  public ngOnInit(): void {
    this.actions.pipe(ofActionSuccessful(CreatePlanSuccess), takeUntil(this.unsub$)).subscribe(res => {
      this.store.dispatch(new Navigate(['./', { planId: res.payload.id }], null, { relativeTo: this.route }));
    });

    this.route.params
      .pipe(
        filter(params => params.planId),
        takeUntil(this.unsub$),
      )
      .subscribe(params => this.store.dispatch(new GetPlan({ planId: params.planId })));

    this.instantiateWizard$ = combineLatest([this.route.params, this.plan$]).pipe(
      map(([params, plan]) => {
        if (!params.planId) {
          return true;
        }

        if (plan && plan.id) {
          return true;
        }
      }),
    );
  }

  public ngOnDestroy(): void {
    this.unsub$.next();
    this.unsub$.complete();
  }

  public onUpdatePlan({ plan, onSuccess }: { plan: IPlan; onSuccess(): void }): void {
    this.store
      .dispatch(plan.id ? new UpdatePlan(plan) : new CreatePlan(plan))
      .pipe(take(1))
      .subscribe(() => onSuccess());
  }
}
