import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';

@Component({
  selector: 'pip-password-form',
  templateUrl: './password-form.component.html',
  styleUrls: ['./password-form.component.scss'],
})
export class PasswordFormComponent implements OnInit {
  @Input() public userId: string;
  public form: FormGroup;

  @Output() private updatePassword: EventEmitter<{ password: string; id: string }> = new EventEmitter();

  constructor() {}

  public ngOnInit(): void {
    this.form = new FormGroup(
      {
        password: new FormControl(null, [Validators.required, Validators.minLength(6)]),
        password_confirm: new FormControl(null, Validators.required),
      },
      { validators: [this.validatePassword] },
    );
  }

  public onSubmit(): void {
    const payload: { password: string; id: string } = {
      password: this.form.get('password').value,
      id: this.userId,
    };

    this.updatePassword.emit(payload);
  }

  private validatePassword(group: FormGroup): ValidationErrors {
    const pass = group.get('password').value;
    const confirmPass = group.get('password_confirm').value;

    return pass === confirmPass ? null : { notSame: true };
  }
}
