import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Administrator, ICarrier, IPlan, Plan, PlanStatus } from '@app/data';
import { PaAccess } from '@app/data/enums/pa-access.enum';
import { yearMonthDay } from '@app/utils/year-month-day';
import { parseISO } from 'date-fns';
import * as moment from 'moment';

@Component({
  selector: 'pip-plan-administrator-form',
  templateUrl: './plan-administrator-form.component.html',
  styleUrls: ['./plan-administrator-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanAdministratorFormComponent implements OnInit {
  @Input() public plan: IPlan;
  @Input() public carrier: ICarrier;
  @Input() public confirmButton: string = 'Save';
  @Input() public requirePaAccess = true;
  @Output() public save = new EventEmitter<IPlan>();

  public form: FormGroup;
  public paAccess = PaAccess;

  public get showSecurityFields(): boolean {
    return this.plan && this.plan.attributes.status === PlanStatus.EnrollClient;
  }

  constructor() {}

  public ngOnInit(): void {
    this.initForm();
  }

  public onSubmit(): void {
    const { id, type } = this.plan || Plan.create();

    const { administrator, pa_access } = this.form.value;

    const plan: IPlan = {
      id,
      type,
      attributes: {
        ...(this.requirePaAccess && { pa_access }),
        administrator: {
          ...this.plan.attributes.administrator,
          ...administrator,
          born_at: yearMonthDay(administrator.born_at),
        },
      },
    };

    this.save.emit(plan);
  }

  private initForm(): void {
    const paAccessPattern = new RegExp(`^(?!.*${PaAccess.None}).*$`);

    const { attributes } = this.plan || Plan.create();
    const { administrator, pa_access } = attributes;

    const { email, first_name, last_name, phone, born_at, mothers_name } = administrator || Administrator.create();

    this.form = new FormGroup({
      administrator: new FormGroup({
        first_name: new FormControl(first_name, [Validators.required]),
        last_name: new FormControl(last_name, [Validators.required]),
        email: new FormControl(email, [Validators.required]),
        phone: new FormControl(phone, [Validators.required]),
        ...(this.showSecurityFields && {
          born_at: new FormControl(born_at && parseISO(born_at), [Validators.required]),
          mothers_name: new FormControl(mothers_name, [Validators.required]),
        }),
      }),
      pa_access: new FormControl(
        pa_access,
        this.requirePaAccess ? [Validators.required, Validators.pattern(new RegExp(paAccessPattern, 'i'))] : [],
      ),
    });

    if (this.plan.attributes.status === PlanStatus.EnrollClient) {
      this.form.disable();
      this.form.get('pa_access').enable();
      this.form.get('administrator.born_at').enable();
      this.form.get('administrator.mothers_name').enable();
    }
  }
}
