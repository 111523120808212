<fieldset [formGroup]="controlContainer.control">
  <legend class="subtitle">Business Address</legend>

  <div class="form-group row">
    <label for="address1" class="col col-form-label label">Address</label>
    <div class="col-sm-9">
      <input type="text" nbInput fullWidth id="address1" formControlName="address1" />
    </div>
  </div>

  <div class="form-group row">
    <label for="address2" class="col col-form-label label">Unit <small class="text-hint">(optional)</small></label>
    <div class="col-sm-9">
      <input type="text" nbInput fullWidth id="address2" formControlName="address2" />
    </div>
  </div>

  <div class="form-group row">
    <label for="city" class="col col-form-label label">City</label>
    <div class="col-sm-9">
      <input type="text" nbInput fullWidth id="city" formControlName="city" />
    </div>
  </div>

  <div class="form-group row" *ngIf="withCountry">
    <label for="country" class="col col-form-label label">Country</label>
    <div class="col-sm-9">
      <nb-select id="country" fullWidth formControlName="country" (selectedChange)="controlContainer.control.get('state').setValue(null)">
        <nb-option value="" selected disabled>Select country</nb-option>
        <nb-option [value]="country.Canada">Canada</nb-option>
        <nb-option [value]="country.USA">USA</nb-option>
      </nb-select>
    </div>
  </div>

  <div class="form-group row">
    <label for="state" class="col col-form-label label">
      <span *ngIf="countryCtrl.value === country.Canada">Province</span>
      <span *ngIf="countryCtrl.value === country.USA">State</span>
    </label>
    <div class="col-sm-9">
      <nb-select fullWidth id="provinceState" formControlName="state">
        <nb-option value="" selected disabled>Select province or state</nb-option>
        <nb-option *ngFor="let province of provinces" [value]="province.value">{{ province.label }}</nb-option>
      </nb-select>
    </div>
  </div>

  <div class="form-group row">
    <label for="postal_code" class="col col-form-label label">
      <span *ngIf="countryCtrl.value === country.Canada">Postal code</span>
      <span *ngIf="countryCtrl.value === country.USA">Zip code</span>
    </label>
    <div class="col-sm-9">
      <input type="text" nbInput fullWidth id="postal_code" formControlName="postal_code" />
    </div>
  </div>
</fieldset>
