<button
  [ngClass]="{ invis: isHidden }"
  nbButton
  status="primary"
  ghost
  size="tiny"
  class="px-1"
  [disabled]="isHidden"
  (click)="onSelect()"
>
  <nb-icon
    style="vertical-align: middle; font-size: 0.5em"
    icon="radio-button-on"
    [status]="isComplete ? 'success' : 'warning'"
  ></nb-icon>
</button>
