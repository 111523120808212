<form [formGroup]="form" (ngSubmit)="onSubmit()" class="form-horizontal">
  <nb-card>
    <nb-card-header>Profile</nb-card-header>
    <nb-card-body>
      <pip-user-details-from-group formGroupName="userDetails" [isAdvisor]="isAdvisor"></pip-user-details-from-group>
      <pip-business-address-formgroup *ngIf="isAdvisor" formGroupName="userDetails" [withCountry]="false"></pip-business-address-formgroup>
    </nb-card-body>
    <nb-card-footer class="d-flex justify-content-end">
      <button nbButton status="primary" type="submit">Update profile</button>
    </nb-card-footer>
  </nb-card>
</form>
