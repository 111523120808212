import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgxMaskModule } from 'ngx-mask';
import { NgxPaginationModule } from 'ngx-pagination';
import { components } from './components';
import { containers } from './containers';
import { directives } from './directives';
import { NebularModule } from './nebular/nebular.module';
import { pipes } from './pipes';

const sharedModules: any[] = [
  CommonModule,
  ReactiveFormsModule,
  FormsModule,
  RouterModule,
  NebularModule,
  NgxPaginationModule,
  NgxMaskModule.forRoot({ thousandSeparator: ',' }),
];

@NgModule({
  declarations: [...containers, ...components, ...pipes, ...directives],
  imports: [...sharedModules],
  exports: [...sharedModules, ...containers, ...components, ...pipes, ...directives],
})
export class SharedModule {}
