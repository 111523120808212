import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import {
  CreatePaymentInfo,
  CreatePaymentInfoSuccess,
  IPaymentInformation,
  IPlan,
  PlansState,
  QuotesState,
  ResourceObject,
  UpdatePlan,
} from '@app/data';
import { PaymentInformationFormComponent } from '@app/shared/components/forms/payment-information-form/payment-information-form.component';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { map, take } from 'rxjs/operators';

@Component({
  selector: 'pip-plan-payment-info',
  templateUrl: './plan-payment-info.component.html',
  styleUrls: ['./plan-payment-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanPaymentInfoComponent implements OnInit {
  @ViewChild('CreateForm') private createPaymentInfoComponent: PaymentInformationFormComponent;

  public plan$ = this.store.select(PlansState.activePlan);
  public carrier$ = this.store.select(QuotesState.selectedCarrier);
  public paymentInfos$ = this.store.select(PlansState.activePlanPaymentInfos);
  public isAutomaticPayments$ = this.plan$.pipe(map((plan) => plan.attributes.payment_method === 'automatic'));

  constructor(private store: Store, private actions$: Actions) {}

  public ngOnInit(): void {}

  public onSave(payload: IPlan): void {
    this.store.dispatch(new UpdatePlan(payload));
  }

  public onCreate(paymentInfo: IPaymentInformation, planId: string): void {
    this.actions$.pipe(ofActionSuccessful(CreatePaymentInfoSuccess), take(1)).subscribe((_) => {
      this.createPaymentInfoComponent.form.reset();
    });

    this.store.dispatch(new CreatePaymentInfo({ paymentInfo: paymentInfo, planId }));
  }

  public trackById(_: number, item: ResourceObject): string {
    return item.id;
  }

  public updatePaymentMethod(checked: boolean, plan: IPlan): void {
    const payload: IPlan = {
      ...plan,
      attributes: {
        payment_method: checked ? 'automatic' : 'manual',
      },
    };

    this.store.dispatch(new UpdatePlan(payload));
  }
}
