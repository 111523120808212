import { HttpParams } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { GetPlan } from '@app/data';
import { IPaginatedLink } from '@app/data/models/paginated-link';
import { Store } from '@ngxs/store';

@Component({
  selector: 'pip-employee-search',
  templateUrl: './employee-search.component.html',
  styleUrls: ['./employee-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmployeeSearchComponent implements OnInit {
  @Input() public planId: string;
  @Input() public paginatedLinks: IPaginatedLink[];

  public employeeSearch: string;
  public employeeComplete?: boolean;

  constructor(private store: Store, private cd: ChangeDetectorRef) {}

  public ngOnInit(): void {}

  public onSearch(employeeSearch: string): void {
    this.employeeSearch = employeeSearch;

    this.fetchEmployees();
  }

  public onFilterComplete(complete?: boolean): void {
    this.employeeComplete = complete;
    this.cd.markForCheck();

    this.fetchEmployees();
  }

  private fetchEmployees(): void {
    if (!this.employeeComplete && !this.employeeSearch) {
      return;
    }
    const query = new HttpParams({
      fromObject: {
        ...(this.employeeSearch && { employeeSearch: this.employeeSearch }),
        ...(this.employeeComplete && { employeeComplete: this.employeeComplete }),
      },
    });

    this.store.dispatch(new GetPlan({ planId: this.planId, query }));
  }
}
