import { HttpParams } from '@angular/common/http';
import { PlanStatus } from '@app/data/enums';
import { PlanConfirmation } from '@app/data/enums/plan-confirmation.enum';
import { IPagination, IPlan, IRenewal, ISegment, IUser } from '../../models';
import { PlanListKey } from './plans.models';

export class GetDefaultPlans {
  public static readonly type = '[Plans] Get Default Plans';
}

export class GetCarriers {
  public static readonly type = '[Plans] Get Carriers';
}

export class GetUserPlans {
  public static readonly type = '[Plans] Get User Plans';
  constructor(public payload: { userId: string }) {}
}

export class GetPlans {
  public static readonly type = '[Plans] Get Plans';
  constructor(
    public payload: {
      statuses?: PlanStatus[];
      onlyMine?: boolean;
      search?: string;
      pagination?: IPagination;
      sort?: string;
      listKey?: PlanListKey;
    },
  ) {}
}

export class GetPlansSuccess {
  public static readonly type = '[Plans] Get Plans Success';
  constructor() {}
}

export class GetPlan {
  public static readonly type = '[Plans] Get Plan';
  constructor(public payload: { planId: string; query?: HttpParams }) {}
}

export class CreatePlan {
  public static readonly type = '[Plans] Create Plan';
  constructor(public payload: IPlan) {}
}

export class CreatePlanSuccess {
  public static readonly type = '[Plans] Create Plan Success';
  constructor(public payload: IPlan) {}
}

export class UpdatePlan {
  public static readonly type = '[Plans] Update Plan';
  constructor(public payload: IPlan) {}
}

export class UpdatePlanSuccess {
  public static readonly type = '[Plans] Update Plan Success';
  constructor(public payload: { plan: IPlan }) {}
}

export class DeletePlan {
  public static readonly type = '[Plans] Delete Plan';
  constructor(public payload: { planId: string }) {}
}

export class AddPlanEmployees {
  public static readonly type = '[Plans] Add Plan Employees';
  constructor(public payload: { planId: string; employeeIds: string[] }) {}
}

export class IncludePlans {
  public static readonly type = '[Plans] Include Plans';
  constructor(public payload: IPlan[]) {}
}

export class PlanConfirmations {
  public static readonly type = '[Plans] Confirm Plan Employees';
  constructor(public payload: { planId: string; confirmation: PlanConfirmation }) {}
}

export class UpdatePlanAssociate {
  public static readonly type = '[Plans] Update Plan Associate';
  constructor(public payload: { planId: string; type: 'rep' | 'associate'; user: IUser }) {}
}

export class RemovePlanAssociate {
  public static readonly type = '[Plans] Remove Plan Associate';
  constructor(public payload: { planId: string }) {}
}

export class CreatePlanRenewal {
  public static readonly type = '[Plans] Create Plan Renewal';
  constructor(public payload: { planId: string }) {}
}

export class GetPlanRenewal {
  public static readonly type = '[Plans] Get Plan Renewal';
  constructor(public payload: { renewalId: string }) {}
}

export class IncludePlanRenewals {
  public static readonly type = '[Plans] Include Plan Renewals';
  constructor(public payload: IRenewal[]) {}
}
export class UpdatePlanRenewal {
  public static readonly type = '[Plans] Update Plan Renewal';
  constructor(public payload: { renewal: IRenewal }) {}
}

export class GetRenewalSegment {
  public static readonly type = '[Plans] Get Renewal Segment';
  constructor(public payload: { segmentId: string }) {}
}

export class UpdateRenewalSegment {
  public static readonly type = '[Plans] Update Renewal Segment';
  constructor(public payload: { segment: ISegment }) {}
}

export class CreatePlanFromExisting {
  public static readonly type = '[Plans] Create Plan From Existing';
  constructor(public payload: { planId: string }) {}
}
