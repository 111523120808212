<form [formGroup]="form" (ngSubmit)="saveSegment()" *ngIf="!limited; else LimitedView">
  <div class="form-group row">
    <p for="created_at" class="col col-form-label label">Date</p>
    <div class="col-sm-9">
      {{ segment.attributes.created_at | date: 'longDate' }}
    </div>
  </div>

  <div class="row my-3">
    <div class="col">
      <pip-media-uploader
        class="d-block w-100"
        [uploadLabelOverride]="'Upload ' + (segment.attributes.type | segmentType)"
        [existingMedia]="proposalDoc"
        [modelId]="segment.id"
        [mediaType]="mediaType.Proposal"
        [mediaModel]="mediaModel.Segment"
      ></pip-media-uploader>
    </div>
  </div>

  <div class="form-group row">
    <label for="premiums" class="col col-form-label label">Proposed Premiums</label>
    <div class="col-sm-9">
      <input
        [readonly]="!canEdit"
        type="text"
        nbInput
        fullWidth
        id="premiums"
        formControlName="premiums"
        mask="separator"
        prefix="$"
      />
    </div>
  </div>
  <div class="form-group row">
    <label for="change" class="col col-form-label label">Percentage Change</label>
    <div class="col-sm-9">
      <input
        [readonly]="!canEdit"
        type="text"
        nbInput
        fullWidth
        id="change"
        formControlName="change"
        mask="separator"
        [allowNegativeNumbers]="true"
        suffix="%"
      />
    </div>
  </div>
  <div class="form-group row">
    <label for="notes" class="col col-form-label label">Notes</label>
    <div class="col-sm-9">
      <textarea [readonly]="!canEdit" nbInput fullWidth id="notes" formControlName="notes"></textarea>
    </div>
  </div>

  <div class="d-flex flex-column align-items-start">
    <!-- Initial Proposal Actions -->
    <ng-container *ngIf="showProposalActions">
      <button type="submit" nbButton status="default" *ngIf="canEdit">save</button>
      <button
        class="mt-3"
        type="button"
        nbButton
        status="primary"
        [disabled]="!canSubmit"
        (click)="submitSegmentWithStatus(segmentStatus.Closed, 'submit the initial proposal')"
      >
        next
      </button>
    </ng-container>

    <!-- Analysis/Revisions Actions -->
    <ng-container *ngIf="showAnalysisActions">
      <button type="submit" nbButton status="default" *ngIf="canEdit">save</button>
      <button
        class="mt-3"
        type="button"
        nbButton
        status="default"
        [disabled]="!canSubmit"
        (click)="submitSegmentWithStatus(segmentStatus.RepClosed, 'add a revision')"
      >
        add a revision
      </button>
      <button
        class="mt-3"
        type="button"
        nbButton
        status="primary"
        [disabled]="!canSubmit"
        (click)="submitSegmentWithStatus(segmentStatus.RepDecision, 'complete and send to rep')"
      >
        complete - send to rep
      </button>
    </ng-container>

    <!-- Acceptance -->
    <ng-container *ngIf="showAcceptanceActions">
      <button type="submit" nbButton status="default" *ngIf="canEdit">save</button>
      <button
        class="mt-3"
        type="button"
        nbButton
        status="primary"
        [disabled]="!canSubmit"
        (click)="submitSegmentWithStatus(segmentStatus.RepAccept, 'present revised renewal to the client')"
      >
        Yes, I will present revised renewal to the client
      </button>
      <button
        class="mt-3"
        type="button"
        nbButton
        status="primary"
        [disabled]="!canSubmit"
        (click)="submitSegmentWithStatus(segmentStatus.RepReject, 'reject and further negotiate with the carrier')"
      >
        No, I will further negotiate the renewal with the carrier
      </button>
    </ng-container>

    <!-- Final/Started Actions -->
    <ng-container *ngIf="showFinalStartedActions">
      <button type="submit" nbButton status="default" *ngIf="canEdit">Save</button>
      <button
        class="mt-3"
        type="button"
        nbButton
        status="primary"
        [disabled]="!canSubmit"
        (click)="submitSegmentWithStatus(segmentStatus.RepDecision, 'complete and send to rep')"
      >
        Send to rep for client acceptance
      </button>
    </ng-container>

    <!-- Final/Rep Decision -->
    <ng-container *ngIf="showFinalRepDecisionActions">
      <div>
        <h3>Client Acceptance</h3>
        <pip-renewal-signature [reqSignature]="reqSignature" (sign)="onSign($event)"></pip-renewal-signature>
      </div>
    </ng-container>

    <!-- Final/Rep Decision -->
    <ng-container *ngIf="showFinalClosedActions">
      <div>
        <h3>Client Acceptance</h3>
        <dl class="">
          <div class="d-flex align-items-center mb-2">
            <dt class="mr-1">Signature:</dt>
            <dd class="mb-0">{{ renewal.attributes.signature }}</dd>
          </div>
          <div class="d-flex align-items-center">
            <dt class="mr-1">Date:</dt>
            <dd class="mb-0">{{ renewal.attributes.signed_at | date: 'longDate' }}</dd>
          </div>
        </dl>
      </div>
    </ng-container>
  </div>
</form>

<ng-template #LimitedView>
  <dl>
    <div class="form-group row">
      <p for="created_at" class="col col-form-label label">Date</p>
      <div class="col-sm-10">
        {{ segment.attributes.created_at | date: 'longDate' }}
      </div>
    </div>
    <div class="form-group row" *ngFor="let file of media">
      <dt for="created_at" class="col col-form-label label">Download</dt>
      <dd class="col-sm-10">
        <a [href]="file?.links?.self" [download]="true">{{ file?.attributes?.file_name }}</a>
      </dd>
    </div>
  </dl>
</ng-template>
