import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { IPlan } from '@app/data';

@Component({
  selector: 'pip-administrator-formgroup',
  templateUrl: './administrator-formgroup.component.html',
  styleUrls: ['./administrator-formgroup.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class AdministratorFormgroupComponent implements OnInit {
  @Input() plan: IPlan;
  @Input() showInviteAdminCtrl: boolean;
  @Input() showSecurityFields: boolean;

  constructor(public controlContainer: ControlContainer) {}

  public ngOnInit(): void {}
}
