<div class="row justify-content-center">
  <div class="col-xl-6">
    <h2>Company Info</h2>
    <pip-field-status-alert
      *ngIf="(plan$ | async)?.meta?.field_statuses?.organization_info as organization_info"
      [fieldStatus]="organization_info"
      key="confirmed"
    ></pip-field-status-alert>
    <pip-organization-info-form
      *ngIf="plan$ | async as plan"
      [plan]="plan"
      [confirmButton]="confirmButton$ | async"
      (save)="onUpdatePlan($event)"
      (cancel)="onCancel()"
    ></pip-organization-info-form>
  </div>
</div>
