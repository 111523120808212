import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import * as Data from '@app/data/models';

@Injectable({
  providedIn: 'root'
})
export class RolesService {

  constructor(private api: ApiService) {}

  public getRoles(): Observable<Data.Response<Data.IRole[]>> {
    return this.api.get('roles');
  }
}
