import { AfterViewInit, ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ControlContainer, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'pip-contributions-formgroup',
  templateUrl: './contributions-formgroup.component.html',
  styleUrls: ['./contributions-formgroup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContributionsFormgroupComponent implements OnInit, AfterViewInit {
  public get allocationsCtrl(): FormControl {
    return this.controlContainer?.control.parent.get('allocations') as FormControl;
  }

  public get isLTDZero(): boolean {
    return this.controlContainer.control.parent.get('ltd_employee_paid').value;
  }

  public contributionValue = new FormControl();

  public get allEqual(): boolean {
    return this.contributionControls.every((ctrl) => ctrl.value === this.contributionControls[0].value);
  }

  public get showOptimizedValue(): boolean {
    return this.allocationsCtrl.value;
  }

  private get contributionControls(): FormControl[] {
    return Object.values((this.controlContainer.control as FormGroup).controls) as FormControl[];
  }

  constructor(public controlContainer: ControlContainer) {}

  public ngOnInit(): void {
    this.contributionControls.forEach((control) =>
      control.valueChanges.subscribe((value) => {
        if (value === '' || value === null || value === undefined || value < 0) {
          control.setValue(0);
        }
      }),
    );

    this.contributionValue.valueChanges.subscribe((value) => {
      this.contributionControls.forEach((control) => {
        control.setValue(value);
      });
    });
  }

  public ngAfterViewInit(): void {
    if (this.allocationsCtrl.value) {
      this.contributionValue.setValue(this.contributionControls[0].value);
    }
  }
}
