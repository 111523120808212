import { ChangeDetectionStrategy, Component, Input, OnInit, TemplateRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DataType, ICompanyInfo, IPlan, IPlanStandards, PlanStandards, UpdatePlan, UpdatePlanSuccess } from '@app/data';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { Store, Actions, ofActionSuccessful } from '@ngxs/store';
import { take } from 'rxjs/operators';

@Component({
  selector: 'pip-plan-standards-info',
  templateUrl: './plan-standards-info.component.html',
  styleUrls: ['./plan-standards-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanStandardsInfoComponent {
  @Input() plan: IPlan;
  @Input() planStandards: IPlanStandards;
  @Input() canEdit: boolean = false;

  public dialogRef: NbDialogRef<any>;

  constructor(private store: Store, private actions$: Actions, private dialog: NbDialogService) {}

  public handleEdit(templateRef: TemplateRef<any>): void {
    const {
      allocations,
      employees_minimum,
      employer_contribution,
      ltd_employee_paid,
      overtime_earnings,
      pay_cycle,
      probation_months,
      std_employee_paid,
      waiting_period,
      work_week_hours,
      workers_compensation,
      benefits_continuation,
    } = this.planStandards || PlanStandards.create();

    const form = new FormGroup({
      employees_minimum: new FormControl(employees_minimum, [Validators.required]),
      allocations: new FormControl(allocations, []),
      employer_contribution: new FormGroup({
        life: new FormControl(employer_contribution.life, []),
        add: new FormControl(employer_contribution.add, []),
        dependentLife: new FormControl(employer_contribution.dependentLife, []),
        criticalIllness: new FormControl(employer_contribution.criticalIllness, []),
        ltd: new FormControl(employer_contribution.ltd, []),
        std: new FormControl(employer_contribution.std, []),
        healthcare: new FormControl(employer_contribution.healthcare, []),
        dentalcare: new FormControl(employer_contribution.dentalcare, []),
        virtualCare: new FormControl(employer_contribution.virtualCare, []),
      }),
      ltd_employee_paid: new FormControl(ltd_employee_paid, [Validators.required]),
      overtime_earnings: new FormControl(overtime_earnings, [Validators.required]),
      pay_cycle: new FormControl(pay_cycle, [Validators.required]),
      probation_months: new FormControl(probation_months, [Validators.required]),
      std_employee_paid: new FormControl(std_employee_paid, [Validators.required]),
      waiting_period: new FormControl(waiting_period, [Validators.required]),
      work_week_hours: new FormControl(work_week_hours, [Validators.required]),
      workers_compensation: new FormControl(workers_compensation, [Validators.required]),
      benefits_continuation: new FormControl(benefits_continuation),
    });

    this.dialogRef = this.dialog.open(templateRef, { context: { form } });
  }

  public handleSubmit(formValues: IPlanStandards): void {
    const plan: IPlan = {
      id: this.plan.id,
      type: DataType.Plans,
      attributes: {
        standards: { ...formValues },
      },
    };

    this.actions$.pipe(ofActionSuccessful(UpdatePlanSuccess), take(1)).subscribe(() => this.dialogRef.close());

    this.store.dispatch(new UpdatePlan(plan));
  }
}
