import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { EmployeeDetailComponent } from '@app/shared/containers';
import { DashboardPaths } from '../dashboard';
import * as Containers from './containers';
import { PlansPaths } from './plans.paths';

const routes: Route[] = [
  {
    path: `${PlansPaths.ROOT}`,
    redirectTo: DashboardPaths.ROOT,
  },
  {
    path: `${PlansPaths.ROOT}/${PlansPaths.Wizard}`,
    component: Containers.QuoteWizardComponent,
  },
  {
    path: `${PlansPaths.ROOT}/${PlansPaths.NewQuote}`,
    component: Containers.NewQuoteComponent,
  },
  {
    path: `${PlansPaths.ROOT}/:planId/${PlansPaths.Tasks}`,
    component: Containers.QuoteTasksComponent,
    children: [
      {
        path: '',
        component: Containers.QuoteTaskListComponent,
      },
      {
        path: PlansPaths.CompanyInfo,
        component: Containers.QuoteTaskCompanyInfoComponent,
      },
      {
        path: PlansPaths.Administration,
        component: Containers.QuoteTaskPlanAdministratorComponent,
      },
      {
        path: PlansPaths.Benefits,
        component: Containers.QuoteTaskPlanDetailsComponent,
      },
      {
        path: PlansPaths.Employees,
        component: Containers.QuoteTaskCensusComponent,
      },
      // {
      //   path: PlansPaths.Employees,
      //   component: Containers.QuoteTaskEmployeesComponent,
      // },
      {
        path: `${PlansPaths.Employees}/:employeeId`,
        component: EmployeeDetailComponent,
      },
      {
        path: PlansPaths.Review,
        component: Containers.QuoteTaskReviewProposalComponent,
      },
    ],
  },
  {
    path: `${PlansPaths.ROOT}/:planId/${PlansPaths.PATasks}`,
    component: Containers.QuoteTasksComponent,
    children: [
      {
        path: '',
        component: Containers.PATaskListComponent,
      },
      {
        path: PlansPaths.StartDate,
        component: Containers.QuoteTaskStartDateComponent,
      },
      {
        path: PlansPaths.CompanyInfo,
        component: Containers.QuoteTaskCompanyInfoComponent,
      },
      {
        path: PlansPaths.Standards,
        component: Containers.QuoteTaskStandardsComponent,
      },
      {
        path: PlansPaths.Administration,
        component: Containers.PaTaskMainContactComponent,
      },
      {
        path: PlansPaths.Benefits,
        component: Containers.QuoteTaskPlanReviewComponent,
      },
      {
        path: PlansPaths.Employees,
        component: Containers.QuoteTaskEmployeesComponent,
      },
      {
        path: `${PlansPaths.Employees}/:employeeId`,
        component: EmployeeDetailComponent,
      },
      {
        path: PlansPaths.PaymentInfo,
        component: Containers.QuoteTaskPaymentInfoComponent,
      },
      {
        path: PlansPaths.Documents,
        component: Containers.QuoteTaskDocumentsComponent,
      },
    ],
  },
  {
    path: `${PlansPaths.ROOT}/:planId`,
    component: Containers.PlanComponent,
    children: [
      {
        path: PlansPaths.Detail,
        component: Containers.PlanDetailComponent,
      },
      {
        path: PlansPaths.Benefits,
        component: Containers.PlanInsuranceStatusComponent,
      },
      {
        path: PlansPaths.ClientDocuments,
        component: Containers.ClientDocumentsComponent,
      },
      {
        path: PlansPaths.Employees,
        component: Containers.EmployeeListComponent,
      },
      {
        path: `${PlansPaths.Employees}/:employeeId`,
        component: EmployeeDetailComponent,
      },
      {
        path: PlansPaths.Proposal,
        component: Containers.PlanSummariesComponent,
      },
      {
        path: PlansPaths.Standards,
        component: Containers.PlanStandardsComponent,
      },
      {
        path: PlansPaths.PaymentInfo,
        component: Containers.PlanPaymentInfoComponent,
      },
      {
        path: PlansPaths.SecureAccess,
        component: Containers.PlanSecureAccessComponent,
      },
      {
        path: PlansPaths.Rates,
        component: Containers.PlanRatesComponent,
      },
      {
        path: PlansPaths.Administration,
        component: Containers.PlanAdministrationComponent,
      },
      {
        path: '',
        redirectTo: PlansPaths.Detail,
        pathMatch: 'full',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PlansRouterModule {}
