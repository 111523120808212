<button nbButton status="primary" size="tiny" ghost (click)="onClick(employeeInfoDialog)">
  {{ employee.attributes.employee_id ? employee.attributes.employee_id : 'Employee Id' }}
</button>

<ng-template #employeeInfoDialog let-ref="dialogRef">
  <nb-card status="basic">
    <nb-card-header>Employee ID</nb-card-header>
    <nb-card-body>
      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <fieldset class="p-0">
          <div class="form-group row">
            <label for="employee_id" class="col-3 col-form-label label">Employee ID number</label>
            <div class="col">
              <input type="text" nbInput fullWidth id="employee_id" formControlName="employee_id" />
            </div>
            <div class="col">
              <button nbButton status="primary" type="submit" [disabled]="form.invalid">Update ID number</button>
            </div>
          </div>
          <div class="form-group row">
            <label class="col col-form-label label">Employee ID Card</label>
            <div class="col-sm-9">
              <pip-media-uploader
                class="ml-0"
                [existingMedia]="(media$ | async)[mediaType.EmployeeIdCard]"
                [mediaType]="mediaType.EmployeeIdCard"
                [modelId]="employee.id"
                [mediaModel]="mediaModel.Employee"
              ></pip-media-uploader>
            </div>
          </div>
        </fieldset>
      </form>
    </nb-card-body>
    <nb-card-footer class="d-flex justify-content-end">
      <button nbButton status="primary" ghost (click)="ref.close(false)">Close</button>
    </nb-card-footer>
  </nb-card>
</ng-template>
