<fieldset [formGroup]="controlContainer.control">
  <div class="form-row">
    <div class="form-group col">
      <label for="occupation" class="label">Occupation</label>
      <input type="text" nbInput fullWidth id="occupation" formControlName="occupation" />
    </div>
    <div class="form-group col">
      <label for="state_employment" class="label">Province of employment</label>
      <nb-select fullWidth id="state_employment" formControlName="state_employment">
        <nb-option-group title="Canada">
          <nb-option *ngFor="let prov of canadianProvinces" [value]="prov.value">{{ prov.label }}</nb-option>
        </nb-option-group>
        <nb-option-group title="USA">
          <nb-option *ngFor="let state of usStates" [value]="state.value">{{ state.label }}</nb-option>
        </nb-option-group>
      </nb-select>
    </div>
    <div class="form-group col">
      <label for="hired_at" class="label">Hire date</label>
      <input
        type="text"
        nbInput
        fullWidth
        id="hired_at"
        [formControl]="controlContainer.control.get('hired_at')"
        [nbDatepicker]="hiredAtDatepicker"
      />
      <nb-datepicker format="yyyy-MM-dd" #hiredAtDatepicker></nb-datepicker>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col">
      <label for="earnings_frequency" class="label">Earnings frequency</label>
      <nb-select fullWidth id="earnings_frequency" formControlName="earnings_frequency">
        <nb-option *ngFor="let item of earningsFrequencyDictionary | keyvalue" [value]="item.key">{{
          item.value
        }}</nb-option>
      </nb-select>
    </div>
    <div class="form-group col">
      <label for="earnings_amount" class="label">Earnings amount</label>
      <input type="text" nbInput fullWidth id="earnings_amount" formControlName="earnings_amount" />
    </div>
    <div class="form-group col">
      <label for="weekly_hours" class="label">Weekly hours</label>
      <input type="text" #test nbInput fullWidth id="weekly_hours" formControlName="weekly_hours" />
    </div>
    <div class="form-group col">
      <label for="division" class="label">Division</label>
      <nb-select fullWidth id="division" formControlName="division">
        <nb-option *ngFor="let item of billingDivisions" [value]="item.id">{{ item.attributes.name }}</nb-option>
      </nb-select>
    </div>
    <div class="form-group col">
      <label for="rate" class="label">Benefit class</label>
      <nb-select fullWidth id="rate" formControlName="rate">
        <nb-option *ngFor="let rate of rates" [value]="rate.id">{{ rate.attributes.name }}</nb-option>
      </nb-select>
    </div>
  </div>

  <div class="form-row">
    <div class="form-group col">
      <label class="label">Is this employee an independent contractor?</label>
      <nb-radio-group name="contractor" formControlName="contractor" class="d-flex">
        <nb-radio [disabled]="controlContainer.disabled" [value]="false">No</nb-radio>
        <nb-radio [disabled]="controlContainer.disabled" [value]="true">Yes</nb-radio>
      </nb-radio-group>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col">
      <label class="label">Is this employee currently absent from work?</label>
      <nb-radio-group name="absent" formControlName="absent" class="d-flex">
        <nb-radio [disabled]="controlContainer.disabled" [value]="false">No</nb-radio>
        <nb-radio [disabled]="controlContainer.disabled" [value]="true">Yes</nb-radio>
      </nb-radio-group>
    </div>
  </div>
  <ng-container *ngIf="controlContainer.control.get('absent').value === true" formGroupName="claims">
    <div class="form-row">
      <div class="form-group col">
        <label class="label">Employee is currently off work on...</label>
      </div>
      <div class="col-8">
        <nb-radio-group name="type" formControlName="type">
          <nb-radio [disabled]="controlContainer.disabled" [value]="claimType.EI"
            >Employment Insurance (EI) claim</nb-radio
          >
          <nb-radio [disabled]="controlContainer.disabled" [value]="claimType.STD"
            >Short Term Disability (STD) claim</nb-radio
          >
          <nb-radio [disabled]="controlContainer.disabled" [value]="claimType.LTD"
            >Long Term Disability (LTD) claim</nb-radio
          >
          <nb-radio [disabled]="controlContainer.disabled" [value]="claimType.ParentalMaintainAll">
            <div>Parental Leave - Maintain all benefits</div>
            <div class="label">
              <small>(employee must continue paying their deductions with post-dated cheques)</small>
            </div>
          </nb-radio>
          <nb-radio [disabled]="controlContainer.disabled" [value]="claimType.ParentalMaintainAllWithoutLTD">
            <div>Parental Leave - Maintain all benefits except Long Term Disability (if applicable)</div>
            <div class="label">
              <small>(employee must continue paying their deductions with post-dated cheques)</small>
            </div>
          </nb-radio>
          <nb-radio [disabled]="controlContainer.disabled" [value]="claimType.ParentalDiscontinue">
            <div>Parental Leave - Discontinue benefits during leave</div>
          </nb-radio>
          <nb-radio [disabled]="controlContainer.disabled" [value]="claimType.Other">Other</nb-radio>
        </nb-radio-group>
      </div>
    </div>
    <div class="form-row">
      <div class="col"></div>
      <div class="form-group col">
        <label for="start_at" class="label">Date claim began</label>
        <input
          type="text"
          nbInput
          fullWidth
          id="start_at"
          formControlName="start_at"
          readonly
          [nbDatepicker]="claimStartDatepicker"
        />
        <nb-datepicker format="yyyy-MM-dd" #claimStartDatepicker></nb-datepicker>
      </div>
      <div class="form-group col">
        <label for="end_at" class="label">Date expected to return</label>
        <input
          type="text"
          nbInput
          fullWidth
          id="end_at"
          formControlName="end_at"
          readonly
          [nbDatepicker]="claimEndDatepicker"
        />
        <nb-datepicker format="yyyy-MM-dd" #claimEndDatepicker></nb-datepicker>
      </div>
    </div>
    <div class="form-row">
      <div class="col"></div>
      <div class="form-group col-8">
        <label for="cause" class="label">Cause of claim</label>
        <textarea nbInput fullWidth id="cause" formControlName="cause"></textarea>
      </div>
    </div>
    <div class="form-row">
      <div class="col"></div>
      <div class="form-group col-8">
        <label class="label">Waiver approved</label
        ><nb-radio-group name="waiver" formControlName="waiver_approved">
          <nb-radio [value]="true">Yes</nb-radio>
          <nb-radio [value]="false">No</nb-radio>
        </nb-radio-group>
      </div>
    </div>
  </ng-container>
</fieldset>
