import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { MediaType, MediaTypes, PlansState } from '@app/data';
import { Store } from '@ngxs/store';
import { map } from 'rxjs';

@Component({
  selector: 'pip-quote-task-review-proposal',
  templateUrl: './quote-task-review-proposal.component.html',
  styleUrls: ['./quote-task-review-proposal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuoteTaskReviewProposalComponent implements OnInit {
  public planRep$ = this.store.select(PlansState.activePlanRep);
  public planMedia$ = this.store.select(PlansState.activePlanMedia);

  public proposalLink$ = this.planMedia$.pipe(map((media) => media?.[MediaType.Proposal]?.links?.self));
  public bookingLink$ = this.planRep$.pipe(map((rep) => rep?.links?.booking));

  constructor(private store: Store) {}

  public ngOnInit(): void {}
}
