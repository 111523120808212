import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ControlContainer, FormControl } from '@angular/forms';
import { CanadianProvinces, Country, USStates } from '@app/data';

@Component({
  selector: 'pip-business-address-formgroup',
  templateUrl: './business-address-formgroup.component.html',
  styleUrls: ['./business-address-formgroup.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class BusinessAddressFormgroupComponent implements OnInit {
  @Input() public withCountry: boolean = true;

  public get countryCtrl(): FormControl {
    return this.controlContainer.control.get('country') as FormControl;
  }

  public get postalCtrl(): FormControl {
    return this.controlContainer.control.get('postal_code') as FormControl;
  }

  public get provinces(): Forms.OptionData[] {
    switch (this.countryCtrl.value) {
      case Country.Canada: {
        return CanadianProvinces;
      }
      case Country.USA: {
        return USStates;
      }
    }
  }

  public country = Country;

  constructor(public controlContainer: ControlContainer) {}

  public ngOnInit(): void {}
}
