<nb-card status="basic" class="overflow-hidden">
  <nb-card-body>
    <pip-plan-filters
      [onlyMine]="onlyMine$ | async"
      [search]="search$ | async"
      [statuses]="statuses$ | async"
      [availableStatuses]="statuses"
      (onlyMineChange)="onOnlyMineChange($event)"
      (searchChange)="onSearchChange($event)"
      (statusChange)="onStatusChange($event)"
    ></pip-plan-filters>
  </nb-card-body>
  <pip-plans-table
    [plans]="plans$ | async"
    [pagination]="pagination$ | async"
    [sort]="sort$ | async"
    [roles]="roles$ | async"
    [showColStartAt]="showColStartAt"
    [showColExpireAt]="showColExpireAt"
    [showColCreatedAt]="showColCreatedAt"
    [showColUpdatedAt]="showColUpdatedAt"
    (pageChange)="onPaginationChange($event)"
    (sortChange)="onSortChange($event)"
  ></pip-plans-table>
</nb-card>
