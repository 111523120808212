<ng-container *ngIf="plan$ | async as plan">
  <ng-container *ngIf="plan.attributes.status === planStatus.Archived">
    <nb-alert status="danger" class="d-flex justify-content-between flex-row align-items-center">
      <span class="mr-3">This plan is archived!</span>
      <a [routerLink]="['../', plan.meta.succession[plan.meta.succession.length - 1].id]">Go to the latest version</a>
    </nb-alert>
  </ng-container>
  <nb-card statuc="basic">
    <nb-card-body class="d-flex align-items-center justify-content-between">
      <h1 class="subtitle my-0">
        {{ plan.attributes.organization_name }}
        <small class="caption text-hint d-block">{{ plan.attributes.policy_number }}</small>
      </h1>
      <table>
        <tr>
          <th class="caption-2">Effective Date</th>
          <td>{{ plan.attributes.effective_at | date: 'longDate' }}</td>
        </tr>
        <tr>
          <th class="caption-2">Next Renewal</th>
          <td>{{ (plan.attributes.expire_at | date: 'longDate') || 'N/A' }}</td>
        </tr>
      </table>
    </nb-card-body>
  </nb-card>

  <div class="row">
    <div class="col-2">
      <pip-client-menu
        [renewal]="planRenewal$ | async"
        [hideRenewalItem]="hideRenewalMenuItem$ | async"
      ></pip-client-menu>
      <ng-container *ngIf="showStartNewQuote$ | async">
        <hr />
        <button
          *ngIf="isAdvisorOrAnalyst$ | async"
          nbButton
          fullWidth
          size="small"
          status="primary"
          (click)="createFromExisting(plan.id, warningDialog)"
        >
          Start a new Quote
        </button>
      </ng-container>
    </div>
    <div class="col-10">
      <div class="d-flex justify-content-end mb-2">
        <nb-select
          *ngIf="plan?.meta?.succession?.length > 1"
          [selected]="{ id: plan.id }"
          [compareWith]="compareById"
          (selectedChange)="goToRenewal($event)"
        >
          <nb-select-label>Other Versions</nb-select-label>
          <nb-option *ngFor="let prev of otherVersions$ | async" [value]="prev" class="d-flex align-items-start">
            <!-- <small>{{ prev.id }}&nbsp;</small> -->
            <div class="d-flex flex-column">
              <span>{{ prev.status | planStatus }}</span>
              <small>{{ prev.expire_at | date }}</small>
            </div>
          </nb-option>
        </nb-select>
      </div>
      <router-outlet></router-outlet>
    </div>
  </div>
</ng-container>

<ng-template #warningDialog let-data let-ref="dialogRef">
  <nb-card status="basic">
    <nb-card-body> Are you sure you want to start a new quote? </nb-card-body>
    <nb-card-footer class="d-flex justify-content-between">
      <button nbButton status="primary" ghost (click)="ref.close(false)">Cancel</button>
      <button nbButton status="primary" (click)="ref.close(true)">Yes</button>
    </nb-card-footer>
  </nb-card>
</ng-template>
