<div class="d-flex align-items-center">
  <div class="mr-2">
    <nb-user
      *ngIf="user; else EmptyUser"
      color="#ccc"
      [name]="user.attributes.first_name + ' ' + user.attributes.last_name"
      [title]="user.attributes.email"
    ></nb-user>
  </div>

  <button *ngIf="canEdit" type="button" nbButton status="primary" size="tiny" outline (click)="handleEdit(EditDialog)">Edit</button>
</div>

<ng-template #EditDialog let-data let-ref="dialogRef">
  <form class="d-flex" [formGroup]="data.form" (ngSubmit)="handleSearch(data.form.value)">
    <nb-card status="basic">
      <nb-card-body>
        <div class="mb-3 d-flex align-items-center">
          <div class="mr-2">
            <div class="title mb-2">Current {{ type }}</div>
            <nb-user
              *ngIf="user; else EmptyUser"
              color="#ccc"
              [name]="user.attributes.first_name + ' ' + user.attributes.last_name"
              [title]="user.attributes.email"
            ></nb-user>
          </div>

          <button
            *ngIf="data.showRemoveBtn"
            type="button"
            nbButton
            status="primary"
            size="tiny"
            outline
            (click)="handleRemove(user)"
          >
            Remove
          </button>
        </div>

        <div class="d-flex align-items-end">
          <div class="mr-2">
            <label for="search" class="label d-block">Search {{ type }}s</label>
            <input type="text" nbInput id="search" name="search" formControlName="search" />
          </div>
          <button nbButton status="primary" type="submit">Search</button>
        </div>
        <ng-container *ngIf="(users$ | async)?.length">
          <hr />
          <div *ngFor="let user of users$ | async">
            <button
              size="small"
              fullWidth
              status="basic"
              class="mb-2 text-left d-block"
              style="text-transform: none"
              nbButton
              type="button"
              (click)="handleUserSelect(user)"
            >
              <nb-user
                size="small"
                color="#ccc"
                [name]="user.attributes.first_name + ' ' + user.attributes.last_name"
                [title]="user.attributes.email"
              ></nb-user>
            </button>
          </div>
        </ng-container>
      </nb-card-body>
      <nb-card-footer class="d-flex justify-content-end">
        <button nbButton status="primary" ghost type="button" (click)="ref.close()">Close</button>
      </nb-card-footer>
    </nb-card>
  </form>
</ng-template>

<ng-template #EmptyUser>
  <nb-user color="#ccc" [name]="emptyUserName" [title]="emptyUserTitle"></nb-user>
</ng-template>
