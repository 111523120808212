import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IPlan, IPlanMedia, MediaModel, MediaType, Plan } from '@app/data';

@Component({
  selector: 'pip-plan-administration-form',
  templateUrl: './plan-administration-form.component.html',
  styleUrls: ['./plan-administration-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanAdministrationFormComponent implements OnInit {
  @Input() public plan: IPlan;
  @Input() public media: IPlanMedia;

  @Output() public update = new EventEmitter<IPlan>();

  public form: FormGroup;
  public mediaType = MediaType;
  public mediaModel = MediaModel;

  constructor() {}

  public ngOnInit(): void {
    this.initForm();
  }

  public onSubmit(): void {
    const plan: IPlan = {
      id: this.plan.id,
      type: this.plan.type,
      attributes: {
        ...this.form.value,
      },
    };

    this.update.emit(plan);
  }

  private initForm(): void {
    const { policy_number } = this.plan.attributes || Plan.createAttributes();

    this.form = new FormGroup({
      policy_number: new FormControl(policy_number, [Validators.required]),
    });
  }
}
