import { dictionaryToOptionDataList } from '@app/utils/option-data';

export enum Language {
  English = 'en',
  French = 'fr',
}

export const LanguageDictionary: Record<Language, string> = {
  [Language.English]: 'English',
  [Language.French]: 'French',
};

export const Languages = dictionaryToOptionDataList(LanguageDictionary);
