import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { PlansPaths } from '@app/core/plans/plans.paths';

@Component({
  selector: 'pip-create-quote',
  templateUrl: './create-quote.component.html',
  styleUrls: ['./create-quote.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateQuoteComponent implements OnInit {
  public plansPaths = PlansPaths;

  constructor() {}

  public ngOnInit(): void {}
}
