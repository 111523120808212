import { Component, OnInit, ChangeDetectionStrategy, Input, TemplateRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CompanyInfo, DataType, ICompanyInfo, IPlan, UpdatePlan } from '@app/data';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { take } from 'rxjs/operators';

@Component({
  selector: 'pip-business-address',
  templateUrl: './business-address.component.html',
  styleUrls: ['./business-address.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BusinessAddressComponent {
  @Input() public planId: string;
  @Input() public companyInfo: ICompanyInfo;

  private dialogRef: NbDialogRef<any>;

  constructor(private store: Store, private actions$: Actions, public dialog: NbDialogService) {}

  public handleEdit(templateRef: TemplateRef<any>): void {
    const { address1, address2, city, country, industry, postal_code, state, website } = this.companyInfo;

    const form = new FormGroup({
      address1: new FormControl(address1, [Validators.required]),
      address2: new FormControl(address2, [Validators.required]),
      city: new FormControl(city, [Validators.required]),
      country: new FormControl(country, [Validators.required]),
      industry: new FormControl(industry, [Validators.required]),
      postal_code: new FormControl(postal_code, [Validators.required]),
      state: new FormControl(state, [Validators.required]),
      website: new FormControl(website, [Validators.required]),
    });

    this.dialogRef = this.dialog.open(templateRef, { context: { form } });
  }

  public handleSubmit(formValues: ICompanyInfo): void {
    const plan: IPlan = {
      id: this.planId,
      type: DataType.Plans,
      attributes: {
        organization_info: { ...formValues },
      },
    };

    this.actions$.pipe(ofActionSuccessful(UpdatePlan), take(1)).subscribe(() => this.dialogRef.close());

    this.store.dispatch(new UpdatePlan(plan));
  }
}
