import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IPlan, PlansState, UpdatePlan, IDefaultPlan, IPlanMedia } from '@app/data';
import { Navigate } from '@ngxs/router-plugin';
import { Actions, ofActionSuccessful, Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Component({
  selector: 'pip-quote-task-plan-details',
  templateUrl: './quote-task-plan-details.component.html',
  styleUrls: ['./quote-task-plan-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuoteTaskPlanDetailsComponent {
  public plan$: Observable<IPlan> = this.store.select(PlansState.activePlan);
  public defaultPlans$: Observable<IDefaultPlan[]> = this.store.select(PlansState.defaultPlans);
  public loading$: Observable<boolean> = this.store.select(PlansState.loading);
  public planMedia$: Observable<IPlanMedia> = this.store.select(PlansState.activePlanMedia);

  public fieldStatuses$ = this.plan$.pipe(map((plan) => plan?.meta?.field_statuses));
  public insuranceInfoFs$ = this.fieldStatuses$.pipe(map((fs) => fs?.insurance_info));

  constructor(private store: Store, private actions$: Actions, private route: ActivatedRoute) {
    this.actions$
      .pipe(ofActionSuccessful(UpdatePlan), take(1))
      .subscribe(() => this.store.dispatch(new Navigate(['../'], null, { relativeTo: this.route })));
  }
}
