<div class="d-flex justify-content-between align-items-center">
  <h1>Clients</h1>
  <a [routerLink]="routerLinks.newClient" nbButton status="primary" shape="round" outline size="large">
    <nb-icon icon="plus-outline"></nb-icon>
  </a>
</div>

<pip-plans-list
  [statuses]="plansStatuses"
  [defaultStatuses]="selectedStatuses"
  [showColStartAt]="true"
  [showColExpireAt]="true"
  [showColCreatedAt]="false"
  [showColUpdatedAt]="false"
  listKey="clientsList"
></pip-plans-list>
