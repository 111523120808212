import { Provider } from '@angular/core';
import { RouterStateSerializer as NgxsRouterStateSerializer } from '@ngxs/router-plugin';
import { STORAGE_ENGINE } from '@ngxs/storage-plugin';

import { RouterStateSerializer } from './router-state-serializer';
import { StorageEngine } from './storage-engine';

export const providers: Provider[] = [
  { provide: STORAGE_ENGINE, useClass: StorageEngine },
  { provide: NgxsRouterStateSerializer, useClass: RouterStateSerializer },
];

export { RouterStateParams } from './router-state-serializer';
