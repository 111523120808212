<nb-card *ngFor="let info of paymentInfo$ | async">
  <nb-card-header>
    <h2>{{ info.attributes.name }}</h2>
  </nb-card-header>
  <nb-card-body>
    <pip-payment-information-properties
      [paymentInfo]="info"
      [plan]="plan$ | async"
      [allowEdit]="true"
    ></pip-payment-information-properties>
  </nb-card-body>
</nb-card>
