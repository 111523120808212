import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { IPlan, Plan } from '@app/data';

@Component({
  selector: 'pip-confirm-plan-form',
  templateUrl: './confirm-plan-form.component.html',
  styleUrls: ['./confirm-plan-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmPlanFormComponent implements OnInit {
  @Input() public plan: IPlan;
  @Input() public quoteDownload: string;
  @Input() confirmButton = 'Save';
  @Input() cancelButton = 'Cancel';

  @Output() save = new EventEmitter<IPlan>();
  @Output() cancel = new EventEmitter<void>();

  public form: FormGroup;

  public get showEIPremiumReductionMessage(): boolean {
    return this.plan?.attributes?.std;
  }

  constructor() {}

  public ngOnInit(): void {
    this.initForm();
  }

  public onSubmit(): void {
    const { id, type } = this.plan;
    const { deviations } = this.form.value;

    const payload: IPlan = {
      id,
      type,
      attributes: {
        deviations,
      },
    };

    this.save.emit(payload);
  }

  private initForm(): void {
    const { deviations } = this.plan.attributes || Plan.createAttributes();

    this.form = new FormGroup({
      deviations: new FormControl(deviations, []),
    });
  }
}
