<div class="d-flex align-items-center justify-content-end">
  <button *ngIf="allowEdit" nbButton status="primary" size="tiny" outline class="ml-2" (click)="handleEdit(EditDialog)">
    Edit
  </button>
  <button *ngIf="allowEdit" nbButton status="info" size="tiny" outline class="ml-2" (click)="handleCopy(CopyDialog)">
    Copy
  </button>
  <button
    *ngIf="allowEdit"
    nbButton
    status="danger"
    size="tiny"
    outline
    class="ml-2"
    (click)="handleDelete(DeleteDialog)"
  >
    Delete
  </button>
</div>
<div class="row mt-2">
  <div class="col">
    <div class="mb-5" *ngIf="trustedVideoUrl">
      <style>
        .embed-container {
          position: relative;
          padding-bottom: 56.25%;
          height: 0;
          overflow: hidden;
          max-width: 100%;
        }

        .embed-container iframe,
        .embed-container object,
        .embed-container embed {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      </style>
      <div class="embed-container">
        <iframe [src]="trustedVideoUrl" frameborder="0" allowfullscreen></iframe>
      </div>
    </div>
    <!-- Life -->
    <fieldset>
      <legend class="subtitle">Life</legend>
      <div class="form-group row">
        <dt class="col col-form-label label">Rate</dt>
        <div class="col-sm-6">
          <dd>{{ rate?.attributes.life | currency: 'CAD':'symbol-narrow':'0.3-3' }}</dd>
        </div>
      </div>
      <div class="form-group row">
        <dt class="col col-form-label label">Max</dt>
        <div class="col-sm-6">
          <dd>{{ rate?.attributes.life_max | currency }}</dd>
        </div>
      </div>
      <div class="form-group row">
        <dt class="col col-form-label label">Volume Type</dt>
        <div class="col-sm-6">
          <dd>{{ rate?.attributes.life_volume_type }}</dd>
        </div>
      </div>
      <div class="form-group row">
        <dt class="col col-form-label label">Volume</dt>
        <div class="col-sm-6">
          <dd>{{ rate?.attributes.life_volume | currency }}</dd>
        </div>
      </div>
    </fieldset>
    <!-- AD&D -->
    <fieldset>
      <legend class="subtitle">AD&D</legend>
      <div class="form-group row">
        <dt class="col col-form-label label">Rate</dt>
        <div class="col-sm-6">
          <dd>{{ rate?.attributes.add | currency: 'CAD':'symbol-narrow':'0.3-3' }}</dd>
        </div>
      </div>
    </fieldset>
    <!-- Dep. Life -->
    <fieldset>
      <legend class="subtitle">Dep. Life</legend>
      <div class="form-group row">
        <dt class="col col-form-label label">Rate</dt>
        <div class="col-sm-6">
          <dd>{{ rate?.attributes.dependent_life | currency: 'CAD':'symbol-narrow':'0.3-3' }}</dd>
        </div>
      </div>
    </fieldset>
    <!-- Critical Illness -->
    <fieldset>
      <legend class="subtitle">Critical Illness</legend>
      <div class="form-group row">
        <dt class="col col-form-label label">Rate Single</dt>
        <div class="col-sm-6">
          <dd>{{ rate?.attributes.critical_illness_single | currency: 'CAD':'symbol-narrow':'0.3-3' }}</dd>
        </div>
      </div>
      <div class="form-group row">
        <dt class="col col-form-label label">Rate Family</dt>
        <div class="col-sm-6">
          <dd>{{ rate?.attributes.critical_illness | currency: 'CAD':'symbol-narrow':'0.3-3' }}</dd>
        </div>
      </div>
      <div class="form-group row">
        <dt class="col col-form-label label">Max</dt>
        <div class="col-sm-6">
          <dd>{{ rate?.attributes.critical_illness_max | currency }}</dd>
        </div>
      </div>
      <div class="form-group row">
        <dt class="col col-form-label label">Volume Type</dt>
        <div class="col-sm-6">
          <dd>{{ rate?.attributes.critical_illness_volume_type }}</dd>
        </div>
      </div>
      <div class="form-group row">
        <dt class="col col-form-label label">Volume</dt>
        <div class="col-sm-6">
          <dd>{{ rate?.attributes.critical_illness_volume | currency }}</dd>
        </div>
      </div>
    </fieldset>
  </div>
  <div class="col">
    <!-- STD -->
    <fieldset>
      <legend class="subtitle">STD</legend>
      <div class="form-group row">
        <dt class="col col-form-label label">Rate</dt>
        <div class="col-sm-6">
          <dd>{{ rate?.attributes.std | currency: 'CAD':'symbol-narrow':'0.3-3' }}</dd>
        </div>
      </div>
      <div class="form-group row">
        <dt class="col col-form-label label">Max</dt>
        <div class="col-sm-6">
          <dd>{{ rate?.attributes.std_max | currency }}</dd>
        </div>
      </div>
    </fieldset>
    <!-- LTD -->
    <fieldset>
      <legend class="subtitle">LTD</legend>
      <div class="form-group row">
        <dt class="col col-form-label label">Rate</dt>
        <div class="col-sm-6">
          <dd>{{ rate?.attributes.ltd | currency: 'CAD':'symbol-narrow':'0.3-3' }}</dd>
        </div>
      </div>
      <div class="form-group row">
        <label class="col col-form-label label">Max</label>
        <div class="col-sm-6">
          <dd>{{ rate?.attributes.ltd_max | currency }}</dd>
        </div>
      </div>
    </fieldset>
    <!-- Health -->
    <fieldset>
      <legend class="subtitle">Health</legend>
      <div class="form-group row">
        <dt class="col col-form-label label">Single Rate</dt>
        <div class="col-sm-6">
          <dd>{{ rate?.attributes.healthcare_single | currency: 'CAD':'symbol-narrow':'0.3-3' }}</dd>
        </div>
      </div>
      <div class="form-group row">
        <dt class="col col-form-label label">Single Parent Rate</dt>
        <div class="col-sm-6">
          <dd>{{ rate?.attributes.healthcare_single_parent | currency: 'CAD':'symbol-narrow':'0.3-3' }}</dd>
        </div>
      </div>
      <div class="form-group row">
        <dt class="col col-form-label label">Couple Rate</dt>
        <div class="col-sm-6">
          <dd>{{ rate?.attributes.healthcare_couple | currency: 'CAD':'symbol-narrow':'0.3-3' }}</dd>
        </div>
      </div>
      <div class="form-group row">
        <dt class="col col-form-label label">Family Rate</dt>
        <div class="col-sm-6">
          <dd>{{ rate?.attributes.healthcare_family | currency: 'CAD':'symbol-narrow':'0.3-3' }}</dd>
        </div>
      </div>
    </fieldset>

    <!-- Dental -->
    <fieldset>
      <legend class="subtitle">Dental</legend>
      <div class="form-group row">
        <dt class="col col-form-label label">Single Rate</dt>
        <div class="col-sm-6">
          <dd>{{ rate?.attributes.dental_single | currency: 'CAD':'symbol-narrow':'0.3-3' }}</dd>
        </div>
      </div>
      <div class="form-group row">
        <dt class="col col-form-label label">Single Parent Rate</dt>
        <div class="col-sm-6">
          <dd>{{ rate?.attributes.dental_single_parent | currency: 'CAD':'symbol-narrow':'0.3-3' }}</dd>
        </div>
      </div>
      <div class="form-group row">
        <dt class="col col-form-label label">Couple Rate</dt>
        <div class="col-sm-6">
          <dd>{{ rate?.attributes.dental_couple | currency: 'CAD':'symbol-narrow':'0.3-3' }}</dd>
        </div>
      </div>
      <div class="form-group row">
        <dt class="col col-form-label label">Family Rate</dt>
        <div class="col-sm-6">
          <dd>{{ rate?.attributes.dental_family | currency: 'CAD':'symbol-narrow':'0.3-3' }}</dd>
        </div>
      </div>
    </fieldset>
    <!-- EAP -->
    <fieldset>
      <legend class="subtitle">EAP</legend>
      <div class="form-group row">
        <dt class="col col-form-label label">Rate</dt>
        <div class="col-sm-6">
          <dd>{{ rate?.attributes.eap | currency: 'CAD':'symbol-narrow':'0.3-3' }}</dd>
        </div>
      </div>
    </fieldset>
    <!-- Virtual Care -->
    <fieldset>
      <legend class="subtitle">Virtual Care</legend>
      <div class="form-group row">
        <dt class="col col-form-label label">Rate</dt>
        <div class="col-sm-6">
          <dd>{{ rate?.attributes.virtual_care | currency: 'CAD':'symbol-narrow':'0.3-3' }}</dd>
        </div>
      </div>
    </fieldset>
  </div>
</div>

<div class="d-flex align-items-start">
  <div *ngIf="rate?.media" class="mr-3">
    <pip-media-uploader
      [existingMedia]="rate.media[mediaType.RateWelcomePackage]"
      [mediaType]="mediaType.RateWelcomePackage"
      [modelId]="rate.id"
      [mediaModel]="mediaModel.Rate"
    ></pip-media-uploader>
    <pip-media-uploader
      [existingMedia]="rate.media[mediaType.RateBenefitsBooklet]"
      [mediaType]="mediaType.RateBenefitsBooklet"
      [modelId]="rate.id"
      [mediaModel]="mediaModel.Rate"
    ></pip-media-uploader>
  </div>
</div>

<!--  -->
<ng-template #EditDialog let-data let-ref="dialogRef">
  <form class="d-flex" [formGroup]="data.form" (ngSubmit)="onEdit(data.form.value)">
    <nb-card status="basic">
      <nb-card-body>
        <pip-rate-properties-form-group [plan]="plan" [formGroup]="data.form"></pip-rate-properties-form-group>
      </nb-card-body>
      <nb-card-footer class="d-flex justify-content-between">
        <button nbButton status="primary" ghost type="button" (click)="ref.close()">Close</button>
        <button nbButton status="primary" type="submit">Save</button>
      </nb-card-footer>
    </nb-card>
  </form>
</ng-template>

<!--  -->
<ng-template #CopyDialog let-data let-ref="dialogRef">
  <form class="d-flex" [formGroup]="data.form" (ngSubmit)="onCopy(data.form.value)">
    <nb-card status="basic">
      <nb-card-header>Copy rate: {{ rate.attributes.name }}</nb-card-header>
      <nb-card-body *ngIf="planRates$ | async as planRates">
        <fieldset>
          <legend class="subtitle">Copy rate properties to an existing or new rate</legend>
          <div class="form-group" *ngIf="data.form">
            <label for="existingRate" class="label">Select a rate to copy to</label>
            <nb-select
              [selected]="undefined"
              fullWidth
              name="existingRate"
              id="existingRate"
              formControlName="existingRate"
              placeholder="New rate class"
            >
              <nb-option [value]="null">New rate class</nb-option>
              <nb-option *ngFor="let planRate of notActiveRates(planRates)" [value]="planRate">
                {{ planRate.attributes.name }}
              </nb-option>
            </nb-select>
          </div>

          <div class="form-group" *ngIf="!data.form.get('existingRate').value">
            <label for="identifier" class="label">New rate class name</label>
            <div class="">
              <input type="text" nbInput fullWidth id="identifier" formControlName="identifier" />
            </div>
          </div>
        </fieldset>
      </nb-card-body>
      <nb-card-footer class="d-flex justify-content-between">
        <button nbButton status="primary" ghost type="button" (click)="ref.close()">Close</button>
        <button nbButton status="primary" type="submit">Copy</button>
      </nb-card-footer>
    </nb-card>
  </form>
</ng-template>

<!--  -->
<ng-template #DeleteDialog let-data let-ref="dialogRef">
  <nb-card status="basic">
    <nb-card-body> Are you sure you want to delete this rate class?</nb-card-body>
    <nb-card-footer class="d-flex justify-content-between">
      <button nbButton status="primary" ghost type="button" (click)="ref.close(false)">Close</button>
      <button nbButton status="primary" type="submit" (click)="ref.close(true)">Delete</button>
    </nb-card-footer>
  </nb-card>
</ng-template>
