<div>
  <pip-plan-menu-item *ngFor="let item of menuItems" [menuItem]="item" (info)="onInfo($event, infoDialog)"></pip-plan-menu-item>
</div>

<ng-template #infoDialog let-data let-ref="dialogRef">
  <nb-card status="basic">
    <nb-card-header>Section status info</nb-card-header>
    <nb-card-body>
      <pip-field-status-alert [fieldStatus]="data"></pip-field-status-alert>
    </nb-card-body>
    <nb-card-footer class="d-flex justify-content-end">
      <button nbButton status="primary" ghost (click)="ref.close(false)">Close</button>
    </nb-card-footer>
  </nb-card>
</ng-template>
