import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ClientsPaths } from '@app/core/clients/clients.paths';
import { PlanStatus } from '@app/data';

@Component({
  selector: 'pip-client-list',
  templateUrl: './client-list.component.html',
  styleUrls: ['./client-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientListComponent implements OnInit {
  public plansStatuses: PlanStatus[] = [PlanStatus.Confirmed, PlanStatus.Archived, PlanStatus.Expired];
  public selectedStatuses: PlanStatus[] = [PlanStatus.Confirmed];

  public routerLinks = {
    newClient: ['/', ClientsPaths.ROOT, ClientsPaths.NewClient],
  };

  constructor() {}

  public ngOnInit(): void {}
}
