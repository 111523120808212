import { ChangeDetectionStrategy, Component, Input, OnInit, TemplateRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CreateNote, DeleteNote, GetNotes, INote, NotesState, UpdateNote } from '@app/data';
import { NbDialogService } from '@nebular/theme';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { filter, take } from 'rxjs/operators';

@Component({
  selector: 'pip-client-notes',
  templateUrl: './client-notes.component.html',
  styleUrls: ['./client-notes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientNotesComponent implements OnInit {
  @Input() public organizationId: string;
  @Input() public allowEdit = true;

  public notes$ = this.store.select(NotesState.activePlanOrganizationNotes);

  constructor(private store: Store, private dialog: NbDialogService, private actions$: Actions) {}

  public ngOnInit(): void {
    this.store.dispatch(new GetNotes({ organizationId: this.organizationId }));
  }

  public handleSubmit({ form, note }: { form: FormGroup; note: INote }) {
    const payload: INote = {
      ...note,
      attributes: {
        info: form.value.info,
      },
    };

    if (note?.id) {
      this.store.dispatch(new UpdateNote({ note: payload }));
    } else {
      this.store.dispatch(new CreateNote({ organizationId: this.organizationId, note: payload }));
    }
  }

  public handleEdit(templateRef: TemplateRef<any>, note?: INote) {
    const form = new FormGroup({
      info: new FormControl(note?.attributes?.info, [Validators.required]),
    });

    const dialog = this.dialog.open(templateRef, { context: { form, note } });

    this.actions$.pipe(ofActionSuccessful(UpdateNote, CreateNote), take(1)).subscribe(() => {
      dialog.close();
    });
  }

  public handleDelete(templateRef: TemplateRef<any>, note: INote) {
    this.dialog
      .open(templateRef, { context: { note } })
      .onClose.pipe(
        filter(confirmed => !!confirmed),
        take(1),
      )
      .subscribe(() => this.store.dispatch(new DeleteNote({ noteId: note.id })));
  }
}
