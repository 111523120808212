<div class="d-flex align-items-center">
  <h2>
    <ng-container *ngIf="!companyInfo?.website; else WithLink">
      {{ companyName }}
    </ng-container>
    <ng-template #WithLink>
      <a [href]="companyInfo?.website" target="_blank">{{ companyName }}</a>
    </ng-template>
  </h2>
  <button nbButton status="primary" size="tiny" outline class="ml-2" (click)="handleEdit(EditDialog)">Edit</button>
</div>
<dl>
  <dt>Industry</dt>
  <dd>{{ companyInfo?.industry }}</dd>
</dl>

<!--  -->
<ng-template #EditDialog let-data let-ref="dialogRef">
  <form [formGroup]="data.form" (ngSubmit)="handleSubmit(data.form.value)">
    <nb-card status="basic">
      <nb-card-body>
        <pip-company-info-formgroup [formGroup]="data.form"></pip-company-info-formgroup>
      </nb-card-body>
      <nb-card-footer class="d-flex justify-content-between">
        <button nbButton status="primary" ghost type="button" (click)="ref.close()">Close</button>
        <button nbButton status="primary" type="submit">Save</button>
      </nb-card-footer>
    </nb-card>
  </form>
</ng-template>
