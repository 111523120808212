import { HttpClient, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

import { Observable } from 'rxjs';

import {
  HttpResponseOptions,
  ResourceObjectOrObjects,
  Response,
  ResponseWithData,
  ResponseWithErrors,
  ResponseWithMetaData,
} from '../models';

@Injectable({ providedIn: 'root' })
export class ApiService {
  constructor(private http: HttpClient) {}

  public request(req: HttpRequest<any>) {
    return this.http.request(req);
  }

  /**
   * GET from API
   * @param url Resource path
   * @param options GET options
   */
  public get<
    R extends
      | Response<ResourceObjectOrObjects>
      | ResponseWithData<ResourceObjectOrObjects>
      | ResponseWithErrors<ResourceObjectOrObjects>
      | ResponseWithMetaData<ResourceObjectOrObjects>,
  >(url: string | string[], options?: HttpResponseOptions): Observable<R> {
    return this.http.get<R>(this.urlBuilder(url), { withCredentials: true, ...options });
  }

  /**
   * POST to API
   * @param url Resource path
   * @param data POST payload
   * @param options POST options
   */
  public post<
    R extends
      | Response<ResourceObjectOrObjects>
      | ResponseWithData<ResourceObjectOrObjects>
      | ResponseWithErrors<ResourceObjectOrObjects>
      | ResponseWithMetaData<ResourceObjectOrObjects>,
    D extends Partial<ResourceObjectOrObjects> | FormData | Record<string, unknown>,
  >(url: string | string[], data: D, options?: HttpResponseOptions): Observable<R> {
    const payload = !data ? null : data instanceof FormData ? data : { data };

    return this.http.post<R>(this.urlBuilder(url), payload, { withCredentials: true, ...options });
  }

  /**
   * PATCH API
   * @param url Resource path
   * @param data PATCH payload
   * @param options PATCH options
   */
  public patch<
    R extends
      | Response<ResourceObjectOrObjects>
      | ResponseWithData<ResourceObjectOrObjects>
      | ResponseWithErrors<ResourceObjectOrObjects>
      | ResponseWithMetaData<ResourceObjectOrObjects>,
    D extends Partial<ResourceObjectOrObjects>,
  >(url: string | string[], data: D, options?: HttpResponseOptions): Observable<R> {
    return this.http.patch<R>(this.urlBuilder(url), { data }, { withCredentials: true, ...options });
  }

  /**
   * DELETE from API
   * @param url Resource path
   * @param options DELETE options
   */
  public delete<
    R extends
      | Response<ResourceObjectOrObjects>
      | ResponseWithData<ResourceObjectOrObjects>
      | ResponseWithErrors<ResourceObjectOrObjects>
      | ResponseWithMetaData<ResourceObjectOrObjects>,
  >(url: string | string[], options?: HttpResponseOptions): Observable<R> {
    return this.http.delete<R>(this.urlBuilder(url), { withCredentials: true, ...options });
  }

  private urlBuilder(url: string | string[]): string {
    if (typeof url === 'string' && /^http/gi.test(url)) {
      return url;
    }

    let path: string;

    if (typeof url === 'object') {
      path = url.join('/');
    } else {
      path = url;
    }

    return `${environment.url.api}/api/${path}`;
  }
}
