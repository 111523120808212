import { IPaymentInformation } from '.';
import { DataType, PlanStatus } from '../enums';
import { PaAccess } from '../enums/pa-access.enum';
import { Administrator, IAdministrator } from './administrator';
import { IChecklistItem } from './checklist-item';
import { CommonRateProperties, ICommonRateProperties } from './common-rate-properties';
import { CompanyInfo, ICompanyInfo } from './company-info';
import { IDefaultPlan } from './default-plan';
import { IEmployee } from './employee';
import { IFieldStatuses } from './field-statuses';
import { Attributes, Links, Meta, Relationship, RelationshipData, Relationships, ResourceObject } from './json-api';
import { IMedia } from './media';
import { IOrganization } from './organization';
import { IPaginatedLink } from './paginated-link';
import { IPlanActivity } from './plan-activity';
import { IPlanStandards, PlanStandards } from './plan-standards';
import { IQuote } from './quote';
import { IRate, Rate } from './rate';
import { IRenewal } from './renewal';
import { IStatusOptions } from './status-options';
import { IUser } from './user';

export interface IPlan extends ResourceObject {
  type: DataType.Plans;
  attributes: Partial<IPlanAttributes>;
  links?: IPlanLinks;
  meta?: Partial<IPlanMeta>;
  relationships?: Partial<IPlanRelationships>;
}

export interface IPlanAttributes extends Attributes, ICommonRateProperties {
  administrator: IAdministrator;
  advisor_signature: string;
  advisor_signed_at: string;
  agent_letter: boolean;
  client_at: string;
  created_at: string;
  critical_illness: boolean;
  deviations: string;
  deviations_confirmation: boolean;
  eap: boolean;
  expire_at: string;
  renewed_at: string;
  features: IDefaultPlan[];
  health_spending_account: boolean;
  incepted_at: string;
  label: string;
  ltd_flat: number;
  ltd: boolean;
  options: string[];
  organization_info: ICompanyInfo;
  organization_name: string;
  pa_access: PaAccess;
  pa_signature_province: string;
  pa_signature: string;
  pa_signed_at: string;
  payment_method: 'automatic' | 'manual' | null;
  payment_signoff: boolean;
  policy_number: string;
  rates: IRate;
  requests: string;
  standards: IPlanStandards;
  start_at: string;
  status: PlanStatus;
  std_flat: number;
  std: boolean;
  updated_at: string;
  virgin: boolean;
  virtual_care: boolean;
  effective_at: string;
}

export interface IPlanMeta extends Meta {
  checklist: IChecklistItem[];
  field_statuses: IFieldStatuses;
  status_options: IStatusOptions;
  activity: IPlanActivity;
  succession: IPlanSuccession[];
  rtq_id: string;
}

export interface IPlanLinks extends Links {
  employees: string;
  options: string;
}

export interface IPlanRelationships extends Relationships {
  associate: Relationship<IUser>;
  employees: {
    data: RelationshipData<IEmployee[]>;
    links?: IPaginatedLink[];
    total?: number;
  };
  media: Relationship<IMedia[]>;
  quotes: Relationship<IQuote[]>;
  rates: Relationship<IRate[]>;
  renewals: Relationship<IRenewal[]>;
  rep: Relationship<IUser>;
  organizations: Relationship<IOrganization>;
  predecessor?: Relationship<IPlan>;
  payment_infos?: Relationship<IPaymentInformation[]>;
}

export interface IPlanSuccession {
  id: string;
  expire_at: string;
  status: PlanStatus;
}
export class Plan {
  public static create(): IPlan {
    return {
      attributes: Plan.createAttributes(),
      id: null,
      meta: null,
      type: DataType.Plans,
    };
  }

  public static createAttributes(): IPlanAttributes {
    return {
      administrator: Administrator.create(),
      advisor_signature: undefined,
      advisor_signed_at: undefined,
      agent_letter: undefined,
      client_at: undefined,
      created_at: undefined,
      critical_illness: false,
      deviations: undefined,
      deviations_confirmation: undefined,
      eap: false,
      expire_at: new Date().toUTCString(),
      features: [],
      health_spending_account: false,
      incepted_at: undefined,
      label: undefined,
      ltd_flat: undefined,
      ltd: false,
      options: [],
      organization_info: CompanyInfo.create(),
      organization_name: undefined,
      pa_access: undefined,
      pa_signature_province: undefined,
      pa_signature: undefined,
      pa_signed_at: undefined,
      payment_method: 'manual',
      payment_signoff: false,
      policy_number: undefined,
      rates: Rate.create(),
      requests: undefined,
      standards: PlanStandards.create(),
      start_at: new Date().toUTCString(),
      status: undefined,
      std_flat: undefined,
      std: false,
      updated_at: undefined,
      virgin: null,
      virtual_care: false,
      ...CommonRateProperties.create(),
      effective_at: undefined,
      renewed_at: undefined
    };
  }
}
