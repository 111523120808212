import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truthyStatus',
})
export class TruthyStatusPipe implements PipeTransform {
  transform(value: boolean): string {
    return value ? 'Yes' : value === false ? 'No' : '-';
  }
}
