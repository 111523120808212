<fieldset [formGroup]="controlContainer.control">
  <div class="form-row">
    <div class="form-group col">
      <label for="email" class="label">Email</label>
      <input type="email" nbInput fullWidth id="email" formControlName="email" />
    </div>
    <div class="form-group col">
      <label for="mobile" class="label">Mobile</label>
      <input type="text" nbInput fullWidth id="mobile" formControlName="mobile" mask="(000) 000-0000" />
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col">
      <label for="first_name" class="label">First name</label>
      <input type="text" nbInput fullWidth id="first_name" formControlName="first_name" />
    </div>
    <div class="form-group col">
      <label for="middle_name" class="label">Middle name <small>optional</small></label>
      <input type="text" nbInput fullWidth id="middle_name" formControlName="middle_name" />
    </div>
    <div class="form-group col">
      <label for="last_name" class="label">Last name</label>
      <input type="text" nbInput fullWidth id="last_name" formControlName="last_name" />
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col">
      <label for="born_at" class="label">Date of birth</label>
      <input
        type="born_at"
        nbInput
        fullWidth
        id="born_at"
        formControlName="born_at"
        readonly
        [nbDatepicker]="bornAtPicker"
      />
      <nb-datepicker format="yyyy-MM-dd" #bornAtPicker></nb-datepicker>
    </div>
    <div class="form-group col">
      <label for="marital_status" class="label">Marital status</label>
      <nb-select fullWidth id="marital_status" formControlName="marital_status">
        <nb-option [value]="martialStatus.Single">Single</nb-option>
        <nb-option [value]="martialStatus.Married">Married</nb-option>
        <nb-option [value]="martialStatus.CommonLaw">Common-law</nb-option>
      </nb-select>
    </div>
    <div class="form-group col">
      <ng-container *ngIf="maritalStatusCtrl.value === 'CommonLaw'">
        <label for="cohabitation_at" class="label">Co-habitation date</label>
        <input
          type="cohabitation_at"
          nbInput
          fullWidth
          id="cohabitation_at"
          formControlName="cohabitation_at"
          readonly
          [nbDatepicker]="cohabitationPicker"
        />
        <nb-datepicker format="yyyy-MM-dd" #cohabitationPicker></nb-datepicker>
      </ng-container>
    </div>
    <div class="form-group col">
      <label for="gender" class="label">Gender</label>
      <nb-radio-group class="d-flex" id="gender" formControlName="gender">
        <nb-radio value="Male">M</nb-radio>
        <nb-radio value="Female">F</nb-radio>
        <nb-radio value="Other">O</nb-radio>
      </nb-radio-group>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col">
      <label for="address1" class="col col-form-label label">Address</label>

      <input type="text" nbInput fullWidth id="address1" formControlName="address1" />
    </div>
    <div class="form-group col">
      <label for="address2" class="col col-form-label label">Unit</label>

      <input type="text" nbInput fullWidth id="address2" formControlName="address2" />
    </div>
    <div class="form-group col">
      <label for="city" class="col col-form-label label">City</label>

      <input type="text" nbInput fullWidth id="city" formControlName="city" />
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col">
      <label for="country" class="col col-form-label label">Country</label>

      <nb-select
        id="country"
        fullWidth
        formControlName="country"
        (selectedChange)="controlContainer.control.get('state_residence').setValue(null)"
      >
        <nb-option value="" selected disabled>Select country</nb-option>
        <nb-option value="CA">Canada</nb-option>
        <nb-option value="US">USA</nb-option>
      </nb-select>
    </div>
    <div class="form-group col">
      <label for="state_residence" class="label">Province of residence</label>
      <nb-select fullWidth id="state_residence" formControlName="state_residence">
        <nb-option-group title="Canada">
          <nb-option *ngFor="let prov of canadianProvinces" [value]="prov.value">{{ prov.label }}</nb-option>
        </nb-option-group>
        <nb-option-group title="USA">
          <nb-option *ngFor="let state of usStates" [value]="state.value">{{ state.label }}</nb-option>
        </nb-option-group>
      </nb-select>
    </div>
    <div class="form-group col">
      <label for="postal_code" class="col col-form-label label">Postal/Zip code</label>
      <input type="text" nbInput fullWidth id="postal_code" formControlName="postal_code" />
    </div>
  </div>
</fieldset>
