import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { IPaymentInformation, IPlan, IRate, Response } from '../models';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class PaymentInfosService {
  private params = { include: 'plans,payment-infos' };

  constructor(private api: ApiService) {}

  public getPlanPaymentInfos(planId: string): Observable<Response<IPaymentInformation[]>> {
    return this.api.get(['plans', planId, 'relationships', 'Rate'], { params: this.params }).pipe(
      map((res) => ({
        ...res,
        data: [...(res.data as IPaymentInformation[]).map(this.transformIncomingPaymentInfo)],
      })),
    );
  }

  public getPaymentInfo(paymentInfoId: string): Observable<Response<IPaymentInformation>> {
    return this.api
      .get(['payment-infos', paymentInfoId], { params: this.params })
      .pipe(map((res) => ({ ...res, data: this.transformIncomingPaymentInfo(res.data as IPaymentInformation) })));
  }

  public createPaymentInfo(
    paymentInfo: IPaymentInformation,
    planId: string,
  ): Observable<Response<IPaymentInformation>> {
    const data = this.transformOutgoingPaymentInfo(paymentInfo);

    return this.api.post(['plans', planId, 'relationships', 'payment-infos'], data, { params: this.params });
  }

  public updatePaymentInfo(paymentInfo: IPaymentInformation): Observable<Response<IPlan>> {
    const data = this.transformOutgoingPaymentInfo(paymentInfo);

    return this.api.patch(['payment-infos', data.id], data, { params: this.params });
  }

  public deletePaymentInfo(id: string): Observable<Response<null>> {
    return this.api.delete(['payment-infos', id], { params: this.params });
  }

  public transformIncomingPaymentInfo(paymentInfo: IPaymentInformation): IPaymentInformation {
    return paymentInfo;
  }

  private transformOutgoingPaymentInfo(paymentInfo: IPaymentInformation): IPaymentInformation {
    return paymentInfo;
  }
}
