import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { IEmployee, Response } from '../models';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class InvitationsService {
  constructor(private api: ApiService) {}

  public invitePlanAdministrator(planId: string): Observable<Response<any>> {
    return this.api.post(['invitations', 'plans', planId, 'administrator'], undefined);
  }

  public inviteEmployee(employeeId: string): Observable<Response<any>> {
    return this.api.post(['invitations', 'employees', employeeId], undefined);
  }

  public invitePlanEmployees(planId: string, employeeIds: string[]): Observable<Response<IEmployee[]>> {
    return this.api.post(['invitations', 'plans', planId], { employee_ids: employeeIds });
  }

  public inviteProposalReview(planId: string): Observable<Response<any>> {
    return this.api.post(['invitations', 'plans', planId, 'administrator', 'proposal'], undefined);
  }
}
