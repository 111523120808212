export enum DataType {
  Carriers = 'carriers',
  Confirmations = 'plan-confirmations',
  DefaultPlans = 'defaultplans',
  Employees = 'employees',
  Media = 'media',
  Organizations = 'organizations',
  Plans = 'plans',
  Profiles = 'profiles',
  Quotes = 'quotes',
  Rates = 'rates',
  Renewals = 'renewals',
  Roles = 'roles',
  Segments = 'segments',
  Tokens = 'tokens',
  Users = 'users',
  Notes = 'notes',
  PaymentInformation = 'payment-infos',
}
