<ng-container *ngIf="plan$ | async as plan">
  <pip-field-status-alert [fieldStatus]="plan?.meta?.field_statuses?.rates"></pip-field-status-alert>
  <nb-card status="basic">
    <nb-card-header>Common Rate Properties</nb-card-header>
    <nb-card-body>
      <pip-common-rate-properties-form
        [attributes]="plan.attributes"
        (update)="onSalaryThresholdUpdate($event, plan)"
      ></pip-common-rate-properties-form>
    </nb-card-body>
  </nb-card>
  <nb-card status="basic">
    <nb-card-header>Rate Classes</nb-card-header>
    <!-- TODO: Zero state when no current rates -->
    <nb-accordion>
      <nb-accordion-item *ngFor="let rate of rates$ | async; trackBy: trackById">
        <nb-accordion-item-header>{{ rate.attributes.name }}</nb-accordion-item-header>
        <nb-accordion-item-body>
          <pip-rate-properties
            [plan]="plan$ | async"
            [rate]="rate"
            [allowEdit]="isAdvisorOrAnalyst$ | async"
          ></pip-rate-properties>
        </nb-accordion-item-body>
      </nb-accordion-item>
    </nb-accordion>
  </nb-card>
  <nb-card status="basic">
    <nb-card-header>Create New Class</nb-card-header>
    <nb-card-body>
      <pip-rates-form [plan]="plan" #CreateForm (update)="onCreate($event, plan.id)"></pip-rates-form>
    </nb-card-body>
  </nb-card>
</ng-container>
