export interface IBeneficiary {
  id: string;
  first_name: string;
  last_name: string;
  middle_name: string;
  born_at: string;
  allocation: number;
  relationship: string;
  contingent?: boolean;
}

export class Beneficiary {
  constructor() {}

  public static create(): IBeneficiary {
    return {
      id: undefined,
      born_at: undefined,
      first_name: null,
      last_name: null,
      middle_name: null,
      allocation: null,
      relationship: null,
      contingent: false,
    };
  }
}
