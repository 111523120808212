<nb-card>
  <nb-card-body>
    <pip-common-rate-properties
      [resource]="plan$ | async"
      [firstRenewal]="firstRenewal$ | async"
      [allowEdit]="isAdvisorOrAnalyst$ | async"
    ></pip-common-rate-properties>
  </nb-card-body>
</nb-card>
<nb-card>
  <nb-card-body>
    <div class="d-flex align-items-center justify-content-between">
      <h2>Rates</h2>
      <button
        *ngIf="isAdvisorOrAnalyst$ | async"
        nbButton
        status="primary"
        size="tiny"
        outline
        class="ml-2"
        (click)="handleCreate(CreateRateDialog)"
      >
        Create
      </button>
    </div>
    <nb-accordion>
      <nb-accordion-item *ngFor="let rate of rates$ | async" class="mb-3">
        <nb-accordion-item-header>{{ rate.attributes.name }}</nb-accordion-item-header>
        <nb-accordion-item-body>
          <pip-rate-properties
            [plan]="plan$ | async"
            [rate]="rate"
            [allowEdit]="isAdvisorOrAnalyst$ | async"
          ></pip-rate-properties>
        </nb-accordion-item-body>
      </nb-accordion-item>
    </nb-accordion>
  </nb-card-body>
</nb-card>

<ng-template #CreateRateDialog let-data let-ref="dialogRef">
  <ng-container *ngIf="plan$ | async as plan">
    <form class="d-flex" [formGroup]="data.form" (ngSubmit)="handleSubmit(data.form.value, plan.id)">
      <nb-card status="basic">
        <nb-card-body>
          <pip-rate-properties-form-group [plan]="plan" [formGroup]="data.form"></pip-rate-properties-form-group>
        </nb-card-body>
        <nb-card-footer class="d-flex justify-content-between">
          <button nbButton status="primary" ghost type="button" (click)="ref.close()">Close</button>
          <button nbButton status="primary" type="submit">Save</button>
        </nb-card-footer>
      </nb-card>
    </form>
  </ng-container>
</ng-template>
