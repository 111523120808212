import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { IPaymentInformation, IPlan, MediaModel, MediaType, PaymentInformation, Role } from '@app/data';

@Component({
  selector: 'pip-payment-information-form',
  templateUrl: './payment-information-form.component.html',
  styleUrls: ['./payment-information-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentInformationFormComponent implements OnInit {
  @Input() public paymentInfo = PaymentInformation.create();
  @Input() public roles: Role[];
  @Input() public plan: IPlan;
  @Output() private update = new EventEmitter<IPaymentInformation>();

  public mediaType = MediaType;
  public mediaModel = MediaModel;
  public form: FormGroup;

  constructor() {}

  public ngOnInit(): void {
    this.initForm();
  }

  public onSubmit(): void {
    const { ...attributes } = this.form.value;

    const paymentInfo: IPaymentInformation = {
      ...this.paymentInfo,
      attributes: {
        ...attributes,
      },
    };

    this.update.emit(paymentInfo);
  }

  private initForm(): void {
    const { attributes } = this.paymentInfo;
    const { account_number, institution_id, name, transit } = attributes || PaymentInformation.createAttributes();

    this.form = new FormGroup({
      name: new FormControl(name),
      account_number: new FormControl(account_number),
      institution_id: new FormControl(institution_id),
      transit: new FormControl(transit),
    });
  }
}
