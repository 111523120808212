import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { PlanStatus } from '@app/data';

@Component({
  selector: 'pip-plan-filters',
  templateUrl: './plan-filters.component.html',
  styleUrls: ['./plan-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanFiltersComponent implements OnChanges {
  @Input() public onlyMine: boolean;
  @Input() public search: string;
  @Input() public statuses: PlanStatus[];
  @Input() public availableStatuses: PlanStatus[];

  @Output() public onlyMineChange = new EventEmitter<boolean>();
  @Output() public searchChange = new EventEmitter<string>();
  @Output() public statusChange = new EventEmitter<PlanStatus[]>();

  public statusList: PlanStatus[];

  constructor() {}

  public ngOnChanges(): void {
    if (!this.availableStatuses) return;

    this.statusList = this.availableStatuses.sort((a, b) => (a > b ? 1 : -1));
  }
}
