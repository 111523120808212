export enum ClientsPaths {
  ROOT = 'clients',
  Administration = 'administration',
  CompanyInfo = 'company-info',
  NewClient = 'new-client',
  PlanMembers = 'plan-members',
  Rates = 'rates',
  Renewal = 'renewal',
  PaymentInfo = 'payment-info',
}
