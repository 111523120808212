<fieldset [formGroup]="controlContainer.control">
  <legend class="subtitle d-flex justify-content-between">
    <span>Main contact</span>
    <pip-invite-plan-admin [plan]="plan" *ngIf="showInviteAdminCtrl"></pip-invite-plan-admin>
  </legend>

  <div class="form-group row">
    <label for="first_name" class="col col-form-label label">First name</label>
    <div class="col-sm-9">
      <input type="text" nbInput fullWidth id="first_name" formControlName="first_name" />
    </div>
  </div>

  <div class="form-group row">
    <label for="last_name" class="col col-form-label label">Last name</label>
    <div class="col-sm-9">
      <input type="text" nbInput fullWidth id="last_name" formControlName="last_name" />
    </div>
  </div>

  <div class="form-group row">
    <label for="email" class="col col-form-label label">Email</label>
    <div class="col-sm-9">
      <input type="email" nbInput fullWidth id="email" formControlName="email" />
    </div>
  </div>

  <div class="form-group row">
    <label for="phone" class="col col-form-label label">Phone</label>
    <div class="col-sm-9">
      <input type="tel" nbInput fullWidth id="phone" formControlName="phone" />
    </div>
  </div>

  <ng-container *ngIf="showSecurityFields">
    <div class="form-group row">
      <label for="born_at" class="col col-form-label label">Date of birth</label>
      <div class="col-sm-9">
        <input
          type="text"
          nbInput
          fullWidth
          id="born_at"
          formControlName="born_at"
          readonly
          [nbDatepicker]="bordAtDatepicker"
        />
        <nb-datepicker format="yyyy-MM-dd" #bordAtDatepicker></nb-datepicker>
      </div>
    </div>
    <div class="form-group row">
      <label for="mothers_name" class="col col-form-label label">Mother's first name</label>
      <div class="col-sm-9">
        <input type="text" nbInput fullWidth id="mothers_name" formControlName="mothers_name" />
      </div>
    </div>
  </ng-container>
</fieldset>
