// Typescript definitions for JSONAPI.org standard schema
// https://github.com/rmarganti/ts-json-api/blob/master/types/structure.d.ts

// Created by: https://github.com/rmarganti
// GitHub repo: https://github.com/rmarganti/ts-json-api

export * from './attributes';
export * from './error';
export * from './link-object';
export * from './links';
export * from './meta';
export * from './new-resource-object';
export * from './relationship';
export * from './relationship-data';
export * from './relationships';
export * from './request';
export * from './resource-object';
export * from './resource-object-or-objects';
export * from './resource-objects';
export * from './response';
export * from './response-with-data';
export * from './response-with-errors';
export * from './response-with-meta-data';
