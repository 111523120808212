<ng-container *ngIf="plan$ | async as plan">
  <h2>Renewal</h2>
  <dl>
    <div class="d-flex">
      <dt class="mr-2">Renewal date:</dt>
      <dd>
        {{ (plan.attributes.expire_at | date: 'longDate') || 'N/A' }}
      </dd>
    </div>
  </dl>
  <ng-container *ngIf="plan.relationships?.renewals?.data.length === 0; else RenewalDetail">
    <button nbButton status="primary" size="small" (click)="createRenewal(plan.id)">
      <nb-icon icon="plus-circle-outline"></nb-icon>
      Begin Renewal
    </button>
  </ng-container>
  <ng-template #RenewalDetail>
    <pip-client-renewal-detail [renewalId]="(plan.relationships?.renewals?.data)[0].id"></pip-client-renewal-detail>
  </ng-template>
</ng-container>
