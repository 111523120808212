import { Params, RouterStateSnapshot } from '@angular/router';
import { RouterStateSerializer as NgxsRouterStateSerializer } from '@ngxs/router-plugin';
import { Injectable } from "@angular/core";

export interface RouterStateParams {
  url: string;
  params: Params;
  queryParams: Params;
  level: number;
}

@Injectable()
export class RouterStateSerializer implements NgxsRouterStateSerializer<RouterStateParams> {
  public serialize(routerState: RouterStateSnapshot): RouterStateParams {
    const {
      url,
      root: { queryParams },
    } = routerState;

    let { root: route } = routerState;
    let params = {};
    while (route.firstChild) {
      route = route.firstChild;
      params = { ...params, ...route.params };
    }

    const level = routerState.url.split('/').length - 1;

    return { url, params, queryParams, level };
  }
}
