import { Observable, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { GetPlans, IPagination, IPlanListItem, PlansState, PlanStatus, Role, UsersState } from '@app/data';
import { PlanListKey } from '@app/data/store/plans/plans.models';
import { Navigate, RouterState } from '@ngxs/router-plugin';
import { Select, Store } from '@ngxs/store';

@Component({
  selector: 'pip-plans-list',
  templateUrl: './plans-list.component.html',
  styleUrls: ['./plans-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlansListComponent implements OnInit, OnDestroy {
  // TODO: Create intermediate state for each module (i.e. Quotes/Enrollments/Clients)
  // This will store the current page of plans and the search/pagination state
  // This should stop the flicker in-between Quotes/Enrollments/Clients and allow
  // us to keep the current pagination/search for each section
  // Once that is done, pass in the planListItems from the intermediate state instead of global

  @Input() public statuses: PlanStatus[];
  @Input() public defaultStatuses: PlanStatus[];
  @Input() public listKey: PlanListKey;

  @Input() public showColStartAt: boolean;
  @Input() public showColExpireAt: boolean;
  @Input() public showColCreatedAt: boolean;
  @Input() public showColUpdatedAt: boolean;

  @Select(PlansState.pagination) public pagination$: Observable<IPagination>;
  @Select(UsersState.activeUserRoles) public roles$: Observable<Role[]>;

  public plans$: Observable<IPlanListItem[]>;

  public routeParams$: Observable<Params> = this.store
    .select(RouterState)
    .pipe(map((state) => state.state.queryParams));
  public onlyMine$: Observable<boolean> = this.routeParams$.pipe(map((qp) => qp.onlyMine === 'true'));
  public search$: Observable<string> = this.routeParams$.pipe(map((qp) => qp.search));
  public sort$: Observable<string> = this.routeParams$.pipe(map((qp) => qp.sort));
  public statuses$: Observable<string[]> = this.routeParams$.pipe(
    map((qp) => {
      if (typeof qp.status === 'string') {
        return [qp.status];
      }

      return qp.status || this.defaultStatuses;
    }),
  );

  public unsub$ = new Subject<void>();

  constructor(private store: Store, private route: ActivatedRoute) {}

  public ngOnInit(): void {
    this.plans$ = this.store.select(PlansState[this.listKey]);

    this.routeParams$.pipe(takeUntil(this.unsub$)).subscribe((qp) => {
      const { onlyMine, current_page, per_page, sort, search, status } = qp;

      this.store.dispatch(
        new GetPlans({
          statuses: status ? [status] : this.defaultStatuses,
          onlyMine,
          sort,
          search,
          pagination: {
            current_page: current_page ? parseInt(current_page, 10) : null,
            per_page: per_page ? parseInt(per_page, 10) : null,
          },
          listKey: this.listKey,
        }),
      );
    });
  }

  public ngOnDestroy(): void {
    this.unsub$.next();
    this.unsub$.complete();
  }

  public onPaginationChange({ current_page, per_page }: IPagination): void {
    const qp = this.store.selectSnapshot(RouterState).state.queryParams;
    this.store.dispatch(new Navigate(['.'], { ...qp, current_page, per_page }, { relativeTo: this.route }));
  }

  public onSortChange(sort: string): void {
    const qp = this.store.selectSnapshot(RouterState).state.queryParams;
    this.store.dispatch(new Navigate(['.'], { ...qp, sort }, { relativeTo: this.route }));
  }

  public onOnlyMineChange(onlyMine: boolean): void {
    const qp = this.store.selectSnapshot(RouterState).state.queryParams;
    this.store.dispatch(new Navigate(['.'], { ...qp, onlyMine }, { relativeTo: this.route }));
  }

  public onSearchChange(search: string): void {
    const qp = this.store.selectSnapshot(RouterState).state.queryParams;
    this.store.dispatch(new Navigate(['.'], { ...qp, current_page: 1, search }, { relativeTo: this.route }));
  }

  public onStatusChange(statuses: PlanStatus[]): void {
    const qp = this.store.selectSnapshot(RouterState).state.queryParams;
    this.store.dispatch(
      new Navigate(['.'], { ...qp, status: statuses.length ? statuses : null }, { relativeTo: this.route }),
    );
  }
}
