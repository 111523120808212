<ng-container *ngIf="plan$ | async as plan">
  <pip-field-status-alert [fieldStatus]="plan?.meta?.field_statuses?.payment_info"></pip-field-status-alert>
  <nb-card status="basic">
    <nb-card-header class="d-flex justify-content-between align-items-center">
      <span>Payment Info</span>
      <nb-toggle
        labelPosition="start"
        [ngModel]="isAutomaticPayments$ | async"
        (checkedChange)="updatePaymentMethod($event, plan)"
        >Pre-authorized payments</nb-toggle
      >
    </nb-card-header>
    <ng-container *ngIf="isAutomaticPayments$ | async; else ManualPaymentsInfo">
      <nb-accordion>
        <nb-accordion-item *ngFor="let item of paymentInfos$ | async; trackBy: trackById">
          <nb-accordion-item-header>{{ item.attributes.name }}</nb-accordion-item-header>
          <nb-accordion-item-body>
            <pip-payment-information-properties [plan]="plan" [paymentInfo]="item"></pip-payment-information-properties>
          </nb-accordion-item-body>
        </nb-accordion-item>
      </nb-accordion>
    </ng-container>
    <ng-template #ManualPaymentsInfo>
      <nb-card-body>
        <nb-alert accent="warning">You must arrange payment details with your carrier.</nb-alert>
      </nb-card-body>
    </ng-template>
  </nb-card>
  <nb-card status="basic" *ngIf="isAutomaticPayments$ | async">
    <nb-card-header>Payment Authorization</nb-card-header>
    <nb-card-body class="border-top">
      <pip-preauthorized-payment-signoff-form
        [plan]="plan"
        [carrier]="carrier$ | async"
        (save)="onSave($event)"
      ></pip-preauthorized-payment-signoff-form>
    </nb-card-body>
  </nb-card>
  <nb-card status="basic" *ngIf="isAutomaticPayments$ | async">
    <nb-card-header>Create new payment info</nb-card-header>
    <nb-card-body>
      <pip-payment-information-form #CreateForm (update)="onCreate($event, plan.id)"></pip-payment-information-form>
    </nb-card-body>
  </nb-card>
</ng-container>
