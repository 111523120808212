import { ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { ControlContainer, Validators } from '@angular/forms';
import { CoverageStatus } from '@app/data/enums/coverage-status.enum';
import { MaritalStatus } from '@app/data/enums/marital-status.enum';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'pip-employee-coverage-formgroup',
  templateUrl: './employee-coverage-formgroup.component.html',
  styleUrls: ['./employee-coverage-formgroup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmployeeCoverageFormgroupComponent implements OnChanges, OnInit, OnDestroy {
  @Input() public showRAMQ: boolean;
  @Input() public disabled: boolean;
  @Input() public maritalStatus: MaritalStatus;

  public unsub$ = new Subject<void>();
  public coverageStatus = CoverageStatus;

  public showSingleOpt = false;
  public showSingleParentOpt = false;
  public showCoupleOpt = false;
  public showFamilyOpt = false;
  public showWaivedOpt = false;
  public showPolicyInputs = false;

  constructor(public controlContainer: ControlContainer) {}

  public ngOnChanges(): void {
    if (this.showRAMQ === false) {
      this.controlContainer.control.get('ramq').setValue(null);
    }

    if (this.maritalStatus) {
      switch (this.maritalStatus) {
        case MaritalStatus.Single: {
          this.showPolicyInputs = false;
          this.showSingleOpt = true;
          this.showSingleParentOpt = true;
          this.showCoupleOpt = false;
          this.showFamilyOpt = false;
          this.showWaivedOpt = false;

          this.setDefaultIfNotValid(
            'coverage_status_health',
            [CoverageStatus.Single, CoverageStatus.SingleParent],
            CoverageStatus.Single,
          );

          this.setDefaultIfNotValid(
            'coverage_status_dental',
            [CoverageStatus.Single, CoverageStatus.SingleParent],
            CoverageStatus.Single,
          );

          this.controlContainer.control.get('spouse_carrier').clearValidators();
          this.controlContainer.control.get('spouse_policy_number').clearValidators();

          this.controlContainer.control.get('spouse_carrier').setValue(null);
          this.controlContainer.control.get('spouse_policy_number').setValue(null);
          break;
        }
        case MaritalStatus.Married:
        case MaritalStatus.CommonLaw: {
          this.showPolicyInputs = true;
          this.showSingleOpt = true;
          this.showSingleParentOpt = false;
          this.showCoupleOpt = true;
          this.showFamilyOpt = true;
          this.showWaivedOpt = true;
          break;
        }
        default: {
          this.showPolicyInputs = false;
          this.showSingleOpt = false;
          this.showSingleParentOpt = false;
          this.showCoupleOpt = false;
          this.showFamilyOpt = false;
          this.showWaivedOpt = false;
        }
      }
    }

    this.setValidators();
  }

  public ngOnInit(): void {
    this.controlContainer.control
      .get('coverage_status_health')
      .valueChanges.pipe(takeUntil(this.unsub$))
      .subscribe(() => {
        this.setValidators();
      });

    this.controlContainer.control
      .get('coverage_status_dental')
      .valueChanges.pipe(takeUntil(this.unsub$))
      .subscribe(() => {
        this.setValidators();
      });
  }

  public ngOnDestroy(): void {
    this.unsub$.next();
    this.unsub$.complete();
  }

  private setValidators(): void {
    this.controlContainer.control.updateValueAndValidity();

    const { coverage_status_health, coverage_status_dental, spouse_carrier, spouse_policy_number } =
      this.controlContainer.control.value;

    if (
      [CoverageStatus.Single, CoverageStatus.Waived].includes(coverage_status_health) &&
      [CoverageStatus.Single, CoverageStatus.Waived].includes(coverage_status_dental)
    ) {
      this.controlContainer.control.get('spouse_carrier').setValidators([Validators.required, Validators.min(2)]);
      this.controlContainer.control.get('spouse_policy_number').setValidators([Validators.required, Validators.min(2)]);
    } else {
      this.controlContainer.control.get('spouse_carrier').clearValidators();
      this.controlContainer.control.get('spouse_policy_number').clearValidators();
    }

    this.controlContainer.control.get('spouse_carrier').setValue(spouse_carrier);
    this.controlContainer.control.get('spouse_policy_number').setValue(spouse_policy_number);

    this.controlContainer.control.updateValueAndValidity();
  }

  private setDefaultIfNotValid(controlName: string, validOpts: string[], defaultOpt: string): void {
    if (!validOpts.includes(this.controlContainer.control.get(controlName).value)) {
      this.controlContainer.control.get(controlName).setValue(defaultOpt);
    }
  }
}
