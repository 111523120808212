import { BenefitsContinuation } from '../enums/benefits-continuation';
import { PayCycle } from '../enums/pay-cycle.enum';

export interface IPlanStandards {
  allocations: boolean;
  employees_minimum: number;
  employer_contribution: IEmployerContribution;
  ltd_employee_paid: boolean;
  overtime_earnings: boolean;
  pay_cycle: PayCycle;
  probation_months: number;
  std_employee_paid: boolean;
  waiting_period: boolean;
  work_week_hours: number;
  workers_compensation: boolean;
  benefits_continuation: BenefitsContinuation;
}

export interface IEmployerContribution {
  life: number;
  add: number;
  dependentLife: number;
  criticalIllness: number;
  ltd: number;
  std: number;
  healthcare: number;
  dentalcare: number;
  virtualCare: number;
}

export class PlanStandards {
  constructor() {}

  public static create(): IPlanStandards {
    return {
      allocations: false,
      employees_minimum: null,
      employer_contribution: PlanStandards.createEmployerContribution(),
      ltd_employee_paid: true,
      overtime_earnings: false,
      pay_cycle: PayCycle.BiWeekly,
      probation_months: null,
      std_employee_paid: true,
      waiting_period: false,
      work_week_hours: null,
      workers_compensation: true,
      benefits_continuation: null,
    };
  }

  public static createEmployerContribution(): IEmployerContribution {
    return {
      life: null,
      add: null,
      dependentLife: null,
      criticalIllness: null,
      ltd: null,
      std: null,
      healthcare: null,
      dentalcare: null,
      virtualCare: null,
    };
  }
}
