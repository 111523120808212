import { HttpParams } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { GetPlan } from '@app/data';
import { IPaginatedLink } from '@app/data/models/paginated-link';
import { Store } from '@ngxs/store';

@Component({
  selector: 'pip-employee-pagination',
  templateUrl: './employee-pagination.component.html',
  styleUrls: ['./employee-pagination.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmployeePaginationComponent implements OnInit {
  @Input() public planId: string;
  @Input() public paginatedLinks: IPaginatedLink[];

  constructor(private store: Store) {}

  public ngOnInit(): void {}

  public linkStatus(active: boolean): 'primary' | 'basic' {
    return active ? 'primary' : 'basic';
  }

  public trackPaginate(idx: number, page: IPaginatedLink): string {
    return idx.toString();
  }

  public onPaginate(url: string): void {
    const search = new URL(url).search;
    // Hack: force setting "?include=employees"
    const query = new HttpParams({ fromString: search }).set('include', 'employees');

    this.store.dispatch(new GetPlan({ planId: this.planId, query }));
  }
}
