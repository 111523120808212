import * as Utils from '@app/utils';
import { Action, State, StateContext } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';

import { Observable } from 'rxjs';

import { DataType, MediaModel } from '../../enums';
import * as Data from '../../models';
import { MediaService } from '../../services';
import { GetEmployee } from '../employees/employees.actions';
import { GetPlan, GetPlanRenewal, GetRenewalSegment } from '../plans/plans.actions';
import { GetProfile } from '../profiles/profiles.actions';
import { GetQuote } from '../quotes/quotes.actions';
import { DeleteMedia, IncludeMedia, UploadMedia, UploadMediaFailed } from './media.actions';
import { GetRate } from '../rates';
import { Injectable } from '@angular/core';
import { GetPaymentInfo } from '../payment-info';

export interface MediaStateModel {
  entities: EntityMap<Data.IMedia>;
}

export const mediaStateDefaults: MediaStateModel = {
  entities: {},
};

@State<MediaStateModel>({
  name: DataType.Media,
  defaults: mediaStateDefaults,
})
@Injectable()
export class MediaState {
  constructor(private media: MediaService) {}

  @Action(IncludeMedia)
  public includeMedia(ctx: StateContext<MediaStateModel>, action: IncludeMedia): void {
    ctx.setState(
      patch<MediaStateModel>({
        entities: patch(Utils.createEntities(action.payload, 'id')),
      }),
    );
  }

  @Action(UploadMedia)
  public async uploadMedia(ctx: StateContext<MediaStateModel>, action: UploadMedia) {
    try {
      const res = await this.media.uploadMedia(action.payload).toPromise();

      ctx.setState(patch<MediaStateModel>({ entities: patch(Utils.createEntities([res.data], 'id')) }));

      this.refreshStores(action.payload.get('model') as MediaModel, action.payload.get('model_id') as string, ctx);
    } catch (res) {
      ctx.dispatch(new UploadMediaFailed(res.error));
      return;
    }
  }

  @Action(DeleteMedia)
  public async deleteMedia(ctx: StateContext<MediaStateModel>, { payload: { media_id, model } }: DeleteMedia) {
    const res = await this.media.deleteMedia(media_id).toPromise();
    const { [media_id]: removed, ...entities } = ctx.getState().entities;

    if (model) {
      this.refreshStores(model.media_model, model.id, ctx);
    }

    ctx.setState({
      entities,
    });
  }

  private refreshStores(model: MediaModel, id: string, ctx: StateContext<MediaStateModel>): Observable<void> {
    switch (model) {
      case MediaModel.Profile: {
        return ctx.dispatch(new GetProfile({ profileId: id }));
      }
      case MediaModel.Plan: {
        return ctx.dispatch(new GetPlan({ planId: id }));
      }
      case MediaModel.Employee: {
        return ctx.dispatch(new GetEmployee({ employeeId: id, updatePlan: false }));
      }
      case MediaModel.Quote: {
        return ctx.dispatch(new GetQuote({ quoteId: id }));
      }
      case MediaModel.Rate: {
        return ctx.dispatch(new GetRate({ rateId: id }));
      }
      case MediaModel.PaymentInfo: {
        return ctx.dispatch(new GetPaymentInfo({ paymentInfoId: id }));
      }
      case MediaModel.Segment: {
        return ctx.dispatch(new GetRenewalSegment({ segmentId: id }));
      }
    }
  }
}
