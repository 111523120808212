import { MediaType } from '../enums';

export interface ICarrierMediaLinks {
  [MediaType.EvidenceOfInsurability]: string;
  [MediaType.SecureOnlineAccess]: string;
  [MediaType.MasterApplication]: string;
  [MediaType.PayorAuthorization]: string;
  [MediaType.AbsentFromWork]: string;
  [MediaType.SalesTaxForm]: string;
  [MediaType.TrusteeAppointment]: string;
  [MediaType.CarrierGuide]: string;
}
