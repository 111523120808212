import { DataType } from '../enums';
import { Attributes, ResourceObject } from './json-api';

export interface ICommonRateProperties extends Attributes {
  critical_illness_nem?: number;
  critical_illness_termination_age?: number;
  dentalcare_termination_age?: number;
  healthcare_termination_age?: number;
  life_nem?: number;
  life_termination_age?: number;
  ltd_graded_scale?: boolean;
  ltd_nem?: number;
  pro_rated?: boolean;
  rate_guarantee_other?: string;
  rate_guarantee_select?: string;
  rate_guarantee?: string;
  renewal_caps?: string;
  renewal_months?: number; // 10-40
  std_graded_scale?: boolean;
  std_nem?: number;
  std_termination_age?: number;
}

export interface ICommonRatePropertiesResource extends ResourceObject {
  type: DataType;
  attributes: ICommonRateProperties;
}

export class CommonRateProperties {
  public static create(): ICommonRateProperties {
    return {
      critical_illness_termination_age: undefined,
      dentalcare_termination_age: undefined,
      healthcare_termination_age: undefined,
      life_nem: undefined,
      life_termination_age: undefined,
      ltd_graded_scale: undefined,
      ltd_nem: undefined,
      pro_rated: undefined,
      rate_guarantee: undefined,
      renewal_caps: undefined,
      renewal_months: undefined,
      std_graded_scale: undefined,
      std_nem: undefined,
      std_termination_age: undefined,
      rate_guarantee_other: undefined,
      rate_guarantee_select: undefined,
    };
  }
}
