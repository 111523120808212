<fieldset [formGroup]="controlContainer.control">
  <div class="form-row">
    <div class="form-group col">
      <label for="first_name" class="label">First name</label>
      <input type="text" nbInput fullWidth id="first_name" formControlName="first_name" />
    </div>
    <div class="form-group col">
      <label for="middle_name" class="label">Middle name</label>
      <input type="text" nbInput fullWidth id="middle_name" formControlName="middle_name" />
    </div>
    <div class="form-group col">
      <label for="last_name" class="label">Last name</label>
      <input type="text" nbInput fullWidth id="last_name" formControlName="last_name" />
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col">
      <label for="born_at" class="label">Date of birth</label>
      <input
        type="text"
        nbInput
        fullWidth
        id="born_at"
        formControlName="born_at"
        readonly
        [nbDatepicker]="dobDatepicker"
      />
      <nb-datepicker #dobDatepicker format="yyyy-MM-dd"></nb-datepicker>
    </div>
    <div class="form-group col">
      <label for="relationship" class="label">Relationship</label>
      <input type="text" nbInput fullWidth id="relationship" formControlName="relationship" />
    </div>
    <div class="form-group col">
      <label for="allocation" class="label">Allocated</label>
      <input type="number" min="0" max="100" nbInput fullWidth id="allocation" formControlName="allocation" />
    </div>

    <div class="form-group col d-flex align-items-end">
      <nb-checkbox id="contingent" formControlName="contingent" disabled>Contingent</nb-checkbox>
    </div>
  </div>
</fieldset>
