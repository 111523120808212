import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { Observable } from 'rxjs';
import { DataType, PlanStatus } from '../enums';
import { PlanConfirmation } from '../enums/plan-confirmation.enum';
import { IDefaultPlan, IOrganization, IPagination, IPlan, ISegment, Response } from '../models';
import { ICarrier } from '../models/carrier';
import { IRenewal } from '../models/renewal';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class PlansService {
  constructor(private api: ApiService) {}

  public getUserPlans(userId: string): Observable<Response<IPlan[]>> {
    return this.api.get(['users', userId, 'relationships', 'plans']);
  }

  public getPlans(
    statuses: PlanStatus[],
    onlyMine?: boolean,
    search?: string,
    pagination?: IPagination,
    sort?: string,
  ): Observable<Response<IPlan[]>> {
    const params: Params = { include: 'advisors', statuses: statuses && statuses.join(','), onlyMine, search, sort };

    if (pagination) {
      params['page[number]'] = pagination.current_page;
      params['page[size]'] = pagination.per_page;
    }

    Object.keys(params).forEach((key) => params[key] === undefined && delete params[key]);

    return this.api.get(['plans'], { params });
  }

  public getPlan(planId: string, query?: HttpParams): Observable<Response<IPlan>> {
    let params = query || new HttpParams();

    if (params.has('include') === false) {
      params = params.set(
        'include',
        'advisors,media,organizations,employees,rates,quotes,renewals,segments,plans,payment-infos',
      );
    }

    return this.api.get(['plans', planId], { params });
  }

  public createPlan(data: IPlan): Observable<Response<IPlan>> {
    return this.api.post(['plans'], data);
  }

  public updatePlan(data: IPlan): Observable<Response<IPlan>> {
    const params: Params = { include: 'employees' };
    return this.api.patch(['plans', data.id], data, { params });
  }

  public getDefaultPlans(): Observable<Response<IDefaultPlan[]>> {
    return this.api.get('defaultplans');
  }

  public getCarriers(): Observable<Response<ICarrier[]>> {
    const params: Params = { include: 'users,media' };
    return this.api.get('carriers', { params });
  }

  public deletePlan(planId: string): Observable<Response<any>> {
    return this.api.delete(['plans', planId]);
  }

  public updatePlanAssociate({
    planId,
    type,
    userId,
  }: {
    planId: string;
    type: 'rep' | 'associate';
    userId: string;
  }): Observable<Response<IPlan>> {
    return this.api.patch(['plans', planId, 'relationships', `${type}s`], { type: DataType.Users, id: userId });
  }

  public removePlanAssociate({ planId }: { planId: string }): Observable<Response<IPlan>> {
    return this.api.delete(['plans', planId, 'relationships', 'associates']);
  }

  public planConfirmation(planId: string, confirmation: PlanConfirmation): Observable<Response<IPlan>> {
    const params: Params = { include: 'advisors,media,organizations,employees' };

    return this.api.post(
      ['plans', planId, 'confirmations'],
      { type: DataType.Confirmations, attributes: { field: confirmation } },
      { params },
    );
  }

  public createPlanRenewal(planId: string): Observable<Response<IRenewal>> {
    const params: Params = { include: 'segments,media,organizations,employees' };

    return this.api.post(['plans', planId, 'renewal'], null, { params });
  }

  public getPlanRenewal(renewalId: string): Observable<Response<IRenewal>> {
    const params: Params = { include: 'segments,media,rates' };
    return this.api.get(['renewals', renewalId], { params });
  }

  public updatePlanRenewal(data: IRenewal): Observable<Response<IRenewal>> {
    const params: Params = { include: 'segments,media,rates' };
    return this.api.patch(['renewals', data.id], data, { params });
  }

  public getRenewalSegment(segmentId: string): Observable<Response<ISegment>> {
    return this.api.get(['segments', segmentId]);
  }

  public updateRenewalSegment(data: ISegment): Observable<Response<IRenewal>> {
    return this.api.patch(['segments', data.id], data);
  }

  public preflightOrganization(search: string): Observable<Response<IOrganization[]>> {
    const data: Partial<IPlan> = {
      type: DataType.Plans,
      attributes: {
        organization_name: search,
      },
    };

    return this.api.post(['plans', 'preflight'], data);
  }
}
