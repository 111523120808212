import { ChangeDetectionStrategy, Component, Input, TemplateRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  CreateRate,
  DataType,
  DeleteRate,
  IPlan,
  IRate,
  IRateAttributes,
  MediaModel,
  MediaType,
  MediaTypeDictionary,
  PlansState,
  PlanStandards,
  Rate,
  UpdateRate,
} from '@app/data';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { take } from 'rxjs/operators';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'pip-rate-properties',
  templateUrl: './rate-properties.component.html',
  styleUrls: ['./rate-properties.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RatePropertiesComponent {
  @Input() public plan: IPlan;
  @Input() public rate: IRate;
  @Input() public allowEdit = true;

  public planRates$ = this.store.select(PlansState.activePlanRates);

  public mediaType = MediaType;
  public mediaModel = MediaModel;
  public mediaDictionary = MediaTypeDictionary;

  public get trustedVideoUrl(): SafeResourceUrl {
    if (!this.rate?.attributes?.url_video) {
      return;
    }

    return this.sanitizer.bypassSecurityTrustResourceUrl(
      `https://www.youtube.com/embed/${this.rate?.attributes?.url_video}`,
    );
  }

  private dialogRef: NbDialogRef<any>;

  constructor(
    private store: Store,
    private actions$: Actions,
    public dialog: NbDialogService,
    private sanitizer: DomSanitizer,
  ) {}

  public handleEdit(templateRef: TemplateRef<any>): void {
    const { attributes } = this.rate;
    const {
      name,
      add,
      eap,
      ltd,
      std,
      life,
      healthcare_single,
      healthcare_single_parent,
      healthcare_couple,
      healthcare_family,
      dental_single,
      dental_single_parent,
      dental_couple,
      dental_family,
      dependent_life,
      critical_illness,
      critical_illness_single,
      life_max,
      std_max,
      ltd_max,
      critical_illness_max,
      life_volume_type,
      life_volume,
      critical_illness_volume_type,
      critical_illness_volume,
      virtual_care,
      standards,
      url_video,
    } = attributes || Rate.createAttributes();

    const {
      overtime_earnings,
      probation_months,
      waiting_period,
      work_week_hours,
      std_employee_paid,
      ltd_employee_paid,
      workers_compensation,
      allocations,
      benefits_continuation,
    } = standards || PlanStandards.create();

    const employer_contribution = standards?.employer_contribution || PlanStandards.createEmployerContribution();

    const form = new FormGroup({
      name: new FormControl(name),
      url_video: new FormControl(url_video),
      add: new FormControl(add),
      eap: new FormControl(eap),
      ltd: new FormControl(ltd),
      std: new FormControl(std),
      life: new FormControl(life),
      healthcare_single: new FormControl(healthcare_single),
      healthcare_single_parent: new FormControl(healthcare_single_parent),
      healthcare_couple: new FormControl(healthcare_couple),
      healthcare_family: new FormControl(healthcare_family),
      dental_single: new FormControl(dental_single),
      dental_single_parent: new FormControl(dental_single_parent),
      dental_couple: new FormControl(dental_couple),
      dental_family: new FormControl(dental_family),
      dependent_life: new FormControl(dependent_life),
      critical_illness: new FormControl(critical_illness),
      critical_illness_single: new FormControl(critical_illness_single),
      life_max: new FormControl(life_max),
      std_max: new FormControl(std_max?.toString()),
      ltd_max: new FormControl(ltd_max),
      critical_illness_max: new FormControl(critical_illness_max),
      life_volume_type: new FormControl(life_volume_type),
      life_volume: new FormControl(life_volume, [Validators.max(1_000_000)]),
      critical_illness_volume_type: new FormControl(critical_illness_volume_type),
      critical_illness_volume: new FormControl(critical_illness_volume),
      virtual_care: new FormControl(virtual_care),
      standards: new FormGroup({
        allocations: new FormControl(allocations, []),
        benefits_continuation: new FormControl(benefits_continuation),
        employer_contribution: new FormGroup({
          life: new FormControl(employer_contribution.life, []),
          add: new FormControl(employer_contribution.add, []),
          dependentLife: new FormControl(employer_contribution.dependentLife, []),
          criticalIllness: new FormControl(employer_contribution.criticalIllness, []),
          ltd: new FormControl(employer_contribution.ltd, []),
          std: new FormControl(employer_contribution.std, []),
          healthcare: new FormControl(employer_contribution.healthcare, []),
          dentalcare: new FormControl(employer_contribution.dentalcare, []),
          virtualCare: new FormControl(employer_contribution.virtualCare, []),
        }),
        overtime_earnings: new FormControl(overtime_earnings),
        probation_months: new FormControl(probation_months),
        waiting_period: new FormControl(waiting_period),
        work_week_hours: new FormControl(work_week_hours),
        std_employee_paid: new FormControl(std_employee_paid),
        ltd_employee_paid: new FormControl(ltd_employee_paid),
        workers_compensation: new FormControl(workers_compensation),
      }),
    });

    this.dialogRef = this.dialog.open(templateRef, { context: { form }, hasScroll: false });
  }

  public handleCopy(dialogRef: TemplateRef<any>): void {
    const form = new FormGroup({
      existingRate: new FormControl(null),
      identifier: new FormControl(null),
    });

    this.dialogRef = this.dialog.open(dialogRef, { context: { form } });
  }

  public handleDelete(dialogRef: TemplateRef<any>): void {
    this.dialog
      .open(dialogRef)
      .onClose.pipe(take(1))
      .subscribe((shouldDelete) => {
        if (shouldDelete) {
          this.store.dispatch(new DeleteRate(this.rate.id));
        }
      });
  }

  public onCopy(form: { identifier: string; existingRate?: IRate }): void {
    const rate: IRate = {
      id: form.existingRate ? form.existingRate.id : null,
      type: DataType.Rates,
      attributes: {
        ...this.rate.attributes,
        name: form.existingRate ? form.existingRate.attributes.name : form.identifier,
      },
    };

    this.actions$.pipe(ofActionSuccessful(UpdateRate, CreateRate), take(1)).subscribe(() => this.dialogRef.close());

    if (rate.id) {
      this.store.dispatch(new UpdateRate(rate));
    } else {
      this.store.dispatch(new CreateRate({ planId: this.plan.id, rate }));
    }
  }

  public onEdit(formValues: IRateAttributes): void {
    const rate: IRate = {
      id: this.rate.id,
      type: DataType.Rates,
      attributes: {
        ...formValues,
      },
    };

    this.actions$.pipe(ofActionSuccessful(UpdateRate), take(1)).subscribe(() => this.dialogRef.close());

    this.store.dispatch(new UpdateRate(rate));
  }

  public notActiveRates(rates: IRate[]): IRate[] {
    return rates.filter((r) => r.id !== this.rate.id);
  }
}
