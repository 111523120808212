import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CarrierQuotesPaths } from '@app/core/carrier-quotes/carrier-quotes.paths';
import { IQuoteItem } from '@app/data';
import { NbSortDirection, NbSortRequest, NbTreeGridDataSource, NbTreeGridDataSourceBuilder } from '@nebular/theme';

@Component({
  selector: 'pip-quotes-table',
  templateUrl: './quotes-table.component.html',
  styleUrls: ['./quotes-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuotesTableComponent implements OnChanges {
  @Input() public quotes: IQuoteItem[];
  @Input() public isLoading: boolean;

  public paths = CarrierQuotesPaths;
  public dataSource: NbTreeGridDataSource<IQuoteItem>;
  public columns = ['company', 'advisors', 'status', 'requestedDate', 'dueDate', 'actions'];

  sortColumn: string = 'status';
  sortDirection: NbSortDirection = NbSortDirection.ASCENDING;

  constructor(private dataSourceBuilder: NbTreeGridDataSourceBuilder<IQuoteItem>) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.quotes) {
      return;
    }

    this.dataSource = this.dataSourceBuilder.create(this.quotes.map(quote => ({ data: quote })));
    this.dataSource.sort({ column: this.sortColumn, direction: this.sortDirection });
  }

  changeSort(sortRequest: NbSortRequest): void {
    this.dataSource.sort(sortRequest);
    this.sortColumn = sortRequest.column;
    this.sortDirection = sortRequest.direction;
  }

  getDirection(column: string): NbSortDirection {
    if (column === this.sortColumn) {
      return this.sortDirection;
    }
    return NbSortDirection.NONE;
  }
}
