<table *ngIf="columns" [nbTreeGrid]="dataSource" nbSort (sort)="changeSort($event)">
  <tr nbTreeGridHeaderRow *nbTreeGridHeaderRowDef="columns"></tr>
  <tr nbTreeGridRow *nbTreeGridRowDef="let row; columns: columns"></tr>

  <ng-container nbTreeGridColumnDef="organization_name">
    <th nbTreeGridHeaderCell *nbTreeGridHeaderCellDef [nbSortHeader]="getDirection('organization_name')">Company</th>
    <td nbTreeGridCell *nbTreeGridCellDef="let row">
      <a [routerLink]="getEditLink(row.data)">{{ row.data.organization_name }}</a>
    </td>
  </ng-container>

  <ng-container nbTreeGridColumnDef="status">
    <th nbTreeGridHeaderCell *nbTreeGridHeaderCellDef [nbSortHeader]="getDirection('status')">Status</th>
    <td nbTreeGridCell *nbTreeGridCellDef="let row">
      <span class="badge" [ngClass]="row.data.status">{{ row.data.status | planStatus }}</span>
    </td>
  </ng-container>

  <ng-container nbTreeGridColumnDef="advisor">
    <th nbTreeGridHeaderCell *nbTreeGridHeaderCellDef>Advisor</th>
    <td nbTreeGridCell *nbTreeGridCellDef="let row">
      <div *ngIf="row.data.rep"><span class="label">Rep:</span> {{ row.data.rep }}</div>
      <div *ngIf="row.data.associate"><span class="label">Associate:</span> {{ row.data.associate }}</div>
    </td>
  </ng-container>

  <ng-container nbTreeGridColumnDef="start_at">
    <th
      [hidden]="!showColStartAt"
      nbTreeGridHeaderCell
      *nbTreeGridHeaderCellDef
      [nbSortHeader]="getDirection('start_at')"
    >
      Effective Date
    </th>
    <td [hidden]="!showColStartAt" nbTreeGridCell *nbTreeGridCellDef="let row">{{ row.data.start_at | date }}</td>
  </ng-container>

  <ng-container nbTreeGridColumnDef="expire_at">
    <th
      [hidden]="!showColExpireAt"
      nbTreeGridHeaderCell
      *nbTreeGridHeaderCellDef
      [nbSortHeader]="getDirection('expire_at')"
    >
      Renewal Date
    </th>
    <td [hidden]="!showColExpireAt" nbTreeGridCell *nbTreeGridCellDef="let row">
      {{ row.data.renewal_at | date }}
    </td>
  </ng-container>

  <ng-container nbTreeGridColumnDef="created_at">
    <th
      [hidden]="!showColCreatedAt"
      nbTreeGridHeaderCell
      *nbTreeGridHeaderCellDef
      [nbSortHeader]="getDirection('created_at')"
    >
      Quote Started
    </th>
    <td [hidden]="!showColCreatedAt" nbTreeGridCell *nbTreeGridCellDef="let row">{{ row.data.created_at | date }}</td>
  </ng-container>

  <ng-container nbTreeGridColumnDef="updated_at">
    <th
      [hidden]="!showColUpdatedAt"
      nbTreeGridHeaderCell
      *nbTreeGridHeaderCellDef
      [nbSortHeader]="getDirection('updated_at')"
    >
      Last Edit
    </th>
    <td [hidden]="!showColUpdatedAt" nbTreeGridCell *nbTreeGridCellDef="let row">{{ row.data.updated_at | date }}</td>
  </ng-container>

  <ng-container nbTreeGridColumnDef="actions">
    <th nbTreeGridHeaderCell *nbTreeGridHeaderCellDef></th>
    <td nbTreeGridCell *nbTreeGridCellDef="let row">
      <a nbButton status="primary" size="tiny" ghost *ngIf="row.data" [routerLink]="getEditLink(row.data)">
        <nb-icon icon="edit-outline"></nb-icon>
        Edit
      </a>
      <a
        *ngIf="shouldShowEnroll(row.data)"
        nbButton
        status="success"
        size="tiny"
        ghost
        [routerLink]="['/', paths.ROOT, row.data.id, paths.Proposal]"
      >
        <nb-icon icon="checkmark-square-outline"></nb-icon>
        Enroll
      </a>
    </td>
  </ng-container>
</table>

<pip-pagination
  *ngIf="showPagination"
  [collection]="plans"
  [pagination]="pagination"
  (paginationChange)="onPaginationChange($event)"
>
</pip-pagination>
