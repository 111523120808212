import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PlansPaths } from '@app/core/plans/plans.paths';
import {
  CreatePlanFromExisting,
  CreatePlanSuccess,
  GetPlan,
  IPlanSuccession,
  PlansState,
  PlanStatus,
  UsersState,
} from '@app/data';
import { NbDialogService } from '@nebular/theme';
import { Navigate } from '@ngxs/router-plugin';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { combineLatest, Subject } from 'rxjs';
import { filter, map, take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'pip-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientComponent implements OnInit, OnDestroy {
  public plan$ = this.store.select(PlansState.activePlan);
  public isAdvisorOrAnalyst$ = this.store.select(UsersState.isActiveUserAnAdvisorOrAnalyst);
  public hasRenewal$ = this.store.select(PlansState.doesActivePlanHaveRenewal);
  public planRenewal$ = this.store.select(PlansState.activeRenewal);

  public hideRenewalMenuItem$ = combineLatest([this.isAdvisorOrAnalyst$, this.hasRenewal$]).pipe(
    map(([isAorA, hasRenewal]) => {
      return !isAorA && !hasRenewal;
    }),
  );

  public otherVersions$ = this.plan$.pipe(
    map((plan) =>
      plan?.meta?.succession?.map(({ expire_at, ...other }) => ({
        expire_at: expire_at?.split('T')[0],
        ...other,
      })),
    ),
  );

  public showStartNewQuote$ = this.plan$.pipe(
    map((plan) => plan?.meta?.succession?.findIndex((p) => p.id === plan.id) === plan?.meta?.succession.length - 1),
  );

  public planStatus = PlanStatus;

  private destroy = new Subject<void>();

  constructor(
    public store: Store,
    private actions$: Actions,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: NbDialogService,
  ) {}

  public ngOnInit(): void {
    this.store
      .select(PlansState.activePlanId)
      .pipe(takeUntil(this.destroy))
      .subscribe((planId) => {
        this.store.dispatch(new GetPlan({ planId }));
      });
  }

  public ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  public goToRenewal(other: IPlanSuccession): void {
    if ([PlanStatus.Confirmed, PlanStatus.Archived].includes(other.status)) {
      this.router.navigate(['../', other.id], { relativeTo: this.route });
    } else {
      this.router.navigate(['/', PlansPaths.ROOT, other.id]);
    }
  }

  public createFromExisting(planId: string, templateRef: TemplateRef<any>): void {
    this.dialog
      .open(templateRef)
      .onClose.pipe(
        take(1),
        filter((confirm) => !!confirm),
      )
      .subscribe(() => {
        this.actions$
          .pipe(ofActionSuccessful(CreatePlanSuccess), take(1))
          .subscribe((res) => this.store.dispatch(new Navigate([PlansPaths.ROOT, res.payload.id])));

        this.store.dispatch(new CreatePlanFromExisting({ planId }));
      });
  }

  public compareById(v1, v2): boolean {
    return v1.id === v2.id;
  }
}
