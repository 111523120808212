import { Component, OnInit, ChangeDetectionStrategy, Input, EventEmitter, Output, OnChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IEmployee, IUser } from '@app/data';
import { escapeRegExp } from '@app/utils/escape-reg-exp';

@Component({
  selector: 'pip-renewal-signature',
  templateUrl: './renewal-signature.component.html',
  styleUrls: ['./renewal-signature.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RenewalSignatureComponent implements OnInit, OnChanges {
  @Input() public reqSignature: string;
  @Input() public locked = false;
  @Output() public sign = new EventEmitter<string>();

  public form: FormGroup;
  constructor() {}

  public ngOnInit(): void {
    this.initForm();
  }

  public ngOnChanges(): void {
    if (this.locked) {
      this.form?.disable();
    }
  }

  public onSubmit(): void {
    this.sign.emit(this.form.get('signature').value);
  }

  private initForm(): void {
    this.form = new FormGroup({
      signature: new FormControl(null, [
        Validators.required,
        Validators.pattern(new RegExp(`^${escapeRegExp(this.reqSignature)}$`, 'i')),
      ]),
      acknowledgement: new FormControl(false, [Validators.requiredTrue]),
    });
  }
}
