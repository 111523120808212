<div class="d-flex align-items-center">
  <h2>Main Contact</h2>
  <button *ngIf="canEdit" nbButton status="primary" size="tiny" outline class="ml-2" (click)="handleEdit(EditDialog)">
    Edit
  </button>
</div>
<dl>
  <dt class="sr-only">Name</dt>
  <dd>{{ administrator?.first_name }} {{ administrator?.last_name }}</dd>
  <dt class="sr-only">Email</dt>
  <dd class="d-flex align-items-center" *ngIf="administrator?.email">
    <a [href]="'mailto:' + administrator?.email" class="mr-2">{{ administrator?.email }}</a>
    <pip-invite-plan-admin *ngIf="canEdit" [plan]="plan"></pip-invite-plan-admin>
  </dd>
  <dt class="sr-only">Phone</dt>
  <dd>
    <a [href]="'tel:' + administrator?.phone">{{ administrator?.phone }}</a>
  </dd>
  <ng-container *ngIf="displayExtraInfo">
    <div class="d-flex">
      <dt class="mr-2">Date of Birth:</dt>
      <dd>{{ administrator?.born_at | date: 'longDate' }}</dd>
    </div>
    <div class="d-flex">
      <dt class="mr-2">Mother's First Name:</dt>
      <dd>{{ administrator?.mothers_name }}</dd>
    </div>
    <div class="d-flex">
      <dt class="mr-2">Advisor Access:</dt>
      <dd>{{ plan?.attributes?.pa_access | paAccess }}</dd>
    </div>
  </ng-container>
</dl>
<ng-container *ngIf="!displayExtraInfo">
  <p>
    To assign the Plan Administrator role to another plan member in your organization please send an email to
    <a href="mailto:info@ingeniousinsurance.ca">info@ingeniousinsurance.ca</a>.
  </p>
</ng-container>

<!--  -->
<ng-template #EditDialog let-data let-ref="dialogRef">
  <form [formGroup]="data.form" (ngSubmit)="handleSubmit(data.form.value)">
    <nb-card status="basic">
      <nb-card-body>
        <pip-administrator-formgroup [formGroup]="data.form"></pip-administrator-formgroup>
      </nb-card-body>
      <nb-card-footer class="d-flex justify-content-between">
        <button nbButton status="primary" ghost type="button" (click)="ref.close()">Close</button>
        <button nbButton status="primary" type="submit">Save</button>
      </nb-card-footer>
    </nb-card>
  </form>
</ng-template>
