<article class="mb-3 mr-3">
  <pip-file-upload-form-group
    #uploader
    [modelId]="modelId"
    [mediaType]="mediaType"
    [mediaModel]="mediaModel"
    [fileTypeLabel]="fileTypeLabel"
    [existingMedia]="existingMedia"
    [isLoading]="isLoading$ | async"
    [requireExpiry]="requireExpiry"
    [warning]="warning"
    [downloadTemplate]="downloadTemplate"
    [required]="required"
    [uploadLabelOverride]="uploadLabelOverride"
    [downloadLabelOverride]="downloadLabelOverride"
    (upload)="onUpload($event)"
    (delete)="onDelete($event)"
    (announceWarning)="showWarning(warningDialog)"
  ></pip-file-upload-form-group>
</article>

<div *ngIf="errors$ | async as errorsObj">
  <nb-alert class="text-left" status="danger" *ngFor="let error of errorsObj?.errors">{{ error?.detail }}</nb-alert>
  <nb-alert class="text-left" accent="danger" *ngFor="let verror of errorsObj?.meta?.validation_errors">{{
    verror
  }}</nb-alert>
</div>

<ng-template #warningDialog let-data let-ref="dialogRef">
  <nb-card status="danger">
    <nb-card-header>Warning!</nb-card-header>
    <nb-card-body>{{ warning }}</nb-card-body>
    <nb-card-footer class="d-flex justify-content-end">
      <button nbButton status="primary" ghost type="button" (click)="showFileSelect(uploader.fileInput, ref)">
        Ok
      </button>
    </nb-card-footer>
  </nb-card>
</ng-template>
