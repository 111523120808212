<div class="row justify-content-center">
  <div class="col-xl-9">
    <h2 class="title">Review Proposal</h2>
    <nb-card>
      <nb-list>
        <nb-list-item>
          <a nbButton status="primary" outline [href]="proposalLink$ | async" download="true">Download proposal</a>
        </nb-list-item>
        <nb-list-item>
          <a nbButton status="primary" outline [href]="bookingLink$ | async" target="_blank">Book with Advisor</a>
        </nb-list-item>
      </nb-list>
      <nb-card-footer>
        <a nbButton status="primary" [routerLink]="['../']">Back to tasks</a>
      </nb-card-footer>
    </nb-card>
  </div>
</div>
