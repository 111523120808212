import { Observable } from 'rxjs';

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { IPlan, IPlanMedia, MediaModel, MediaType, PlansState } from '@app/data';
import { Select } from '@ngxs/store';

@Component({
  selector: 'pip-client-documents',
  templateUrl: './client-documents.component.html',
  styleUrls: ['./client-documents.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientDocumentsComponent implements OnInit {
  @Select(PlansState.activePlan)
  public plan$: Observable<IPlan>;

  @Select(PlansState.activePlanMedia)
  public planMedia$: Observable<IPlanMedia>;

  public mediaType = MediaType;
  public mediaModel = MediaModel;

  constructor() {}

  public ngOnInit(): void {}
}
