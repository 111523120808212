<div class="row justify-content-center">
  <div class="col-xl-6">
    <h2>Contact Information</h2>
    <pip-field-status-alert
      *ngIf="(plan$ | async)?.meta?.field_statuses?.administrator as administrator"
      [fieldStatus]="administrator"
      key="confirmed"
    ></pip-field-status-alert>
    <pip-plan-administrator-form
      *ngIf="plan$ | async as plan"
      [plan]="plan"
      [carrier]="carrier$ | async"
      confirmButton="Save"
      [requirePaAccess]="true"
      (save)="onUpdatePlan($event)"
    ></pip-plan-administrator-form>
  </div>
</div>
