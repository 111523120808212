<div *ngIf="employees$ | async as employees">
  <p>Are any full-time/permanent employees currently off work?</p>

  <div *ngIf="showForm === false && (offwork$ | async).length === 0">
    <button class="mr-3" nbButton status="primary" outline (click)="showForm = true">Yes</button>
    <a nbButton status="primary" outline [routerLink]="['../']">No</a>
  </div>

  <div *ngIf="showForm || (offwork$ | async).length">
    <pip-off-work-employee-form
      #form
      [employees]="employees$ | async"
      (update)="onEmployeeUpdate($event, form)"
    ></pip-off-work-employee-form>
    <pip-off-work-employee-list [employees]="employees$ | async" (removeClaim)="onRemoveClaim($event)"></pip-off-work-employee-list>
  </div>
</div>
