<nb-card>
  <nb-card-body>
    <div>
      <h2>Client Administration</h2>
      <h3 class="subtitle">Client Rep</h3>
      <pip-associate-selector
        [user]="planRep$ | async"
        type="rep"
        [planId]="(plan$ | async)?.id"
        [canEdit]="isAdvisorOrAnalyst$ | async"
      ></pip-associate-selector>
      <h3 class="subtitle mt-3">Client Associate</h3>
      <pip-associate-selector
        [user]="planAssociate$ | async"
        type="associate"
        [planId]="(plan$ | async)?.id"
        [canEdit]="isAdvisorOrAnalyst$ | async"
      ></pip-associate-selector>
    </div>
    <hr />
    <pip-overall-details
      [plan]="plan$ | async"
      [planMedia]="planMedia$ | async"
      [carrier]="carrier$ | async"
      [carriers]="carriers$ | async"
      [carrierMediaLinks]="carrierMediaLinks$ | async"
      [canUploadDocs]="canUploadDocs$ | async"
      [organization]="organization$ | async"
      [canEdit]="isAdvisorOrAnalyst$ | async"
    ></pip-overall-details>
    <pip-additional-benefits [plan]="plan$ | async" [canEdit]="isAdvisorOrAnalyst$ | async"></pip-additional-benefits>
    <pip-plan-standards-info
      [plan]="plan$ | async"
      [planStandards]="planStandards$ | async"
      [canEdit]="isAdvisorOrAnalyst$ | async"
    ></pip-plan-standards-info>
  </nb-card-body>
</nb-card>
