import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
  DataType,
  IMedia,
  IRenewal,
  IRole,
  ISegment,
  IUser,
  MediaModel,
  MediaType,
  SegmentStatus,
  SegmentType,
} from '@app/data';

@Component({
  selector: 'pip-renewal-segment',
  templateUrl: './renewal-segment.component.html',
  styleUrls: ['./renewal-segment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RenewalSegment implements OnInit {
  @Input() public user: IUser;
  @Input() public roles: IRole[];
  @Input() public renewal: IRenewal;
  @Input() public segment: ISegment;
  @Input() public media: IMedia[];
  @Input() public limited: boolean;

  public form: FormGroup;
  public mediaType = MediaType;
  public mediaModel = MediaModel;
  public segmentStatus = SegmentStatus;

  public get proposalDoc(): IMedia[] {
    return this.media.filter((m) => m?.attributes.type === MediaType.Proposal);
  }

  public get additionalDoc(): IMedia {
    return this.media.find((m) => m?.attributes.type === MediaType.AdditionalDocument);
  }

  public get canEdit(): boolean {
    return true; //this.segment.attributes.status !== SegmentStatus.Closed;
  }

  public get canSubmit(): boolean {
    return this.segment.attributes.valid;
  }

  public get showProposalActions(): boolean {
    return this.canEdit && this.segment.attributes.type === SegmentType.InitialProposal;
  }

  public get showAnalysisActions(): boolean {
    return (
      this.canEdit &&
      this.showAcceptanceActions === false &&
      [SegmentStatus.RepAccept, SegmentStatus.RepReject].includes(this.segment.attributes.status) === false &&
      [SegmentType.Analysis, SegmentType.Revision].includes(this.segment.attributes.type)
    );
  }

  public get showAcceptanceActions(): boolean {
    return (
      [SegmentType.Analysis, SegmentType.Revision].includes(this.segment.attributes.type) &&
      this.segment.attributes.status === SegmentStatus.RepDecision
    );
  }

  public get showFinalStartedActions(): boolean {
    return (
      this.segment.attributes.type === SegmentType.Final && this.segment.attributes.status === SegmentStatus.Started
    );
  }

  public get showFinalRepDecisionActions(): boolean {
    return (
      this.segment.attributes.type === SegmentType.Final && this.segment.attributes.status === SegmentStatus.RepDecision
    );
  }

  public get showFinalClosedActions(): boolean {
    return (
      this.segment.attributes.type === SegmentType.Final && this.segment.attributes.status === SegmentStatus.Closed
    );
  }

  public get reqSignature(): string {
    return `${this.user.attributes.first_name} ${this.user.attributes.last_name}`;
  }

  @Output() private update = new EventEmitter<{ segment: ISegment; actionLabel: string }>();
  @Output() private save = new EventEmitter<ISegment>();
  @Output() private sign = new EventEmitter<{ renewal: IRenewal; signature: string }>();

  constructor() {}

  public ngOnInit(): void {
    this.initForm();
  }

  public saveSegment(): void {
    const { premiums, change, notes } = this.form.value;

    const segment: ISegment = {
      id: this.segment.id,
      type: DataType.Segments,
      attributes: {
        premiums,
        change,
        notes,
      },
    };

    this.save.emit(segment);
  }

  public submitSegmentWithStatus(status: SegmentStatus, actionLabel: string): void {
    const { premiums, change, notes } = this.form.value;

    const segment: ISegment = {
      id: this.segment.id,
      type: DataType.Segments,
      attributes: {
        ...this.segment.attributes,
        premiums,
        change,
        notes,
        status,
      },
    };

    this.update.emit({ segment, actionLabel });
  }

  public onSign(signature: string): void {
    this.sign.emit({ renewal: this.renewal, signature });
  }

  private initForm(): void {
    const { change, notes, premiums } = this.segment?.attributes;

    this.form = new FormGroup({
      premiums: new FormControl(premiums),
      change: new FormControl(change),
      notes: new FormControl(notes),
    });
  }
}
