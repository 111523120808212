import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ICarrierMediaLinks, IPlan, IPlanMedia, MediaModel, MediaType, PlanConfirmations, PlansState, QuotesState } from '@app/data';
import { PlanConfirmation } from '@app/data/enums/plan-confirmation.enum';
import { Navigate } from '@ngxs/router-plugin';
import { Actions, ofActionSuccessful, Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Component({
  selector: 'pip-quote-task-documents',
  templateUrl: './quote-task-documents.component.html',
  styleUrls: ['./quote-task-documents.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuoteTaskDocumentsComponent implements OnInit {
  public plan$ = this.store.select(PlansState.activePlan);
  public planMedia$ = this.store.select(PlansState.activePlanMedia);
  public carrierMediaLinks$ = this.store.select(QuotesState.carrierMediaLinks);

  public isSubmittable$ = this.planMedia$.pipe(
    map(media => !!media.SecureOnlineAccess && !!media.MasterApplication && !!media.PayorAuthorization && !!media.SalesTaxForm),
  );

  public mediaType = MediaType;
  public mediaModel = MediaModel;

  constructor(private store: Store, private actions$: Actions, private route: ActivatedRoute) {}

  public ngOnInit(): void {}

  public onUpdatePlan(plan: IPlan): void {
    this.actions$.pipe(ofActionSuccessful(PlanConfirmations), take(1)).subscribe(() => this.routeBack());
    this.store.dispatch(new PlanConfirmations({ planId: plan.id, confirmation: PlanConfirmation.Legal }));
  }

  public onCancel(): void {
    this.routeBack();
  }

  private routeBack(): void {
    this.store.dispatch(new Navigate(['../'], null, { relativeTo: this.route }));
  }
}
