<ng-container *ngIf="plan$ | async as plan">
  <pip-field-status-alert [fieldStatus]="plan.meta?.field_statuses?.organization_info"></pip-field-status-alert>
  <div class="row">
    <div class="col">
      <pip-company-info-form
        [locked]="locked$ | async"
        [plan]="plan"
        [defaultPlans]="defaultPlans$ | async"
        [loading]="loading$ | async"
      ></pip-company-info-form>
    </div>
  </div>
</ng-container>
