import { ChangeDetectionStrategy, Component, Input, OnChanges, TemplateRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DashboardPaths } from '@app/core/dashboard';
import {
  DataType,
  DeleteMedia,
  ICarrier,
  IMedia,
  IPlan,
  IQuote,
  IUser,
  PlansState,
  Response,
  UpdatePlan,
  UpdateQuote,
  UploadMedia,
} from '@app/data';
import { QuotesService } from '@app/data/services';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { Navigate } from '@ngxs/router-plugin';
import { Actions, ofActionSuccessful, Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { endWith, filter, map, share, startWith, take } from 'rxjs/operators';

@Component({
  selector: 'pip-carrier-document',
  templateUrl: './carrier-document.component.html',
  styleUrls: ['./carrier-document.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarrierDocumentComponent implements OnChanges {
  @Input() public quoteId: string;
  @Input() public showEnrollCtrls: boolean;
  @Input() public showDocCtrls: boolean;
  @Input() public users: EntityMap<IUser>;

  @Select(PlansState.activePlan) public plan$: Observable<IPlan>;
  public quoteRes$: Observable<Response<IQuote>>;
  public quote$: Observable<IQuote>;
  public carrier$: Observable<ICarrier>;
  public media$: Observable<IMedia[]>;

  public isLoading$: Observable<boolean>;
  public isUploading$: Observable<boolean>;
  public isDeleting$: Observable<boolean>;
  public declineFormDialog: NbDialogRef<any>;
  public declineForm: FormGroup;

  constructor(
    private store: Store,
    private actions$: Actions,
    private quoteService: QuotesService,
    private dialog: NbDialogService,
  ) {}

  public ngOnChanges(): void {
    if (!this.quoteId) {
      return;
    }

    this.fetchQuote();
  }

  public fetchQuote(): void {
    this.quoteRes$ = this.quoteService.getQuote(this.quoteId).pipe(share());

    this.isLoading$ = this.quoteRes$.pipe(
      map(res => !!res),
      startWith(true),
      endWith(false),
    );

    this.quote$ = this.quoteRes$.pipe(map(res => res.data));
    this.carrier$ = this.quoteRes$.pipe(
      map(res => res.included.filter(i => i.type === DataType.Carriers)[0] as ICarrier),
    );
    this.media$ = this.quoteRes$.pipe(
      map(
        res =>
          res.included.filter(
            i => i.type === DataType.Media && res.data.relationships.media.data.find(m => m.id === i.id),
          ) as IMedia[],
      ),
    );
  }

  public onCarrierDecline({ carrier, quote }: { carrier: ICarrier; quote: IQuote }, dialogRef: TemplateRef<any>): void {
    this.declineForm = new FormGroup({
      notes: new FormControl(),
    });

    this.declineFormDialog = this.dialog.open(dialogRef, { context: { carrier, quote } });
  }

  public declineQuote(id: string): void {
    const quote: IQuote = {
      type: DataType.Quotes,
      id,
      attributes: { response: false, notes: this.declineForm.get('notes').value },
    };

    this.store
      .dispatch(new UpdateQuote({ quote }))
      .pipe(take(1))
      .subscribe(() => this.declineFormDialog.close());
  }

  public onUploadMedia(data: FormData): void {
    this.actions$.pipe(ofActionSuccessful(UploadMedia), take(1)).subscribe(() => this.fetchQuote());
    this.isUploading$ = this.store.dispatch(new UploadMedia(data)).pipe(startWith(true), endWith(false));
  }

  public onDeleteMedia(mediaId: string): void {
    this.actions$.pipe(ofActionSuccessful(DeleteMedia), take(1)).subscribe(() => this.fetchQuote());
    this.isDeleting$ = this.store
      .dispatch(new DeleteMedia({ media_id: mediaId }))
      .pipe(startWith(true), endWith(false));
  }

  public onCarrierInvite(id: string, dialog: TemplateRef<any>): void {
    this.dialog
      .open(dialog)
      .onClose.pipe(
        take(1),
        filter(c => c),
      )
      .subscribe(() => {
        const quote: IQuote = {
          type: DataType.Quotes,
          id,
          attributes: { response: null },
        };
        this.store.dispatch(new UpdateQuote({ quote }));
      });
  }

  public onQuoteEnroll(
    { quote, options, plan }: { quote: IQuote; options: IMedia[]; plan: IPlan },
    dialog: TemplateRef<any>,
  ): void {
    const form = new FormGroup({
      selected_media_id: new FormControl(null, [Validators.required]),
      start_at: new FormControl(null, [Validators.required]),
    });

    this.actions$
      .pipe(ofActionSuccessful(UpdatePlan), take(1))
      .subscribe(() => this.store.dispatch(new Navigate(['/', DashboardPaths.ROOT])));

    this.dialog
      .open(dialog, {
        context: {
          form,
          quote,
          plan,
          options,
        },
      })
      .onClose.pipe(
        take(1),
        filter(c => c),
      )
      .subscribe(() => {
        const { selected_media_id, start_at } = form.value;

        const quotePayload: IQuote = {
          type: DataType.Quotes,
          id: quote.id,
          attributes: { selected_media_id, selected: true },
        };

        const planPayload: IPlan = {
          type: DataType.Plans,
          id: plan.id,
          attributes: { start_at },
        };

        this.store.dispatch([new UpdateQuote({ quote: quotePayload }), new UpdatePlan(planPayload)]);
      });
  }
}
