<fieldset [formGroup]="controlContainer.control">
  <div class="row">
    <div class="col">
      <img class="mx-auto my-4" src="/assets/cheque_explain.png" alt="Example cheque">
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col">
      <label for="transit" class="label">Transit number</label>
      <input type="text" nbInput fullWidth id="transit" formControlName="transit" />
    </div>
    <div class="form-group col">
      <label for="institution_id" class="label">Institution number</label>
      <input type="text" nbInput fullWidth id="institution_id" formControlName="institution_id" />
    </div>
    <div class="form-group col">
      <label for="account_number" class="label">Account number</label>
      <input type="text" nbInput fullWidth id="account_number" formControlName="account_number" />
    </div>
  </div>
</fieldset>
