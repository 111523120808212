export enum LifeVolumeType {
  Fixed = 'Fixed',
  SalaryX1 = 'SalaryX1',
  SalaryX15 = 'SalaryX15',
  SalaryX2 = 'SalaryX2',
  SalaryX25 = 'SalaryX25',
  SalaryX3 = 'SalaryX3',
}

export const LifeVolumeTypeDictionary: Record<LifeVolumeType, string> = {
  [LifeVolumeType.SalaryX1]: 'Salary x1',
  [LifeVolumeType.SalaryX15]: 'Salary x1.5',
  [LifeVolumeType.SalaryX2]: 'Salary x2',
  [LifeVolumeType.SalaryX25]: 'Salary x2.5',
  [LifeVolumeType.SalaryX3]: 'Salary x3',
  [LifeVolumeType.Fixed]: 'Fixed',
};

export const LifeVolumeTypes: [LifeVolumeType, string][] = Object.entries(LifeVolumeTypeDictionary) as [
  LifeVolumeType,
  string,
][];
