import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Administrator, CompanyInfo, IDefaultPlan, IPlan, Plan, UpdatePlan } from '@app/data';
import { yearMonthDay } from '@app/utils/year-month-day';
import { Store } from '@ngxs/store';
import { parseISO } from 'date-fns';
import * as moment from 'moment';

@Component({
  selector: 'pip-company-info-form',
  templateUrl: './company-info-form.component.html',
  styleUrls: ['./company-info-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class CompanyInfoFormComponent implements OnInit, OnChanges {
  @Input() public plan: IPlan;
  @Input() public defaultPlans: IDefaultPlan[];
  @Input() public loading: boolean;
  @Input() public locked: boolean;

  public form: FormGroup;

  constructor(private fb: FormBuilder, private store: Store) {}

  public ngOnInit(): void {}

  public ngOnChanges(): void {
    this.initForm();
  }

  public onSubmit(): void {
    const { id, type } = this.plan;
    const { organization_name, ...companyInfo } = this.form.get('companyInfo').value;
    const { ...businessAddress } = this.form.get('businessAddress').value;
    const administrator = this.form.get('administrator').value;

    const plan: IPlan = {
      id,
      type,
      attributes: {
        organization_name,
        organization_info: {
          ...companyInfo,
          ...businessAddress,
        },
        administrator: {
          ...administrator,
          born_at: yearMonthDay(administrator.born_at),
        },
      },
    };

    this.store.dispatch(new UpdatePlan(plan));
  }

  private initForm(): void {
    const {
      organization_name,
      organization_info: company_info,
      administrator,
      virgin,
      ltd,
      std,
      requests,
    } = this.plan.attributes || Plan.createAttributes();
    const { address1, address2, city, country, industry, postal_code, state, website, language } =
      company_info || CompanyInfo.create();
    const { email, first_name, last_name, phone, born_at, mothers_name } = administrator || Administrator.create();

    this.form = this.fb.group({
      companyInfo: this.fb.group({
        organization_name: [organization_name, [Validators.required]],
        industry: [industry],
        website: [website],
        language: [language],
      }),
      businessAddress: this.fb.group({
        address1: [address1],
        address2: [address2],
        city: [city],
        state: [state],
        country: [country],
        postal_code: [postal_code],
      }),
      administrator: this.fb.group({
        email: [email],
        first_name: [first_name],
        last_name: [last_name],
        phone: [phone],
        born_at: [(born_at && parseISO(born_at)) || undefined],
        mothers_name: [mothers_name],
      }),
      insuranceStatus: this.fb.group({
        virgin: [virgin],
        ltd: [ltd],
        std: [std],
        requests: [requests],
        selectedPlans: this.fb.array(
          this.defaultPlans.map((dp) => {
            return this.fb.group({
              name: dp.id,
              selected:
                this.plan.attributes.options && this.plan.attributes.options.find((o) => o === dp.id) ? true : false,
            });
          }),
        ),
      }),
    });

    if (this.locked) {
      this.form.disable();
    }
  }
}
