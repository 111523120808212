import { Observable } from 'rxjs';

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ICarrierMediaLinks, IPlan, IPlanMedia, MediaModel, MediaType, PlansState, QuotesState } from '@app/data';
import { Select } from '@ngxs/store';

@Component({
  selector: 'pip-plan-secure-access',
  templateUrl: './plan-secure-access.component.html',
  styleUrls: ['./plan-secure-access.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanSecureAccessComponent implements OnInit {
  @Select(PlansState.activePlan)
  public plan$: Observable<IPlan>;

  @Select(PlansState.activePlanMedia)
  public planMedia$: Observable<IPlanMedia>;

  @Select(QuotesState.carrierMediaLinks)
  public carrierMediaLinks$: Observable<ICarrierMediaLinks>;

  public mediaType = MediaType;
  public mediaModel = MediaModel;

  constructor() {}

  public ngOnInit(): void {}
}
