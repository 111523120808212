<fieldset [formGroup]="controlContainer.control">
  <div *ngIf="defaultPlans">
    <pip-pick-a-plan-table formGroupName="selectedPlans" [defaultPlans]="defaultPlans"></pip-pick-a-plan-table>
  </div>
</fieldset>

<fieldset [formGroup]="controlContainer.control">
  <legend class="subtitle">Select any additional benefits</legend>
  <div class="form-group row">
    <div class="col d-flex flex-column">
      <nb-checkbox id="std" formControlName="std">Short-term disability</nb-checkbox>
      <nb-checkbox id="ltd" formControlName="ltd">Long-term disability</nb-checkbox>
    </div>
    <div class="col d-flex flex-column">
      <nb-checkbox id="eap" formControlName="eap">Employee assistance program</nb-checkbox>
      <nb-checkbox id="virtual_care" formControlName="virtual_care">Virtual care</nb-checkbox>
    </div>
    <div class="col d-flex flex-column">
      <nb-checkbox id="eap" formControlName="critical_illness">Critical illness</nb-checkbox>
      <nb-checkbox id="virtual_care" formControlName="health_spending_account">Health spending account</nb-checkbox>
    </div>
  </div>
</fieldset>

<fieldset [formGroup]="controlContainer.control" *ngIf="!isVirgin">
  <div class="form-group">
    <label for="requests" class="form-label label"> Special request (specify below) </label>
    <textarea
      nbInput
      fullWidth
      id="requests"
      rows="5"
      placeholder="If you wish to see any other plan changes please list them here."
      formControlName="requests"
    >
    </textarea>
  </div>
</fieldset>

<fieldset [formGroup]="controlContainer.control" *ngIf="isVirgin">
  <div class="form-group row">
    <label for="requests" class="col col-form-label label">
      Personalized plan design requests: <small>(e.g. Base Plan - change Life insurance and AD&D to $50,000)</small>
    </label>
    <div class="col-sm-9">
      <textarea
        nbInput
        fullWidth
        id="requests"
        rows="5"
        placeholder="If you wish to see any other plan changes please list them here."
        formControlName="requests"
      >
      </textarea>
    </div>
  </div>
</fieldset>
