import { MediaType } from '../enums';
import { IMedia } from './media';

export interface IEmployeeMedia {
  [MediaType.EmployeeApplication]: IMedia;
  [MediaType.EmployeeHealth]: IMedia;
  [MediaType.EvidenceOfInsurability]: IMedia;
  [MediaType.TrusteeAppointment]: IMedia[];
  [MediaType.EmployeeIdCard]: IMedia;
  [MediaType.BenefitsTeam]: IMedia;
  [MediaType.RateWelcomePackage]: IMedia[];
  [MediaType.RateBenefitsBooklet]: IMedia;
}
