<nb-card status="basic">
  <nb-card-header>Administration</nb-card-header>
  <nb-card-body>
    <div>
      <h3 class="subtitle mt-3">Client Rep</h3>
      <div>
        <pip-associate-selector
          [user]="planRep$ | async"
          type="rep"
          [planId]="(plan$ | async)?.id"
          [canEdit]="isAdvisorOrAnalyst$ | async"
        ></pip-associate-selector>
      </div>
      <h3 class="subtitle mt-3">Client Associate</h3>
      <div>
        <pip-associate-selector
          [user]="planAssociate$ | async"
          type="associate"
          [planId]="(plan$ | async)?.id"
          [canEdit]="isAdvisorOrAnalyst$ | async"
        ></pip-associate-selector>
      </div>
      <hr />
    </div>
    <dl *ngIf="carrier$ | async as carrier">
      <div class="row">
        <dt class="col-sm-3">Carrier Guide</dt>
        <dd class="col">
          <a
            *ngIf="carrierMediaLinks$ | async as carrierMediaLinks"
            [href]="carrierMediaLinks.CarrierGuide"
            target="_blank"
          >
            Download {{ carrier.attributes.name }}'s carrier guide
          </a>
        </dd>
        <hr />
      </div>
      <div class="row">
        <dt class="col-sm-3">Carrier Online Access</dt>
        <dd class="col">
          <a *ngIf="carrier.attributes.online_access_url" [href]="carrier.attributes.online_access_url" target="_blank">
            {{ carrier.attributes.online_access_url }}
          </a>
        </dd>
        <hr />
      </div>
      <div class="row" *ngIf="showResources$ | async">
        <dt class="col-sm-3">Resources</dt>
        <dd class="col">
          <pip-media-uploader
            class="ml-0"
            [existingMedia]="(planMedia$ | async)[mediaType.BenefitsTeam]"
            [mediaType]="mediaType.BenefitsTeam"
            [modelId]="(plan$ | async).id"
            [mediaModel]="mediaModel.Plan"
          ></pip-media-uploader>
        </dd>
      </div>
    </dl>
    <ng-container *ngIf="(plan$ | async).attributes.status === planStatus.Setup">
      <hr />
      <pip-plan-administration-form
        [plan]="plan$ | async"
        [media]="planMedia$ | async"
        (update)="onUpdate($event)"
      ></pip-plan-administration-form>
    </ng-container>
  </nb-card-body>
</nb-card>
