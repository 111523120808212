<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <nb-card>
    <nb-card-header>Update password</nb-card-header>
    <nb-card-body>
      <div class="form-group row">
        <label for="password" class="col col-form-label label">Password</label>
        <div class="col-sm-9">
          <input type="password" nbInput fullWidth id="password" formControlName="password" />
          <ng-container *ngIf="form.get('password').invalid && form.get('password').touched">
            <div class="text-hint" *ngIf="form.get('password').hasError('required')">Password is required.</div>
            <div class="text-hint" *ngIf="form.get('password').hasError('minlength')">Password is required to have 6 characters.</div>
          </ng-container>
        </div>
      </div>
      <div class="form-group row">
        <label for="password_confirm" class="col col-form-label label">Confirm password</label>
        <div class="col-sm-9">
          <input type="password" nbInput fullWidth id="password_confirm" formControlName="password_confirm" />
          <ng-container *ngIf="form.get('password_confirm').touched">
            <div class="text-hint" *ngIf="form.hasError('notSame')">Passwords do not match.</div>
          </ng-container>
        </div>
      </div>
    </nb-card-body>
    <nb-card-footer class="d-flex justify-content-end">
      <button nbButton status="primary" type="submit" [disabled]="form.valid === false">Update password</button>
    </nb-card-footer>
  </nb-card>
</form>
