<!-- <h1 class="text-center h3 mt-0 text-uppercase">{{ fileTypeLabel }}{{ isArray ? 's' : '' }}</h1> -->
<div class="d-flex align-items-center flex-wrap">
  <div *ngIf="downloadTemplate">
    <a nbButton status="primary" outline [href]="downloadTemplate" class="">
      <nb-icon icon="cloud-download-outline"></nb-icon>
      1.&nbsp;
      <span *ngIf="!downloadLabelOverride">{{ templateDictionary[mediaType] }}</span>
      <span *ngIf="downloadLabelOverride">{{ downloadLabelOverride }}</span>
    </a>
    <nb-icon icon="arrow-forward-outline" class="mx-3"></nb-icon>
  </div>
  <div>
    <button
      (click)="onClick()"
      nbButton
      status="primary"
      outline
      type="button"
      [nbSpinner]="isLoading"
      nbSpinnerStatus="primary"
    >
      <nb-icon icon="cloud-upload-outline"></nb-icon>
      <span *ngIf="downloadTemplate">2.&nbsp;</span>
      <span *ngIf="!uploadLabelOverride">{{ uploadDictionary[mediaType] }}</span>
      <span *ngIf="uploadLabelOverride">{{ uploadLabelOverride }}</span>
      <span class="text-muted optional" *ngIf="!required">&nbsp;(if available)</span>
    </button>
  </div>
</div>
<div class="mt-2" *ngIf="items?.length">
  <div class="mb-1 font-weight-bold" style="font-size: smaller">Uploaded:</div>
  <div class="mb-1 d-flex" *ngFor="let item of items">
    <ng-container *ngIf="item">
      <button type="button" (click)="deleteMedia(item.id)" nbButton ghost status="danger" size="tiny">
        <nb-icon icon="trash-2-outline"></nb-icon>
      </button>
      <a class="name" [href]="item.links['self']" [title]="item.attributes.name">{{ item.attributes.name }}</a>
      <!-- <small> ({{ item.attributes.size }})</small> -->
    </ng-container>
  </div>
</div>

<ng-container *ngIf="requireExpiry">
  <div class="form-group row d-flex align-items-center">
    <label class="col col-form-label label">Expires at</label>
    <div class="col-sm-9">
      <input nbInput fullWidth placeholder="Pick Date" readonly [nbDatepicker]="formpicker" [(ngModel)]="expiry" />
      <nb-datepicker format="yyyy-MM-dd" #formpicker></nb-datepicker>
    </div>
  </div>
</ng-container>

<label #input class="m-0 p-0 d-none">
  <input type="file" class="d-none" (change)="onChange($event)" />
</label>
