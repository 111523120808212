<table class="table table-bordered table-striped">
  <thead>
    <tr>
      <th scope="col"><span class="sr-only">Feature</span></th>
      <th *ngFor="let plan of defaultPlans" [innerHTML]="plan?.attributes.name"></th>
    </tr>
  </thead>
  <tbody>
    <!-- TODO: Flatten feature groups across all plans to ensure all are displayed not just the groups in [0] -->
    <tr *ngFor="let feature_group of defaultPlans[0]?.attributes.feature_groups; let i = index">
      <th class="text-right">{{ feature_group.group }}</th>
      <td *ngFor="let plan of defaultPlans; let idx = index" [ngClass]="{ selected: (formArray?.value)[idx]?.selected }">
        <ul>
          <li *ngFor="let feature of defaultPlans[idx].attributes.feature_groups[i].features" [innerHTML]="feature"></li>
        </ul>
      </td>
    </tr>
  </tbody>
  <tfoot [formGroup]="formArray.control.parent" *ngIf="isForm === true">
    <tr [formArrayName]="formArray.name">
      <th><span class="sr-only">Select options</span></th>
      <td *ngFor="let plan of defaultPlans; let idx = index" [formGroupName]="idx">
        <nb-checkbox formControlName="selected">Select {{ plan.attributes.name }}</nb-checkbox>
      </td>
    </tr>
  </tfoot>
</table>
