import {
  HttpErrorResponse as NgHttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthPaths } from '@app/auth/auth.paths';
import { AuthState, Logout } from '@app/auth/store';
import { NbToastrService } from '@nebular/theme';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { IHttpError } from '../models/http/http-error-response';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private store: Store, private toastr: NbToastrService) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((res) => {
        if (res.error.errors) {
          res.error.errors.forEach((error) => {
            if (typeof error === 'string') {
              this.displayError({ detail: error, title: 'API Error' });
            } else {
              this.displayError(error);
            }
          });
        } else {
          this.displayError({ detail: 'Oops, something went wrong', title: 'API Error' });
        }

        if (res instanceof NgHttpErrorResponse && res.status === 401) {
          this.handleAccessDenied();
        }

        return throwError(res);
      }),
    );
  }

  private displayError(error: IHttpError): void {
    this.toastr.danger(error.detail, error.title, { duration: 7 * 1000 });
  }

  private handleAccessDenied() {
    console.log('ErrorInterceptor::handleAccessDenied()');

    this.store.dispatch(new Logout());
  }
}
