import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { IPlan, MediaModel, MediaType, PlansState, PlanStatus, UpdatePlan } from '@app/data';
import { Store } from '@ngxs/store';
import { map } from 'rxjs/operators';

@Component({
  selector: 'pip-plan-standards',
  templateUrl: './plan-standards.component.html',
  styleUrls: ['./plan-standards.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanStandardsComponent implements OnInit {
  public plan$ = this.store.select(PlansState.activePlan);
  public loading$ = this.store.select(PlansState.loading);

  public isVirgin$ = this.plan$.pipe(map((plan) => plan?.attributes?.virgin));

  public planMedia$ = this.store.select(PlansState.activePlanMedia);
  public terminationLetter$ = this.planMedia$.pipe(map((media) => media.TerminationLetter));
  public finalBill$ = this.planMedia$.pipe(map((media) => media.FinalBill));
  public EP3$ = this.planMedia$.pipe(map((media) => media.EP3));
  public other$ = this.planMedia$.pipe(map((media) => media.PlanOther));

  public locked$ = this.plan$.pipe(
    map((plan) => plan.attributes.status === PlanStatus.Setup || plan.attributes.status === PlanStatus.Confirmed),
  );

  public mediaModel = MediaModel;
  public mediaType = MediaType;

  constructor(private store: Store) {}

  public ngOnInit(): void {}

  public onSave(payload: IPlan): void {
    this.store.dispatch(new UpdatePlan(payload));
  }
}
