import { IQuote, IQuoteStatus } from '@app/data/models';

export class IncludeQuotes {
  public static readonly type = '[Quotes] Include Quotes';
  constructor(public payload: IQuote[]) {}
}

export class GetQuotes {
  public static readonly type = '[Quotes] Get Quotes';
  constructor(public payload: { status: IQuoteStatus }) {}
}

export class GetQuote {
  public static readonly type = '[Quotes] Get Quote';
  constructor(public payload: { quoteId: string }) {}
}

export class UpdateQuote {
  public static readonly type = '[Quotes] Update Quote';
  constructor(public payload: { quote: IQuote }) {}
}

export class CreateQuote {
  public static readonly type = '[Quotes] Create Quote';
  constructor(public payload: { planId: string; carrierId: string; contacts?: string[] }) {}
}
