<button nbButton status="primary" size="small" (click)="startNewQuote(createQuoteDialog)">
  <nb-icon icon="plus-circle-outline"></nb-icon>
  Add employee
</button>

<ng-template #createQuoteDialog let-data let-ref="dialogRef">
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <nb-card status="basic" [nbSpinner]="loading" nbSpinnerStatus="primary">
      <nb-card-header>Add a New Employee</nb-card-header>
      <nb-card-body>
        <div class="form-group">
          <label class="label" for="first_name">First name</label>
          <input
            nbInput
            fullWidth
            type="text"
            class="form-control"
            id="first_name"
            placeholder="John"
            formControlName="first_name"
            autofocus
          />
        </div>
        <div class="form-group">
          <label class="label" for="last_name">Last name</label>
          <input
            nbInput
            fullWidth
            type="text"
            class="form-control"
            id="last_name"
            placeholder="Doe"
            formControlName="last_name"
            autofocus
          />
        </div>
      </nb-card-body>
      <nb-card-footer>
        <button nbButton status="primary" type="submit">Create employee</button>
        <button nbButton status="primary" ghost type="button" (click)="ref.close()">Cancel</button>
      </nb-card-footer>
    </nb-card>
  </form>
</ng-template>
