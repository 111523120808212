import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ControlContainer } from '@angular/forms';

@Component({
  selector: 'pip-employee-beneficiary-formgroup',
  templateUrl: './employee-beneficiary-formgroup.component.html',
  styleUrls: ['./employee-beneficiary-formgroup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmployeeBeneficiaryFormgroupComponent {
  constructor(public controlContainer: ControlContainer) {}
}
