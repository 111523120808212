import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import * as Data from '@app/data/models';
import * as Utils from '@app/utils/index';
import { NbToastrService } from '@nebular/theme';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';

import { createEntities } from '../../../utils';
import { DataType, MediaType } from '../../enums';
import { ProfilesService } from '../../services';
import { IncludeMedia } from '../media/media.actions';
import { MediaState, MediaStateModel } from '../media/media.state';
import { UsersState } from '../users/users.state';
import { GetProfile, IncludeProfiles, UpdateProfile } from './profiles.actions';
import { Injectable } from '@angular/core';

export interface ProfilesStateModel {
  entities: EntityMap<Data.IProfile>;
}

export const profileStateDefaults: ProfilesStateModel = {
  entities: {},
};

@State<ProfilesStateModel>({
  name: DataType.Profiles,
  defaults: profileStateDefaults,
})
@Injectable()
export class ProfilesState {
  constructor(private profiles: ProfilesService, private toastr: NbToastrService) {}

  @Action(GetProfile)
  public getProfile(ctx: StateContext<ProfilesStateModel>, action: GetProfile): Observable<Data.Response<Data.IProfile>> {
    return this.profiles.getProfile(action.payload.profileId).pipe(
      tap(res => {
        ctx.setState(
          patch({
            entities: createEntities([res.data], 'id'),
          }),
        );

        const media = res.included.filter(i => i.type === DataType.Media) as Data.IMedia[];
        ctx.dispatch(new IncludeMedia(media));
      }),
    );
  }

  @Action(UpdateProfile)
  public updateProfile(ctx: StateContext<ProfilesStateModel>, action: UpdateProfile): Observable<Data.Response<Data.IProfile>> {
    return this.profiles.updateProfile(action.payload).pipe(
      tap(res =>
        ctx.setState(
          patch({
            entities: createEntities([res.data], 'id'),
          }),
        ),
      ),
      tap(() => this.toastr.success('User updated', 'Success!', null)),
    );
  }

  @Action(IncludeProfiles)
  public includeProfiles(ctx: StateContext<ProfilesStateModel>, action: IncludeProfiles) {
    ctx.setState(
      patch<ProfilesStateModel>({
        entities: patch(Utils.createEntities(action.payload, 'id')),
      }),
    );
  }
}
