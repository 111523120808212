import { ChangeDetectionStrategy, Component, Input, TemplateRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Administrator, DataType, IAdministrator, IPlan, UpdatePlan } from '@app/data';
import { yearMonthDay } from '@app/utils/year-month-day';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { parseISO } from 'date-fns';
import { take } from 'rxjs/operators';

@Component({
  selector: 'pip-main-contact',
  templateUrl: './main-contact.component.html',
  styleUrls: ['./main-contact.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainContactComponent {
  @Input() public plan: IPlan;
  @Input() public administrator: IAdministrator;
  @Input() public displayExtraInfo: boolean = false;
  @Input() public canEdit: boolean = false;

  private dialogRef: NbDialogRef<any>;

  constructor(private store: Store, private actions$: Actions, public dialog: NbDialogService) {}

  public handleEdit(templateRef: TemplateRef<any>): void {
    const { born_at, email, first_name, last_name, mothers_name, phone } = this.administrator || Administrator.create();

    const form = new FormGroup({
      born_at: new FormControl(born_at && parseISO(born_at), [Validators.required]),
      email: new FormControl(email, [Validators.required]),
      first_name: new FormControl(first_name, [Validators.required]),
      last_name: new FormControl(last_name, [Validators.required]),
      mothers_name: new FormControl(mothers_name, [Validators.required]),
      phone: new FormControl(phone, [Validators.required]),
    });

    this.dialogRef = this.dialog.open(templateRef, { context: { form } });
  }

  public handleSubmit(formValues): void {
    const plan: IPlan = {
      id: this.plan.id,
      type: DataType.Plans,
      attributes: {
        administrator: {
          ...formValues,
          born_at: yearMonthDay(formValues.born_at),
        },
      },
    };

    this.actions$.pipe(ofActionSuccessful(UpdatePlan), take(1)).subscribe(() => this.dialogRef.close());

    this.store.dispatch(new UpdatePlan(plan));
  }
}
