<div class="row justify-content-center" *ngIf="plan$ | async as plan">
  <div class="col-xl-6">
    <h1>RTQ To Do List</h1>
    <p>Please click on each task to complete the steps required.</p>

    <pip-tasks-table [tasks]="tasks$ | async"></pip-tasks-table>

    <!-- Plan Admin -->
    <ng-container *ngIf="isPlanAdmin$ | async">
      <!-- Completed message -->
      <ng-container *ngIf="isSubmittable$ | async">
        <nb-alert accent="primary" class="text-center">
          You have completed all tasks, please wait for the Advisor to take the next step
        </nb-alert>
      </ng-container>
      <!-- /Completed message -->
    </ng-container>
    <!-- /Plan Admin -->

    <!-- Advisor/Analyst -->
    <ng-container *ngIf="isAdvisorOrAnalyst$ | async">
      <div *ngIf="plan?.attributes?.administrator?.email && !(isSubmittable$ | async)" class="my-3 text-center">
        <pip-invite-plan-admin
          label="Invite Main Contact to complete this RTQ To Do List"
          [plan]="plan"
        ></pip-invite-plan-admin>
      </div>
      <!-- Sign-off form -->
      <form
        *ngIf="isSubmittable$ | async; else AdvisorNotCompleteWarning"
        [formGroup]="form"
        (ngSubmit)="onSubmit(plan)"
        class="text-center"
      >
        <div class="form-group row">
          <div class="col">
            <nb-checkbox id="acknowledgement" formControlName="acknowledgement"
              >I hereby acknowledge that all information provided is accurate, correct, and complete.</nb-checkbox
            >
          </div>
        </div>
        <div class="form-group row">
          <div class="col">
            <button nbButton status="primary" type="submit" [disabled]="!form.valid">Submit RTQ</button>
          </div>
        </div>
      </form>
    </ng-container>
  </div>
</div>

<!-- Advisor Not Complete Warning -->
<ng-template #AdvisorNotCompleteWarning>
  <nb-alert accent="primary" class="text-center">
    All the tasks above must be completed before you can submit the RTQ and invite Carriers to quote.
  </nb-alert>
</ng-template>
