import { MediaModel } from '@app/data/enums';
import { IMedia } from '../../models';

export class IncludeMedia {
  public static readonly type = '[Media] Include Media';
  constructor(public payload: IMedia[]) {}
}

export class UploadMedia {
  public static readonly type = '[Media] Upload Media';
  constructor(public payload: FormData) {}
}

export class UploadMediaFailed {
  public static readonly type = '[Media] Upload Media Failed';
  constructor(public payload: any) {}
}

export class DeleteMedia {
  public static readonly type = '[Media] Delete Media';
  constructor(public payload: { media_id: string; model?: { id: string; media_model: MediaModel } }) {}
}
