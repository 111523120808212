import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { IPlan } from '@app/data';

@Component({
  selector: 'pip-plan-renewal-date',
  templateUrl: './plan-renewal-date.component.html',
  styleUrls: ['./plan-renewal-date.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanRenewalDateComponent implements OnInit {
  @Input() public plan: IPlan;

  constructor() {}

  public ngOnInit(): void {}
}
