import * as Data from '../../models';

export class IncludeRates {
  public static readonly type = '[Rates] Include Rates';
  constructor(public payload: Data.IRate[]) {}
}

export class GetRate {
  public static readonly type = '[Rates] Get Rate';
  constructor(public payload: { rateId: string }) {}
}

export class CreateRate {
  public static readonly type = '[Rates] Create Rate';
  constructor(public payload: { rate: Data.IRate; planId: string }) {}
}

export class CreateRateSuccess {
  public static readonly type = '[Rates] Create Rate Success';
  constructor(public payload: Data.IRate) {}
}

export class UpdateRate {
  public static readonly type = '[Rates] Update Rate';
  constructor(public payload: Data.IRate) {}
}

export class DeleteRate {
  public static readonly type = '[Rates] Delete Rate';
  constructor(public payload: string) {}
}
