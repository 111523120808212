import { IPlan, PlanStatus, Role } from '@app/data';
import { PlansPaths } from '../../plans.paths';
import { IPlanMenuItem } from '../plan-menu-item/plan-menu-item.component';

export const PlanMenu = (plan: IPlan, status: PlanStatus, roles: Role[]): IPlanMenuItem[] => {
  if (!plan || !plan.meta || !plan.meta.field_statuses || !roles) return [];

  const menu = defaultMenu(plan);

  if (roles?.includes(Role.PlanAdmin)) {
    menu.administration.hidden = false;
    menu.companyInfo.hidden = false;
    menu.insuranceSelection.hidden = true;
    menu.employees.hidden = false;
    menu.summaries.hidden = true;
    menu.rates.hidden = true;
    menu.standards.hidden = false;
    menu.paymentInfo.hidden = !(status === PlanStatus.Setup || status === PlanStatus.Confirmed);
  } else {
    menu.administration.hidden = false;
    menu.companyInfo.hidden = false;
    menu.insuranceSelection.hidden = false;
    menu.employees.hidden = false;
    menu.summaries.hidden = status === PlanStatus.InProgress;
    menu.rates.hidden = !(
      status === PlanStatus.EnrollIngenious ||
      status === PlanStatus.EnrollClient ||
      status === PlanStatus.Setup ||
      status === PlanStatus.Aborted
    );
    menu.standards.hidden = !(
      status === PlanStatus.EnrollClient ||
      status === PlanStatus.EnrollIngenious ||
      status === PlanStatus.Signoff ||
      status === PlanStatus.Setup ||
      status === PlanStatus.Aborted
    );
    menu.paymentInfo.hidden = !(
      status === PlanStatus.EnrollClient ||
      status === PlanStatus.Setup ||
      status === PlanStatus.Aborted
    );
  }

  return Object.values(menu);
};

const defaultMenu = (plan) => ({
  administration: {
    label: 'Administration',
    link: PlansPaths.Administration,
    field_status: plan.meta.field_statuses.administration,
    hidden: undefined,
  },
  companyInfo: {
    label: 'Company Info',
    link: PlansPaths.Detail,
    field_status: plan.meta.field_statuses.organization_info,
    hidden: undefined,
  },
  insuranceSelection: {
    label: 'Benefits',
    link: PlansPaths.Benefits,
    field_status: plan.meta.field_statuses.insurance_info,
    hidden: undefined,
  },
  employees: {
    label: 'Employees',
    link: PlansPaths.Employees,
    field_status: plan.meta.field_statuses.employees,
    hidden: undefined,
  },
  summaries: {
    label: summariesLabelName(plan.attributes.status),
    link: PlansPaths.Proposal,
    field_status: plan.meta.field_statuses.summaries,
    hidden: undefined,
  },
  rates: {
    label: 'Rates',
    link: PlansPaths.Rates,
    field_status: plan.meta.field_statuses.rates,
    hidden: undefined,
  },
  standards: {
    label: 'Standards',
    link: PlansPaths.Standards,
    field_status: plan.meta.field_statuses.standards,
    hidden: undefined,
  },
  paymentInfo: {
    label: 'Payment info',
    link: PlansPaths.PaymentInfo,
    field_status: plan.meta.field_statuses.payment_info,
    hidden: undefined,
  },
});

function summariesLabelName(status: PlanStatus): string {
  switch (status) {
    case PlanStatus.Proposal:
      return 'Enroll';
    case PlanStatus.RTQ:
      return 'RTQ';
    default:
      return 'Proposal';
  }
}
