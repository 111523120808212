import { ChangeDetectionStrategy, Component, Input, OnInit, TemplateRef } from '@angular/core';
import { DashboardPaths } from '@app/core/dashboard';
import { DeletePlan } from '@app/data';
import { NbDialogService } from '@nebular/theme';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { take } from 'rxjs/operators';

@Component({
  selector: 'pip-delete-plan',
  templateUrl: './delete-plan.component.html',
  styleUrls: ['./delete-plan.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeletePlanComponent implements OnInit {
  @Input() public planId: string;

  constructor(private store: Store, private dialog: NbDialogService) {}

  public ngOnInit(): void {}

  public deletePlan(template: TemplateRef<any>): void {
    this.dialog
      .open(template)
      .onClose.pipe(take(1))
      .subscribe(should => {
        if (should) {
          this.store.dispatch(new DeletePlan({ planId: this.planId }));
          this.store.dispatch(new Navigate([DashboardPaths.ROOT]));
        }
      });
  }
}
