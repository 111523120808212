<fieldset [formGroup]="controlContainer.control">
  <div class="form-group row">
    <label for="first_name" class="col col-form-label label">First name</label>
    <div class="col-sm-9">
      <input type="text" nbInput fullWidth id="first_name" formControlName="first_name" />
    </div>
  </div>

  <div class="form-group row">
    <label for="last_name" class="col col-form-label label">Last name</label>
    <div class="col-sm-9">
      <input type="text" nbInput fullWidth id="last_name" formControlName="last_name" />
    </div>
  </div>

  <div class="form-group row">
    <label for="phone" class="col col-form-label label">Phone</label>
    <div class="col-sm-9">
      <input type="text" nbInput fullWidth id="phone" formControlName="phone" />
    </div>
  </div>

  <div class="form-group row" *ngIf="isAdvisor">
    <label for="company" class="col col-form-label label">Company</label>
    <div class="col-sm-9">
      <input type="text" nbInput fullWidth id="company" formControlName="company" />
    </div>
  </div>

  <div class="form-group row" *ngIf="isAdvisor">
    <label for="groupExperience" class="col col-form-label label">Inforce Groups</label>
    <div class="col-sm-9">
      <nb-select id="groupExperience" fullWidth formControlName="group_experience">
        <nb-option value="" selected disabled>Select Inforce Groups</nb-option>
        <nb-option *ngFor="let opt of groupExpOpts" [value]="opt[0]">{{ opt[1] }}</nb-option>
      </nb-select>
    </div>
  </div>
</fieldset>
