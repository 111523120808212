import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { Params } from '@angular/router';

import { IEmployee, Response, IEmployeeMedia } from '../models';
import { ApiService } from './api.service';
import { EmployeeConfirmation } from '../enums/employee-confirmation.enum';
import { DataType } from '../enums';

@Injectable({
  providedIn: 'root',
})
export class EmployeesService {
  private params: Params = { include: 'rates,media,plans' };

  constructor(private api: ApiService) {}

  public getAuthUserEmployees(): Observable<Response<IEmployee[]>> {
    return this.api.get(['user', 'relationships', 'employees'], { params: this.params });
  }

  public getUserEmployees(userId?: string): Observable<Response<IEmployee[]>> {
    return this.api.get(['users', userId, 'relationships', 'employees'], { params: this.params });
  }

  public getPlanEmployees(planId: string): Observable<Response<IEmployee[]>> {
    return this.api.get(['plans', planId, 'relationships', 'employees']);
  }

  public getEmployee(employeeId: string): Observable<Response<IEmployee>> {
    return this.api.get(['employees', employeeId], { params: this.params });
  }

  public createEmployee(data: IEmployee, planId: string): Observable<Response<IEmployee>> {
    return this.api.post(['plans', planId, 'relationships', 'employees'], data);
  }

  public updateEmployee(data: IEmployee): Observable<Response<IEmployee>> {
    return this.api.patch(['employees', data.id], data);
  }

  public deleteEmployee(id: string): Observable<Response<null>> {
    return this.api.delete(['employees', id]);
  }

  public employeeConfirmation(employeeId: string, confirmation: EmployeeConfirmation): Observable<Response<IEmployee>> {
    const params: Params = { include: 'advisors,media,organizations,employees' };

    return this.api.post(
      ['employees', employeeId, 'confirmations'],
      { type: DataType.Confirmations, attributes: { field: confirmation } },
      { params },
    );
  }

  public searchEmployees(search: string): Observable<Response<IEmployee[]>> {
    return this.api.get(['employees'], { params: { search, include: 'plans' } });
  }
}
