import { IProfile } from '../../models';

export class GetProfile {
  public static readonly type = '[Profiles] Get Profile';
  constructor(public payload: { profileId: string }) {}
}

export class UpdateProfile {
  public static readonly type = '[Profiles] Update Profile';
  constructor(public payload: IProfile) {}
}

export class IncludeProfiles {
  public static readonly type = '[Profiles] Include Profiles';
  constructor(public payload: IProfile[]) {}
}
