import { ChangeDetectionStrategy, Component, Input, TemplateRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DataType, IPlan, IRenewal, Plan, UpdatePlan, UpdatePlanRenewal } from '@app/data';
import { RateGuaranteeOpts } from '@app/data/enums/rate-guarantee-opts';
import {
  CommonRateProperties,
  ICommonRateProperties,
  ICommonRatePropertiesResource,
} from '@app/data/models/common-rate-properties';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { take } from 'rxjs/operators';

@Component({
  selector: 'pip-common-rate-properties',
  templateUrl: './common-rate-properties.component.html',
  styleUrls: ['./common-rate-properties.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommonRatePropertiesComponent {
  @Input() public resource: ICommonRatePropertiesResource;
  @Input() public allowEdit = true;
  @Input() public firstRenewal = true;

  public get data(): ICommonRateProperties {
    return this.resource?.attributes;
  }

  public get showRenewalCaps(): boolean {
    return this.firstRenewal;
  }

  public get showRateGuarantee(): boolean {
    return this.firstRenewal;
  }

  private rateGuaranteeOpts = RateGuaranteeOpts;
  private dialogRef: NbDialogRef<any>;

  constructor(private store: Store, private actions$: Actions, public dialog: NbDialogService) {}

  public handleEdit(templateRef: TemplateRef<any>): void {
    const {
      critical_illness_nem,
      critical_illness_termination_age,
      dentalcare_termination_age,
      healthcare_termination_age,
      life_nem,
      life_termination_age,
      ltd_flat,
      ltd_graded_scale,
      ltd_nem,
      pro_rated,
      rate_guarantee,
      renewal_caps,
      renewal_months,
      std_flat,
      std_graded_scale,
      std_nem,
      std_termination_age,
    } = this.resource.attributes || CommonRateProperties.create();

    const rate_guarantee_select = this.rateGuaranteeOpts.includes(rate_guarantee) ? rate_guarantee : 'other';
    const rate_guarantee_other = rate_guarantee_select === 'other' ? rate_guarantee : undefined;

    const form = new FormGroup({
      critical_illness_nem: new FormControl(critical_illness_nem, [Validators.min(10_000), Validators.max(150_000)]),
      critical_illness_termination_age: new FormControl(critical_illness_termination_age),
      dentalcare_termination_age: new FormControl(dentalcare_termination_age),
      healthcare_termination_age: new FormControl(healthcare_termination_age),
      life_nem: new FormControl(life_nem),
      life_termination_age: new FormControl(life_termination_age),
      ltd_flat: new FormControl(ltd_flat),
      ltd_graded_scale: new FormControl(ltd_graded_scale),
      ltd_nem: new FormControl(ltd_nem),
      pro_rated: new FormControl(pro_rated),
      rate_guarantee_other: new FormControl(rate_guarantee_other),
      rate_guarantee_select: new FormControl(rate_guarantee_select),
      renewal_caps: new FormControl(renewal_caps),
      renewal_months: new FormControl(renewal_months),
      std_flat: new FormControl(std_flat),
      std_graded_scale: new FormControl(std_graded_scale),
      std_nem: new FormControl(std_nem),
      std_termination_age: new FormControl(std_termination_age),
    });

    this.dialogRef = this.dialog.open(templateRef, { context: { form } });
  }

  public handleSubmit(formValues: Partial<ICommonRateProperties>): void {
    const payload: ICommonRatePropertiesResource = {
      id: this.resource.id,
      type: this.resource.type,
      attributes: {
        ...formValues,
        rate_guarantee:
          formValues.rate_guarantee_select === 'other'
            ? formValues.rate_guarantee_other
            : formValues.rate_guarantee_select,
      },
    };

    switch (payload.type) {
      case DataType.Renewals: {
        this.actions$.pipe(ofActionSuccessful(UpdatePlanRenewal), take(1)).subscribe(() => this.dialogRef.close());
        this.store.dispatch(new UpdatePlanRenewal({ renewal: payload as IRenewal }));
        break;
      }
      case DataType.Plans: {
        this.actions$.pipe(ofActionSuccessful(UpdatePlan), take(1)).subscribe(() => this.dialogRef.close());
        this.store.dispatch(new UpdatePlan(payload as IPlan));
        break;
      }
    }
  }
}
