import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { PlansState, QuotesState, Role, UsersState } from '@app/data';
import { Store } from '@ngxs/store';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'pip-client-administration',
  templateUrl: './client-administration.component.html',
  styleUrls: ['./client-administration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientAdministrationComponent implements OnInit {
  public plan$ = this.store.select(PlansState.activePlan);
  public planMedia$ = this.store.select(PlansState.activePlanMedia);
  public carriers$ = this.store.select(PlansState.planCarriers);
  public carrier$ = this.store.select(QuotesState.selectedCarrier);
  public carrierMediaLinks$ = this.store.select(QuotesState.carrierMediaLinks);
  public planRep$ = this.store.select(PlansState.activePlanRep);
  public planAssociate$ = this.store.select(PlansState.activePlanAssociate);
  public userRoles$ = this.store.select(UsersState.activeUserRoles);
  public organization$ = this.store.select(PlansState.activePlanOrganization);

  public planStandards$ = this.plan$.pipe(map((plan) => plan && plan.attributes.standards));
  public isAnalyst$ = this.userRoles$.pipe(map((roles) => roles?.includes(Role.Analyst)));
  public isAdvisor$ = this.userRoles$.pipe(map((roles) => roles?.includes(Role.Advisor)));
  public canUploadDocs$ = this.isAnalyst$;
  public isAdvisorOrAnalyst$ = combineLatest([this.isAnalyst$, this.isAdvisor$]).pipe(
    map(([isAnalyst, isAdvisor]) => isAdvisor || isAnalyst),
  );

  constructor(public store: Store) {}

  public ngOnInit(): void {}
}
