export enum PlanStatus {
  Archived = 'Archived',
  Closed = 'Closed',
  Aborted = 'Aborted',
  Confirmed = 'Confirmed',
  Expired = 'Expired',
  //
  InProgress = 'InProgress',
  RTQ = 'RTQ',
  Proposal = 'Proposal',
  EnrollIngenious = 'EnrollIngenious',
  EnrollClient = 'EnrollClient',
  Signoff = 'Signoff',
  Setup = 'Setup',
  Pending = 'Pending',
}

export const PlanStatusDictionary: Record<PlanStatus, string> = {
  [PlanStatus.Archived]: 'Archived',
  [PlanStatus.Closed]: 'Closed',
  [PlanStatus.Aborted]: 'Aborted',
  [PlanStatus.Confirmed]: 'Confirmed',
  //
  [PlanStatus.InProgress]: 'In Progress',
  [PlanStatus.RTQ]: 'RTQ',
  [PlanStatus.Proposal]: 'Proposal',
  [PlanStatus.EnrollIngenious]: 'Enroll - Ingenious',
  [PlanStatus.EnrollClient]: 'Enroll - Client',
  [PlanStatus.Signoff]: 'Signoff',
  [PlanStatus.Setup]: 'Setup',
  [PlanStatus.Pending]: 'Inforce',
  [PlanStatus.Expired]: 'Expired',
};
