import { ChangeDetectionStrategy, Component, Input, OnChanges, TemplateRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ClientsPaths } from '@app/core/clients/clients.paths';
import {
  GetPlanRenewal,
  IMedia,
  IRenewal,
  ISegment,
  PlansState,
  RenewalStatus,
  Role,
  SegmentType,
  UpdatePlanRenewal,
  UpdateRenewalSegment,
  UsersState,
} from '@app/data';
import { NbDialogService } from '@nebular/theme';
import { Navigate } from '@ngxs/router-plugin';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { filter, map, take, withLatestFrom } from 'rxjs/operators';

@Component({
  selector: 'pip-client-renewal-detail',
  templateUrl: './client-renewal-detail.component.html',
  styleUrls: ['./client-renewal-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientRenewalDetailComponent implements OnChanges {
  @Input() public renewalId: string;

  public plan$ = this.store.select(PlansState.activePlan);
  public renewal$ = this.store.select(PlansState.activeRenewal);
  public segments$ = this.store.select(PlansState.activeRenewalSegments);
  public segmentsMedia$ = this.store.select(PlansState.activeRenewalSegmentsMedia);
  public renewalRates$ = this.store.select(PlansState.activeRenewalRates);
  public userRoles$ = this.store.select(UsersState.activeUserRoles);
  public user$ = this.store.select(UsersState.activeUser);
  public isActiveUserAnAdvisorOrAnalyst$ = this.store.select(UsersState.isActiveUserAnAdvisorOrAnalyst);

  public showApproveRenewalBtn$ = this.renewal$.pipe(
    withLatestFrom(this.userRoles$),
    map(([renewal, roles]) => {
      return renewal?.attributes.status === RenewalStatus.Accepted && roles.includes(Role.Analyst);
    }),
  );

  public renewalStatus = RenewalStatus;
  public segmentType = SegmentType;

  constructor(
    private store: Store,
    private actions$: Actions,
    private dialog: NbDialogService,
    private Route: ActivatedRoute,
  ) {}

  public ngOnChanges(): void {
    if (this.renewalId) {
      this.store.dispatch(new GetPlanRenewal({ renewalId: this.renewalId }));
    }
  }

  public handleSaveSegment(segment: ISegment): void {
    this.store.dispatch(new UpdateRenewalSegment({ segment }));
  }

  public handleSignRenewal({ renewal, signature }: { renewal: IRenewal; signature: string }): void {
    const payload: IRenewal = {
      id: renewal.id,
      type: renewal.type,
      attributes: {
        signature,
      },
    };

    this.store.dispatch(new UpdatePlanRenewal({ renewal: payload }));
  }

  public handleApproveRenewal(renewal: IRenewal, templateRef: TemplateRef<any>) {
    this.actions$.pipe(ofActionSuccessful(UpdatePlanRenewal), take(1)).subscribe(() => {
      this.store.dispatch(new Navigate(['/', ClientsPaths.ROOT], null, { relativeTo: this.Route }));
    });

    this.dialog
      .open(templateRef, { context: { actionLabel: 'approve this renewal' } })
      .onClose.pipe(
        filter((submit) => !!submit),
        take(1),
      )
      .subscribe(() => {
        const payload: IRenewal = {
          id: renewal.id,
          type: renewal.type,
          attributes: {
            status: RenewalStatus.Ready,
          },
        };

        this.store.dispatch(new UpdatePlanRenewal({ renewal: payload }));
      });
  }

  public handleUpdateSegment(
    { segment, actionLabel }: { segment: ISegment; actionLabel: string },
    templateRef: TemplateRef<any>,
  ): void {
    this.dialog
      .open(templateRef, { context: { segment, actionLabel } })
      .onClose.pipe(
        filter((submit) => !!submit),
        take(1),
      )
      .subscribe(() => {
        this.store.dispatch(new UpdateRenewalSegment({ segment }));
      });
  }

  public segmentMediaByIndex$(segmentIdx: number): Observable<IMedia[]> {
    return this.segmentsMedia$.pipe(map((media) => media && media[segmentIdx]));
  }

  public getRevisionIndex(segments: ISegment[], segmentId: string): number {
    return segments
      .filter((segments) => segments.attributes.type === SegmentType.Revision)
      .findIndex((segment) => segment.id === segmentId);
  }
}
