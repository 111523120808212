import { ChangeDetectionStrategy, Component, OnInit, TemplateRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import {
  DeleteEmployee,
  EmployeesState,
  GetEmployee,
  GetPlan,
  IEmployee,
  Plan,
  PlansState,
  PlanStatus,
  QuotesState,
  Role,
  UpdateEmployee,
  UsersState,
} from '@app/data';
import { NbDialogService } from '@nebular/theme';
import { Navigate, RouterState } from '@ngxs/router-plugin';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { filter, map, take, withLatestFrom } from 'rxjs/operators';

@Component({
  selector: 'pip-employee-detail',
  templateUrl: './employee-detail.component.html',
  styleUrls: ['./employee-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmployeeDetailComponent implements OnInit {
  public plan$ = this.store.select(PlansState.activePlan);
  public user$ = this.store.select(UsersState.activeUser);
  public userRoles$ = this.store.select(UsersState.activeUserRoles);
  public rates$ = this.store.select(PlansState.activePlanRates);
  public employee$ = this.store.select(EmployeesState.activeEmployee);
  public media$ = this.store.select(EmployeesState.activeEmployeeMedia);
  public carrierMediaLinks$ = this.store.select(QuotesState.carrierMediaLinks);
  public billingDivisions$ = this.store.select(PlansState.activePlanPaymentInfos);

  public hideBenefitsInfo$ = this.store.select(UsersState.userRoles).pipe(
    filter((roles) => !!roles),
    map((roles) => roles?.length === 1 && roles?.every((r) => r.id === Role.PlanAdmin)),
  );

  public isEmployeeCurrentUser$ = this.employee$.pipe(
    withLatestFrom(this.user$),
    filter(([employee, user]) => !!employee && !!user),
    map(([employee, user]) => employee.attributes.email === user.attributes.email),
  );

  public showOverrides$ = this.plan$.pipe(
    filter((plan) => !!plan),
    map((plan) => {
      return [
        PlanStatus.EnrollIngenious,
        PlanStatus.EnrollClient,
        PlanStatus.Signoff,
        PlanStatus.Setup,
        PlanStatus.Confirmed,
      ].includes(plan.attributes.status);
    }),
  );

  public allowEmployeeTermination$ = this.plan$.pipe(
    filter((plan) => !!plan),
    map((plan) => {
      return [PlanStatus.Confirmed].includes(plan.attributes.status);
    }),
  );

  public isAnalyst$ = this.userRoles$.pipe(map((roles) => roles.includes(Role.Analyst)));
  public isAdvisor$ = this.userRoles$.pipe(map((roles) => roles.includes(Role.Advisor)));

  constructor(
    private store: Store,
    private actions$: Actions,
    private dialog: NbDialogService,
    private route: ActivatedRoute,
  ) {}

  public ngOnInit(): void {
    const employeeId: string = this.store.selectSnapshot(RouterState).state.params.employeeId;

    this.store.dispatch(new GetEmployee({ employeeId, updatePlan: false }));
  }

  public onSaveEmployee(employee: IEmployee): void {
    this.actions$
      .pipe(ofActionSuccessful(UpdateEmployee), take(1), withLatestFrom(this.plan$))
      .subscribe(([_, plan]) => {
        this.store.dispatch([
          // new GetPlan({ planId: plan.id }),
          new Navigate(['../'], null, { relativeTo: this.route }),
        ]);
      });

    this.store.dispatch(new UpdateEmployee(employee));
  }

  public onDeleteEmployee(dialog: TemplateRef<any>, employee: IEmployee): void {
    this.dialog
      .open(dialog, { context: `${employee.attributes.first_name} ${employee.attributes.last_name}` })
      .onClose.pipe(
        take(1),
        filter((shouldDelete) => shouldDelete),
      )
      .subscribe(() =>
        this.store.dispatch([new DeleteEmployee(employee.id), new Navigate(['../'], null, { relativeTo: this.route })]),
      );
  }

  public onRequestToTerminateEmployee(dialog: TemplateRef<any>, employee: IEmployee): void {
    const form = new FormGroup({
      terminated_at: new FormControl(null, [Validators.required]),
      terminated_reason: new FormControl(null, [Validators.required]),
    });

    const dialogRef = this.dialog.open(dialog, { context: { form, employee } });

    this.actions$.pipe(ofActionSuccessful(UpdateEmployee), take(1)).subscribe(() => {
      dialogRef.close();
    });
  }

  public sendRequestToTerminate(form: FormGroup, employee: IEmployee): void {
    const payload: IEmployee = {
      id: employee.id,
      type: employee.type,
      attributes: {
        terminated_at: form.value.terminated_at,
        terminated_reason: form.value.terminated_reason,
      },
    };
    this.store.dispatch(new UpdateEmployee(payload));
  }

  public onConfirmTermination(dialog: TemplateRef<any>, employee: IEmployee): void {
    this.dialog
      .open(dialog, { context: `${employee.attributes.first_name} ${employee.attributes.last_name}` })
      .onClose.pipe(
        take(1),
        filter((shouldDelete) => shouldDelete),
      )
      .subscribe(() => {
        const payload: IEmployee = {
          id: employee.id,
          type: employee.type,
          attributes: {
            terminated_confirmed: true,
          },
        };

        this.store.dispatch([new UpdateEmployee(payload), new Navigate(['../'], null, { relativeTo: this.route })]);
      });
  }

  public onReinstateEmployee(dialog: TemplateRef<any>, employee: IEmployee): void {
    this.dialog
      .open(dialog, { context: `${employee.attributes.first_name} ${employee.attributes.last_name}` })
      .onClose.pipe(
        take(1),
        filter((shouldDelete) => shouldDelete),
      )
      .subscribe(() => {
        const payload: IEmployee = {
          id: employee.id,
          type: employee.type,
          attributes: {
            terminated_at: null,
            terminated_confirmed: false,
            terminated_reason: null,
          },
        };

        this.store.dispatch([new UpdateEmployee(payload), new Navigate(['../'], null, { relativeTo: this.route })]);
      });
  }
}
