import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { PlansState, Role, UsersState } from '@app/data';
import { Store } from '@ngxs/store';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'pip-client-company-info',
  templateUrl: './client-company-info.component.html',
  styleUrls: ['./client-company-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientCompanyInfoComponent implements OnInit {
  public plan$ = this.store.select(PlansState.activePlan).pipe(filter((plan) => !!plan));
  public organizationId$ = this.store.select(PlansState.activePlanOrganization).pipe(map((org) => org?.id));
  public isActiveUserActivePlanAssociate$ = this.store.select(PlansState.isActiveUserActivePlanAssociate);
  public roles$ = this.store.select(UsersState.activeUserRoles);

  public companyName$ = this.plan$.pipe(map((plan) => plan?.attributes.organization_name));
  public companyInfo$ = this.plan$.pipe(map((plan) => plan?.attributes.organization_info));
  public administrator$ = this.plan$.pipe(map((plan) => plan?.attributes.administrator));

  public showNotes$ = this.roles$.pipe(
    filter((roles) => {
      const res = [Role.Advisor, Role.Analyst].filter((role) => roles.includes(role));
      return !!res.length;
    }),
  );

  public isAnalystOrAdvisor$ = this.roles$.pipe(
    map((roles) => !!roles?.find((role) => [Role.Advisor, Role.Analyst].includes(role))),
  );

  constructor(public store: Store) {}

  ngOnInit() {}
}
