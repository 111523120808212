<button nbButton status="primary" size="small" (click)="openModal(modal)">
  <nb-icon icon="upload-outline"></nb-icon>
  Import employee data
</button>

<ng-template #modal let-data let-ref="dialogRef">
  <form #form (ngSubmit)="onSubmit(form)">
    <nb-card status="basic" [nbSpinner]="loading" nbSpinnerStatus="primary">
      <nb-card-header>Import employee data</nb-card-header>
      <nb-card-body>
        <label class="label d-block" for="file">File</label>
        <input fullWidth type="file" class="" id="file" name="file" autofocus />

        <nb-alert class="mt-3" *ngIf="response?.errors && response?.meta?.validation_errors" accent="danger">
          <h2>{{ response.errors[0].title }}</h2>
          <p>{{ response.errors[0].detail }}</p>
          <ul *ngIf="response?.meta?.validation_errors">
            <li *ngFor="let err of response?.meta?.validation_errors">{{ err }}</li>
          </ul>
        </nb-alert>
      </nb-card-body>
      <nb-card-footer>
        <button nbButton status="primary" type="submit">Import employee data</button>
        <button nbButton status="primary" ghost type="button" (click)="ref.close()">Cancel</button>
      </nb-card-footer>
    </nb-card>
  </form>
</ng-template>
