import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ClaimType, DataType, Employee, IEmployee } from '@app/data';

@Component({
  selector: 'pip-off-work-employee-form',
  templateUrl: './off-work-employee-form.component.html',
  styleUrls: ['./off-work-employee-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class OffWorkEmployeeFormComponent implements OnInit {
  @Input() public employee: IEmployee = Employee.create();
  @Input() public employees: IEmployee[];

  public form: FormGroup;
  public claimType = ClaimType;

  public get offwork(): IEmployee[] {
    return this.employees.filter((e) => e.attributes.absent === false);
  }

  @Output() private update = new EventEmitter<IEmployee>();

  constructor() {}

  public ngOnInit(): void {
    this.initForm();
  }

  public onSubmit(): void {
    this.update.emit({
      id: this.form.get('id').value,
      type: DataType.Employees,
      attributes: {
        absent: true,
        claims: {
          type: this.form.get('claim_type').value,
          cause: this.form.get('claim_cause').value,
          start_at: this.form.get('claim_start').value,
          end_at: this.form.get('claim_end').value,
          waiver_approved: this.form.get('waver_approved').value,
        },
      },
    });
  }

  public initForm(): void {
    const { claims } = this.employee.attributes;

    this.form = new FormGroup({
      id: new FormControl(this.employee.id, [Validators.required]),
      claim_type: new FormControl(claims?.type, [Validators.required]),
      claim_cause: new FormControl(claims?.cause, [Validators.required]),
      claim_start: new FormControl(claims?.start_at, [Validators.required]),
      claim_end: new FormControl(claims?.end_at, []),
      waver_approved: new FormControl(claims?.waiver_approved, [Validators.required]),
    });
  }
}
