<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <pip-rate-properties-form-group [plan]="plan" [formGroup]="form"></pip-rate-properties-form-group>
  <hr />

  <div *ngIf="showRateBenefitsBooklet">
    <pip-media-uploader
      [existingMedia]="rate.media[mediaType.RateBenefitsBooklet]"
      [mediaType]="mediaType.RateBenefitsBooklet"
      [modelId]="rate.id"
      [mediaModel]="mediaModel.Rate"
    ></pip-media-uploader>
  </div>

  <div *ngIf="showRateWelcomePackage">
    <pip-media-uploader
      [existingMedia]="rate.media[mediaType.RateWelcomePackage]"
      [mediaType]="mediaType.RateWelcomePackage"
      [modelId]="rate.id"
      [mediaModel]="mediaModel.Rate"
    ></pip-media-uploader>
  </div>

  <div class="d-flex justify-content-end">
    <button nbButton status="primary" type="submit">Save</button>
  </div>
</form>
