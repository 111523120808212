import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import * as Data from '@app/data/models';

import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  private params: Params = { include: 'roles,organizations,profiles' };

  constructor(private api: ApiService) {}

  public getUser(userId: string): Observable<Data.Response<Data.IUser>> {
    return this.api.get(['users', userId], { params: this.params });
  }

  public updateUser(data: Partial<Data.IUser>): Observable<Data.Response<Data.IUser>> {
    return this.api.patch(['users', data.id], data, { params: this.params });
  }

  public getUsers({
    pagination,
    search,
    roles,
    insurance_type,
  }: {
    pagination?: Data.IPagination;
    search?: string;
    roles?: string[];
    insurance_type?: string;
  }): Observable<Data.Response<Data.IUser[]>> {
    this.initPagination(pagination);

    return this.api.get('users', {
      params: {
        ...this.params,
        ...(search && { search }),
        ...(roles && roles.length && { roles: roles.toString() }),
        ...(insurance_type && { insurance_type }),
      },
    });
  }

  private initPagination(pagination: Data.IPagination): void {
    if (pagination) {
      this.params['page[number]'] = pagination.current_page;
      this.params['page[size]'] = pagination.per_page;
    }
  }
}
