import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ICarrier, IPlan, Plan } from '@app/data';
import { environment } from '@env/environment';

@Component({
  selector: 'pip-preauthorized-payment-signoff-form',
  templateUrl: './preauthorized-payment-signoff-form.component.html',
  styleUrls: ['./preauthorized-payment-signoff-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PreauthorizedPaymentSignoffFormComponent implements OnInit {
  @Input() public plan: IPlan;
  @Input() public carrier: ICarrier;

  @Output() private save = new EventEmitter<IPlan>();

  public get padURL(): SafeUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(`${environment.url.api}/carriers/${this.carrier.id}/pad`);
  }

  public form: FormGroup;

  constructor(private sanitizer: DomSanitizer) {}

  public ngOnInit(): void {
    this.initForm();
  }

  public onSubmit(): void {
    const { id, type } = this.plan;

    const payload: IPlan = { id, type, attributes: { ...this.form.value } };

    this.save.emit(payload);
  }

  private initForm(): void {
    const { payment_signoff } = this.plan.attributes || Plan.createAttributes();

    this.form = new FormGroup({
      payment_signoff: new FormControl(payment_signoff, this.carrier ? [Validators.requiredTrue] : []),
    });

    if (payment_signoff) {
      this.form.disable();
    }
  }
}
