import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ClientsPaths } from '@app/core/clients/clients.paths';
import { PAPlansPaths } from '@app/core/pa-plans/pa-plans.paths';
import { PlansPaths } from '@app/core/plans/plans.paths';
import { IPagination, IPlan, IPlanListItem, PlanStatus, Role } from '@app/data';
import { NbSortDirection, NbSortRequest, NbTreeGridDataSource, NbTreeGridDataSourceBuilder } from '@nebular/theme';

@Component({
  selector: 'pip-plans-table',
  templateUrl: './plans-table.component.html',
  styleUrls: ['./plans-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlansTableComponent implements OnChanges, OnInit {
  @Input() public plans: IPlanListItem[];
  @Input() public pagination: IPagination;
  @Input() public sort: string;
  @Input() public showPagination = true;
  @Input() public roles: Role[] = [];

  @Input() public showColStartAt: boolean;
  @Input() public showColExpireAt: boolean;
  @Input() public showColCreatedAt: boolean;
  @Input() public showColUpdatedAt: boolean;

  @Output() public pageChange: EventEmitter<number>;
  @Output() public sortChange: EventEmitter<string>;

  public paths = PlansPaths;
  public dataSource: NbTreeGridDataSource<IPlan>;
  public columns = [
    'organization_name',
    'status',
    'advisor',
    'start_at',
    'expire_at',
    'created_at',
    'updated_at',
    'actions',
  ];

  public get sortDirection(): NbSortDirection {
    if (!this.sort) {
      return NbSortDirection.NONE;
    }

    return this.sort.startsWith('-') ? NbSortDirection.DESCENDING : NbSortDirection.ASCENDING;
  }

  public get sortColumn(): string {
    const column = this.sort;
    return column && column.replace(/-/, '');
  }

  constructor(private dataSourceBuilder: NbTreeGridDataSourceBuilder<IPlan>) {
    this.pageChange = new EventEmitter();
    this.sortChange = new EventEmitter();
  }

  public ngOnInit(): void {}

  public ngOnChanges(changes): void {
    if (!this.plans) {
      return;
    }

    this.dataSource = this.dataSourceBuilder.create(this.plans.map((p) => ({ data: { ...p } })));
  }

  public changeSort(sortRequest: NbSortRequest): void {
    switch (sortRequest.direction) {
      case NbSortDirection.ASCENDING: {
        this.sortChange.emit(sortRequest.column);
        break;
      }
      case NbSortDirection.DESCENDING: {
        this.sortChange.emit(`-${sortRequest.column}`);
        break;
      }
      case NbSortDirection.NONE: {
        this.sortChange.emit('');
        break;
      }
    }
  }

  public getDirection(column: string): NbSortDirection {
    if (column === this.sortColumn) {
      return this.sortDirection;
    }

    return NbSortDirection.NONE;
  }

  public onPaginationChange($event: number) {
    this.pageChange.emit($event);
  }

  public getEditLink(plan: IPlanListItem): any[] {
    if (plan.status === PlanStatus.InProgress) {
      return ['/', this.paths.ROOT, plan.id, this.paths.Tasks];
    }

    if (plan.status === PlanStatus.RTQ) {
      return ['/', this.paths.ROOT, plan.id, this.paths.Proposal];
    }

    if ([PlanStatus.Confirmed, PlanStatus.Aborted, PlanStatus.Expired].includes(plan.status as PlanStatus)) {
      return ['/', ClientsPaths.ROOT, plan.id];
    }

    if (this.roles?.includes(Role.PlanAdmin)) {
      if (plan.status === PlanStatus.Confirmed) {
        return ['/', PAPlansPaths.ROOT, plan.id];
      } else {
        return ['/', this.paths.ROOT, plan.id, this.paths.PATasks];
      }
    }

    return ['/', this.paths.ROOT, plan.id];
  }

  public shouldShowEnroll(plan: IPlanListItem): boolean {
    return this.roles?.includes(Role.Advisor) && plan.status === PlanStatus.Proposal;
  }
}
