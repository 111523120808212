import { Component, OnInit, ChangeDetectionStrategy, Output, EventEmitter, OnChanges, SimpleChanges, Input } from '@angular/core';
import { IPagination } from '@app/data';
import { PaginatePipe } from 'ngx-pagination';

@Component({
  selector: 'pip-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [PaginatePipe],
})
export class PaginationComponent implements OnInit, OnChanges {

  @Input() public collection: any[];
  @Input() public pagination: IPagination;
  @Output() public paginationChange: EventEmitter<IPagination>;

  public pageSizeOpts: Array<number>;
  public pageSizeSelected: number;

  constructor(private paginate: PaginatePipe) {
    this.paginationChange = new EventEmitter();
    this.pageSizeOpts = [15, 20, 25, 30];
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.pagination) {
      this.initPagination();
    }
  }

  public onChange($event: number, isPageSize = false) {
    const pagination = {...this.pagination};

    (isPageSize) ? pagination.per_page = $event : pagination.current_page = $event;

    this.paginationChange.emit(pagination);

    document.querySelector('h1').scrollIntoView(true);
  }

  /**
   * We import and initialize the pagination pipe manually
   * since we aren't using *ngFor in the view
   */
  private initPagination() {
    this.paginate.transform(this.collection, {
      id: 'pagination',
      itemsPerPage: this.pagination.per_page,
      currentPage: this.pagination.current_page,
      totalItems: this.pagination.total,
    });

    this.pageSizeSelected = this.pagination.per_page || this.pageSizeOpts[0];
  }

}
