import { DataType } from '../enums/data-type.enum';
import { ICommonRateProperties, ICommonRatePropertiesResource } from './common-rate-properties';
import { Attributes, Relationship, Relationships } from './json-api';
import { IMedia } from './media';
import { ISegment } from './segment';

export enum RenewalStatus {
  Abandoned = 'Abandoned',
  Accepted = 'Accepted',
  Completed = 'Completed',
  Ongoing = 'Ongoing',
  Ready = 'Ready',
}

export interface IRenewal extends ICommonRatePropertiesResource {
  type: DataType.Renewals;
  attributes: Partial<IRenewalAttributes>;
  relationships?: IRenewalRelationships;
  included?: Array<ISegment | IMedia>;
}

export interface IRenewalAttributes extends Attributes, ICommonRateProperties {
  start_at?: string;
  status?: RenewalStatus;
  created_at?: string;
  updated_at?: string;
  signature?: string;
  signed_at?: string;
}

export interface IRenewalRelationships extends Relationships {
  segments: Relationship<ISegment[]>;
  media: Relationship<IMedia[]>;
}
