<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <nb-card>
    <nb-card-body>
      <pip-administrator-formgroup
        [plan]="plan"
        [showSecurityFields]="showSecurityFields"
        formGroupName="administrator"
      ></pip-administrator-formgroup>
      <div class="legal" *ngIf="carrier">
        <pip-access-agreement-content [carrierName]="carrier.attributes.name"></pip-access-agreement-content>
        <p>Please provide my advisor with access to:</p>
        <nb-radio-group formControlName="pa_access">
          <nb-radio [disabled]="form.disabled" [value]="paAccess.Full">
            experience, claims, billing and enrollment (recommended)
          </nb-radio>
          <nb-radio [disabled]="form.disabled" [value]="paAccess.Limited">
            experience and claims only
          </nb-radio>
        </nb-radio-group>
      </div>
    </nb-card-body>
    <nb-card-footer class="d-flex justify-content-between">
      <a nbButton status="primary" ghost [routerLink]="['../']">Cancel</a>
      <button nbButton status="primary" type="submit" [disabled]="!form.valid">{{ confirmButton }}</button>
    </nb-card-footer>
  </nb-card>
</form>
