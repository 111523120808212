import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import {
  ICarrier,
  ICarrierMediaLinks,
  IMedia,
  IPlan,
  IPlanMedia,
  MediaModel,
  MediaType,
  PaymentInformation,
  Plan,
} from '@app/data';
import { environment } from '@env/environment';

@Component({
  selector: 'pip-quote-payment-form',
  templateUrl: './quote-payment-form.component.html',
  styleUrls: ['./quote-payment-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuotePaymentFormComponent implements OnInit {
  @Input() public plan: IPlan;
  @Input() public planMedia: IPlanMedia;
  @Input() public carrier: ICarrier;
  @Input() public carrierMedia: ICarrierMediaLinks;
  @Input() public loading: boolean;
  @Input() public confirmButton = 'Save';
  @Input() public cancelButton = 'Cancel';
  @Input() public showSalesTaxFormUpload = true;

  @Output() public save = new EventEmitter<IPlan>();
  @Output() public cancel = new EventEmitter<void>();

  public mediaType = MediaType;
  public mediaModel = MediaModel;
  public form: FormGroup;

  public get padURL(): SafeUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(`${environment.url.api}/carriers/${this.carrier.id}/pad`);
  }

  public get carrierSalesTaxForm(): string {
    return this.carrierMedia?.SalesTaxForm;
  }

  public get planSalesTaxForm(): IMedia {
    return this.planMedia?.SalesTaxForm;
  }

  constructor(private sanitizer: DomSanitizer) {}

  public ngOnInit(): void {
    this.initForm();
  }

  public onSubmit(): void {
    const { id, type } = this.plan;

    const payload: IPlan = { id, type, attributes: { ...this.form.value } };

    this.save.emit(payload);
  }

  private initForm(): void {
    const { payment_signoff } = this.plan.attributes || Plan.createAttributes();

    this.form = new FormGroup({
      payment_signoff: new FormControl(payment_signoff, this.carrier ? [Validators.requiredTrue] : []),
    });

    if (payment_signoff) {
      this.form.disable();
    }
  }
}
