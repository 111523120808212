import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IOrganization, IPlan } from '@app/data';
import { PlansService } from '@app/data/services';
import { debounceTime, distinctUntilChanged, filter, map, Observable, tap } from 'rxjs';

@Component({
  selector: 'pip-create-new-plan',
  templateUrl: './create-new-plan.component.html',
  styleUrls: ['./create-new-plan.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateNewPlanComponent implements OnInit {
  @Input() public quoteType;
  @Input() public activeUser;
  @Input() public isAnalyst;
  @Input() public isGroupRep;

  @Output() public save = new EventEmitter<IPlan>();

  public organization: Partial<IOrganization>;
  public companies$: Observable<IOrganization[]>;
  public form: FormGroup;

  constructor(private plansService: PlansService, private cdRef: ChangeDetectorRef) {}

  public ngOnInit(): void {
    this.initForm();
  }

  public onCreatePlan(plan: IPlan): void {
    this.save.emit(plan);
  }

  public onCompanySearch(search: string): void {
    this.companies$ = this.plansService.preflightOrganization(search).pipe(map((res) => res.data));
    this.cdRef.detectChanges();
  }

  public setCompany(org: Partial<IOrganization>): void {
    this.organization = org;
  }

  private initForm(): void {
    this.form = new FormGroup({
      organization_name: new FormControl(null, [Validators.required]),
    });

    this.form
      .get('organization_name')
      .valueChanges.pipe(
        tap(() => (this.companies$ = null)),
        debounceTime(300),
        distinctUntilChanged(),
        filter((val) => {
          return val.length >= 2;
        }),
      )
      .subscribe((val) => {
        if (val) {
          this.onCompanySearch(val);
        }
      });
  }
}
