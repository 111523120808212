import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  Administrator,
  CompanyInfo,
  Country,
  DataType,
  IChecklistItem,
  IDefaultPlan,
  IPlan,
  IPlanMedia,
  MediaModel,
  MediaType,
  Plan,
  PlanStatus,
} from '@app/data';
import { environment } from '@env/environment';
import { NbStepperComponent } from '@nebular/theme';

const POSTAL_REGEX = /^([ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVWXYZ])\ {0,1}(\d[ABCEGHJKLMNPRSTVWXYZ]\d)$/gi;
const ZIP_REGEX = /^\d{5}([\-]?\d{4})?$/gi;

@Component({
  selector: 'pip-quote-wizard-stepper',
  templateUrl: './quote-wizard-stepper.component.html',
  styleUrls: ['./quote-wizard-stepper.component.scss'],
})
export class QuoteWizardStepperComponent implements OnInit {
  @Input() public isVirgin: boolean;
  @Input() public plan: IPlan;
  @Input() public planMedia: IPlanMedia;
  @Input() public defaultPlans: IDefaultPlan[];
  @ViewChild(NbStepperComponent) public stepper: NbStepperComponent;

  public form: FormGroup;
  public mediaType = MediaType;
  public mediaModel = MediaModel;
  public censusTemplateUrl = `${environment.url.api}/assets/census-template.xlsx`;

  public get incompleteFields(): IChecklistItem[] {
    return (
      this.plan &&
      this.plan.meta &&
      this.plan.meta.checklist &&
      this.plan.meta.checklist.filter((item) => item.complete === false)
    );
  }

  public get isSubmitted(): boolean {
    return this.plan && this.plan.attributes.status === PlanStatus.RTQ;
  }

  public get hasSelectedPlans(): boolean {
    return this.form.get('selectedPlans').value.some((p) => p.selected);
  }

  public get hasBenefitsBooklet(): boolean {
    return !!(this.planMedia && this.planMedia[this.mediaType.BenefitsBooklet].length);
  }

  public get hasRenewal(): boolean {
    return !!(this.planMedia && this.planMedia[this.mediaType.Renewal]);
  }

  public get hasCensus(): boolean {
    return !!(this.planMedia && this.planMedia[this.mediaType.Census]);
  }

  public get isClientProfileValid(): boolean {
    return (
      this.form.get('companyInfo').valid &&
      this.form.get('businessAddress').valid &&
      this.form.get('administrator').valid
    );
  }

  @Output() private update = new EventEmitter<{ plan: IPlan; onSuccess(): void }>();

  constructor() {}

  public ngOnInit(): void {
    this.initForm();
  }

  public updatePlan(): void {
    const { requests, selectedPlans, ltd, std, eap, virtual_care } = this.form.value;
    const { organization_name, industry, website, language } = this.form.get('companyInfo').value;
    const { address1, address2, city, state, country, postal_code } = this.form.get('businessAddress').value;
    const { email, first_name, last_name, phone } = this.form.get('administrator').value;

    const options: string[] = [];

    selectedPlans.forEach((p) => {
      if (p.selected) {
        options.push(p.name);
      }
    });

    this.update.emit({
      plan: {
        id: this.plan && this.plan.id,
        type: DataType.Plans,
        attributes: {
          virgin: this.isVirgin,
          organization_name,
          requests,
          organization_info: { address1, address2, city, country, industry, postal_code, state, website, language },
          administrator: { email, first_name, last_name, phone, born_at: null, mothers_name: null },
          options,
          ltd,
          std,
          eap,
          virtual_care,
        },
      },
      onSuccess: () => this.stepper.next(),
    });
  }

  public submitPlan(): void {
    this.update.emit({
      plan: {
        id: this.plan.id,
        type: this.plan.type,
        attributes: {
          status: PlanStatus.RTQ,
        },
      },
      onSuccess: () => {
        this.stepper.next();
      },
    });
  }

  public onPrev(): void {
    this.stepper.previous();
  }

  private initForm(): void {
    const {
      organization_name,
      administrator,
      organization_info: company_info,
      requests,
      ltd,
      std,
      eap,
      virtual_care,
    } = (this.plan && this.plan.attributes) || Plan.createAttributes();
    const { email, first_name, last_name, phone } = administrator || Administrator.create();
    const { address1, address2, city, country, industry, postal_code, state, website } =
      company_info || CompanyInfo.create();

    this.form = new FormGroup({
      companyInfo: new FormGroup({
        organization_name: new FormControl(organization_name, [Validators.required]),
        industry: new FormControl(industry, [Validators.required]),
        website: new FormControl(website),
      }),
      businessAddress: new FormGroup({
        address1: new FormControl(address1, [Validators.required]),
        address2: new FormControl(address2),
        city: new FormControl(city, [Validators.required]),
        state: new FormControl(state, [Validators.required]),
        country: new FormControl(country || Country.Canada, [Validators.required]),
        postal_code: new FormControl(postal_code, [Validators.required]),
      }),
      administrator: new FormGroup({
        email: new FormControl(email, [Validators.required, Validators.email]),
        first_name: new FormControl(first_name, [Validators.required]),
        last_name: new FormControl(last_name, [Validators.required]),
        phone: new FormControl(phone, [Validators.required]),
      }),
      ltd: new FormControl(ltd),
      std: new FormControl(std),
      eap: new FormControl(eap),
      virtual_care: new FormControl(virtual_care),
      requests: new FormControl(requests),
      selectedPlans: new FormArray(
        this.defaultPlans.map((dp) => {
          return new FormGroup({
            name: new FormControl(dp.id),
            selected: new FormControl(
              this.plan && this.plan.attributes.options && this.plan.attributes.options.find((o) => o === dp.id)
                ? true
                : false,
            ),
          });
        }),
      ),
    });
  }
}
