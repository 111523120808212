<ng-container *ngIf="plan$ | async as plan">
  <pip-field-status-alert [fieldStatus]="plan.meta?.field_statuses?.insurance_info"></pip-field-status-alert>
  <div class="row">
    <div class="col">
      <pip-insurance-status-form
        [plan]="plan"
        [defaultPlans]="defaultPlans$ | async"
        [loading]="loading$ | async"
        [planMedia]="planMedia$ | async"
        [showBenefitsBooklet]="true"
      ></pip-insurance-status-form>
    </div>
  </div>
</ng-container>
