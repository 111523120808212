import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IRenewal, RenewalStatus } from '@app/data';
import { ClientsPaths } from '../../../core/clients/clients.paths';

@Component({
  selector: 'pip-client-menu',
  templateUrl: './client-menu.component.html',
  styleUrls: ['./client-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientMenuComponent implements OnInit {
  @Input() public renewal: IRenewal;
  @Input() public hideRenewalItem: boolean;

  private get menu() {
    return [
      { label: 'Company Info', link: ClientsPaths.CompanyInfo },
      { label: 'Administration', link: ClientsPaths.Administration },
      { label: 'Rates', link: ClientsPaths.Rates },
      { label: 'Plan Members', link: ClientsPaths.PlanMembers },
      { label: 'Payment Info', link: ClientsPaths.PaymentInfo },
      {
        label: 'Renewal',
        link: ClientsPaths.Renewal,
        hidden: this.hideRenewalItem,
        badge: this.renewalStatusBadge(this.renewal),
      },
    ];
  }

  public get visibleMenu() {
    return this.menu.filter((item) => !item.hidden);
  }

  constructor(private _router: Router) {}

  public ngOnInit(): void {}

  public isActivePage(link: string): boolean {
    return !!this._router.url.match(link)?.length;
  }

  private renewalStatusBadge(renewal: IRenewal): { status: 'warning' | 'danger' | 'success'; label: string } {
    if (!renewal) {
      return;
    }

    switch (renewal?.attributes.status) {
      case RenewalStatus.Ongoing:
      case RenewalStatus.Accepted: {
        return { status: 'success', label: 'In Progress' };
      }
      case RenewalStatus.Ready: {
        return { status: 'warning', label: 'Pending' };
      }
      case RenewalStatus.Abandoned: {
        return { status: 'danger', label: 'Aborted' };
      }
    }
  }
}
