import { DataType, LifeVolumeType } from '../enums';
import { Attributes, ResourceObject } from './json-api';
import { IPlanStandards } from './plan-standards';
import { IRateMedia } from './rate-media';

export interface IRate extends ResourceObject {
  type: DataType.Rates;
  attributes: IRateAttributes;
  media?: IRateMedia;
}

export interface IRateAttributes extends Attributes {
  name: string;
  add: number | string;
  eap: number | string;
  ltd: number | string;
  std: number | string;
  life: number | string;
  healthcare_single: number | string;
  healthcare_single_parent: number | string;
  healthcare_couple: number | string;
  healthcare_family: number | string;
  dental_single: number | string;
  dental_single_parent: number | string;
  dental_couple: number | string;
  dental_family: number | string;
  dependent_life: number | string;
  critical_illness: number | string;
  critical_illness_single: number | string;
  life_max: number | string;
  std_max: number | string;
  ltd_max: number | string;
  critical_illness_max: number | string;
  life_volume_type: LifeVolumeType;
  life_volume: number | string;
  critical_illness_volume_type: LifeVolumeType;
  critical_illness_volume: number | string;
  virtual_care: number | string;
  standards: IPlanStandards;
  url_video: string;
}

export class Rate {
  public static create(): IRate {
    return {
      id: null,
      type: DataType.Rates,
      attributes: Rate.createAttributes(),
    };
  }

  public static createAttributes(): IRateAttributes {
    return {
      name: '',
      add: undefined,
      eap: undefined,
      ltd: undefined,
      std: undefined,
      life: undefined,
      healthcare_single: undefined,
      healthcare_single_parent: undefined,
      healthcare_couple: undefined,
      healthcare_family: undefined,
      dental_single: undefined,
      dental_single_parent: undefined,
      dental_couple: undefined,
      dental_family: undefined,
      dependent_life: undefined,
      critical_illness: undefined,
      critical_illness_single: undefined,
      salary_threshold: undefined,
      life_max: undefined,
      std_max: undefined,
      ltd_max: undefined,
      critical_illness_max: undefined,
      life_volume_type: undefined,
      life_volume: undefined,
      critical_illness_volume_type: undefined,
      critical_illness_volume: undefined,
      virtual_care: undefined,
      standards: undefined,
      url_video: undefined,
    };
  }
}
