import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, TemplateRef } from '@angular/core';
import {
  DeleteMedia,
  GroupExperience,
  IMedia,
  MediaModel,
  MediaType,
  MediaTypeDictionary,
  UploadMedia,
  UploadMediaFailed,
} from '@app/data';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { Actions, ofActionDispatched, Store } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { catchError, endWith, map, startWith } from 'rxjs/operators';

@Component({
  selector: 'pip-media-uploader',
  templateUrl: './media-uploader.component.html',
  styleUrls: ['./media-uploader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MediaUploaderComponent implements OnInit {
  @Input() public existingMedia: IMedia | IMedia[];
  @Input() public modelId: string;
  @Input() public experience: GroupExperience;
  @Input() public mediaType: MediaType;
  @Input() public mediaModel: MediaModel;
  @Input() public requireExpiry: boolean;
  @Input() public warning: string;
  @Input() public downloadTemplate: string;
  @Input() public required = true;
  @Input() public uploadLabelOverride: string;
  @Input() public downloadLabelOverride: string;

  public isLoading$: Observable<boolean>;
  public errors$: Observable<any>;

  public get fileTypeLabel(): string {
    return MediaTypeDictionary[this.mediaType];
  }

  constructor(private store: Store, private dialog: NbDialogService, private actions$: Actions) {}

  public ngOnInit(): void {}

  public onUpload(data: FormData): void {
    this.errors$ = this.actions$.pipe(
      ofActionDispatched(UploadMedia, UploadMediaFailed),
      map((e) => {
        return e && e.payload && e.payload.errors && e.payload;
      }),
    );

    // TODO: Look into better way to track independent loading.
    // This works... But seems like a very strange way to track loading...
    this.isLoading$ = this.store.dispatch(new UploadMedia(data)).pipe(
      startWith(true),
      endWith(false),
      catchError((error) => {
        return of(false);
      }),
    );
  }

  public onDelete(mediaId: string): void {
    this.errors$ = this.actions$.pipe(
      ofActionDispatched(UploadMedia, UploadMediaFailed),
      map((e) => {
        return e && e.payload && e.payload.errors && e.payload;
      }),
    );

    this.isLoading$ = this.store
      .dispatch(new DeleteMedia({ media_id: mediaId, model: { media_model: this.mediaModel, id: this.modelId } }))
      .pipe(
        startWith(true),
        endWith(false),
        catchError((error) => {
          return of(false);
        }),
      );
  }

  public showWarning(template: TemplateRef<any>): void {
    this.dialog.open(template);
  }

  public showFileSelect(input: ElementRef<HTMLInputElement>, dialogRef: NbDialogRef<any>): void {
    dialogRef.close();
    input.nativeElement.click();
  }
}
