import { DataType } from '../enums/data-type.enum';
import { Attributes, Relationship, Relationships, ResourceObject } from './json-api';
import { IMedia } from './media';

export enum SegmentType {
  InitialProposal = 'InitialProposal',
  Analysis = 'Analysis',
  Revision = 'Revision',
  Final = 'Final',
}

export enum SegmentStatus {
  Started = 'Started',
  Closed = 'Closed',
  RepDecision = 'RepDecision',
  RepAccept = 'RepAccept',
  RepReject = 'RepReject',
  RepClosed = 'RepClosed',
}

export const SegmentTypeDictionary: Record<SegmentType, string> = {
  InitialProposal: 'Initial Proposal',
  Analysis: 'Analysis',
  Revision: 'Revision',
  Final: 'Final',
};

export interface ISegment extends ResourceObject {
  type: DataType.Segments;
  attributes: Partial<ISegmentAttributes>;
  relationships?: ISegmentRelationships;
}

export interface ISegmentAttributes extends Attributes {
  premiums: number;
  change: number;
  notes: string;
  valid: boolean;
  type: SegmentType;
  status: SegmentStatus;
  created_at: string;
  updated_at: string;
}

export interface ISegmentRelationships extends Relationships {
  media: Relationship<IMedia[]>;
}
