import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { IEmployeeBenefitContribution, IEmployeeBenefitCost } from '@app/data';
import { IEmployeeCost } from '@app/data/models/employee-cost';

@Component({
  selector: 'pip-cost-summary',
  templateUrl: './cost-summary.component.html',
  styleUrls: ['./cost-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CostSummaryComponent implements OnInit {
  @Input() public payCycle: string;
  @Input() public employeeDeductions: IEmployeeBenefitContribution;
  @Input() public employerContributions: IEmployeeBenefitContribution;

  constructor() {}

  ngOnInit() {}
}
