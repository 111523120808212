import { Injectable } from '@angular/core';
import { AuthState, AuthStateModel } from '@app/auth/store';
import { IUserItem } from '@app/core/user-management/models';
import * as Data from '@app/data/models';
import { UsersService } from '@app/data/services';
import { IncludeProfiles } from '@app/data/store/profiles/profiles.actions';
import { IncludeRoles } from '@app/data/store/roles/roles.action'; // @TODO: Fix circular dependency
import { RouterStateParams } from '@app/store';
import * as Utils from '@app/utils/index';
import { NbToastrService } from '@nebular/theme';
import { RouterState, RouterStateModel } from '@ngxs/router-plugin';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { tap } from 'rxjs/operators';
import { DataType, MediaType, Role } from '../../enums';
import { MediaState, MediaStateModel } from '../media/media.state';
import { ProfilesState, ProfilesStateModel } from '../profiles/profiles.state';
import { RolesState, RolesStateModel } from '../roles/roles.state';
import {
  GetUser,
  GetUserSuccess,
  GetUsers,
  IncludeUsers,
  SetSelectedUserID,
  UpdateUser,
  UpdateUserPassword,
} from './users.action';

export interface UsersStateModel {
  entities: EntityMap<Data.IUser>;
  selectedUserID: string;
  meta: Data.IPagination;
}

export const usersStateDefaults: UsersStateModel = {
  entities: {},
  selectedUserID: null,
  meta: null,
};

@State<UsersStateModel>({
  name: DataType.Users,
  defaults: usersStateDefaults,
})
@Injectable()
export class UsersState {
  constructor(private users: UsersService, private toastr: NbToastrService) {}

  //#region Selectors

  @Selector()
  public static pagination(state: UsersStateModel): Data.IPagination {
    return state.meta;
  }

  @Selector([AuthState])
  public static activeUserId(state: UsersStateModel, authState: AuthStateModel): string {
    return authState.user.id;
  }

  @Selector([UsersState.activeUserId])
  public static activeUser(state: UsersStateModel, activeUserId: string): Data.IUser {
    return state.entities[activeUserId];
  }

  @Selector([RolesState])
  public static users(state: UsersStateModel, rolesState: RolesStateModel): Data.IUser[] {
    return Utils.entitiesToArray(state.entities);
  }

  @Selector([UsersState.users, RolesState])
  public static userItemList(state: UsersStateModel, users: Data.IUser[], rolesState: RolesStateModel): IUserItem[] {
    return users.map((user) => {
      const roles = this.getRoleLabels(user.relationships.roles, rolesState);

      return this.getUserItem(user, roles);
    });
  }

  @Selector()
  public static selectedUserID(state: UsersStateModel): string {
    return state.selectedUserID;
  }

  @Selector([RouterState])
  public static user(state: UsersStateModel, router: RouterStateModel<RouterStateParams>): Data.IUser {
    const userID = router.state.params.userID;
    return state.entities[userID];
  }

  // TODO: Refactor/move all profile selectors to the profile.state.ts
  @Selector([UsersState.user, ProfilesState])
  public static userProfile(state: UsersStateModel, user: Data.IUser, profileState: ProfilesStateModel): Data.IProfile {
    const profile = profileState.entities[user.relationships.profiles.data.id];

    return profile;
  }

  @Selector([UsersState.activeUserId, ProfilesState])
  public static activeUserProfile(
    state: UsersStateModel,
    userId: string,
    profileState: ProfilesStateModel,
  ): Data.IProfile {
    const profileId = state.entities[userId].relationships.profiles.data.id;
    const profile = profileState.entities[profileId];

    return profile;
  }

  @Selector([UsersState.activeUser, RolesState])
  public static activeUserRoles(state: UsersStateModel, user: Data.IUser, rolesState: RolesStateModel): Role[] {
    return (
      (user?.relationships?.roles.data as Data.RelationshipData<Data.IRole[]>)
        .map((r) => rolesState.entities[r.id])
        .map((role) => role?.id as Role) || []
    );
  }

  @Selector([UsersState.user, RolesState])
  public static userRoles(state: UsersStateModel, user: Data.IUser, rolesState: RolesStateModel): Data.IRole[] {
    const roles =
      (user?.relationships.roles.data as Data.RelationshipData<Data.IRole[]>).map((r) => rolesState.entities[r.id]) ||
      [];

    return roles;
  }

  @Selector([UsersState.activeUserProfile, MediaState])
  public static getProfileMedia(
    state: UsersStateModel,
    profile: Data.IProfile,
    media: MediaStateModel,
  ): Data.IProfileMedia {
    const profileMedia =
      (profile.relationships.media.data as Data.RelationshipData<Data.IMedia>[]).map((i) => media.entities[i.id]) || [];

    return {
      [MediaType.ErrorsOmissions]: profileMedia.find((i) => i.attributes.type === MediaType.ErrorsOmissions),
      [MediaType.LifeLicense]: profileMedia.find((i) => i.attributes.type === MediaType.LifeLicense),
      [MediaType.AdvisorAgreement]: profileMedia.find((i) => i.attributes.type === MediaType.AdvisorAgreement),
    };
  }

  @Selector([UsersState.activeUserRoles])
  public static isActiveUserAnAnalyst(state: UsersStateModel, activeUserRoles: Role[]): boolean {
    return activeUserRoles.includes(Role.Analyst);
  }

  @Selector([UsersState.activeUserRoles])
  public static isActiveUserAnAdvisor(state: UsersStateModel, activeUserRoles: Role[]): boolean {
    return activeUserRoles.includes(Role.Advisor);
  }

  @Selector([UsersState.isActiveUserAnAdvisor, UsersState.isActiveUserAnAnalyst])
  public static isActiveUserAnAdvisorOrAnalyst(
    state: UsersStateModel,
    isAdvisor: boolean,
    isAnalyst: boolean,
  ): boolean {
    return isAdvisor || isAnalyst;
  }

  //#endregion

  //#region Helper Static methods

  private static getRoleLabels(roles: Data.Relationship<Data.IRole[]>, rolesState: RolesStateModel): string[] {
    return (
      roles &&
      (roles.data as Data.RelationshipData<Data.IRole>[]).map((role) => rolesState.entities[role.id].attributes.label)
    );
  }

  private static getUserItem(user: Data.IUser, roles: string[]): IUserItem {
    const attr = user.attributes;
    return {
      id: user.id,
      active: attr && attr.active,
      email: attr && attr.email,
      first_name: attr && attr.first_name,
      last_login: attr && attr.last_login,
      last_name: attr && attr.last_name,
      roles,
    };
  }

  //#endregion

  //#region Actions
  @Action(IncludeUsers)
  public includeUsers(ctx: StateContext<UsersStateModel>, action: IncludeUsers) {
    ctx.setState(
      patch<UsersStateModel>({
        entities: patch(Utils.createEntities(action.payload, 'id')),
      }),
    );
  }

  @Action(GetUser)
  public getUser(ctx: StateContext<UsersStateModel>, action: GetUser) {
    return this.users.getUser(action.payload.userId).pipe(
      tap((res) => {
        const user = res.data;

        ctx.setState(
          patch<UsersStateModel>({
            entities: patch({ [user.id]: user }),
          }),
        );

        this.updateStores(ctx, res.included);

        ctx.dispatch(new GetUserSuccess());
      }),
    );
  }

  @Action(UpdateUser)
  public updateUser(ctx: StateContext<UsersStateModel>, action: UpdateUser) {
    return this.users.updateUser(action.payload.user).pipe(
      tap((res) => {
        const user = res.data;

        ctx.setState(
          patch<UsersStateModel>({
            entities: patch({ [user.id]: user }),
          }),
        );
      }),
      tap(() => this.toastr.success('User updated', 'Success!', null)),
    );
  }

  @Action(UpdateUserPassword)
  public updateUserPassword(ctx: StateContext<UsersStateModel>, action: UpdateUserPassword) {
    const { id, password } = action.payload;

    const user: Partial<Data.IUser> = {
      id,
      attributes: { password },
    };

    return this.users.updateUser(user).pipe(tap(() => this.toastr.success('Password updated', 'Success!', null)));
  }

  @Action(GetUsers)
  public getUsers(ctx: StateContext<UsersStateModel>, action: GetUsers) {
    const { pagination } = action.payload;

    return this.users.getUsers({ pagination }).pipe(
      tap((res) => {
        const users = res.data;

        ctx.setState(
          patch<UsersStateModel>({
            entities: Utils.createEntities(users, 'id'),
            meta: res.meta as Data.IPagination,
          }),
        );

        this.updateStores(ctx, res.included);
      }),
    );
  }

  @Action(SetSelectedUserID)
  public setSelectedUserID(ctx: StateContext<UsersStateModel>, { payload }: SetSelectedUserID) {
    ctx.setState(
      patch<UsersStateModel>({
        selectedUserID: payload,
      }),
    );
  }

  //#endregion

  private updateStores(ctx: StateContext<UsersStateModel>, included: Data.ResourceObject[]) {
    const roles = included.filter((i) => i && i.type === DataType.Roles) as Data.IRole[];
    const profiles = included.filter((i) => i && i.type === DataType.Profiles) as Data.IProfile[];

    if (roles.length) {
      ctx.dispatch(new IncludeRoles(roles));
    }

    if (profiles.length) {
      ctx.dispatch(new IncludeProfiles(profiles));
    }
  }
}
