export class GetAuthUser {
  public static readonly type = '[Auth] Get Auth User';
  constructor() {}
}

export class Login {
  public static readonly type = '[Auth] Login';
  constructor(public payload: { email: string; password: string }) {}
}

export class Logout {
  public static readonly type = '[Auth] Logout';
  constructor() {}
}

export class ResetPassword {
  public static readonly type = '[Auth] Reset Password';
  constructor(public payload: { email: string }) {}
}

export class VerifyEmail {
  public static readonly type = '[Auth] Verify Email';
  constructor(public payload: { email: string }) {}
}

export class VerifyCode {
  public static readonly type = '[Auth] Verify Code';
  constructor(public payload: { email: string; code: string }) {}
}

export class VerifyInvitation {
  public static readonly type = '[Auth] Verify Invitation';
  constructor(public payload: { url: string }) {}
}
