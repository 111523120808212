import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { GroupExperienceDictionary } from '@app/data';

@Component({
  selector: 'pip-user-details-from-group',
  templateUrl: './user-details-from-group.component.html',
  styleUrls: ['./user-details-from-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserDetailsFromGroupComponent implements OnInit {
  @Input() public isAdvisor: boolean;
  public groupExpOpts = Object.entries(GroupExperienceDictionary);

  constructor(public controlContainer: ControlContainer) {}

  ngOnInit() {}
}
