import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { PlansState } from '@app/data';
import { Select, Store } from '@ngxs/store';
import { map } from 'rxjs/operators';

@Component({
  selector: 'pip-plan-insurance-status',
  templateUrl: './plan-insurance-status.component.html',
  styleUrls: ['./plan-insurance-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanInsuranceStatusComponent implements OnInit {
  public plan$ = this.store.select(PlansState.activePlan);
  public defaultPlans$ = this.store.select(PlansState.defaultPlans);
  public loading$ = this.store.select(PlansState.loading);
  public planMedia$ = this.store.select(PlansState.activePlanMedia);

  constructor(private store: Store) {}

  ngOnInit() {}
}
