<nb-card>
  <nb-card-body *ngIf="plan$ | async as plan">
    <div class="row">
      <div class="col">
        <pip-organization-info
          [planId]="plan.id"
          [companyInfo]="companyInfo$ | async"
          [companyName]="companyName$ | async"
        ></pip-organization-info>
      </div>
      <div class="col">
        <div>
          <pip-business-address [planId]="plan.id" [companyInfo]="companyInfo$ | async"></pip-business-address>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <pip-main-contact
          [plan]="plan"
          [administrator]="administrator$ | async"
          [displayExtraInfo]="isAnalystOrAdvisor$ | async"
          [canEdit]="isAnalystOrAdvisor$ | async"
        ></pip-main-contact>
      </div>
    </div>
  </nb-card-body>
</nb-card>

<ng-container *ngIf="showNotes$ | async">
  <pip-client-notes
    *ngIf="organizationId$ | async as organizationId"
    [organizationId]="organizationId"
    [allowEdit]="!(isActiveUserActivePlanAssociate$ | async)"
  ></pip-client-notes>
</ng-container>
