import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { PlansState } from '@app/data';
import { Store } from '@ngxs/store';

@Component({
  selector: 'pip-client-payment-info',
  templateUrl: './client-payment-info.component.html',
  styleUrls: ['./client-payment-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientPaymentInfoComponent implements OnInit {
  public plan$ = this.store.select(PlansState.activePlan);
  public paymentInfo$ = this.store.select(PlansState.activePlanPaymentInfos);

  constructor(private store: Store) {}

  ngOnInit(): void {}
}
