<div class="row justify-content-center">
  <div class="col-xl-6">
    <h2>Main Contact</h2>
    <pip-plan-administrator-form
      *ngIf="plan$ | async as plan"
      [plan]="plan"
      [carrier]="carrier$ | async"
      confirmButton="Save"
      [requirePaAccess]="false"
      (save)="onUpdatePlan($event)"
    ></pip-plan-administrator-form>
  </div>
</div>
