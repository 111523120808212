<nb-card [nbSpinner]="isLoading$ | async" *ngIf="plan$ | async as plan">
  <nb-card-body>
    <div class="d-flex justify-content-between align-items-center">
      <h2>Plan Members</h2>
      <div>
        <pip-import-employee-data class="mr-1" [planId]="plan.id"></pip-import-employee-data>
        <a class="mr-1" nbButton [href]="employeeDownload$ | async" size="small" status="primary" download>
          <nb-icon icon="download"></nb-icon>Export
        </a>
        <pip-create-employee class="mr-1" [planId]="plan.id"></pip-create-employee>
        <button
          nbButton
          status="primary"
          size="small"
          (click)="inviteSelectedEmployees(plan.id)"
          [disabled]="!selectedEmployees.length"
        >
          <!-- <nb-icon icon="plus-circle-outline"></nb-icon> -->
          Invite Selected
        </button>
      </div>
    </div>
    <div class="">
      <pip-employee-search [planId]="(plan$ | async).id"></pip-employee-search>
    </div>
  </nb-card-body>
  <pip-plan-members
    [employees]="employees$ | async"
    [rates]="rates$ | async"
    (selectedEmployees)="onSelectedEmployeesChange($event)"
  ></pip-plan-members>
  <nb-card-footer class="d-flex justify-content-center">
    <pip-employee-pagination
      [paginatedLinks]="(plan$ | async).relationships.employees.links"
      [planId]="(plan$ | async).id"
    ></pip-employee-pagination>
  </nb-card-footer>
</nb-card>
