<form [formGroup]="form" (ngSubmit)="onSubmit(warningDialog)">
  <div class="form-group row">
    <div class="col">
      <div class="legal" *ngIf="carrier">
        <pip-master-authorization-content [carrierName]="carrier.attributes.name"></pip-master-authorization-content>
      </div>
      <div class="row">
        <div class="col">
          <label for="pa_signature" class="label d-block">
            Please enter <strong>{{ signatureName }}</strong> to sign
          </label>
          <input nbInput fullWidth name="pa_signature" id="pa_signature" type="text" formControlName="pa_signature" />
        </div>
        <div class="col">
          <label for="pa_signature_province" class="label d-block">
            Signed at
          </label>
          <nb-select fullWidth name="pa_signature_province" id="pa_signature_province" formControlName="pa_signature_province">
            <nb-option *ngFor="let province of provinces" [value]="province.value">{{ province.label }}</nb-option>
          </nb-select>
        </div>
      </div>
    </div>
  </div>
  <div class="form-group row">
    <div class="col">
      <button nbButton status="primary" type="submit" [disabled]="!form.valid">
        Complete Enrollment
      </button>
    </div>
  </div>
</form>

<ng-template #warningDialog let-data let-ref="dialogRef">
  <nb-card status="basic">
    <nb-card-header>Please Confirm</nb-card-header>
    <nb-card-body>
      <p>After you click Confirm, you will no longer be able to make changes to your enrollment.</p>
    </nb-card-body>
    <nb-card-footer class="d-flex justify-content-between">
      <button nbButton status="primary" ghost (click)="ref.close(false)">Close</button>
      <button nbButton status="primary" (click)="ref.close(true)">Confirm</button>
    </nb-card-footer>
  </nb-card>
</ng-template>
