import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'split',
})
export class SplitPipe implements PipeTransform {
  public transform(value: string, { split = '_', replace = ' ' } = {}): string {
    return value.split(split).join(replace);
  }
}
