<div *ngIf="plan$ | async as plan">
  <h1 class="title my-0">
    {{ plan.attributes.organization_name }}
    <small class="caption text-hint">{{ plan.id }}</small>
  </h1>
  <dl>
    <div class="d-flex">
      <dt class="font-bold mr-1">Date began:</dt>
      <dd>{{ plan.attributes.created_at | date }}</dd>
    </div>
    <div class="d-flex">
      <dt class="font-bold mr-1">Last update:</dt>
      <dd>{{ plan.attributes.updated_at | date }}</dd>
    </div>
    <div class="d-flex">
      <dt class="font-bold mr-1">Status:</dt>
      <dd>{{ plan.attributes.status | planStatus }}</dd>
    </div>
  </dl>
  <pip-delete-plan *ngIf="showDelete$ | async" [planId]="plan.id"></pip-delete-plan>
</div>

<router-outlet></router-outlet>
