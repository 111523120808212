import * as Data from '@app/data/models';
import { IPagination } from '@app/data/models';

export class IncludeUsers {
  public static readonly type = '[Users] Include Users';
  constructor(public payload: Data.IUser[]) {}
}

export class GetUser {
  public static readonly type = '[Users] Get User';
  constructor(public payload: { userId: string }) {}
}

export class GetUserSuccess {
  public static readonly type = '[Users] Get UserSuccess';
  constructor() {}
}

export class UpdateUser {
  public static readonly type = '[Users] Update User';
  constructor(public payload: { user: Partial<Data.IUser> }) {}
}

export class UpdateUserPassword {
  public static readonly type = '[Users] Update User Password';
  constructor(public payload: { password: string; id: string }) {}
}

export class GetUsers {
  public static readonly type = '[Users] Get Users';
  constructor(public payload: { pagination?: IPagination }) {}
}

export class SetSelectedUserID {
  public static readonly type = '[Users] Set Selected User ID';
  constructor(public payload: string) {}
}
