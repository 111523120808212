<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <fieldset class="p-0">
    <legend class="subtitle">Contract Information</legend>

    <div class="form-group row">
      <label for="policy_number" class="col-3 col-form-label label">Policy number</label>
      <div class="col">
        <input type="text" nbInput fullWidth id="policy_number" formControlName="policy_number" />
      </div>
      <div class="col">
        <button nbButton status="primary" type="submit" [disabled]="form.invalid">Update policy number</button>
      </div>
    </div>
    <div class="form-group row">
      <label for="policy_number" class="col col-form-label label">Contract</label>
      <div class="col-sm-9">
        <pip-media-uploader
          class="ml-0"
          [existingMedia]="media.Contract"
          [mediaType]="mediaType.Contract"
          [modelId]="plan.id"
          [mediaModel]="mediaModel.Plan"
        ></pip-media-uploader>
      </div>
    </div>
  </fieldset>
</form>
