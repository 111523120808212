import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { DataType, IEmployee, PlansState, UpdateEmployee } from '@app/data';
import { OffWorkEmployeeFormComponent } from '@app/shared/components/forms/off-work-employee-form/off-work-employee-form.component';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Component({
  selector: 'pip-employees-off-work',
  templateUrl: './employees-off-work.component.html',
  styleUrls: ['./employees-off-work.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class EmployeesOffWorkComponent implements OnInit {
  public employees$: Observable<IEmployee[]> = this.store.select(PlansState.activePlanEmployees);
  public offwork$: Observable<IEmployee[]> = this.employees$.pipe(map(employees => employees.filter(e => e.attributes.absent)));

  public showForm = false;

  constructor(private store: Store, private actions$: Actions) {}

  public ngOnInit(): void {}

  public onEmployeeUpdate(event: IEmployee, formCmpnt: OffWorkEmployeeFormComponent): void {
    this.actions$.pipe(ofActionSuccessful(UpdateEmployee), take(1)).subscribe(() => formCmpnt.initForm());
    this.store.dispatch(new UpdateEmployee(event));
  }

  public onRemoveClaim(id: string): void {
    const payload: IEmployee = {
      id,
      type: DataType.Employees,
      attributes: { absent: false, claims: null },
    };

    this.store.dispatch(new UpdateEmployee(payload));
  }
}
