export enum Role {
  GlobalAdmin = 'global-administrator',
  Advisor = 'advisor',
  Pro = 'pro',
  Prospect = 'prospect',
  Analyst = 'analyst',
  PlanAdmin = 'plan-administrator',
  Employee = 'employee',
  Carrier = 'carrier',
}

// FIXME: Can't compile as prod if we reference keys like we do with other dictionarys
// I.e: { [Role.Advisor] = "Advisor" }
// No clue why... And for only this dictionary... Others work fine!?
export const RoleDictionary: Record<Role, string> = {
  'global-administrator': 'Global Administrator',
  advisor: 'Advisor',
  pro: 'Pro',
  prospect: 'Prospect',
  analyst: 'Analyst',
  'plan-administrator': 'Plan Administrator',
  employee: 'Employee',
  carrier: 'Carrier',
};
