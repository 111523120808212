<ng-container [formGroup]="controlContainer.control">
  <div class="form-group row">
    <label for="name" class="col col-form-label label">Billing division</label>
    <div class="col-sm-9">
      <input type="text" nbInput fullWidth id="name" formControlName="name" />
    </div>
  </div>
  <div class="row">
    <div class="col">
      <div class="row">
        <div class="col">
          <img class="mx-auto my-3" src="/assets/cheque_explain.png" alt="Example cheque" />
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col">
          <label for="transit" class="label">Transit number</label>
          <input type="text" nbInput fullWidth id="transit" formControlName="transit" />
        </div>
        <div class="form-group col">
          <label for="institution_id" class="label">Institution number</label>
          <input type="text" nbInput fullWidth id="institution_id" formControlName="institution_id" />
        </div>
        <div class="form-group col">
          <label for="account_number" class="label">Account number</label>
          <input type="text" nbInput fullWidth id="account_number" formControlName="account_number" />
        </div>
      </div>
    </div>
  </div>
</ng-container>
