import * as Data from '../../models';

export class IncludePaymentInfos {
  public static readonly type = '[Payment Infos] Include Payment Infos';
  constructor(public payload: Data.IPaymentInformation[]) {}
}

export class GetPaymentInfo {
  public static readonly type = '[Payment Infos] Get Payment Info';
  constructor(public payload: { paymentInfoId: string }) {}
}

export class CreatePaymentInfo {
  public static readonly type = '[Payment Infos] Create Payment Info';
  constructor(public payload: { paymentInfo: Data.IPaymentInformation; planId: string }) {}
}

export class CreatePaymentInfoSuccess {
  public static readonly type = '[Payment Infos] Create Payment Info Success';
  constructor(public payload: Data.IPaymentInformation) {}
}

export class UpdatePaymentInfo {
  public static readonly type = '[Payment Infos] Update Payment Info';
  constructor(public payload: Data.IPaymentInformation) {}
}

export class DeletePaymentInfo {
  public static readonly type = '[Payment Infos] Delete Payment Info';
  constructor(public payload: string) {}
}
