import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IEmployee } from '@app/data';
import { escapeRegExp } from '@app/utils/escape-reg-exp';
import { NbDialogService } from '@nebular/theme';
import { filter, take } from 'rxjs/operators';

@Component({
  selector: 'pip-employee-acknowledgement',
  templateUrl: './employee-acknowledgement.component.html',
  styleUrls: ['./employee-acknowledgement.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmployeeAcknowledgementComponent implements OnInit {
  @Input() public employee: IEmployee;
  @Output() public save = new EventEmitter<IEmployee>();

  public get reqSignature(): string {
    const { first_name, last_name } = this.employee && this.employee.attributes;
    return `${first_name} ${last_name}`;
  }

  public form: FormGroup;

  constructor(public dialog: NbDialogService) {}

  public ngOnInit(): void {
    this.initForm();
  }

  public onSubmit(employee: IEmployee, templateRef: TemplateRef<any>): void {
    const dialog = this.dialog.open(templateRef);

    dialog.onClose
      .pipe(
        take(1),
        filter((is) => is),
      )
      .subscribe(() => {
        const { id, type } = employee;
        const payload: IEmployee = {
          id,
          type,
          attributes: {
            signature: this.form.get('signature').value,
          },
        };

        this.save.emit(payload);
      });
  }

  private initForm(): void {
    this.form = new FormGroup({
      signature: new FormControl(null, [
        Validators.required,
        Validators.pattern(new RegExp(`^${escapeRegExp(this.reqSignature)}$`, 'ig')),
      ]),
      acknowledgement: new FormControl(false, [Validators.requiredTrue]),
    });

    this.form.get('acknowledgement').disable;
  }
}
