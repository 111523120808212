export enum EarningsFrequency {
  Hourly = 'Hourly',
  Weekly = 'Weekly',
  BiWeekly = 'BiWeekly',
  Monthly = 'Monthly',
  SemiMonthly = 'SemiMonthly',
  Annually = 'Annually',
}

export const EarningsFrequencyDictionary: Record<EarningsFrequency, string> = {
  Hourly: 'Hourly',
  Weekly: 'Weekly',
  BiWeekly: 'Bi Weekly',
  Monthly: 'Monthly',
  SemiMonthly: 'Semi Monthly',
  Annually: 'Annually',
};
