import { DevUsers } from './data';
import { Environment } from './models/environment';

export const environment: Environment = {
  production: false,
  local: false,
  url: {
    api: 'https://app.dev.pip.marsworks.com',
  },
  storage: {
    prefix: 'pip__',
  },
  users: DevUsers,
};
