import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { IPlan, PlansState, PlanStatus, Role, UpdatePlan, UsersState } from '@app/data';
import { Navigate } from '@ngxs/router-plugin';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { filter, map, take, withLatestFrom } from 'rxjs/operators';
import { PlansPaths } from '../../plans.paths';

@Component({
  selector: 'pip-quote-task-list',
  templateUrl: './quote-task-list.component.html',
  styleUrls: ['./quote-task-list.component.scss'],
})
export class QuoteTaskListComponent implements OnInit {
  public plan$ = this.store.select(PlansState.activePlan);
  public planMedia$ = this.store.select(PlansState.activePlanMedia);
  public isAnalyst$ = this.store.select(UsersState.isActiveUserAnAnalyst);
  public isAdvisor$ = this.store.select(UsersState.isActiveUserAnAdvisor);

  public isPlanAdmin$ = this.store
    .select(UsersState.activeUserRoles)
    .pipe(map((roles) => roles?.includes(Role.PlanAdmin)));

  public hasProposal$ = this.planMedia$.pipe(map((media) => !!media?.Proposal?.links?.self));

  public isAdvisorOrAnalyst$ = this.store.select(UsersState.isActiveUserAnAdvisorOrAnalyst);

  public isSubmittable$ = this.plan$.pipe(
    filter((plan) => !!plan),
    withLatestFrom(this.isAdvisor$),
    map(([p, isAdvisor]) => {
      const fieldStatuses = p && p.meta && p.meta.field_statuses;

      if (isAdvisor) {
        return fieldStatuses && Object.values(fieldStatuses).every((fs) => fs.complete);
      } else {
        return fieldStatuses && fieldStatuses.employees.complete && fieldStatuses.insurance_info.complete;
      }
    }),
  );

  public tasks$ = this.plan$.pipe(
    filter((plan) => !!plan),
    withLatestFrom(this.isPlanAdmin$, this.hasProposal$),
    map(([plan, isPlanAdmin, hasProposal]) => {
      const organization_info = {
        label: 'Company Information',
        routerLink: ['./', PlansPaths.CompanyInfo],
        fieldStatus: plan?.meta?.field_statuses?.organization_info,
      };

      const administrator = {
        label: 'Main Contact',
        routerLink: ['./', PlansPaths.Administration],
        fieldStatus: plan?.meta?.field_statuses?.administrator,
      };

      const insurance_info = {
        label: 'Plan Selection',
        routerLink: ['./', PlansPaths.Benefits],
        fieldStatus: plan?.meta?.field_statuses?.insurance_info,
      };

      const employees = {
        label: 'Employee Census',
        routerLink: ['./', PlansPaths.Employees],
        fieldStatus: plan?.meta?.field_statuses?.employees,
      };

      const reviewProposal = {
        label: 'Review Proposal',
        routerLink: ['./', PlansPaths.Review],
        fieldStatus: null,
      };

      return isPlanAdmin
        ? [organization_info, insurance_info, employees, ...(hasProposal ? [reviewProposal] : [])]
        : [organization_info, administrator, insurance_info, employees];
    }),
  );

  public form: FormGroup;

  constructor(private store: Store, private actions$: Actions, private route: ActivatedRoute) {}

  public ngOnInit(): void {
    this.initForm();
  }

  public onSubmit(plan: IPlan): void {
    const payload: IPlan = {
      id: plan.id,
      type: plan.type,
      attributes: { status: PlanStatus.RTQ },
    };

    this.actions$.pipe(ofActionSuccessful(UpdatePlan), take(1)).subscribe(() => this.redirectToProposal());

    this.store.dispatch(new UpdatePlan(payload));
  }

  private redirectToProposal() {
    this.store.dispatch(new Navigate(['../', PlansPaths.Proposal], undefined, { relativeTo: this.route }));
  }

  private initForm(): void {
    this.form = new FormGroup({
      acknowledgement: new FormControl(false, [Validators.requiredTrue]),
    });

    this.form.get('acknowledgement').disable;
  }
}
