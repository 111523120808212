<div class="row justify-content-center" *ngIf="plan$ | async as plan">
  <div class="col-lg-6">
    <ng-container *ngIf="!plan.attributes.pa_signed_at; else Complete">
      <h1>Enrollment To Do List</h1>
      <p>Please click on each task to complete the steps required.</p>
      <pip-tasks-table [tasks]="tasks$ | async" property="confirmed"></pip-tasks-table>
      <ng-container *ngIf="isSubmittable$ | async; else Warning">
        <nb-accordion>
          <nb-accordion-item>
            <nb-accordion-item-header>Authorization and Consent</nb-accordion-item-header>
            <nb-accordion-item-body>
              <pip-master-authorization
                [profile]="profile$ | async"
                [carrier]="carrier$ | async"
                [plan]="plan"
                (save)="onSubmit($event)"
              ></pip-master-authorization>
            </nb-accordion-item-body>
          </nb-accordion-item>
        </nb-accordion>
      </ng-container>
      <ng-template #Warning>
        <nb-alert accent="primary" class="text-center"
          >You must complete all the tasks above before you are able to complete enrollment.</nb-alert
        >
      </ng-template>
    </ng-container>
  </div>
</div>

<ng-template #Complete>
  <nb-card>
    <nb-card-header>
      Your enrollment is complete.
    </nb-card-header>
    <nb-card-body>
      <p>Thank you for completing your enrollment.</p>
      <p>You will be notified by email when setup is complete.</p>
      <p>
        If you have any questions in the meantime, please do not hesitate to
        <a href="maitlto:info@ingeniousinsurance.ca">contact us</a>.
      </p>
    </nb-card-body>
    <nb-card-footer>
      <a nbButton status="primary" [routerLink]="routerLinks.login">Close</a>
    </nb-card-footer>
  </nb-card>
</ng-template>
