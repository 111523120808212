export enum PlansPaths {
  ROOT = 'plans',
  Employees = 'employees',
  Detail = 'detail',
  Benefits = 'benefits',
  ClientDocuments = 'client-documents',
  Proposal = 'proposal',
  Standards = 'standards',
  PaymentInfo = 'payment-info',
  SecureAccess = 'secure-access',
  Wizard = 'wizard',
  Virgin = 'virgin',
  Rates = 'rates',
  Administration = 'administration',
  Tasks = 'tasks',
  NewQuote = 'new-quote',
  CompanyInfo = 'company-info',
  Census = 'census',
  PATasks = 'pa-tasks',
  StartDate = 'start-date',
  Documents = 'documents',
  Review = 'review-proposal',
}
