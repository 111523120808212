import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { INote, Response } from '../models';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class NotesService {
  constructor(private api: ApiService) {}

  public getNotes(organizationId: string): Observable<Response<INote[]>> {
    const include = ['organizations', 'users'];
    const params = { include: include.join() };

    return this.api.get(['organizations', organizationId, 'relationships', 'notes'], { params });
  }

  public createNote(organizationId: string, note: INote): Observable<Response<INote>> {
    const include = ['organizations', 'users'];
    const params = { include: include.join() };

    return this.api.post(['organizations', organizationId, 'relationships', 'notes'], note, { params });
  }

  public getNote(noteId: string): Observable<Response<INote>> {
    const include = ['organizations', 'users'];
    const params = { include: include.join() };

    return this.api.get(['notes', noteId], { params });
  }

  public updateNote(note: INote): Observable<Response<INote>> {
    const include = ['organizations', 'users'];
    const params = { include: include.join() };

    return this.api.patch(['notes', note.id], note, { params });
  }

  public deleteNote(noteId: string): Observable<Response<INote>> {
    const include = ['organizations', 'users'];
    const params = { include: include.join() };

    return this.api.delete(['notes', noteId], { params });
  }
}
