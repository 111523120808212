import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IPlan, Plan, PlanStandards } from '@app/data';
import { PayCycle } from '@app/data/enums/pay-cycle.enum';

@Component({
  selector: 'pip-quote-standards-form',
  templateUrl: './quote-standards-form.component.html',
  styleUrls: ['./quote-standards-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuoteStandardsFormComponent implements OnInit, OnChanges {
  @Input() public plan: IPlan;
  @Input() public loading: boolean;
  @Input() public locked: boolean;
  @Input() public confirmButton = 'Save';
  @Input() public cancelButton = 'Cancel';
  @Input() public showStartAt = true;

  @Output() public cancel = new EventEmitter<void>();
  @Output() public save = new EventEmitter<IPlan>();

  public form: FormGroup;
  public payCycle = PayCycle;

  constructor() {}

  public ngOnInit(): void {
    this.initForm();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.initForm();
  }

  public onSubmit(): void {
    const { id, type } = this.plan;
    const { standards, start_at } = this.form.value;

    const payload: IPlan = {
      id,
      type,
      attributes: {
        standards,
        ...(this.showStartAt && { start_at }),
      },
    };

    this.save.emit(payload);
  }

  private initForm(): void {
    const { start_at, standards } = this.plan.attributes || Plan.createAttributes();

    const {
      allocations,
      employees_minimum,
      employer_contribution,
      overtime_earnings,
      probation_months,
      waiting_period,
      work_week_hours,
      std_employee_paid,
      ltd_employee_paid,
      pay_cycle,
      workers_compensation,
      benefits_continuation,
    } = standards || PlanStandards.create();

    this.form = new FormGroup({
      start_at: new FormControl(start_at, [Validators.required]),
      standards: new FormGroup({
        employees_minimum: new FormControl(employees_minimum),
        allocations: new FormControl(allocations, []),
        employer_contribution: new FormGroup({
          life: new FormControl(employer_contribution.life, []),
          add: new FormControl(employer_contribution.add, []),
          dependentLife: new FormControl(employer_contribution.dependentLife, []),
          criticalIllness: new FormControl(employer_contribution.criticalIllness, []),
          ltd: new FormControl(employer_contribution.ltd, []),
          std: new FormControl(employer_contribution.std, []),
          healthcare: new FormControl(employer_contribution.healthcare, []),
          dentalcare: new FormControl(employer_contribution.dentalcare, []),
          virtualCare: new FormControl(employer_contribution.virtualCare, []),
        }),
        overtime_earnings: new FormControl(overtime_earnings),
        probation_months: new FormControl(probation_months),
        waiting_period: new FormControl(waiting_period),
        work_week_hours: new FormControl(work_week_hours),
        std_employee_paid: new FormControl(std_employee_paid),
        ltd_employee_paid: new FormControl(ltd_employee_paid),
        pay_cycle: new FormControl(pay_cycle || PayCycle.BiWeekly),
        workers_compensation: new FormControl(workers_compensation),
        benefits_continuation: new FormControl(benefits_continuation),
      }),
    });

    if (this.locked) {
      this.form.disable();
    }
  }
}
