import { ChangeDetectionStrategy, Component, Input, OnInit, TemplateRef } from '@angular/core';
import { IPlan } from '@app/data';
import { InvitationsService } from '@app/data/services';
import { NbDialogService, NbToastrService } from '@nebular/theme';

import { filter, take } from 'rxjs/operators';

@Component({
  selector: 'pip-invite-plan-admin',
  templateUrl: './invite-plan-admin.component.html',
  styleUrls: ['./invite-plan-admin.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvitePlanAdminComponent implements OnInit {
  @Input() plan: IPlan;
  @Input() label = 'Invite plan administrator';

  constructor(private invitations: InvitationsService, private dialog: NbDialogService, private toastr: NbToastrService) {}

  public ngOnInit(): void {}

  public onInvite(templateRef: TemplateRef<any>): void {
    this.dialog
      .open(templateRef)
      .onClose.pipe(
        take(1),
        filter(c => c),
      )
      .subscribe(() => {
        this.invitations
          .invitePlanAdministrator(this.plan.id)
          .toPromise()
          .then(() => {
            this.toastr.success('Successfully invited plan administrator', 'Success');
          });
      });
  }
}
