<div class="d-flex justify-content-between align-items-center">
  <nb-checkbox [(ngModel)]="onlyMine" (change)="onlyMineChange.emit(onlyMine)">Show only my plans</nb-checkbox>

  <nb-select
    multiple
    class="flex-shrink-0 flex-grow-1"
    [(ngModel)]="statuses"
    (selectedChange)="statusChange.emit(statuses)"
  >
    <nb-option *ngFor="let status of statusList" [value]="status">{{ status | planStatus }}</nb-option>
  </nb-select>

  <form (ngSubmit)="searchChange.emit(search)">
    <input type="text" nbInput [(ngModel)]="search" name="search" />
    <button class="ml-2" nbButton status="primary" type="submit">
      <nb-icon icon="search-outline"></nb-icon>
    </button>
  </form>
</div>
