import { HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { interceptors } from './interceptors';
import { states } from './store';

@NgModule({
  declarations: [],
  imports: [
    HttpClientModule,
    HttpClientXsrfModule.withOptions({
      cookieName: 'XSRF-TOKEN',
      headerName: 'X-XSRF-TOKEN',
    }),
    NgxsModule.forFeature([...states]),
  ],
  providers: [...interceptors],
})
export class DataModule {}
