<form [formGroup]="form" (submit)="onSubmit()">
  <nb-alert status="warning" *ngIf="isPendingTermination"> This employee is pending termination. </nb-alert>
  <nb-alert status="warning" *ngIf="isTerminatedConfirmed">
    This employee will be terminated on {{ employee.attributes.terminated_at | date: 'longDate' }}.
  </nb-alert>
  <nb-alert status="danger" *ngIf="isTerminated"> This employee is terminated.</nb-alert>
  <nb-alert
    style="position: sticky; top: 30px; right: 30px; z-index: 10"
    status="danger"
    *ngIf="form && form.touched && form.dirty"
  >
    You have unsaved changes
  </nb-alert>
  <nb-alert status="success" *ngIf="signedAt && !isAnalyst">
    <div>{{ employeeName }} completed their profile and signed off on {{ signedAt | date }}</div>
    <div>
      For any further changes needed for this Employee, please email
      <a href="mailto:info@ingeniousinsurance.ca">info@ingeniousinsurance.ca</a>.
    </div>
  </nb-alert>
  <nb-card status="basic">
    <nb-card-header>
      <span>{{ employeeName }}</span>
    </nb-card-header>
    <nb-accordion>
      <nb-accordion-item [expanded]="true">
        <nb-accordion-item-header>
          <pip-status-indicator
            [isGood]="employee?.meta?.field_statuses?.personal_info?.complete"
          ></pip-status-indicator>
          Personal Info
        </nb-accordion-item-header>
        <nb-accordion-item-body>
          <pip-field-status-alert
            [fieldStatus]="employee?.meta?.field_statuses?.personal_info"
          ></pip-field-status-alert>
          <pip-employee-detail-formgroup formGroupName="personal_info"></pip-employee-detail-formgroup>
        </nb-accordion-item-body>
      </nb-accordion-item>
      <nb-accordion-item>
        <nb-accordion-item-header>
          <pip-status-indicator
            [isGood]="employee?.meta?.field_statuses?.occupation_and_earnings?.complete"
          ></pip-status-indicator>
          Occupation and Earnings
        </nb-accordion-item-header>
        <nb-accordion-item-body>
          <pip-field-status-alert
            [fieldStatus]="employee?.meta?.field_statuses?.occupation_and_earnings"
          ></pip-field-status-alert>
          <pip-employee-occupation-formgroup
            formGroupName="occupation_and_earnings"
            [rates]="rates"
            [billingDivisions]="billingDivisions"
          ></pip-employee-occupation-formgroup>
        </nb-accordion-item-body>
      </nb-accordion-item>
      <nb-accordion-item>
        <nb-accordion-item-header>
          <pip-status-indicator
            [isGood]="employee?.meta?.field_statuses?.coverage_info?.complete"
          ></pip-status-indicator>
          Coverage
        </nb-accordion-item-header>
        <nb-accordion-item-body>
          <pip-field-status-alert
            [fieldStatus]="employee?.meta?.field_statuses?.coverage_info"
          ></pip-field-status-alert>
          <pip-employee-coverage-formgroup
            formGroupName="coverage"
            [disabled]="form.disabled"
            [showRAMQ]="showRAMQ"
            [maritalStatus]="maritalStatus"
          ></pip-employee-coverage-formgroup>
          <fieldset *ngIf="showOverrides" formGroupName="overrides">
            <legend class="subtitle">Employee overrides</legend>
            <div class="form-row">
              <div class="form-group col">
                <label for="life_nem_override" class="label">Life</label>
                <input type="number" nbInput fullWidth id="life_nem_override" formControlName="life_nem_override" />
              </div>
              <div class="form-group col">
                <label for="critical_illness_volume_override" class="label">Critical Illness</label>
                <input
                  type="number"
                  nbInput
                  fullWidth
                  id="critical_illness_volume_override"
                  formControlName="critical_illness_volume_override"
                />
              </div>
              <div class="form-group col">
                <label for="ltd_nem_override" class="label">LTD</label>
                <input type="number" nbInput fullWidth id="ltd_nem_override" formControlName="ltd_nem_override" />
              </div>
              <div class="form-group col">
                <label for="std_nem_override" class="label">STD</label>
                <input type="number" nbInput fullWidth id="std_nem_override" formControlName="std_nem_override" />
              </div>
            </div>
          </fieldset>
          <fieldset>
            <div class="form-row" *ngIf="isAdvisorOrAnalyst" formGroupName="coverage">
              <div class="form-group col">
                <label for="optional_benefits" class="label">Optional Benefits</label>
                <input
                  type="text"
                  #test
                  nbInput
                  fullWidth
                  id="optional_benefits"
                  formControlName="optional_benefits"
                  mask="separator"
                  prefix="$"
                />
              </div>
            </div>
          </fieldset>
        </nb-accordion-item-body>
      </nb-accordion-item>
      <nb-accordion-item>
        <nb-accordion-item-header>
          <pip-status-indicator [isGood]="employee?.meta?.field_statuses?.dependents?.complete"></pip-status-indicator>
          Dependents
        </nb-accordion-item-header>
        <nb-accordion-item-body formArrayName="dependents">
          <div class="card p-3 mb-3" *ngFor="let dependent of dependents.controls; let idx = index">
            <pip-employee-dependent-formgroup [formGroupName]="idx"></pip-employee-dependent-formgroup>
            <div class="d-flex justify-content-end">
              <button type="button" nbButton outline status="danger" size="tiny" (click)="dependents.removeAt(idx)">
                Remove dependent
              </button>
            </div>
          </div>
          <button type="button" nbButton status="primary" outline (click)="addDependent()">Add dependent</button>
        </nb-accordion-item-body>
      </nb-accordion-item>
      <nb-accordion-item>
        <nb-accordion-item-header>
          <pip-status-indicator
            [isGood]="employee?.meta?.field_statuses?.beneficiaries?.complete"
          ></pip-status-indicator>
          Beneficiaries
        </nb-accordion-item-header>
        <nb-accordion-item-body>
          <div class="form-group col">
            <label for="revocable" class="label">Revocable</label>
            <nb-radio-group class="d-flex" fullWidth name="revocable" formControlName="revocable">
              <nb-radio [disabled]="form.disabled" [value]="false">No</nb-radio>
              <nb-radio [disabled]="form.disabled" [value]="true">Yes</nb-radio>
            </nb-radio-group>
          </div>
          <ng-container formArrayName="beneficiaries">
            <div class="card p-3 mb-3" *ngFor="let beneficiary of beneficiaries.controls; let idx = index">
              <pip-employee-beneficiary-formgroup [formGroupName]="idx"></pip-employee-beneficiary-formgroup>
              <div class="d-flex justify-content-end">
                <button
                  type="button"
                  nbButton
                  outline
                  status="danger"
                  size="tiny"
                  (click)="beneficiaries.removeAt(idx)"
                >
                  Remove beneficiary
                </button>
              </div>
            </div>
          </ng-container>
          <button type="button" nbButton status="primary" outline (click)="addBeneficiary(null, false)">
            Add beneficiary
          </button>
          <hr />
          <ng-container formArrayName="contingent_beneficiaries">
            <div class="card p-3 mb-3" *ngFor="let beneficiary of contingentBeneficiaries.controls; let idx = index">
              <pip-employee-beneficiary-formgroup [formGroupName]="idx"></pip-employee-beneficiary-formgroup>
              <div class="d-flex justify-content-end">
                <button
                  type="button"
                  nbButton
                  outline
                  status="danger"
                  size="tiny"
                  (click)="contingentBeneficiaries.removeAt(idx)"
                >
                  Remove contingent beneficiary
                </button>
              </div>
            </div>
          </ng-container>
          <button type="button" nbButton status="primary" outline (click)="addBeneficiary(null, true)">
            Add contingent beneficiary
          </button>
        </nb-accordion-item-body>
      </nb-accordion-item>
      <nb-accordion-item>
        <nb-accordion-item-header>
          <pip-status-indicator
            [isGood]="employee?.meta?.field_statuses?.direct_deposit?.complete"
          ></pip-status-indicator>
          Claims Direct Deposit Information
        </nb-accordion-item-header>
        <nb-accordion-item-body>
          <pip-employee-payment-info-formgroup
            formGroupName="payment_information"
          ></pip-employee-payment-info-formgroup>
        </nb-accordion-item-body>
      </nb-accordion-item>
      <nb-accordion-item>
        <nb-accordion-item-header>
          <pip-status-indicator
            [isGood]="employee?.meta?.field_statuses?.signoffs_legal?.complete"
          ></pip-status-indicator>
          Signoffs/Legal
        </nb-accordion-item-header>
        <nb-accordion-item-body>
          <div class="row my-3">
            <div class="col-xl-8">
              <ng-container *ngIf="!media[mediaType.EmployeeApplication]; else Download">
                <p>
                  This form is only required if the employee does not have email. If there is an email address, add it
                  in the Personal Information section, then click on Enroll to invite the employee to complete their own
                  enrollment online.
                </p>
                <p>
                  If the employee does not have email, first complete their Occupation and Earnings section, then
                  download this Application Form and have the employee fill it out. Upload the
                  <strong>signed form</strong> here to complete this employee's enrollment.
                </p>
                <pip-media-uploader
                  class="mb-2"
                  [downloadTemplate]="employee.links.download"
                  [existingMedia]="media[mediaType.EmployeeApplication]"
                  [mediaType]="mediaType.EmployeeApplication"
                  [modelId]="employee.id"
                  [mediaModel]="mediaModel.Employee"
                ></pip-media-uploader>
              </ng-container>
              <ng-template #Download>
                <a [href]="media[mediaType.EmployeeApplication]?.links?.self">View enrollment application form</a>
              </ng-template>
            </div>
          </div>

          <ng-container *ngIf="employee.attributes?.salary_threshold && carrierMediaLinks?.EvidenceOfInsurability">
            <hr />
            <div class="row my-3">
              <div class="col-xl-8">
                <p>
                  This employee’s earnings are higher than the non-medical limits. While completion of this form is
                  <b>not</b> mandatory, it is highly recommended. Please share this form with the employee, encourage
                  them to complete, and return to your attention. You can then upload a signed copy here.
                </p>
              </div>
            </div>
            <pip-media-uploader
              class="mb-2"
              [downloadTemplate]="carrierMediaLinks.EvidenceOfInsurability"
              [existingMedia]="media[mediaType.EvidenceOfInsurability]"
              [mediaType]="mediaType.EvidenceOfInsurability"
              [modelId]="employee.id"
              [mediaModel]="mediaModel.Employee"
            ></pip-media-uploader>
          </ng-container>

          <div class="" *ngIf="showTrusteeAppointment">
            <nb-alert accent="primary">
              <div class="row">
                <div class="col-6">
                  <h2 class="h4">Trustee appointment</h2>
                  <p>
                    You have specified that you have a beneficiary under the age of 18. It is recommended you provide a
                    trustee appointment form.
                  </p>
                </div>
                <pip-media-uploader
                  class="col"
                  [downloadTemplate]="carrierMediaLinks.TrusteeAppointment"
                  [existingMedia]="media[mediaType.TrusteeAppointment]"
                  [mediaType]="mediaType.TrusteeAppointment"
                  [modelId]="employee.id"
                  [mediaModel]="mediaModel.Employee"
                ></pip-media-uploader>
              </div>
            </nb-alert>
          </div>

          <ng-container *ngIf="isEmployeeCurrentUser && !employee.attributes.signed_at">
            <hr />
            <pip-employee-acknowledgement
              [employee]="employee"
              (save)="onSignature($event)"
            ></pip-employee-acknowledgement>
          </ng-container>
        </nb-accordion-item-body>
      </nb-accordion-item>
      <nb-accordion-item>
        <nb-accordion-item-header>
          <pip-status-indicator></pip-status-indicator>
          Deductions/Contributions
        </nb-accordion-item-header>
        <nb-accordion-item-body>
          <div class="row">
            <div class="col">
              <pip-cost-summary
                [payCycle]="employee?.meta?.benefits?.pay_cycle"
                [employeeDeductions]="employee?.meta?.benefits?.contributions.employee"
                [employerContributions]="employee?.meta?.benefits?.contributions.employer"
              ></pip-cost-summary>
            </div>

            <div class="col">
              <table class="table-bordered table-striped table" *ngIf="employee?.meta?.benefits?.benefit_costs?.length">
                <thead>
                  <tr>
                    <th>Benefit</th>
                    <th class="">Employee&nbsp;Payroll&nbsp;Deductions ({{ employee.meta.benefits.pay_cycle }})</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let cost of employee?.meta?.benefits?.benefit_costs">
                    <th>{{ cost.benefit }}</th>
                    <td>{{ cost.amount }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </nb-accordion-item-body>
      </nb-accordion-item>
    </nb-accordion>
    <nb-card-footer class="d-flex justify-content-between">
      <a [routerLink]="['..']" nbButton status="primary" ghost type="button">Cancel</a>
      <button
        *ngIf="showDeleteEmployee"
        type="button"
        nbButton
        ghost
        size="small"
        status="danger"
        (click)="deleteEmployee.emit(employee)"
      >
        <nb-icon icon="trash-2-outline"></nb-icon>
        Delete {{ employee.attributes.first_name }} {{ employee.attributes.last_name }}
      </button>
      <button
        *ngIf="showRequestToTerminate"
        type="button"
        nbButton
        ghost
        size="small"
        status="danger"
        (click)="terminateEmployee.emit(employee)"
      >
        <nb-icon icon="person-a-outline"></nb-icon>
        Request to terminate
      </button>
      <button
        *ngIf="showReinstate"
        type="button"
        nbButton
        ghost
        size="small"
        status="success"
        (click)="reinstateEmployee.emit(employee)"
      >
        <nb-icon icon="person-done-outline"></nb-icon>
        Reinstate
      </button>
      <button
        *ngIf="showConfirmTermination"
        type="button"
        nbButton
        ghost
        size="small"
        status="danger"
        (click)="confirmTermination.emit(employee)"
      >
        <nb-icon icon="person-delete-outline"></nb-icon>
        Confirm termination
      </button>
      <button type="submit" nbButton status="primary">Save</button>
    </nb-card-footer>
  </nb-card>
</form>
