<h1>New Quote</h1>
<div class="row justify-content-center">
  <div class="col-lg-6">
    <ng-container *ngIf="userRoles$ | async as userRoles">
      <pip-create-new-plan
        [quoteType]="'newQuote'"
        [activeUser]="activeUser$ | async"
        [isAnalyst]="isAnalyst$ | async"
        [isGroupRep]="isGroupRep$ | async"
        (save)="onCreatePlan($event)"
      ></pip-create-new-plan>
    </ng-container>
  </div>
</div>
