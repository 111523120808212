export enum Industry {
  Accommodation = 'Accommodation',
  Administrative = 'Administrative',
  Agriculture = 'Agriculture',
  Arts = 'Arts',
  Construction = 'Construction',
  Educational = 'Educational',
  Finance = 'Finance',
  Healthcare = 'Healthcare',
  Information = 'Information',
  Management = 'Management',
  Manufacturing = 'Manufacturing',
  Mining = 'Mining',
  OtherServices = 'OtherServices',
  Professional = 'Professional',
  PublicAdministration = 'PublicAdministration',
  RealEstate = 'RealEstate',
  Retail = 'Retail',
  Transportation = 'Transportation',
  Utilities = 'Utilities',
  Wholesale = 'Wholesale',
}

export const IndustryDictionary: Record<Industry, string> = {
  [Industry.Accommodation]: 'Accommodation and food services',
  [Industry.Administrative]: 'Administrative and support, waste management and remediation services',
  [Industry.Agriculture]: 'Agriculture, forestry, fishing and hunting',
  [Industry.Arts]: 'Arts, entertainment and recreation',
  [Industry.Construction]: 'Construction',
  [Industry.Educational]: 'Educational services',
  [Industry.Finance]: 'Finance and insurance',
  [Industry.Healthcare]: 'Health care and social assistance',
  [Industry.Information]: 'Information and cultural industries',
  [Industry.Management]: 'Management of companies and enterprises',
  [Industry.Manufacturing]: 'Manufacturing',
  [Industry.Mining]: 'Mining, quarrying, and oil and gas extraction',
  [Industry.OtherServices]: 'Other services (except public administration)',
  [Industry.Professional]: 'Professional, scientific and technical services',
  [Industry.PublicAdministration]: 'Public administration',
  [Industry.RealEstate]: 'Real estate and rental and leasing',
  [Industry.Retail]: 'Retail trade',
  [Industry.Transportation]: 'Transportation and warehousing',
  [Industry.Utilities]: 'Utilities',
  [Industry.Wholesale]: 'Wholesale trade',
};
