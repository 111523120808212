<div class="row justify-content-center">
  <div class="col-xl-9">
    <h2>Plan Details</h2>
    <pip-field-status-alert [fieldStatus]="insuranceInfoFs$ | async"></pip-field-status-alert>
    <pip-insurance-status-form
      *ngIf="plan$ | async as plan"
      [plan]="plan"
      [defaultPlans]="defaultPlans$ | async"
      [loading]="loading$ | async"
      [planMedia]="planMedia$ | async"
    ></pip-insurance-status-form>
  </div>
</div>
