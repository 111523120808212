<div *ngIf="form" [formGroup]="form">
  <div class="d-flex">
    <nb-select class="mr-2 flex-grow-1" id="start_year" formControlName="start_year" (selectedChange)="clearSubDates()">
      <nb-option *ngFor="let year of selectableYears" [value]="year">{{ year }}</nb-option>
    </nb-select>

    <nb-select
      class="mr-2 flex-grow-1"
      id="start_month"
      formControlName="start_month"
      (selectedChange)="form.get('start_day').setValue(null)"
    >
      <nb-option [disabled]="!isValidDate(form.get('start_year').value, 0, 15)" [value]="0">January</nb-option>
      <nb-option [disabled]="!isValidDate(form.get('start_year').value, 1, 15)" [value]="1">February</nb-option>
      <nb-option [disabled]="!isValidDate(form.get('start_year').value, 2, 15)" [value]="2">March</nb-option>
      <nb-option [disabled]="!isValidDate(form.get('start_year').value, 3, 15)" [value]="3">April</nb-option>
      <nb-option [disabled]="!isValidDate(form.get('start_year').value, 4, 15)" [value]="4">May</nb-option>
      <nb-option [disabled]="!isValidDate(form.get('start_year').value, 5, 15)" [value]="5">June</nb-option>
      <nb-option [disabled]="!isValidDate(form.get('start_year').value, 6, 15)" [value]="6">July</nb-option>
      <nb-option [disabled]="!isValidDate(form.get('start_year').value, 7, 15)" [value]="7">August</nb-option>
      <nb-option [disabled]="!isValidDate(form.get('start_year').value, 8, 15)" [value]="8">September</nb-option>
      <nb-option [disabled]="!isValidDate(form.get('start_year').value, 9, 15)" [value]="9">October</nb-option>
      <nb-option [disabled]="!isValidDate(form.get('start_year').value, 10, 15)" [value]="10">November</nb-option>
      <nb-option [disabled]="!isValidDate(form.get('start_year').value, 11, 15)" [value]="11">December</nb-option>
    </nb-select>
    <nb-select class="flex-grow-1" id="start_day" formControlName="start_day">
      <nb-option [value]="1" [disabled]="!isValidDate(form.get('start_year').value, form.get('start_month').value, 1)"
        >1st</nb-option
      >
      <nb-option [value]="15" [disabled]="!isValidDate(form.get('start_year').value, form.get('start_month').value, 15)"
        >15th</nb-option
      >
    </nb-select>
  </div>
</div>
