import { DataType } from '../enums';
import { Attributes, ResourceObject } from './json-api';
import { IPaymentInformationMedia } from './payment-information-media';

export interface IBasePaymentInformation {
  institution_id: string;
  transit: string;
  account_number: string;
}

export interface IPaymentInformation extends ResourceObject {
  type: DataType.PaymentInformation;
  attributes: IPaymentInformationAttributes;
  media?: IPaymentInformationMedia;
}

export interface IPaymentInformationAttributes extends Attributes, IBasePaymentInformation {
  name: string;
}

export class PaymentInformation {
  public static create(): IPaymentInformation {
    return {
      id: null,
      type: DataType.PaymentInformation,
      attributes: PaymentInformation.createAttributes(),
    };
  }

  public static createAttributes(): IPaymentInformationAttributes {
    return {
      name: '',
      account_number: '',
      institution_id: '',
      transit: '',
    };
  }
}
