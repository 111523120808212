import { IRole } from '@app/data/models';

export class GetRoles {
  public static readonly type = '[Roles] Get Roles';
  constructor() {}
}

export class IncludeRoles {
  public static readonly type = '[Roles] Include Roles';
  constructor(public payload: IRole[]) {}
}
