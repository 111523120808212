import { ChangeDetectionStrategy, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DashboardPaths } from '@app/core/dashboard';
import { GetPlan, IPlan, PlanStatus, PlansState, Role, UpdatePlan, UsersState } from '@app/data';
import { escapeRegExp } from '@app/utils/escape-reg-exp';
import { NbDialogService, NbStepperComponent } from '@nebular/theme';
import { Navigate, RouterState } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { filter, map, take, tap, withLatestFrom } from 'rxjs/operators';
import { PlansPaths } from '../../plans.paths';

@Component({
  selector: 'pip-plan',
  templateUrl: './plan.component.html',
  styleUrls: ['./plan.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanComponent implements OnInit {
  public plan$: Observable<IPlan> = this.store.select(PlansState.activePlan);

  public userRoles$: Observable<Role[]> = this.store.select(UsersState.activeUserRoles).pipe(tap(console.log));

  public planStatus = PlanStatus;
  public plansPaths = PlansPaths;

  public statusProgress = [
    PlanStatus.InProgress,
    PlanStatus.RTQ,
    PlanStatus.Proposal,
    PlanStatus.EnrollIngenious,
    PlanStatus.EnrollClient,
    PlanStatus.Signoff,
    PlanStatus.Setup,
    PlanStatus.Pending,
  ];

  public isSubmittable$ = this.plan$.pipe(
    withLatestFrom(this.userRoles$),
    map(([plan, roles]) => {
      if (!roles || !plan) {
        return;
      }

      if (roles.includes(Role.Analyst)) {
        if (plan.attributes.status === PlanStatus.InProgress) {
          if (Object.values(plan.meta.field_statuses).some((fs) => fs.complete === false)) {
            // Analyst + In Progress + Not All Green
            return false;
          }
        }
      }

      return true;
    }),
  );

  public showSubmitButton$ = this.plan$.pipe(
    withLatestFrom(this.userRoles$),
    map(([plan, roles]) => {
      if (!roles || !plan) {
        return;
      }

      // Advisors specific
      if (roles?.includes(Role.Advisor) && !roles?.includes(Role.Analyst)) {
        switch (plan.attributes.status) {
          case PlanStatus.RTQ:
            return false;
          case PlanStatus.EnrollIngenious:
            return false;
          case PlanStatus.Setup:
            return false;
        }
      }

      // Analyst specific
      // if (roles.includes(Role.Analyst) && !roles.includes(Role.Advisor)) {
      //   switch (plan.attributes.status) {
      //     case PlanStatus.Signoff:
      //       return false;
      //   }
      // }

      // Everyone
      switch (plan.attributes.status) {
        case PlanStatus.Proposal:
          return false;
        // case PlanStatus.EnrollClient:
        //   return false;
      }

      return true;
    }),
  );

  public activeStatusIdx$ = this.plan$.pipe(
    filter((plan) => !!plan),
    map((plan) => {
      const idx = this.statusProgress.indexOf(plan.attributes.status as PlanStatus);
      // HACK: Have to reset the stepper because it marks "viewed" steps as complete... even when setting the [complete]=false attr...
      if (this.stepper) {
        this.stepper.reset();
        this.stepper.selectedIndex = idx;
      }

      return idx;
    }),
  );

  @ViewChild(NbStepperComponent) private stepper: NbStepperComponent;

  constructor(private store: Store, private dialog: NbDialogService) {}

  public ngOnInit(): void {
    const planId = this.store.selectSnapshot(RouterState).state.params.planId;
    this.store.dispatch(new GetPlan({ planId }));
  }

  public updateStatus(statusRef: number | PlanStatus, { id, type }: IPlan): void {
    let status: PlanStatus;
    if (typeof statusRef === 'number') {
      status = this.statusProgress[statusRef];
    } else {
      status = statusRef;
    }

    const payload: IPlan = { id, type, attributes: { status } };

    this.store
      .dispatch(new UpdatePlan(payload))
      .pipe(take(1))
      .subscribe((_) => {
        const enrollClientIdx = this.statusProgress.indexOf(PlanStatus.EnrollClient);

        if (statusRef === enrollClientIdx) {
          this.store.dispatch(new Navigate(['/', DashboardPaths.ROOT]));
        }
      });
  }

  public cancelPlanDialog(templateRef: TemplateRef<any>, plan: IPlan, status: PlanStatus): void {
    const dialog = this.dialog.open(templateRef);

    dialog.onClose
      .pipe(
        take(1),
        filter((is) => is),
      )
      .subscribe(() => this.updateStatus(status, plan));
  }

  public advisorSignoffDialog(templateRef: TemplateRef<any>, plan: IPlan): void {
    const user = this.store.selectSnapshot(UsersState.activeUser);
    const pattern = escapeRegExp(`${user.attributes.first_name} ${user.attributes.last_name}`);

    const form = new FormGroup({
      advisor_signature: new FormControl(null, [
        Validators.required,
        Validators.pattern(new RegExp(`^${pattern}$`, 'i')),
      ]),
    });

    const dialog = this.dialog.open(templateRef, { context: { form, pattern } });

    dialog.onClose
      .pipe(
        take(1),
        filter((is) => is),
      )
      .subscribe(() => {
        const payload: IPlan = {
          id: plan.id,
          type: plan.type,
          attributes: { ...form.value },
        };

        this.store.dispatch(new UpdatePlan(payload));
      });
  }

  public scrollTo(ref: HTMLElement): void {
    ref && ref.scrollIntoView({ behavior: 'smooth' });
  }
}
