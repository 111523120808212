import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  CanadianProvince,
  CompanyInfo,
  Country,
  DataType,
  IOrganization,
  IPlan,
  IPlanRelationships,
  IUser,
  Plan,
  PlanStatus,
} from '@app/data';
import { Store } from '@ngxs/store';
import { Language } from '@app/data/enums/language.enum';

@Component({
  selector: 'pip-organization-info-form',
  templateUrl: './organization-info-form.component.html',
  styleUrls: ['./organization-info-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrganizationInfoFormComponent implements OnInit, OnChanges {
  @Input() public plan: IPlan;
  @Input() public quoteType: 'newQuote' | 'newClient' | 'existing' = 'existing';
  @Input() public confirmButton: string = 'Save';
  @Input() public cancelButton: string = 'Cancel';
  @Input() public activeUser: IUser = null;
  @Input() public isAnalyst: boolean = false;
  @Input() public isGroupRep: boolean = false;
  @Input() public existingOrganization: Partial<IOrganization>;

  @Output() public save = new EventEmitter<IPlan>();
  @Output() public cancel = new EventEmitter<void>();

  public form: FormGroup;
  public planRep: IUser = null;
  public addAssociateSelector = false;

  constructor(public store: Store) {}

  public ngOnInit(): void {
    this.initForm();
    this.addAssociateSelector = this.quoteType === 'newQuote' || this.quoteType === 'newClient' ? true : false;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.planRep = this.isGroupRep ? this.activeUser : null;
  }

  public onSubmit(): void {
    const { id, type, attributes } = this.plan || Plan.create();

    const { companyInfo, businessAddress } = this.form.value;
    const { organization_name, industry, website, language } = companyInfo;
    const { address1, address2, city, state, country, postal_code } = businessAddress;

    let virgin: boolean;
    let status: PlanStatus;
    let relationships: Partial<IPlanRelationships> = {};

    virgin = attributes.virgin;

    if (this.quoteType === 'newClient') {
      status = PlanStatus.Confirmed;
    }

    if (this.existingOrganization?.id) {
      relationships = {
        ...relationships,
        organizations: {
          data: {
            type: DataType.Organizations,
            id: this.existingOrganization.id,
          },
        },
      };
    }

    if (this.planRep) {
      relationships = {
        ...relationships,
        rep: {
          data: {
            type: DataType.Users,
            id: this.planRep.id,
          },
        },
      };
    }

    const plan: IPlan = {
      id,
      type,
      attributes: {
        organization_name,
        virgin,
        ...(status && { status }),
        organization_info: {
          address1,
          address2,
          city,
          country,
          industry,
          postal_code,
          state,
          website,
          language,
        },
      },
      ...(Object.keys(relationships).length && { relationships }),
    };

    this.save.emit(plan);
  }

  public onUserSelected(user: IUser) {
    this.planRep = user;
  }

  public isValid(): boolean {
    let res = false;

    if (this.form.valid) {
      res = true;

      if (this.addAssociateSelector) {
        res = this.planRep ? true : false;
      }
    }

    return res;
  }

  private initForm(): void {
    const { attributes } = this.plan || Plan.create();
    const { organization_name, organization_info } = attributes;

    const { address1, website, state, address2, postal_code, industry, country, city, language } =
      organization_info || CompanyInfo.create();

    this.form = new FormGroup({
      companyInfo: new FormGroup({
        organization_name: new FormControl(this.existingOrganization?.attributes.name || organization_name, [
          Validators.required,
        ]),
        industry: new FormControl(this.existingOrganization?.attributes.industry || industry, [Validators.required]),
        website: new FormControl(this.existingOrganization?.attributes.website || website),
        language: new FormControl(this.existingOrganization?.attributes?.language || language || Language.English),
      }),
      businessAddress: new FormGroup({
        address1: new FormControl(this.existingOrganization?.attributes.address1 || address1, [Validators.required]),
        address2: new FormControl(this.existingOrganization?.attributes.address2 || address2),
        city: new FormControl(this.existingOrganization?.attributes.city || city, [Validators.required]),
        state: new FormControl(this.existingOrganization?.attributes.state || state || CanadianProvince.Ontario, [
          Validators.required,
        ]),
        country: new FormControl(this.existingOrganization?.attributes.country || country || Country.Canada, [
          Validators.required,
        ]),
        postal_code: new FormControl(this.existingOrganization?.attributes.postal_code || postal_code, [
          Validators.required,
        ]),
      }),
    });
  }
}
