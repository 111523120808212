import { StorageEngine as NgxsStorageEngine } from '@ngxs/storage-plugin';
import { environment } from '../../../environments/environment';
import { Injectable } from "@angular/core";

@Injectable()
export class StorageEngine implements NgxsStorageEngine {
  private prefix: string = environment.storage.prefix;

  /**
   * Return the number of items in localStorage that are relevant (prefixed)
   */
  public get length(): number {
    return this.relevantKeys.length;
  }

  private get relevantKeys(): string[] {
    return Object.keys(localStorage).filter(key => key.startsWith(this.prefix));
  }

  public getItem(key: string): any {
    return localStorage.getItem(this.prefix + key);
  }

  public setItem(key: string, val: any): void {
    localStorage.setItem(this.prefix + key, val);
  }

  public removeItem(key: string): void {
    localStorage.removeItem(this.prefix + key);
  }

  /**
   * Clear only the items in localStorage that are relevant (prefixed)
   */
  public clear(): void {
    this.relevantKeys.forEach(key => localStorage.removeItem(key));
  }
}
