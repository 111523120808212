<a
  nbButton
  status="primary"
  shape="round"
  outline
  size="large"
  class=""
  [routerLink]="['/', plansPaths.ROOT, plansPaths.NewQuote]"
>
  <nb-icon icon="plus-outline"></nb-icon>
</a>
