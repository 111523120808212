<form [formGroup]="form" novalidate>
  <table class="table">
    <thead>
      <tr>
        <th>
          <input
            type="checkbox"
            id="selectAll"
            formControlName="selectAll"
            (change)="toggleSelectAll($event.target.checked)"
          />
          <label for="selectAll" class="sr-only">Select all</label>
        </th>
        <th>Employee Name</th>
        <th>Rate Class</th>
        <th>Employee ID</th>
        <th>Status</th>
        <th><span class="sr-only">Actions</span></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let employee of sortedEmployees; let i = index" formArrayName="selected">
        <ng-container [formGroupName]="i">
          <th>
            <input type="checkbox" [id]="'select' + i" formControlName="isChecked" />
            <label [for]="'select' + i" class="sr-only">Select all</label>
          </th>
          <td class="position-relative">
            <div class="d-flex align-items-center">
              <a [routerLink]="['./', employee.id]">
                {{ employee?.attributes.last_name }}, {{ employee?.attributes.first_name }}
              </a>
              <nb-icon
                class="ml-2"
                style="font-size: smaller"
                *ngIf="showMissingOccupationDetails(employee)"
                icon="alert-triangle-outline"
                status="warning"
                size="sm"
                nbTooltip="Missing occupation details"
              ></nb-icon>

              <nb-icon
                class=""
                style="font-size: smaller"
                *ngIf="!employee.attributes.email"
                icon="email-outline"
                status="warning"
                size="sm"
                nbTooltip="Missing email"
              ></nb-icon>
            </div>
            <div>
              <a class="text-muted" [href]="'mailto:' + employee?.attributes.email">{{ employee?.attributes.email }}</a>
            </div>
          </td>
          <td>
            {{ employeeRateClass(employee)?.attributes.name }}
          </td>
          <td>
            <pip-attach-employee-id [employee]="employee"></pip-attach-employee-id>
          </td>
          <td>
            <div class="position-relative" [ngSwitch]="employee.attributes.status">
              <nb-badge
                class="position-relative"
                *ngSwitchCase="employeeStatus.Invited"
                text="Invited"
                status="basic"
              ></nb-badge>
              <nb-badge
                class="position-relative"
                *ngSwitchCase="employeeStatus.Created"
                text="Created"
                status="basic"
              ></nb-badge>
              <nb-badge
                class="position-relative"
                *ngSwitchCase="employeeStatus.Confirmed"
                text="Confirmed"
                status="basic"
              ></nb-badge>
              <nb-badge
                class="position-relative"
                *ngSwitchCase="employeeStatus.TerminationPending"
                text="Pending termination"
                status="warning"
              ></nb-badge>
              <nb-badge
                class="position-relative"
                *ngSwitchCase="employeeStatus.TerminationConfirmed"
                text="Termination confirmed"
                status="warning"
              ></nb-badge>
              <nb-badge
                class="position-relative"
                *ngSwitchCase="employeeStatus.Terminated"
                text="Terminated"
                status="danger"
              ></nb-badge>
            </div>
          </td>
          <td>
            <a nbButton size="tiny" [routerLink]="['./', employee.id]"> Edit </a>

            <pip-invite-employee
              *ngIf="employee?.attributes?.email"
              #invEmployee
              [employee]="{
                email: employee.attributes.email,
                id: employee.id,
                last_invited_at: employee.attributes.last_invited_at,
                signed_at: employee.attributes.signed_at
              }"
              [fieldStatuses]="employee?.meta?.field_statuses"
            ></pip-invite-employee>
          </td>
        </ng-container>
      </tr>
    </tbody>
  </table>
</form>
