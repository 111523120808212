import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { IRenewal } from '@app/data';

@Component({
  selector: 'pip-renewal-list',
  templateUrl: './renewal-list.component.html',
  styleUrls: ['./renewal-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RenewalListComponent implements OnInit {
  @Input() public renewals: IRenewal[];

  constructor() {}

  public ngOnInit(): void {}
}
