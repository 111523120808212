import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, Output } from '@angular/core';
import { FormArray, FormControl, FormControlName, FormGroup, Validators } from '@angular/forms';
import {
  Beneficiary,
  CanadianProvince,
  DataType,
  Dependent,
  Employee,
  IBeneficiary,
  ICarrierMediaLinks,
  IDependent,
  IEmployee,
  IEmployeeMedia,
  IPaymentInformation,
  IRate,
  MediaModel,
  MediaType,
} from '@app/data';
import { EmployeeStatus } from '@app/data/enums/employee-status.enum';
import { MaritalStatus } from '@app/data/enums/marital-status.enum';
import { IBenefit } from '@app/data/models/benefit';
import { yearMonthDay } from '@app/utils/year-month-day';
import { format, parseISO } from 'date-fns';
import * as moment from 'moment';

@Component({
  selector: 'pip-employee-detail-form',
  templateUrl: './employee-detail-form.component.html',
  styleUrls: ['./employee-detail-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmployeeDetailFormComponent implements OnChanges, OnDestroy {
  @Input() public isAnalyst = false;
  @Input() public isAdvisor = false;
  @Input() public employee: IEmployee = Employee.create();
  @Input() public media: IEmployeeMedia;
  @Input() public rates: IRate[];
  @Input() public billingDivisions: IPaymentInformation[];
  @Input() public carrierMediaLinks: ICarrierMediaLinks;
  @Input() public isOccupationDisabled: boolean;
  @Input() public showBenefitsInfo: boolean = true;
  @Input() public isEmployeeCurrentUser: boolean = false;
  @Input() public showOverrides: boolean = false;
  @Input() public allowTermination: boolean = false;

  @Output() public deleteEmployee = new EventEmitter<IEmployee>();
  @Output() public terminateEmployee = new EventEmitter<IEmployee>();
  @Output() public confirmTermination = new EventEmitter<IEmployee>();
  @Output() public reinstateEmployee = new EventEmitter<IEmployee>();
  @Output() private saveEmployee = new EventEmitter<IEmployee>();

  public form: FormGroup;
  public mediaType = MediaType;
  public mediaModel = MediaModel;

  public get isPendingTermination(): boolean {
    return this.employee?.attributes?.status === EmployeeStatus.TerminationPending;
  }

  public get isTerminated(): boolean {
    return this.employee?.attributes?.status === EmployeeStatus.Terminated;
  }

  public get isTerminatedConfirmed(): boolean {
    return this.employee?.attributes?.status === EmployeeStatus.TerminationConfirmed;
  }

  public get dependents(): FormArray {
    return this.form.get('dependents') as FormArray;
  }

  public get beneficiaries(): FormArray {
    return this.form.get('beneficiaries') as FormArray;
  }

  public get contingentBeneficiaries(): FormArray {
    return this.form.get('contingent_beneficiaries') as FormArray;
  }

  public get showRAMQ(): boolean {
    return this.isQC && this.isOver65;
  }

  public get maritalStatus(): MaritalStatus {
    return this.form.get('personal_info').get('marital_status').value;
  }

  public get benefits(): IBenefit[] {
    if (!this.employee || !this.employee.meta || !this.employee.meta.costs || !this.employee.meta.costs.costs) {
      return [];
    }

    return this.employee.meta.costs.costs.filter((b) => b.label !== 'id');
  }

  public get showTrusteeAppointment(): boolean {
    return (
      this.carrierMediaLinks &&
      this.carrierMediaLinks.TrusteeAppointment &&
      this.beneficiaries.controls.some((ctrl) => {
        const diff = ctrl.value.born_at && moment.utc().diff(ctrl.value.born_at, 'years');
        return diff < 18;
      })
    );
  }

  public get employeeName(): string {
    return (
      this.employee &&
      this.employee.attributes &&
      `${this.employee.attributes.first_name} ${this.employee.attributes.last_name}`
    );
  }

  public get signedAt(): string {
    return this.employee && this.employee.attributes && this.employee.attributes.signed_at;
  }

  public get allocKey(): string {
    return this.employee.meta.costs.employeeDeductions.pay_cycle.toLowerCase();
  }

  public get isAdvisorOrAnalyst(): boolean {
    return this.isAdvisor || this.isAnalyst;
  }

  public get showDeleteEmployee(): boolean {
    return this.isAdvisorOrAnalyst;
  }

  public get showRequestToTerminate(): boolean {
    return this.allowTermination && this.isActive;
  }

  public get showConfirmTermination(): boolean {
    return this.allowTermination && this.isAnalyst && this.isPendingTermination;
  }

  public get showReinstate(): boolean {
    return this.allowTermination && !this.isActive;
  }

  private get isActive(): boolean {
    return !(this.isTerminated || this.isPendingTermination || this.isTerminatedConfirmed);
  }

  private get isQC(): boolean {
    return this.form.get('personal_info').get('state_residence').value === CanadianProvince.Quebec;
  }

  private get isOver65(): boolean {
    const value = this.form.get('personal_info').get('born_at').value;

    return value && moment.utc().diff(value, 'years') >= 65;
  }

  constructor() {
    this.isOccupationDisabled = false;
  }

  public ngOnChanges(): void {
    this.initForm();

    if (this.isOccupationDisabled) {
      this.form.get('occupation_and_earnings').disable({ onlySelf: false, emitEvent: true });
    }

    setTimeout(() => this.form.markAsPristine(), 0);
  }

  public ngOnDestroy(): void {}

  public onSubmit(): void {
    const {
      occupation,
      state_employment,
      earnings_frequency,
      earnings_amount,
      contractor,
      absent,
      weekly_hours,
      hired_at,
      claims,
      rate,
      division,
    } = this.form.get('occupation_and_earnings').value;

    const { cohabitation_at, born_at } = this.form.get('personal_info').value;

    const employee: IEmployee = {
      id: this.employee.id,
      type: this.employee.type,
      attributes: {
        ...this.form.get('overrides').value,
        ...this.form.get('personal_info').value,
        ...this.form.get('coverage').value,
        occupation,
        state_employment,
        earnings_frequency,
        earnings_amount,
        contractor,
        absent,
        weekly_hours,
        claims: absent ? claims : null,
        born_at: yearMonthDay(born_at),
        cohabitation_at: yearMonthDay(cohabitation_at),
        hired_at: yearMonthDay(hired_at),
        dependents: [...this.dependents.value.map((i) => ({ ...i, born_at: yearMonthDay(i.born_at) }))],
        beneficiaries: [...this.beneficiaries.value, ...this.contingentBeneficiaries.value].map((i) => ({
          ...i,
          born_at: yearMonthDay(i.born_at),
        })),
        payment_information: this.form.get('payment_information').value,
        revocable: this.form.value.revocable,
      },
      relationships: {
        ...(rate && {
          rates: {
            data: { id: rate, type: DataType.Rates },
          },
        }),
        ...(division && {
          payment_infos: {
            data: { id: division, type: DataType.PaymentInformation },
          },
        }),
      },
    };

    this.saveEmployee.emit(employee);
  }

  public onSignature(employee: IEmployee): void {
    const { id, type, attributes } = employee;
    const payload: IEmployee = {
      id,
      type,
      attributes: {
        signature: attributes.signature,
      },
    };

    this.saveEmployee.emit(payload);
  }

  public addDependent(dependent?: IDependent): void {
    const {
      born_at,
      first_name,
      last_name,
      middle_name,
      relationship,
      disabled,
      gender,
      student,
      id,
      school_end_at,
      school_name,
      school_start_at,
    } = dependent || Dependent.create();

    const group: FormGroup = new FormGroup({
      id: new FormControl(id),
      school_name: new FormControl(school_name),
      school_start_at: new FormControl(school_start_at),
      school_end_at: new FormControl(school_end_at),
      born_at: new FormControl((born_at && parseISO(born_at)) || undefined, [Validators.required]),
      first_name: new FormControl(first_name, [Validators.required]),
      last_name: new FormControl(last_name, [Validators.required]),
      middle_name: new FormControl(middle_name),
      relationship: new FormControl(relationship, [Validators.required]),
      disabled: new FormControl(disabled, [Validators.required]),
      gender: new FormControl(gender, [Validators.required]),
      student: new FormControl(student, [Validators.required]),
    });

    this.dependents.push(group);
  }

  public addBeneficiary(beneficiary?: IBeneficiary, contingent?: boolean): void {
    const { born_at, first_name, last_name, middle_name, relationship, allocation, id } =
      beneficiary || Beneficiary.create();

    const group: FormGroup = new FormGroup({
      id: new FormControl(id),
      born_at: new FormControl((born_at && parseISO(born_at)) || undefined, [Validators.required]),
      first_name: new FormControl(first_name, [Validators.required]),
      last_name: new FormControl(last_name, [Validators.required]),
      middle_name: new FormControl(middle_name),
      relationship: new FormControl(relationship, [Validators.required]),
      allocation: new FormControl(allocation),
      contingent: new FormControl(contingent || false),
    });

    if (contingent) {
      this.contingentBeneficiaries.push(group);
    } else {
      this.beneficiaries.push(group);
    }
  }

  private initForm(): void {
    const {
      absent,
      born_at,
      contractor,
      coverage_status,
      earnings_amount,
      earnings_frequency,
      email,
      first_name,
      gender,
      hired_at,
      last_name,
      marital_status,
      middle_name,
      mobile,
      occupation,
      state_employment,
      state_residence,
      weekly_hours,
      spouse_policy_number,
      spouse_carrier,
      coverage_status_health,
      coverage_status_dental,
      ramq,
      payment_information,
      address1,
      address2,
      country,
      city,
      postal_code,
      cohabitation_at,
      revocable,
      life_nem_override,
      critical_illness_volume_override,
      ltd_nem_override,
      std_nem_override,
      optional_benefits,
    } = this.employee.attributes;

    // TODO: Data is expected as an array here... But an object in the API... Not sure if API should be changed?
    const rate =
      this.employee.relationships && this.employee.relationships.rates && this.employee.relationships.rates.data['id'];

    const { cause, type, start_at, end_at, waiver_approved } = this.employee.attributes.claims || {
      cause: null,
      type: null,
      start_at: null,
      end_at: null,
      waiver_approved: false,
    };

    const dependents = this.employee.attributes.dependents || [];
    const beneficiaries = (this.employee.attributes.beneficiaries || []).filter((b) => !b.contingent);
    const contingentBeneficiaries = (this.employee.attributes.beneficiaries || []).filter((b) => b.contingent);

    const { account_number, transit, institution_id } = payment_information || {
      account_number: null,
      institution_id: null,
      transit: null,
    };

    const division = this.employee?.relationships?.payment_infos?.data?.['id'];

    this.form = new FormGroup({
      personal_info: new FormGroup({
        first_name: new FormControl(first_name, [Validators.required]),
        last_name: new FormControl(last_name, [Validators.required]),
        middle_name: new FormControl(middle_name),
        mobile: new FormControl(mobile),
        email: new FormControl(email, [Validators.required, Validators.email]),
        born_at: new FormControl(born_at && parseISO(born_at), [Validators.required]),
        gender: new FormControl(gender, [Validators.required]),
        marital_status: new FormControl(marital_status, [Validators.required]),
        state_residence: new FormControl(state_residence, [Validators.required]),
        coverage_status: new FormControl(coverage_status),
        address1: new FormControl(address1),
        address2: new FormControl(address2),
        country: new FormControl(country),
        city: new FormControl(city),
        postal_code: new FormControl(postal_code),
        cohabitation_at: new FormControl(cohabitation_at && parseISO(cohabitation_at)),
      }),
      occupation_and_earnings: new FormGroup({
        occupation: new FormControl(occupation),
        state_employment: new FormControl(state_employment),
        hired_at: new FormControl(hired_at && parseISO(hired_at)),
        earnings_frequency: new FormControl(earnings_frequency),
        earnings_amount: new FormControl(earnings_amount),
        contractor: new FormControl(contractor),
        absent: new FormControl(absent),
        weekly_hours: new FormControl(weekly_hours, [Validators.min(15), Validators.max(100)]),
        division: new FormControl(division),
        claims: new FormGroup({
          type: new FormControl(type),
          cause: new FormControl(cause),
          start_at: new FormControl(start_at && parseISO(start_at)),
          end_at: new FormControl(end_at && parseISO(end_at)),
          waiver_approved: new FormControl(waiver_approved),
        }),
        rate: new FormControl(rate),
      }),
      coverage: new FormGroup({
        optional_benefits: new FormControl(optional_benefits),
        spouse_policy_number: new FormControl(spouse_policy_number),
        spouse_carrier: new FormControl(spouse_carrier),
        coverage_status_health: new FormControl(coverage_status_health),
        coverage_status_dental: new FormControl(coverage_status_dental),
        ramq: new FormControl(ramq),
      }),
      overrides: new FormGroup({
        life_nem_override: new FormControl(life_nem_override),
        critical_illness_volume_override: new FormControl(critical_illness_volume_override),
        ltd_nem_override: new FormControl(ltd_nem_override),
        std_nem_override: new FormControl(std_nem_override),
      }),
      payment_information: new FormGroup({
        account_number: new FormControl(account_number),
        transit: new FormControl(transit),
        institution_id: new FormControl(institution_id),
      }),
      dependents: new FormArray([]),
      beneficiaries: new FormArray([]),
      contingent_beneficiaries: new FormArray([]),
      revocable: new FormControl(
        !(revocable === null || revocable === undefined)
          ? revocable
          : state_residence === CanadianProvince.Quebec
          ? false
          : true,
      ),
    });

    dependents.map((d) => this.addDependent(d));
    beneficiaries.map((b) => this.addBeneficiary(b, false));
    contingentBeneficiaries.map((b) => this.addBeneficiary(b, true));

    if (this.isTerminated || this.isPendingTermination || (this.signedAt && !this.isAnalyst)) {
      this.form.disable();
    }
  }
}
