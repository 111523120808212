export enum GroupExperience {
  New = 'New',
  Somewhat = 'Somewhat',
  Very = 'Very',
  Expert = 'Expert',
}

export const GroupExperienceDictionary: Record<GroupExperience, string> = {
  [GroupExperience.New]: 'None',
  [GroupExperience.Somewhat]: '1-5',
  [GroupExperience.Very]: '6-20',
  [GroupExperience.Expert]: '21+',
};
