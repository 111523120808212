import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import * as moment from 'moment';

const CUSTOM_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => PlanStartDateComponent),
  multi: true,
};

@Component({
  selector: 'pip-plan-start-date',
  templateUrl: './plan-start-date.component.html',
  styleUrls: ['./plan-start-date.component.scss'],
  providers: [CUSTOM_VALUE_ACCESSOR],
})
export class PlanStartDateComponent implements ControlValueAccessor, OnInit {
  @Input() allowBackdate: boolean;
  public startAt;
  public form: FormGroup;
  public selectableYears: number[];

  private minDate: moment.Moment;
  private disabled: boolean;
  private onChange: Function;
  private onTouched: Function;

  constructor() {
    this.onChange = (_: any) => {};
    this.onTouched = () => {};
    this.disabled = false;
  }

  public ngOnInit(): void {
    this.minDate = this.allowBackdate ? moment().subtract(20, 'year') : moment().subtract(30, 'day');

    this.initForm();
  }

  public clearSubDates(): void {
    this.form.get('start_month').setValue(null);
    this.form.get('start_day').setValue(null);
  }

  public isValidDate(year: number, month: number, day?: number): boolean {
    const today = moment();

    return moment()
      .year(year)
      .month(month ?? today.month())
      .date(day || today.date())
      .isSameOrAfter(this.minDate);
  }

  writeValue(obj: any): void {
    this.startAt = obj;
    this.initForm(this.startAt);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public setStartDate(): void {
    const { start_year, start_month, start_day } = this.form.value;

    this.startAt =
      typeof start_year === 'number' && typeof start_month === 'number' && typeof start_day === 'number'
        ? moment()
            .year(this.form.get('start_year').value)
            .month(this.form.get('start_month').value)
            .date(this.form.get('start_day').value)
            .format('YYYY-MM-DD')
        : null;
    this.onChange(this.startAt);
    this.onTouched();
  }

  private initForm(value?): void {
    const date = moment(value || new Date().toUTCString());

    const startYear = date.year();
    const startMonth = date.month(); // 0-11
    const startDay = date.date() > 1 ? 15 : 1;

    this.selectableYears = new Array(this.allowBackdate ? 25 : 5).fill(this.minDate.year()).map((v, i) => v + i);

    this.form = new FormGroup({
      start_year: new FormControl(startYear, [Validators.required]),
      start_month: new FormControl(startMonth, [Validators.required]),
      start_day: new FormControl(startDay, [Validators.required]),
    });

    this.setStartDate();

    this.form.valueChanges.pipe().subscribe(() => {
      this.setStartDate();
    });
  }
}
