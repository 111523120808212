import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'pip-master-authorization-content',
  templateUrl: './master-authorization-content.component.html',
  styleUrls: ['./master-authorization-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MasterAuthorizationContentComponent implements OnInit {
  @Input() public carrierName: string;

  constructor() {}

  ngOnInit() {}
}
