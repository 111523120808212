<div class="row justify-content-center">
  <div class="col">
    <div class="d-flex justify-content-between align-items-center">
      <h2>Enroll Employees</h2>
      <pip-create-employee [planId]="(plan$ | async)?.id"></pip-create-employee>
    </div>
    <div class="row my-3">
      <div class="col-xl-8">
        <p>
          Please click <strong>EDIT</strong> to complete each employee’s <strong>email</strong>,
          <strong>occupation and earnings</strong>
          if missing.
        </p>
        <p>
          If an Employee does not have email, go to their Signoffs/Legal section for a downloadable PDF enrollment form.
        </p>
      </div>
    </div>
    <pip-field-status-alert
      *ngIf="(plan$ | async)?.meta?.field_statuses?.employees as employees"
      [fieldStatus]="employees"
      message="If you are seeing ‘ENROLL’ in yellow, click it to find out what’s missing.  Once info is fully completed for an employee, ‘ENROLL’ will turn green and allow you to send out an e-invite for virtual enrolment to commence."
      key="confirmed"
    ></pip-field-status-alert>
    <nb-card status="basic" class="overflow-hidden">
      <pip-employees-table
        [showEnroll]="true"
        [showEmployeeId]="false"
        [employees]="employees$ | async"
        [rates]="rates$ | async"
        (getInfo)="onInfo($event, employeeInfo)"
        (delete)="onDelete($event, deleteDialog)"
      ></pip-employees-table>
      <div class="d-flex justify-content-center"></div>
      <nb-card-footer class="d-flex justify-content-between">
        <button nbButton status="primary" ghost type="button" (click)="onCancel()">Cancel</button>

        <pip-employee-pagination
          [paginatedLinks]="(plan$ | async)?.relationships.employees.links"
          [planId]="(plan$ | async)?.id"
        ></pip-employee-pagination>
        <ng-container *ngIf="(isSubmittable$ | async) && (plan$ | async) as plan; else Warning">
          <button nbButton status="primary" type="button" (click)="onUpdatePlan(plan)">Confirm</button>
        </ng-container>
        <ng-template #Warning>
          <nb-alert accent="primary" class="text-center mb-0">
            You must complete all the employee information above before you are able to confirm.
          </nb-alert>
        </ng-template>
      </nb-card-footer>
    </nb-card>
  </div>
</div>

<ng-template #deleteDialog let-data let-ref="dialogRef">
  <nb-card status="basic">
    <nb-card-header>Delete employee</nb-card-header>
    <nb-card-body class="p-5">
      Are you sure you want to delete <strong>{{ data }}</strong
      >? This action cannot be undone.
    </nb-card-body>
    <nb-card-footer class="d-flex justify-content-between">
      <button nbButton status="danger" (click)="ref.close(true)">Delete {{ data }}</button>
      <button nbButton status="primary" ghost (click)="ref.close(false)">Cancel</button>
    </nb-card-footer>
  </nb-card>
</ng-template>

<ng-template #employeeInfo let-data let-ref="dialogRef">
  <nb-card status="basic">
    <nb-card-header>Employee info</nb-card-header>
    <nb-card-body>
      <nb-alert accent="warning" *ngIf="!data.email">
        <h2 class="h5">Missing Email!</h2>
        <p>Employee email is missing/incomplete.</p>
      </nb-alert>
      <ng-container *ngIf="data.length; else AllComplete">
        <pip-field-status-alert *ngFor="let item of data.fs" [fieldStatus]="item"></pip-field-status-alert>
      </ng-container>
      <ng-template #AllComplete>
        <nb-alert accent="success">Employee is ready for enrollment</nb-alert>
      </ng-template>
    </nb-card-body>
    <nb-card-footer class="d-flex justify-content-end">
      <button nbButton status="primary" ghost (click)="ref.close(false)">Close</button>
    </nb-card-footer>
  </nb-card>
</ng-template>
