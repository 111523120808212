import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { IPlan } from '@app/data';
import { BenefitsContinuations } from '@app/data/enums/benefits-continuation';
import { PayCycle, PayCycleDictionary } from '@app/data/enums/pay-cycle.enum';

@Component({
  selector: 'pip-plan-standards-form-group',
  templateUrl: './plan-standards-form-group.component.html',
  styleUrls: ['./plan-standards-form-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanStandardsFormGroupComponent implements OnInit {
  @Input() public plan: IPlan;

  public payCycle = PayCycle;
  public payCycleDictionary = PayCycleDictionary;
  public benefitsContinuations = BenefitsContinuations;

  constructor(public controlContainer: ControlContainer) {}

  public ngOnInit(): void {}
}
