import { Injectable } from '@angular/core';
import { DataType } from '@app/data/enums';
import { INote, IOrganization, IUser, ResourceObject } from '@app/data/models';
import { INoteDetail } from '@app/data/models/note-detail';
import { NotesService } from '@app/data/services';
import { PlansState } from '@app/data/store/plans';
import { IncludeUsers, UsersState, UsersStateModel } from '@app/data/store/users';
import * as Utils from '@app/utils';
import { NbToastrService } from '@nebular/theme';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { CreateNote, DeleteNote, GetNote, GetNotes, IncludeNotes, UpdateNote } from './notes.actions';

export interface NotesStateModel {
  entities: EntityMap<INote>;
}

export const notesStateDefaults: NotesStateModel = {
  entities: {},
};

@State<NotesStateModel>({
  name: DataType.Notes,
  defaults: notesStateDefaults,
})
@Injectable()
export class NotesState {
  @Selector([PlansState.activePlanOrganization, UsersState, UsersState.activeUserId])
  public static activePlanOrganizationNotes(
    notesState: NotesStateModel,
    activeOrg: IOrganization,
    usersState: UsersStateModel,
    activeUserId: string,
  ): INoteDetail[] {
    return Utils.entitiesToArray(notesState.entities)
      .filter(note => {
        return note?.relationships?.organizations?.data?.id === activeOrg.id;
      })
      .map(note => {
        const user = usersState.entities[note?.relationships?.users?.data?.id];

        return {
          detail: note,
          user,
          canEdit: user.id === activeUserId,
        };
      });
  }

  constructor(public notes: NotesService, public toastr: NbToastrService) {}

  @Action(IncludeNotes)
  public includeNotes(ctx: StateContext<NotesStateModel>, action: IncludeNotes) {
    ctx.setState(
      patch<NotesStateModel>({
        entities: patch(Utils.createEntities(action.payload.notes, 'id')),
      }),
    );
  }

  @Action(GetNotes)
  public async getNotes(ctx: StateContext<NotesStateModel>, action: GetNotes) {
    const notes = await this.notes.getNotes(action.payload.organizationId).toPromise();
    const state = ctx.getState();

    ctx.setState({
      ...state,
      entities: { ...state.entities, ...Utils.createEntities(notes.data, 'id') },
    });

    this.updateStores(ctx, notes.included);
  }

  @Action(GetNote)
  public async getNote(ctx: StateContext<NotesStateModel>, action: GetNote) {
    const notes = await this.notes.getNote(action.payload.noteId).toPromise();
    const state = ctx.getState();

    ctx.setState({
      ...state,
      entities: { ...state.entities, ...Utils.createEntities([notes.data], 'id') },
    });
  }

  @Action(CreateNote)
  public async createNote(ctx: StateContext<NotesStateModel>, action: CreateNote) {
    const note = await this.notes.createNote(action.payload.organizationId, action.payload.note).toPromise();

    ctx.setState(
      patch<NotesStateModel>({
        entities: patch({ [note.data.id]: note.data }),
      }),
    );
  }

  @Action(UpdateNote)
  public async updateNote(ctx: StateContext<NotesStateModel>, action: UpdateNote) {
    const note = await this.notes.updateNote(action.payload.note).toPromise();

    ctx.setState(
      patch<NotesStateModel>({
        entities: patch({ [note.data.id]: note.data }),
      }),
    );
  }

  @Action(DeleteNote)
  public async deleteNote(ctx: StateContext<NotesStateModel>, action: DeleteNote) {
    const note = await this.notes.deleteNote(action.payload.noteId).toPromise();

    const { [action.payload.noteId]: removed, ...entities } = ctx.getState().entities;

    ctx.setState(
      patch<NotesStateModel>({
        entities,
      }),
    );
  }

  private updateStores(ctx: StateContext<NotesStateModel>, included: ResourceObject[]) {
    const users = included.filter(i => i && i.type === DataType.Users) as IUser[];

    if (users.length) {
      ctx.dispatch(new IncludeUsers(users));
    }
  }
}
