<form [formGroup]="form" (submit)="onSubmit()" class="form-horizontal">
  <nb-card status="basic" [nbSpinner]="loading" nbSpinnerStatus="primary">
    <nb-card-header>Company Info</nb-card-header>
    <nb-card-body>
      <pip-company-info-formgroup formGroupName="companyInfo"></pip-company-info-formgroup>
      <pip-business-address-formgroup formGroupName="businessAddress"></pip-business-address-formgroup>
      <pip-administrator-formgroup [plan]="plan" [showSecurityFields]="true" formGroupName="administrator"></pip-administrator-formgroup>
    </nb-card-body>
    <nb-card-footer class="d-flex justify-content-end">
      <button nbButton status="primary" type="submit">Save</button>
    </nb-card-footer>
  </nb-card>
</form>
