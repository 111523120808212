<ng-container *ngIf="employee$ | async as employee">
  <pip-employee-detail-form
    [isAnalyst]="isAnalyst$ | async"
    [isAdvisor]="isAdvisor$ | async"
    [employee]="employee"
    [media]="media$ | async"
    [rates]="rates$ | async"
    [carrierMediaLinks]="carrierMediaLinks$ | async"
    [showBenefitsInfo]="(hideBenefitsInfo$ | async) === false"
    [isEmployeeCurrentUser]="isEmployeeCurrentUser$ | async"
    [showOverrides]="showOverrides$ | async"
    [allowTermination]="allowEmployeeTermination$ | async"
    [billingDivisions]="billingDivisions$ | async"
    (saveEmployee)="onSaveEmployee($event)"
    (deleteEmployee)="onDeleteEmployee(deleteDialog, $event)"
    (terminateEmployee)="onRequestToTerminateEmployee(requestToTerminateDialog, $event)"
    (reinstateEmployee)="onReinstateEmployee(confirmReinstateDialog, $event)"
    (confirmTermination)="onConfirmTermination(confirmTerminationDialog, $event)"
  ></pip-employee-detail-form>
</ng-container>

<ng-template #deleteDialog let-data let-ref="dialogRef">
  <nb-card status="basic">
    <nb-card-header>Delete employee</nb-card-header>
    <nb-card-body>
      Are you sure you want to delete <strong>{{ data }}</strong
      >? This action cannot be undone.
    </nb-card-body>
    <nb-card-footer class="d-flex justify-content-between">
      <button nbButton status="danger" (click)="ref.close(true)">Delete employee</button>
      <button nbButton status="primary" ghost (click)="ref.close(false)">Cancel</button>
    </nb-card-footer>
  </nb-card>
</ng-template>

<ng-template #confirmTerminationDialog let-data let-ref="dialogRef">
  <nb-card status="basic">
    <nb-card-header>Confirm Employee Termination</nb-card-header>
    <nb-card-body>
      By hitting on "Yes, Confirm Termination" you confirm that the Carrier has provided written confirmation that this
      employee has been removed from the company's insurance plan.
    </nb-card-body>
    <nb-card-footer class="d-flex justify-content-between">
      <button nbButton status="danger" (click)="ref.close(true)">Yes, Confirm Termination</button>
      <button nbButton status="primary" ghost (click)="ref.close(false)">Cancel</button>
    </nb-card-footer>
  </nb-card>
</ng-template>

<ng-template #confirmReinstateDialog let-data let-ref="dialogRef">
  <nb-card status="basic">
    <nb-card-header>Reinstate Employee</nb-card-header>
    <nb-card-body> Are you sure you want to reinstate {{ data }}? </nb-card-body>
    <nb-card-footer class="d-flex justify-content-between">
      <button nbButton status="success" (click)="ref.close(true)">Yes, Reinstate</button>
      <button nbButton status="primary" ghost (click)="ref.close(false)">Cancel</button>
    </nb-card-footer>
  </nb-card>
</ng-template>

<ng-template #requestToTerminateDialog let-data let-ref="dialogRef">
  <form [formGroup]="data.form" (ngSubmit)="sendRequestToTerminate(data.form, data.employee)">
    <nb-card status="basic">
      <nb-card-header>Request to Terminate Employee</nb-card-header>
      <nb-card-body>
        <div class="form-group">
          <label for="terminated_at" class="label">Termination date</label>
          <input type="date" nbInput fullWidth id="terminated_at" formControlName="terminated_at" />
        </div>
        <div class="form-group">
          <label class="label" for="terminated_reason">Reason for termination</label>
          <!-- <textarea formControlName="terminated_reason" id="terminated_reason" nbInput fullWidth></textarea> -->

          <nb-select class="d-block" id="terminated_reason" formControlName="terminated_reason">
            <nb-option value="Premium non-payment">Premium non-payment</nb-option>
            <nb-option value="End of employment">End of employment</nb-option>
            <nb-option value="Retirement">Retirement</nb-option>
            <nb-option value="Not eligible">Not eligible</nb-option>
            <nb-option value="Death">Death</nb-option>
          </nb-select>
        </div>
      </nb-card-body>
      <nb-card-footer class="d-flex justify-content-between">
        <button nbButton status="danger" type="submit" [disabled]="data.form.invalid">Send request</button>
        <button nbButton status="primary" ghost (click)="ref.close(false)">Cancel</button>
      </nb-card-footer>
    </nb-card>
  </form>
</ng-template>
