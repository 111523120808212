import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IMedia, IPlan, PlansState, UpdatePlan } from '@app/data';
import { Navigate } from '@ngxs/router-plugin';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Component({
  selector: 'pip-quote-task-start-date',
  templateUrl: './quote-task-start-date.component.html',
  styleUrls: ['./quote-task-start-date.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuoteTaskStartDateComponent implements OnInit {
  public plan$: Observable<IPlan> = this.store.select(PlansState.activePlan);
  public terminationLetter$: Observable<IMedia> = this.store.select(PlansState.activePlanMedia).pipe(map(media => media.TerminationLetter));

  constructor(private store: Store, private actions$: Actions, private route: ActivatedRoute) {}

  public ngOnInit(): void {}

  public onUpdatePlan(plan: IPlan): void {
    this.actions$
      .pipe(ofActionSuccessful(UpdatePlan), take(1))
      .subscribe(() => this.store.dispatch(new Navigate(['../'], null, { relativeTo: this.route })));

    this.store.dispatch(new UpdatePlan(plan));
  }

  public onCancel(): void {
    this.store.dispatch(new Navigate(['../'], null, { relativeTo: this.route }));
  }
}
