<button nbButton size="tiny" ghost status="danger" (click)="deletePlan(confirmDelete)">
  <nb-icon icon="trash-2-outline"></nb-icon>
  Delete quote
</button>

<ng-template #confirmDelete let-data let-ref="dialogRef">
  <nb-card status="basic" nbSpinnerStatus="primary">
    <nb-card-header>Delete quote?</nb-card-header>
    <nb-card-body>
      <p>Are you sure you want to delete this quote?</p>
    </nb-card-body>
    <nb-card-footer>
      <button nbButton status="primary" type="submit" (click)="ref.close(true)">Delete quote</button>
      <button nbButton status="primary" ghost type="button" (click)="ref.close(false)">Cancel</button>
    </nb-card-footer>
  </nb-card>
</ng-template>
