<form [formGroup]="form" (submit)="onSubmit()">
  <nb-card status="basic" nbSpinnerStatus="primary">
    <nb-card-body>
      <legend class="subtitle">Select start date</legend>
      <div class="form-group">
        <label class="label">Start date</label>
        <pip-plan-start-date formControlName="start_at"></pip-plan-start-date>
      </div>
      <ng-container *ngIf="terminationLetter">
        <hr />
        <p>
          Remember to cancel your current coverage. Download the form below, sign and submit it to incumbent carrier or
          broker.
        </p>
        <a [href]="terminationLetter?.links?.self" download nbButton status="primary" outline class="mb-3">
          <nb-icon icon="cloud-download-outline"></nb-icon>
          Download termination letter
        </a>
      </ng-container>
    </nb-card-body>
    <nb-card-footer class="d-flex justify-content-between">
      <button nbButton status="primary" ghost type="button" (click)="cancel.emit()">{{ cancelButton }}</button>
      <button nbButton status="primary" type="submit" [disabled]="!form.valid">{{ confirmButton }}</button>
    </nb-card-footer>
  </nb-card>
</form>
