<pip-field-status-alert
  [fieldStatus]="(plan$ | async)?.meta?.field_statuses?.employees"
  message="If you are seeing ‘ENROLL’ in yellow, click it to find out what’s missing.  Once info is fully completed for an employee, ‘ENROLL’ will turn green and allow you to send out an e-invite for virtual enrolment to commence."
></pip-field-status-alert>

<nb-card status="basic" class="overflow-hidden">
  <nb-card-header>
    <div class="d-flex justify-content-between align-items-center">
      <span class="mr-auto">Employees</span>
      <div>
        <div class="text-right">
          <a nbButton status="primary" ghost [href]="(plan$ | async)?.links.employees" size="small">
            <nb-icon icon="cloud-download-outline"></nb-icon>
            Download employees
          </a>
          <pip-create-employee
            *ngIf="
              [
                planStatus.InProgress,
                planStatus.RTQ,
                planStatus.Proposal,
                planStatus.EnrollIngenious,
                planStatus.EnrollClient
              ].includes((plan$ | async)?.attributes.status)
            "
            [planId]="(plan$ | async)?.id"
          ></pip-create-employee>
        </div>
        <pip-employee-search class="mt-3 d-block" [planId]="(plan$ | async).id"></pip-employee-search>
      </div>
    </div>
  </nb-card-header>
  <pip-employees-table
    [showEmployeeId]="(plan$ | async)?.attributes.status === planStatus.Setup"
    [showEnroll]="showEnrollEmployee$ | async"
    [employees]="employees$ | async"
    [rates]="rates$ | async"
    (delete)="onDelete($event, deleteDialog)"
  ></pip-employees-table>
  <nb-card-footer class="d-flex justify-content-center">
    <pip-employee-pagination
      [paginatedLinks]="(plan$ | async).relationships.employees.links"
      [planId]="(plan$ | async).id"
    ></pip-employee-pagination>
  </nb-card-footer>
</nb-card>

<ng-template #deleteDialog let-data let-ref="dialogRef">
  <nb-card status="basic">
    <nb-card-header>Delete employee</nb-card-header>
    <nb-card-body class="p-5">
      Are you sure you want to delete <strong>{{ data }}</strong
      >? This action cannot be undone.
    </nb-card-body>
    <nb-card-footer class="d-flex justify-content-between">
      <button nbButton status="danger" (click)="ref.close(true)">Delete {{ data }}</button>
      <button nbButton status="primary" ghost (click)="ref.close(false)">Cancel</button>
    </nb-card-footer>
  </nb-card>
</ng-template>
