<form [formGroup]="form" (submit)="onSubmit()" class="form-horizontal">
  <nb-card status="basic" [nbSpinner]="loading" nbSpinnerStatus="primary">
    <nb-card-header>Benefits</nb-card-header>
    <nb-card-body>
      <ng-container *ngIf="showVirginSelection" formGroupName="insuranceStatus">
        <nb-radio-group name="virgin" id="virgin" formControlName="virgin">
          <nb-radio [value]="false">Group has existing employee benefits</nb-radio>
          <nb-radio [value]="true">Group does not have existing employee benefits</nb-radio>
        </nb-radio-group>
      </ng-container>

      <div *ngIf="isVirginAnswered && showFileUploads" class="d-flex flex-wrap">
        <pip-media-uploader
          *ngIf="showBenefitsBooklet"
          class="d-block mr-3"
          [existingMedia]="planMedia[mediaType.BenefitsBooklet]"
          [mediaType]="mediaType.BenefitsBooklet"
          [modelId]="plan.id"
          [mediaModel]="mediaModel.Plan"
        ></pip-media-uploader>
        <pip-media-uploader
          class="d-block mr-3"
          [existingMedia]="planMedia?.Renewal"
          [mediaType]="mediaType.Renewal"
          [modelId]="plan.id"
          [mediaModel]="mediaModel.Plan"
        ></pip-media-uploader>
        <pip-media-uploader
          uploadLabelOverride="Upload Experience Report"
          [existingMedia]="planMedia?.AdditionalDocument"
          [mediaType]="mediaType.AdditionalDocument"
          [modelId]="plan.id"
          [mediaModel]="mediaModel.Plan"
          [required]="false"
        ></pip-media-uploader>
      </div>
      <pip-insurance-status-formgroup
        *ngIf="isVirginAnswered"
        formGroupName="insuranceStatus"
        [defaultPlans]="isVirgin ? defaultPlans : null"
        [isVirgin]="isVirgin"
      ></pip-insurance-status-formgroup>
    </nb-card-body>
    <nb-card-footer class="d-flex justify-content-end">
      <button [disabled]="isSaveDisabled" nbButton status="primary" type="submit">Save</button>
    </nb-card-footer>
  </nb-card>
</form>
