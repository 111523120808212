import { ChangeDetectionStrategy, Component, OnInit, TemplateRef } from '@angular/core';
import { FormArray, FormControl, FormGroup, ValidatorFn } from '@angular/forms';
import { DashboardPaths } from '@app/core/dashboard';
import {
  CreateQuote,
  IPlan,
  IUser,
  MediaModel,
  MediaType,
  PlansState,
  PlanStatus,
  QuotesState,
  Role,
  UpdatePlan,
  UsersState,
} from '@app/data';
import { InvitationsService } from '@app/data/services';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { Store } from '@ngxs/store';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, take, withLatestFrom } from 'rxjs/operators';

@Component({
  selector: 'pip-plan-summaries',
  templateUrl: './plan-summaries.component.html',
  styleUrls: ['./plan-summaries.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanSummariesComponent implements OnInit {
  public plan$ = this.store.select(PlansState.activePlan);
  public planMedia$ = this.store.select(PlansState.activePlanMedia);
  public inviteCarriers$ = this.store.select(QuotesState.carriersToInvite);
  public roles$ = this.store.select(UsersState.activeUserRoles);

  public quotes$ = combineLatest([
    this.plan$,
    this.store.select(PlansState.planCarriers),
    this.store.select(QuotesState.quotes),
  ]).pipe(
    map(([plan, carriers, quotes]) => {
      const planQuoteIds = plan?.relationships?.quotes?.data.map((q) => q.id);
      // Sort quotes by carrier name
      return quotes
        .filter((quote) => planQuoteIds?.includes(quote.id))
        .map((quote) => ({
          quote,
          carrier: carriers?.find((c) => c.id === quote.relationships.carrier.data.id)?.attributes?.name,
        }))
        .sort((a, b) => {
          return a.carrier > b.carrier ? 1 : -1;
        })
        .map((q) => q.quote);
    }),
  );

  public hideProposalSection$ = this.plan$.pipe(
    withLatestFrom(this.roles$),
    map(([plan, roles]) => {
      if (roles?.includes(Role.Analyst)) {
        return false;
      }

      return plan.attributes.status === PlanStatus.RTQ && roles?.includes(Role.Advisor);
    }),
  );

  public showProposalReviewBtn$ = this.planMedia$.pipe(map((media) => !!media?.[MediaType.Proposal]));

  public showChangeRequestSection$ = this.plan$.pipe(
    withLatestFrom(this.roles$),
    map(([plan, roles]) => {
      return (
        plan?.attributes.deviations && plan.attributes.status === PlanStatus.Setup && roles?.includes(Role.Analyst)
      );
    }),
  );

  public showMediaUploaders$ = this.roles$.pipe(map((roles) => roles?.includes(Role.Analyst)));

  public users$: Observable<EntityMap<IUser>> = this.store.select(UsersState).pipe(map((s) => s.entities));
  public mediaProposal$ = this.planMedia$.pipe(map((media) => [media.Proposal]));
  public mediaMisc$ = this.planMedia$.pipe(map((media) => [...media.Miscellaneous]));

  public mediaType = MediaType;
  public mediaModel = MediaModel;
  public planStatus = PlanStatus;

  public routes = {
    dashboard: ['/', DashboardPaths.ROOT],
  };

  constructor(
    private store: Store,
    private dialog: NbDialogService,
    private invitationsService: InvitationsService,
    private toastr: NbToastrService,
  ) {}

  public ngOnInit(): void {}

  public onCarrierInvite({ id: carrierId, contacts }, planId: string, dialog: TemplateRef<any>): void {
    const form = new FormGroup({
      contacts: new FormArray(
        contacts.map(
          (contact) =>
            new FormGroup({
              id: new FormControl(contact.id),
              selected: new FormControl(false),
            }),
        ),
        [atLeastOneCheckboxCheckedValidator(1)],
      ),
    });

    this.dialog
      .open(dialog, { context: { contacts, form } })
      .onClose.pipe(
        take(1),
        filter((c) => c),
      )
      .subscribe(() => {
        this.store.dispatch(
          new CreateQuote({
            planId,
            carrierId,
            contacts: form
              .get('contacts')
              .value.filter((ctrl) => ctrl.selected)
              .map((ctrl) => ctrl.id),
          }),
        );
      });
  }

  public onConfirmDeviations(plan: IPlan): void {
    this.store.dispatch(new UpdatePlan(plan));
  }

  public onProposalReview(planId: string): void {
    this.invitationsService
      .inviteProposalReview(planId)
      .pipe(take(1))
      .subscribe(() => {
        this.toastr.success('Successfully sent proposal review notification', 'Success');
      });
  }
}

function atLeastOneCheckboxCheckedValidator(minRequired = 1): ValidatorFn {
  return function validate(FormArray: FormArray) {
    let checked = 0;

    FormArray.controls.forEach((_, idx) => {
      const control = FormArray.controls[idx];

      if (control.value.selected) {
        checked++;
      }
    });

    if (checked < minRequired) {
      return {
        requireCheckboxToBeChecked: true,
      };
    }

    return null;
  };
}
