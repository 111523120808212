import { ChangeDetectionStrategy, Component, Input, OnInit, TemplateRef } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { IDefaultPlan } from '@app/data';
import { NbDialogService } from '@nebular/theme';

@Component({
  selector: 'pip-insurance-status-formgroup',
  templateUrl: './insurance-status-formgroup.component.html',
  styleUrls: ['./insurance-status-formgroup.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class InsuranceStatusFormgroupComponent implements OnInit {
  @Input() public defaultPlans: IDefaultPlan[];
  @Input() public isVirgin: boolean = true;

  constructor(public controlContainer: ControlContainer, private dialog: NbDialogService) {}

  public ngOnInit(): void {}

  public open(templateRef: TemplateRef<any>): void {
    const dialog = this.dialog.open(templateRef);
  }
}
