<div class="d-flex align-items-center">
  <h2>Additional Benefits</h2>
  <button *ngIf="canEdit" nbButton status="primary" size="tiny" outline class="ml-2" (click)="handleEdit(EditDialog)">
    Edit
  </button>
</div>

<div>
  <div class="form-group row">
    <dt class="col col-form-label label">Short-term disability</dt>
    <dd class="col-5">{{ plan.attributes.std | truthyStatus }}</dd>
  </div>
  <div class="form-group row">
    <dt class="col col-form-label label">Long-term disability</dt>
    <dd class="col-5">{{ plan.attributes.ltd | truthyStatus }}</dd>
  </div>
  <div class="form-group row">
    <dt class="col col-form-label label">Employee assistance program</dt>
    <dd class="col-5">{{ plan.attributes.eap | truthyStatus }}</dd>
  </div>
  <div class="form-group row">
    <dt class="col col-form-label label">Virtual care</dt>
    <dd class="col-5">{{ plan.attributes.virtual_care | truthyStatus }}</dd>
  </div>
  <div class="form-group row">
    <dt class="col col-form-label label">Critical illness</dt>
    <dd class="col-5">{{ plan.attributes.critical_illness | truthyStatus }}</dd>
  </div>
  <div class="form-group row">
    <dt class="col col-form-label label">Health spending account</dt>
    <dd class="col-5">{{ plan.attributes.health_spending_account | truthyStatus }}</dd>
  </div>
</div>

<!--  -->
<ng-template #EditDialog let-data let-ref="dialogRef">
  <form [formGroup]="data.form" (ngSubmit)="handleSubmit(data.form.value)">
    <nb-card status="basic">
      <nb-card-body>
        <fieldset>
          <legend class="subtitle">Additional Benefits</legend>
          <div class="form-group d-flex flex-column">
            <nb-checkbox id="std" formControlName="std">Short-term disability</nb-checkbox>
            <nb-checkbox id="ltd" formControlName="ltd">Long-term disability</nb-checkbox>
            <nb-checkbox id="eap" formControlName="eap">Employee assistance program</nb-checkbox>
            <nb-checkbox id="virtual_care" formControlName="virtual_care">Virtual care</nb-checkbox>
            <nb-checkbox id="critical_illness" formControlName="critical_illness">Critical illness</nb-checkbox>
            <nb-checkbox id="health_spending_account" formControlName="health_spending_account"
              >Health spending account</nb-checkbox
            >
          </div>
        </fieldset>
      </nb-card-body>
      <nb-card-footer class="d-flex justify-content-between">
        <button nbButton status="primary" ghost type="button" (click)="ref.close()">Close</button>
        <button nbButton status="primary" type="submit">Save</button>
      </nb-card-footer>
    </nb-card>
  </form>
</ng-template>
