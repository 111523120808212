import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { IEmployee, IRate } from '@app/data';
import { EmployeeStatus } from '@app/data/enums/employee-status.enum';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'pip-plan-members',
  templateUrl: './plan-members.component.html',
  styleUrls: ['./plan-members.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanMembersComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public employees: IEmployee[];
  @Input() public rates: IRate[];

  @Output() private selectedEmployees = new EventEmitter<string[]>();

  public employeeStatus = EmployeeStatus;
  public form: FormGroup;

  public get sortedEmployees(): IEmployee[] {
    return this.employees;

    return this.employees.sort((a, b) => {
      const isATerminated = a.attributes.status === EmployeeStatus.Terminated;
      const isBTerminated = b.attributes.status === EmployeeStatus.Terminated;

      if (isATerminated && !isBTerminated) {
        return 1;
      } else if (!isATerminated && isBTerminated) {
        return -1;
      }

      const valA = a.attributes.last_name + a.attributes.first_name + a.id;
      const valB = b.attributes.last_name + b.attributes.first_name + b.id;

      return valA > valB ? 1 : -1;
    });
  }

  private notifier = new Subject<undefined>();

  private get formSelected(): FormArray {
    return this.form.get('selected') as FormArray;
  }

  constructor() {}

  public ngOnInit(): void {}

  public ngOnChanges(changes: SimpleChanges): void {
    this.initForm();
  }

  public ngOnDestroy(): void {
    this.notifier.next(undefined);
    this.notifier.complete();
  }

  public toggleSelectAll(checked: boolean) {
    this.formSelected.controls.forEach((control) => control.get('isChecked').setValue(checked));
  }

  public employeeRateClass(employee: IEmployee): IRate {
    return this.rates?.find((rate) => rate.id === employee.relationships?.rates?.data?.id);
  }

  public showMissingOccupationDetails(employee: IEmployee): boolean {
    return !employee?.meta?.field_statuses['occupation_and_earnings']?.complete;
  }

  private initForm(): void {
    this.form = new FormGroup({
      selectAll: new FormControl(false),
      selected: new FormArray([]),
    });

    this.sortedEmployees?.forEach((employee) => {
      this.formSelected.push(
        new FormGroup({
          id: new FormControl(employee.id),
          isChecked: new FormControl(false),
        }),
      );
    });

    this.form.valueChanges.pipe(takeUntil(this.notifier)).subscribe((value) => {
      const selectedIds = this.formSelected.value.filter((val) => !!val.isChecked).map((val) => val.id);

      this.selectedEmployees.emit(selectedIds);
    });
  }
}
