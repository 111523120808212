import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CreatePlan, CreatePlanSuccess, IPlan, Role, UsersState } from '@app/data';
import { Navigate, RouterState } from '@ngxs/router-plugin';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { map, take, tap } from 'rxjs/operators';
import { PlansPaths } from '../../plans.paths';

@Component({
  selector: 'pip-new-quote',
  templateUrl: './new-quote.component.html',
  styleUrls: ['./new-quote.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewQuoteComponent {
  public activeUser$ = this.store.select(UsersState.activeUser);
  public activeUserProfile$ = this.store.select(UsersState.activeUserProfile);
  public isGroupRep$ = this.activeUserProfile$.pipe(
    map((profile) => profile?.attributes.insurance_types.includes('Group')),
  );
  public userRoles$ = this.store.select(UsersState.activeUserRoles);
  public isAnalyst$ = this.userRoles$.pipe(map((roles) => roles?.includes(Role.Analyst)));

  constructor(private store: Store, private actions$: Actions) {}

  public onCreatePlan(plan: IPlan): void {
    this.actions$
      .pipe(ofActionSuccessful(CreatePlanSuccess), take(1))
      .subscribe((res) => this.store.dispatch(new Navigate([PlansPaths.ROOT, res.payload.id, PlansPaths.Tasks])));

    this.store.dispatch(new CreatePlan(plan));
  }
}
