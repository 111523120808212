export interface IDependent {
  id: string;
  born_at: string;
  first_name: string;
  last_name: string;
  middle_name: string;
  relationship: string;
  gender: string;
  student: boolean;
  disabled: boolean;

  school_start_at: string;
  school_end_at: string;
  school_name: string;
}

export class Dependent {
  constructor() {}

  public static create(): IDependent {
    return {
      id: undefined,
      born_at: undefined,
      first_name: null,
      last_name: null,
      middle_name: null,
      relationship: null,
      gender: null,
      student: null,
      disabled: null,
      school_start_at: null,
      school_end_at: null,
      school_name: null,
    };
  }
}
