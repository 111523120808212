import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { IndustryDictionary } from '@app/data';
import { Languages } from '@app/data/enums/language.enum';

@Component({
  selector: 'pip-company-info-formgroup',
  templateUrl: './company-info-formgroup.component.html',
  styleUrls: ['./company-info-formgroup.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class CompanyInfoFormgroupComponent implements OnInit {
  public industryOpts = Object.entries(IndustryDictionary).map(([value, label]) => ({ value, label }));
  public languages = Languages;

  constructor(public controlContainer: ControlContainer) {}

  public ngOnInit(): void {}
}
