import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import {
  IPlan,
  MediaModel,
  MediaType,
  PlansState,
  PlanStatus,
  QuotesState,
  Role,
  UpdatePlan,
  UsersState,
} from '@app/data';
import { Store } from '@ngxs/store';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'pip-plan-administration',
  templateUrl: './plan-administration.component.html',
  styleUrls: ['./plan-administration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanAdministrationComponent implements OnInit {
  public carrier$ = this.store.select(QuotesState.selectedCarrier);
  public carrierMediaLinks$ = this.store.select(QuotesState.carrierMediaLinks);
  public plan$ = this.store.select(PlansState.activePlan);
  public planMedia$ = this.store.select(PlansState.activePlanMedia);
  public planRep$ = this.store.select(PlansState.activePlanRep);
  public planAssociate$ = this.store.select(PlansState.activePlanAssociate);
  public userRoles$ = this.store.select(UsersState.activeUserRoles);

  public isAnalyst$ = this.userRoles$.pipe(map((roles) => roles?.includes(Role.Analyst)));
  public isAdvisor$ = this.userRoles$.pipe(map((roles) => roles?.includes(Role.Advisor)));
  public isAdvisorOrAnalyst$ = combineLatest([this.isAnalyst$, this.isAdvisor$]).pipe(
    map(([isAnalyst, isAdvisor]) => isAdvisor || isAnalyst),
  );

  public mediaType = MediaType;
  public mediaModel = MediaModel;
  public planStatus = PlanStatus;

  public showResources$ = this.plan$.pipe(map((plan) => plan?.attributes.status === PlanStatus.Setup));

  constructor(private store: Store) {}

  public ngOnInit(): void {}

  public onUpdate(plan: IPlan): void {
    this.store.dispatch(new UpdatePlan(plan));
  }
}
