import { EmployeesState } from './employees';
import { MediaState } from './media';
import { NotesState } from './notes';
import { OrganizationsState } from './organizations';
import { PaymentInfosState } from './payment-info';
import { PlansState } from './plans';
import { ProfilesState } from './profiles';
import { QuotesState } from './quotes';
import { RatesState } from './rates';
import { RolesState } from './roles';
import { UsersState } from './users';

export const states: any[] = [
  EmployeesState,
  MediaState,
  OrganizationsState,
  PlansState,
  ProfilesState,
  QuotesState,
  RatesState,
  RolesState,
  UsersState,
  NotesState,
  PaymentInfosState,
];

export * from './employees';
export * from './media';
export * from './notes';
export * from './payment-info';
export * from './plans';
export * from './profiles';
export * from './quotes';
export * from './rates';
export * from './roles';
export * from './users';
