<div class="row justify-content-center">
  <div class="col-xl-6">
    <h2>Start Date</h2>
    <pip-field-status-alert
      *ngIf="(plan$ | async)?.meta?.field_statuses?.start_at as start_at"
      [fieldStatus]="start_at"
      key="confirmed"
    ></pip-field-status-alert>
    <pip-start-at-form
      *ngIf="plan$ | async as plan"
      [plan]="plan"
      [terminationLetter]="terminationLetter$ | async"
      confirmButton="Confirm"
      (save)="onUpdatePlan($event)"
      (cancel)="onCancel()"
    ></pip-start-at-form>
  </div>
</div>
