import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IMedia, IPlan } from '@app/data';

@Component({
  selector: 'pip-start-at-form',
  templateUrl: './start-at-form.component.html',
  styleUrls: ['./start-at-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StartAtFormComponent implements OnInit {
  @Input() public plan: IPlan;
  @Input() public confirmButton: string = 'Save';
  @Input() public cancelButton: string = 'Cancel';
  @Input() public terminationLetter: IMedia;

  @Output() save = new EventEmitter<IPlan>();
  @Output() cancel = new EventEmitter<void>();

  public form: FormGroup;

  constructor() {}

  public ngOnInit(): void {
    this.initForm();
  }

  public onSubmit(): void {
    const { id, type } = this.plan;
    const { start_at } = this.form.value;

    const payload: IPlan = { id, type, attributes: { start_at } };

    this.save.emit(payload);
  }

  private initForm(): void {
    this.form = new FormGroup({
      start_at: new FormControl(this.plan.attributes.start_at, [Validators.required]),
    });
  }
}
