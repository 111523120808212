import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from "@angular/core";

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
  private static HEADERS: any = {
    Accept: 'application/json',
  };

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const withHeaders = req.clone({ setHeaders: HeaderInterceptor.HEADERS });

    return next.handle(withHeaders);
  }
}
