<ng-container *ngIf="plan$ | async as plan">
  <nb-card statuc="basic">
    <nb-card-body class="d-flex align-items-center justify-content-between">
      <h1 class="subtitle my-0" #organizationName>
        {{ plan.attributes.organization_name }}
        <small class="caption text-hint d-block">{{ plan.id }}</small>
      </h1>
      <table>
        <tr>
          <th class="caption-2">Start Date</th>
          <td *ngIf="plan.attributes.start_at">{{ plan.attributes.start_at | date: 'longDate' }}</td>
        </tr>
        <tr>
          <th class="caption-2">Renewal Date</th>
          <td>
            <pip-plan-renewal-date [plan]="plan"></pip-plan-renewal-date>
          </td>
        </tr>
      </table>
    </nb-card-body>
  </nb-card>

  <nb-alert
    status="danger"
    *ngIf="plan.attributes.status === planStatus.Closed || plan.attributes.status === planStatus.Aborted"
  >
    {{ plan.attributes.status | planStatus }}
  </nb-alert>

  <div class="col">
    <nb-stepper
      *ngIf="plan.attributes.status !== planStatus.Closed && plan.attributes.status !== planStatus.Aborted"
      [selectedIndex]="activeStatusIdx$ | async"
      [disableStepNavigation]="true"
    >
      <nb-step
        *ngFor="let status of statusProgress; let idx = index"
        [completed]="(activeStatusIdx$ | async) > idx"
        [label]="status | planStatus"
      >
      </nb-step>
    </nb-stepper>
  </div>

  <div class="row">
    <div class="col-2 d-flex flex-column">
      <!-- <ng-container *ngIf="plan.attributes.status === planStatus.Open">
        <hr />
        <a class="d-flex justify-content-start px-1" nbButton status="primary" ghost status="danger" fullWidth [routerLink]="['./', plansPaths.Summaries]">
          <nb-icon icon="checkmark-square-outline"></nb-icon>
          Enroll
        </a>
        <hr />
      </ng-container> -->

      <pip-plan-menu [plan]="plan" [roles]="userRoles$ | async"></pip-plan-menu>
      <hr />
      <button
        class="d-flex justify-content-start px-1"
        nbButton
        fullWidth
        ghost
        size="tiny"
        status="danger"
        *ngIf="(activeStatusIdx$ | async) < 3"
        (click)="cancelPlanDialog(cancelPlanDialogRef, plan, planStatus.Closed)"
      >
        <nb-icon icon="slash-outline"></nb-icon>
        Cancel plan
      </button>
      <button
        class="d-flex justify-content-start px-1"
        nbButton
        fullWidth
        ghost
        size="tiny"
        status="danger"
        *ngIf="(activeStatusIdx$ | async) >= 3"
        (click)="cancelPlanDialog(cancelPlanDialogRef, plan, planStatus.Aborted)"
      >
        <nb-icon icon="slash-outline"></nb-icon>
        Cancel enrollment
      </button>
      <hr />

      <div *ngIf="{ activeStatusIdx: activeStatusIdx$ | async } as data">
        <!-- <button
          type="button"
          nbButton
          status="primary"
          fullWidth
          class="d-block my-2"
          size="small"
          outline
          status="danger"
          *ngIf="data.activeStatusIdx > 1"
          (click)="updateStatus(data.activeStatusIdx - 1, plan)"
        >
          Go back to {{ statusProgress[data.activeStatusIdx - 1] | planStatus }}
        </button> -->
        <button
          type="button"
          nbButton
          status="primary"
          fullWidth
          class="d-block my-2"
          size="small"
          *ngIf="data.activeStatusIdx < statusProgress.length - 1 && showSubmitButton$ | async"
          [disabled]="!(isSubmittable$ | async)"
          (click)="
            statusProgress[data.activeStatusIdx] === planStatus.Signoff
              ? advisorSignoffDialog(signoffDialogRef, plan)
              : updateStatus(data.activeStatusIdx + 1, plan)
          "
        >
          <ng-container [ngSwitch]="data.activeStatusIdx">
            <span *ngSwitchCase="statusProgress.indexOf(planStatus.InProgress)">Submit</span>
            <span *ngSwitchCase="statusProgress.indexOf(planStatus.RTQ)">Submit Proposal</span>
            <span *ngSwitchCase="statusProgress.indexOf(planStatus.Signoff)">Complete Enrollment</span>
            <span *ngSwitchDefault>Move to {{ statusProgress[data.activeStatusIdx + 1] | planStatus }}</span>
          </ng-container>
        </button>
      </div>

      <button class="mt-auto" nbButton status="primary" size="small" (click)="scrollTo(organizationName)">
        Back to top
      </button>
    </div>
    <div class="col-10">
      <router-outlet></router-outlet>
    </div>
  </div>
</ng-container>

<!-- Cancel Dialog -->
<ng-template #cancelPlanDialogRef let-data let-ref="dialogRef">
  <nb-card>
    <nb-card-header>Cancel this plan</nb-card-header>
    <nb-card-body>Are you sure you want to cancel this plan?</nb-card-body>
    <nb-card-footer>
      <button nbButton status="primary" type="button" (click)="ref.close(true)">Yes, cancel plan</button>
      <button nbButton status="primary" ghost type="button" (click)="ref.close(false)">Close</button>
    </nb-card-footer>
  </nb-card>
</ng-template>

<!-- Signoff Dialog -->
<ng-template #signoffDialogRef let-data let-ref="dialogRef">
  <form [formGroup]="data.form" (ngSubmit)="ref.close(true)">
    <nb-card>
      <nb-card-header>Advisor Signoff</nb-card-header>
      <nb-card-body>
        <pip-advisor-signoff-content></pip-advisor-signoff-content>
        <fieldset>
          <div class="form-group">
            <label for="advisor_signature" class="label d-block"
              >Please enter <strong>{{ data.pattern }}</strong> to sign</label
            >
            <input type="text" fullWidth nbInput id="advisor_signature" formControlName="advisor_signature" />
          </div>
        </fieldset>
      </nb-card-body>
      <nb-card-footer>
        <button nbButton status="primary" ghost type="button" (click)="ref.close(false)">Close</button>
        <button [disabled]="data.form.invalid" nbButton status="primary" type="submit">Sign</button>
      </nb-card-footer>
    </nb-card>
  </form>
</ng-template>
