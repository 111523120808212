<ng-container *ngIf="plan$ | async as plan">
  <pip-field-status-alert [fieldStatus]="(plan$ | async)?.meta?.field_statuses?.summaries"></pip-field-status-alert>
  <nb-card status="basic" class="overflow-hidden">
    <nb-card-header>Quotes</nb-card-header>
    <nb-card-body>
      <ng-container [ngSwitch]="(plan$ | async)?.attributes.status">
        <ng-container *ngSwitchCase="planStatus.RTQ">
          <p>
            Invite Carriers below to respond to the RTQ. Once all invited Carriers have responded, the Ingenious
            Insurance Analyst will add a Proposal and you will be notified.
          </p>
        </ng-container>
        <ng-container *ngSwitchCase="planStatus.Proposal">
          <p>If changes were made to original quote, please upload final sold plan before enrolling.</p>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="plan$ | async as plan">
        <ng-container>
          <pip-carrier-document
            *ngFor="let quote of quotes$ | async"
            [quoteId]="quote.id"
            [users]="users$ | async"
            [showDocCtrls]="plan.attributes.status === planStatus.RTQ || plan.attributes.status === planStatus.Proposal"
            [showEnrollCtrls]="plan.attributes.status === planStatus.Proposal"
          ></pip-carrier-document>
        </ng-container>
        <ng-container *ngIf="plan.attributes.status === planStatus.RTQ">
          <pip-carrier-invite
            *ngFor="let carrier of inviteCarriers$ | async"
            [carrier]="carrier"
            [users]="users$ | async"
            (invite)="onCarrierInvite($event, plan?.id, confirmInviteDialog)"
          ></pip-carrier-invite>
        </ng-container>
      </ng-container>
    </nb-card-body>
  </nb-card>
  <ng-container *ngIf="(plan$ | async)?.attributes.status === planStatus.RTQ">
    <a nbButton status="primary" [routerLink]="routes.dashboard">Done Sending Invitations</a>
  </ng-container>

  <nb-card status="basic" class="overflow-hidden" *ngIf="(hideProposalSection$ | async) === false">
    <nb-card-header>Proposal</nb-card-header>
    <nb-card-body>
      <ng-container *ngIf="showMediaUploaders$ | async">
        <div class="row">
          <div class="col d-flex justify-content-center">
            <pip-media-uploader
              class="d-block w-100"
              [existingMedia]="(planMedia$ | async)?.[mediaType.Proposal]"
              [mediaType]="mediaType.Proposal"
              [modelId]="(plan$ | async).id"
              [mediaModel]="mediaModel.Plan"
            ></pip-media-uploader>
          </div>
          <div class="col d-flex justify-content-center">
            <pip-media-uploader
              class="d-block w-100"
              [existingMedia]="(planMedia$ | async)?.[mediaType.Miscellaneous]"
              [mediaType]="mediaType.Miscellaneous"
              [modelId]="(plan$ | async).id"
              [mediaModel]="mediaModel.Plan"
            ></pip-media-uploader>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="!(showMediaUploaders$ | async)">
        <div class="row">
          <div class="col">
            <p>Proposal documents:</p>
            <ul>
              <li *ngFor="let media of mediaProposal$ | async">
                <a [href]="media?.links.self">{{ media?.attributes.name }}</a>
              </li>
            </ul>
          </div>
          <div class="col">
            <p>Additional documents:</p>
            <ul>
              <li *ngFor="let media of mediaMisc$ | async">
                <a [href]="media?.links.self">{{ media?.attributes.name }}</a>
              </li>
            </ul>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="showProposalReviewBtn$ | async">
        <div class="text-center">
          <hr />
          <button nbButton (click)="onProposalReview(plan.id)">Send Proposal for Client Review</button>
        </div>
      </ng-container>
    </nb-card-body>
  </nb-card>

  <ng-container *ngIf="showChangeRequestSection$ | async">
    <pip-deviations-acknowledgement
      [plan]="plan$ | async"
      (confirmDeviations)="onConfirmDeviations($event)"
    ></pip-deviations-acknowledgement>
  </ng-container>
</ng-container>

<ng-template #confirmInviteDialog let-data let-ref="dialogRef">
  <nb-card status="basic">
    <nb-card-header>Confirm invite</nb-card-header>
    <nb-card-body>
      <form [formGroup]="data.form">
        <label>Are you sure you want to invite this carrier? Please select the representative(s) to be notified:</label>
        <ng-container formArrayName="contacts">
          <div *ngFor="let contact of data.contacts; let index = index" [formGroupName]="index">
            <nb-checkbox formControlName="selected">{{ contact.name }}</nb-checkbox>
          </div>
        </ng-container>
      </form>
    </nb-card-body>
    <nb-card-footer>
      <button nbButton status="primary" type="button" (click)="ref.close(true)" [disabled]="data.form.invalid">
        Yes, invite
      </button>
      <button nbButton status="primary" ghost type="button" (click)="ref.close()">No, do not invite</button>
    </nb-card-footer>
  </nb-card>
</ng-template>
