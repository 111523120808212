export enum PaAccess {
  None = 'None',
  Limited = 'Limited',
  Full = 'Full',
}

export const PaAccessDictionary: Record<PaAccess, string> = {
  [PaAccess.None]: 'No agreement selected',
  [PaAccess.Limited]: 'Experience and claims only ',
  [PaAccess.Full]: 'Experience, claims, billing and enrollment',
};
