<div class="legal">
  <p>In consideration of granting access to and use the Internet and Billing site for {{ carrierName }}.</p>

  <p>The Plan Sponsor hereby agrees as follows:</p>

  <ol>
    <li>
      This Agreement, which includes any schedules and appendices attached hereto, applies to any group policy issued by
      {{ carrierName }}, whether issued before or after the effective date of this Agreement.
    </li>
    <li>
      The Plan Sponsor will provide {{ carrierName }} with a list of its employees and agents which may include without limitation advisors
      and third party administrators, if applicable who require access to the Internet and Billing Site for the purposes of plan
      administration and whom the Plan Sponsor has authorized in writing to access the Internet and Billing Site (the "User"). The Plan
      Sponsor will keep such list current and indicate for each User whether any access restrictions are to apply in respect to the Internet
      and Billing Site's Enrolment, Billing or Experience/Claims functions. The Plan Sponsor agrees that its authorization shall be deemed
      to have been given to {{ carrierName }} Life to provide the Plan Sponsor's advisor with access to the Internet and Billing Site's
      Experience/Claims functions as a User. The Plan Sponsor further agrees to notify {{ carrierName }} Life immediately by sending written
      notice to its local {{ carrierName }} office in the event that a User's access to the Internet and Billing Site is to be terminated or
      restricted. {{ carrierName }} Life reserves the right to refuse a User access to the Internet and Billing Site.
    </li>
    <li>
      The Plan Sponsor and Users are granted access to the Internet and Billing Site only for the purpose of administering a Group Policy
      and will not use information obtained through the Internet and Billing Site for any other purpose. The Plan Sponsor is responsible for
      keeping and causing the Users to keep the information obtained through the Internet and Billing Site confidential and is responsible
      for any misuse or unauthorized disclosure by the Plan Sponsor or its Users.
    </li>
    <li>
      The Plan Sponsor authorizes {{ carrierName }} to act upon any instructions and enter any information that may be provided by Users
      relating to a Group Policy.
    </li>
    <li>
      The Plan Sponsor accepts responsibility and agrees that {{ carrierName }} will have no liability for any and all access to and use of
      the Internet and Billing Site by the Users, including without limitation any unauthorized access and use after the User's access has
      been terminated and any access to and use of the Internet and Billing Site by any other person using a User's name and/or password.
    </li>
    <li>
      The Plan Sponsor will indemnify and hold {{ carrierName }} harmless for and from any claims, losses, costs (including without
      limitation all legal fees and disbursements) and damages (including without limitation punitive, exemplary, and aggravated damages)
      arising out of or resulting from any access or use by the Plan Sponsor, Users or by any other person using a User's name and/or
      password.
    </li>
    <li>
      The Plan Sponsor agrees to be bound by the terms and conditions set out in the Internet and Billing Site Legal, Privacy Policy and I
      nternet Security pages contained on the Internet and Billing Site, as amended from time to time, and agrees that such terms and
      conditions shall apply to all use of Internet and Billing Site by Users. The Plan Sponsor shall be responsible for any breach of such
      terms and conditions by it or any User or any other person using a User's name and/or password.
    </li>
    <li>
      The Plan Sponsor may not assign this Agreement. The Plan Sponsor's or any User's right to access and use Internet and Billing Site
      terminates coincident with termination of the Group Policy, or if more than one Group Policy has been issued, then coincident with the
      termination of the last Group Policy in effect. {{ carrierName }} also reserves the right to terminate the Plan Sponsor's or any
      User's right to access and use Internet and Billing Site at any time while a Group Policy is in force. For all other purposes, this
      Agreement shall survive the termination of the Plan Sponsor's and the User's right to access and use Internet and Billing Site and
      shall survive the termination of a Group Policy.
    </li>
    <li>
      The Plan Sponsor agrees that it will and will cause Users to comply with all applicable laws, including any laws governing the use of
      personal information.
    </li>
    <li>
      The terms "Group Policy" and "Plan Sponsor" in this Agreement may be interpreted as "group contract(s)", "Group Contract" and "Group
      Contractholder" respectively where this Agreement is used in connection with an Administrative Services Only (ASO) plan.
    </li>
    <li>
      This Agreement shall take effect on the earlier of the date that the Plan Sponsor or any User is granted access to Internet and
      Billing Site, and the date that {{ carrierName }} receives a signed Agreement.
    </li>
  </ol>
</div>
