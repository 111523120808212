<div class="d-flex align-items-center">
  <h2>Common Rate Properties</h2>
  <button *ngIf="allowEdit" nbButton status="primary" size="tiny" outline class="ml-2" (click)="handleEdit(EditDialog)">
    Edit
  </button>
</div>
<dl class="row">
  <div class="col">
    <fieldset>
      <div class="form-group row">
        <dt class="col col-form-label label">Life Termination Age</dt>
        <dd class="col-6">{{ data?.life_termination_age }}</dd>
      </div>
      <div class="form-group row">
        <dt class="col col-form-label label">STD Termination Age</dt>
        <dd class="col-6">{{ data?.std_termination_age }}</dd>
      </div>
      <div class="form-group row">
        <dt class="col col-form-label label">Critical Illness Termination Age</dt>
        <dd class="col-6">{{ data?.critical_illness_termination_age }}</dd>
      </div>
      <div class="form-group row">
        <dt class="col col-form-label label">Healthcare Termination Age</dt>
        <dd class="col-6">{{ data?.healthcare_termination_age }}</dd>
      </div>
      <div class="form-group row">
        <dt class="col col-form-label label">Dental Termination Age</dt>
        <dd class="col-6">{{ data?.dentalcare_termination_age }}</dd>
      </div>
      <div class="form-group row">
        <dt class="col col-form-label label">Life NEM</dt>
        <dd class="col-6">{{ data?.life_nem | currency }}</dd>
      </div>
      <div class="form-group row">
        <dt class="col col-form-label label">LTD NEM</dt>
        <dd class="col-6">{{ data?.ltd_nem | currency }}</dd>
      </div>
      <div class="form-group row">
        <dt class="col col-form-label label">STD NEM</dt>
        <dd class="col-6">{{ data?.std_nem | currency }}</dd>
      </div>
      <div class="form-group row">
        <dt class="col col-form-label label">CI NEM</dt>
        <dd class="col-6">{{ data?.critical_illness_nem | currency }}</dd>
      </div>
    </fieldset>
  </div>
  <div class="col">
    <fieldset>
      <div class="form-group row">
        <dt class="col col-form-label label">Graded LTD</dt>
        <dd class="col-6">{{ data?.ltd_graded_scale | truthyStatus }}</dd>
      </div>
      <div class="form-group row">
        <dt class="col col-form-label label">Graded STD</dt>
        <dd class="col-6">{{ data?.std_graded_scale | truthyStatus }}</dd>
      </div>
      <div class="form-group row">
        <dt class="col col-form-label label">Daily Pro-Rated</dt>
        <dd class="col-6">{{ data?.pro_rated | truthyStatus }}</dd>
      </div>
      <div class="form-group row">
        <dt class="col col-form-label label">Renewal Months</dt>
        <dd class="col-6">{{ data?.renewal_months }}</dd>
      </div>

      <div class="form-group row" *ngIf="showRenewalCaps">
        <dt class="col col-form-label label">Renewal Caps</dt>
        <dd class="col-6">{{ data?.renewal_caps }}</dd>
      </div>
    </fieldset>
  </div>
</dl>
<div class="row" *ngIf="showRateGuarantee">
  <fieldset>
    <dt class="col col-form-label label">Rate Guarantee</dt>
    <dd class="col">{{ data?.rate_guarantee }}</dd>
  </fieldset>
</div>

<!--  -->
<ng-template #EditDialog let-data let-ref="dialogRef">
  <form class="d-flex" [formGroup]="data.form" (ngSubmit)="handleSubmit(data.form.value)">
    <nb-card status="basic">
      <nb-card-body>
        <pip-common-rate-properties-form-group
          [formGroup]="data.form"
          [showRateGuarantee]="showRateGuarantee"
          [showRenewalCaps]="showRenewalCaps"
        ></pip-common-rate-properties-form-group>
      </nb-card-body>
      <nb-card-footer class="d-flex justify-content-between">
        <button nbButton status="primary" ghost type="button" (click)="ref.close()">Close</button>
        <button nbButton status="primary" type="submit">Save</button>
      </nb-card-footer>
    </nb-card>
  </form>
</ng-template>
