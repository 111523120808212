<fieldset [formGroup]="controlContainer.control">
  <legend class="subtitle">Common Rate Properties</legend>
  <div class="row">
    <div class="col">
      <div class="form-group">
        <label for="life_termination_age" class="label">Life Termination Age</label>
        <input type="number" fullWidth nbInput id="life_termination_age" formControlName="life_termination_age" />
      </div>
      <div class="form-group">
        <label for="std_termination_age" class="label">STD Termination Age</label>
        <input type="number" fullWidth nbInput id="std_termination_age" formControlName="std_termination_age" />
      </div>
      <div class="form-group">
        <label for="critical_illness_termination_age" class="label">Critical Illness Termination Age</label>
        <input
          type="number"
          fullWidth
          nbInput
          id="critical_illness_termination_age"
          formControlName="critical_illness_termination_age"
        />
      </div>
      <div class="form-group">
        <label for="healthcare_termination_age" class="label">Healthcare Termination Age</label>
        <input
          type="number"
          fullWidth
          nbInput
          id="healthcare_termination_age"
          formControlName="healthcare_termination_age"
        />
      </div>
      <div class="form-group">
        <label for="dentalcare_termination_age" class="label">Dental Termination Age</label>
        <input
          type="number"
          fullWidth
          nbInput
          id="dentalcare_termination_age"
          formControlName="dentalcare_termination_age"
        />
      </div>
      <div class="form-group">
        <label for="life_nem" class="label">Life NEM</label>
        <input type="text" fullWidth nbInput id="life_nem" formControlName="life_nem" mask="separator" prefix="$" />
      </div>
      <div class="form-group">
        <label for="ltd_nem" class="label">LTD NEM</label>
        <input type="text" fullWidth nbInput id="ltd_nem" formControlName="ltd_nem" mask="separator" prefix="$" />
      </div>
      <div class="form-group">
        <label for="std_nem" class="label">STD NEM</label>
        <input type="text" fullWidth nbInput id="std_nem" formControlName="std_nem" mask="separator" prefix="$" />
      </div>
      <div class="form-group">
        <label for="critical_illness_nem" class="label">CI NEM</label>
        <input
          type="text"
          fullWidth
          nbInput
          id="critical_illness_nem"
          formControlName="critical_illness_nem"
          mask="separator"
          prefix="$"
        />
      </div>
    </div>
    <div class="col">
      <div class="form-group">
        <label for="ltd_graded_scale" class="label">Graded LTD</label>
        <nb-radio-group name="ltd_graded_scale" class="d-flex" formControlName="ltd_graded_scale">
          <nb-radio [value]="true">Yes</nb-radio>
          <nb-radio [value]="false">No</nb-radio>
        </nb-radio-group>
      </div>
      <div class="form-group">
        <ng-container *ngIf="controlContainer.control.get('ltd_graded_scale').value === false">
          <label for="ltd_flat" class="label">Value</label>
          <input type="number" fullWidth nbInput id="ltd_flat" formControlName="ltd_flat" />
        </ng-container>
      </div>

      <div class="form-group">
        <label for="std_graded_scale" class="label">Graded STD</label>
        <nb-radio-group name="std_graded_scale" class="d-flex" formControlName="std_graded_scale">
          <nb-radio [value]="true">Yes</nb-radio>
          <nb-radio [value]="false">No</nb-radio>
        </nb-radio-group>
      </div>

      <div class="form-group">
        <ng-container *ngIf="controlContainer.control.get('std_graded_scale').value === false">
          <label for="std_flat" class="label">Value</label>
          <input type="number" fullWidth nbInput id="std_flat" formControlName="std_flat" />
        </ng-container>
      </div>

      <div class="form-group">
        <label for="pro_rated" class="label">Daily Pro-rated</label>
        <nb-radio-group name="pro_rated" class="d-flex" formControlName="pro_rated">
          <nb-radio [value]="true">Yes</nb-radio>
          <nb-radio [value]="false">No</nb-radio>
        </nb-radio-group>
      </div>
      <div class="form-group">
        <label for="renewal_months" class="label">Renewal Months</label>
        <input
          type="number"
          min="10"
          max="40"
          step="1"
          fullWidth
          nbInput
          id="renewal_months"
          formControlName="renewal_months"
        />
      </div>
      <div class="form-group" *ngIf="showRenewalCaps">
        <label for="renewal_caps" class="label">Renewal Caps</label>
        <input type="text" fullWidth nbInput id="renewal_caps" formControlName="renewal_caps" />
      </div>
      <ng-container *ngIf="showRateGuarantee">
        <div class="form-group">
          <label for="rate_guarantee_select" class="label">Rate Guarantee</label>
          <nb-select
            fullWidth
            formControlName="rate_guarantee_select"
            (selectedChange)="this.controlContainer.control.get('rate_guarantee_other').setValue(null)"
          >
            <nb-option *ngFor="let opt of rateGuaranteeOpts" [value]="opt">{{ opt }}</nb-option>
            <nb-option value="other">Other</nb-option>
          </nb-select>
        </div>
        <div class="form-group" *ngIf="this.controlContainer.control.get('rate_guarantee_select').value === 'other'">
          <label for="rate_guarantee_other" class="label">Other</label>
          <input type="text" fullWidth nbInput id="rate_guarantee_other" formControlName="rate_guarantee_other" />
        </div>
      </ng-container>
    </div>
  </div>
</fieldset>
