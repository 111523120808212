import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { IDefaultPlan, IPlan, IPlanMedia, MediaModel, MediaType, Plan, PlanStatus, UpdatePlan } from '@app/data';
import { Store } from '@ngxs/store';
import { PlanStartDateComponent } from '../../plan-start-date/plan-start-date.component';

@Component({
  selector: 'pip-insurance-status-form',
  templateUrl: './insurance-status-form.component.html',
  styleUrls: ['./insurance-status-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InsuranceStatusFormComponent implements OnInit, OnChanges {
  @Input() public plan: IPlan;
  @Input() public defaultPlans: IDefaultPlan[];
  @Input() public loading: boolean;
  @Input() public planMedia: IPlanMedia;
  @Input() public showBenefitsBooklet = true;

  public mediaType = MediaType;
  public mediaModel = MediaModel;

  public form: FormGroup;

  public get isVirgin(): boolean {
    return this.form?.get('insuranceStatus').get('virgin').value;
  }

  public get showFileUploads(): boolean {
    return !this.isVirgin;
  }

  public get isVirginAnswered(): boolean {
    return this.isVirgin === true || this.isVirgin === false;
  }

  public get canSave(): boolean {
    return this.isVirginAnswered;
  }

  public get isSaveDisabled(): boolean {
    return this.canSave === false;
  }

  public get showVirginSelection(): boolean {
    return [PlanStatus.InProgress].includes(this.plan.attributes.status);
  }

  constructor(private fb: FormBuilder, private store: Store) {}

  ngOnInit() {
    this.initForm();
  }

  ngOnChanges(changes: SimpleChanges): void {}

  public onSubmit(): void {
    const { id, type } = this.plan;
    const { selectedPlans, ...insuranceStatus } = this.form.get('insuranceStatus').value;
    const options: string[] = [];

    selectedPlans.forEach((p) => {
      if (p.selected) {
        options.push(p.name);
      }
    });

    const plan: IPlan = {
      id,
      type,
      attributes: {
        ...insuranceStatus,
        options,
      },
    };

    this.store.dispatch(new UpdatePlan(plan));
  }

  private initForm(): void {
    const { virgin, ltd, std, requests, eap, virtual_care, critical_illness, health_spending_account } =
      this.plan.attributes || Plan.createAttributes();

    this.form = this.fb.group({
      insuranceStatus: this.fb.group({
        virgin: [virgin],
        ltd: [ltd],
        std: [std],
        eap: [eap],
        critical_illness: [critical_illness],
        health_spending_account: [health_spending_account],
        virtual_care: [virtual_care],
        requests: [requests],
        selectedPlans: this.fb.array(
          this.defaultPlans.map((dp) => {
            return this.fb.group({
              name: dp.id,
              selected:
                this.plan.attributes.options && this.plan.attributes.options.find((o) => o === dp.id) ? true : false,
            });
          }),
        ),
      }),
    });
  }
}
