import { ChangeDetectionStrategy, Component, Input, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CreateEmployee, CreateEmployeeSuccess, DataType, IEmployee } from '@app/data';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { Navigate } from '@ngxs/router-plugin';
import { Actions, ofActionCompleted, ofActionSuccessful, Store } from '@ngxs/store';
import { take } from 'rxjs/operators';

@Component({
  selector: 'pip-create-employee',
  templateUrl: './create-employee.component.html',
  styleUrls: ['./create-employee.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateEmployeeComponent implements OnInit {
  @Input() public planId: string;

  public form: FormGroup;
  public loading: boolean;

  private dialogRef: NbDialogRef<any>;

  constructor(
    private store: Store,
    private actions$: Actions,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private dialog: NbDialogService,
  ) {}

  public ngOnInit(): void {
    this.initForm();
  }

  public startNewQuote(template: TemplateRef<any>): void {
    this.dialogRef = this.dialog.open(template);
  }

  public onSubmit(): void {
    this.loading = true;

    const employee: IEmployee = {
      id: null,
      type: DataType.Employees,
      attributes: { ...this.form.value },
    };

    this.store.dispatch(new CreateEmployee({ employee, planId: this.planId }));

    // Close dialog and navigate to new plan if we get a plan back...
    this.actions$
      .pipe(ofActionSuccessful(CreateEmployeeSuccess), take(1))
      .subscribe(({ payload }: CreateEmployeeSuccess) => {
        this.dialogRef.close();
        this.store.dispatch(new Navigate([payload.id], null, { relativeTo: this.route }));
      });

    this.actions$.pipe(ofActionCompleted(CreateEmployee), take(1)).subscribe(() => {
      this.loading = false;
    });
  }

  private initForm(): void {
    this.form = this.fb.group({
      first_name: [null, Validators.required],
      last_name: [null, Validators.required],
    });
  }
}
