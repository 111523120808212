import { INote } from '../../models';

export class IncludeNotes {
  public static readonly type = '[Notes] Include Notes';
  constructor(public payload: { notes: INote[] }) {}
}

export class GetNotes {
  public static readonly type = '[Notes] Get Notes';
  constructor(public payload: { organizationId: string }) {}
}

export class CreateNote {
  public static readonly type = '[Notes] Create Note';
  constructor(public payload: { organizationId: string; note: INote }) {}
}

export class GetNote {
  public static readonly type = '[Notes] Get Note';
  constructor(public payload: { noteId: string }) {}
}

export class UpdateNote {
  public static readonly type = '[Notes] Update Note';
  constructor(public payload: { note: INote }) {}
}

export class DeleteNote {
  public static readonly type = '[Notes] Delete Note';
  constructor(public payload: { noteId: string }) {}
}
