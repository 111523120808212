<ng-container *ngIf="!employee.signed_at && !employee.complete">
  <button *ngIf="employee.last_invited_at; else InviteBtn" nbButton status="primary" disabled size="tiny" ghost>
    Invited
  </button>
</ng-container>

<ng-template #InviteBtn>
  <button
    *ngIf="showEnrollButton"
    type="button"
    nbButton
    status="primary"
    [status]="allComplete ? 'success' : 'warning'"
    size="tiny"
    ghost
    (click)="onClick()"
  >
    Enroll
  </button>
</ng-template>

<ng-template #confirmInviteDialog let-data let-ref="dialogRef">
  <nb-card status="basic">
    <nb-card-header>Confirm invite</nb-card-header>
    <nb-card-body>
      <p>Are you sure you want to send an invitation to {{ employee.email }}?</p>
    </nb-card-body>
    <nb-card-footer>
      <button nbButton status="primary" type="button" (click)="ref.close(true)">Yes, send invitation</button>
      <button nbButton status="primary" ghost type="button" (click)="ref.close()">No, do not send invitation</button>
    </nb-card-footer>
  </nb-card>
</ng-template>

<ng-template #employeeInfoDialog let-ref="dialogRef">
  <nb-card status="basic">
    <nb-card-header>Employee info</nb-card-header>
    <nb-card-body>
      <nb-alert accent="warning" *ngIf="!employee.email">
        <h2 class="h5">Missing Email</h2>
        <p>Employee email is missing/incomplete.</p>
      </nb-alert>
      <pip-field-status-alert
        *ngIf="!fieldStatuses.occupation_and_earnings.complete"
        [fieldStatus]="fieldStatuses.occupation_and_earnings"
      ></pip-field-status-alert>
    </nb-card-body>
    <nb-card-footer class="d-flex justify-content-end">
      <button nbButton status="primary" ghost (click)="ref.close(false)">Close</button>
    </nb-card-footer>
  </nb-card>
</ng-template>
