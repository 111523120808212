<div class="d-flex">
  <nb-button-group (valueChange)="onFilterComplete($event)">
    <button pressed nbButtonToggle [value]="">All</button>
    <button nbButtonToggle [value]="true">Complete</button>
    <button nbButtonToggle [value]="false">Incomplete</button>
  </nb-button-group>
  <nb-form-field class="ml-3">
    <input #input nbInput type="text" placeholder="Search employees" (change)="onSearch($event.target.value)" />
    <button type="button" nbSuffix nbButton ghost (click)="onSearch(input.value)" title="search">
      <nb-icon icon="search"></nb-icon>
    </button>
  </nb-form-field>
</div>
