import { Meta } from '@angular/platform-browser';
import { IPlan, IRate } from '.';
import { DataType } from '../enums';
import { EarningsFrequency } from '../enums/earnings-frequency.enum';
import { EmployeeStatus } from '../enums/employee-status.enum';
import { IBeneficiary } from './beneficiary';
import { IClaim } from './claim';
import { ICosts } from './costs';
import { IDependent } from './dependent';
import { IFieldStatuses } from './field-statuses';
import { Attributes, Links, Relationship, Relationships, ResourceObject } from './json-api';
import { IMedia } from './media';
import { IBasePaymentInformation, IPaymentInformation } from './payment-information';

export interface IEmployee extends ResourceObject {
  type: DataType.Employees;
  attributes: Partial<IEmployeeAttributes>;
  links?: IEmployeeLinks;
  relationships?: IEmployeeRelationships;
  meta?: IEmployeeMeta;
}

export interface IEmployeeAttributes extends Attributes {
  absent: boolean;
  address1: string;
  address2: string;
  beneficiaries: IBeneficiary[];
  born_at: string;
  city: string;
  claims: IClaim;
  cohabitation_at: string;
  complete: boolean;
  contractor: boolean;
  country: string;
  coverage_status_dental: string;
  coverage_status_health: string;
  coverage_status: string;
  critical_illness_volume_override: number;
  dependents: IDependent[];
  earnings_amount: number;
  earnings_frequency: EarningsFrequency;
  email: string;
  employee_id: string;
  first_name: string;
  gender: string;
  hired_at: string;
  last_invited_at: string;
  last_name: string;
  life_nem_override: number;
  ltd_nem_override: number;
  marital_status: string;
  middle_name: string;
  mobile: string;
  occupation: string;
  optional_benefits: number;
  payment_information: IBasePaymentInformation;
  postal_code: string;
  ramq: boolean;
  revocable: boolean;
  salary_threshold: boolean;
  signature: string;
  signed_at: string;
  spouse_carrier: string;
  spouse_policy_number: string;
  state_employment: string;
  state_residence: string;
  status: EmployeeStatus;
  std_nem_override: number;
  terminated_at: string;
  terminated_confirmed: boolean;
  terminated_reason: string;
  weekly_hours: number;
}

export interface IEmployeeLinks extends Links {
  download: string;
  download_id_card: string;
}

export interface IEmployeeMeta extends Meta {
  costs?: ICosts;
  benefits?: IEmployeeBenefits;
  field_statuses?: IFieldStatuses;
  forms?: IMedia[];
}

export interface IEmployeeBenefits {
  pay_cycle: string;
  contributions: {
    employer: IEmployeeBenefitContribution;
    employee: IEmployeeBenefitContribution;
  };
  benefit_costs: IEmployeeBenefitCost[];
}

export interface IEmployeeBenefitContribution {
  amount: string;
  contribution_percentage: string;
}

export interface IEmployeeBenefitCost {
  benefit: string;
  amount: string;
}

export interface IEmployeeRelationships extends Relationships {
  plans?: Relationship<IPlan>;
  rates?: Relationship<IRate>;
  payment_infos?: Relationship<IPaymentInformation>;
  media?: Relationship<IMedia[]>;
}

export class Employee {
  constructor() {}

  public static create(): IEmployee {
    return {
      id: null,
      type: DataType.Employees,
      attributes: Employee.createAttributes(),
    };
  }

  public static createAttributes(): IEmployeeAttributes {
    return {
      complete: false,
      absent: null,
      address1: null,
      address2: null,
      beneficiaries: [],
      born_at: undefined,
      city: null,
      claims: null,
      cohabitation_at: null,
      contractor: null,
      country: null,
      coverage_status_dental: 'Single',
      coverage_status_health: 'Single',
      coverage_status: null,
      dependents: [],
      earnings_amount: null,
      earnings_frequency: EarningsFrequency.BiWeekly,
      email: null,
      first_name: null,
      gender: null,
      hired_at: null,
      last_invited_at: null,
      last_name: null,
      marital_status: null,
      middle_name: null,
      mobile: null,
      occupation: null,
      payment_information: {
        account_number: null,
        institution_id: null,
        transit: null,
      },
      postal_code: null,
      ramq: null,
      revocable: null,
      salary_threshold: null,
      signature: null,
      signed_at: null,
      spouse_carrier: null,
      spouse_policy_number: null,
      state_employment: null,
      state_residence: null,
      status: null,
      weekly_hours: null,
      employee_id: null,
      life_nem_override: null,
      critical_illness_volume_override: null,
      ltd_nem_override: null,
      std_nem_override: null,
      terminated_at: null,
      terminated_confirmed: false,
      terminated_reason: null,
      optional_benefits: null,
    };
  }
}
