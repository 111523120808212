<form [formGroup]="form" (submit)="onSubmit()">
  <nb-card status="basic" [nbSpinner]="loading" nbSpinnerStatus="primary">
    <nb-card-body>
      <fieldset *ngIf="showStartAt">
        <legend class="subtitle">Start date</legend>

        <div class="form-group row">
          <div class="col">
            <div class="form-group">
              <label class="label">Start date</label>
              <pip-plan-start-date formControlName="start_at"></pip-plan-start-date>
            </div>
            <nb-accordion *ngIf="plan?.meta?.activity?.start_at?.length">
              <nb-accordion-item>
                <nb-accordion-item-header>
                  <nb-icon class="mr-3" status="warning" icon="alert-triangle-outline"></nb-icon>
                  Start date has been modified.
                </nb-accordion-item-header>
                <nb-accordion-item-body class="p-0">
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th>Causer</th>
                        <th>Changed at</th>
                        <th>Old start date</th>
                        <th>New start date</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of plan?.meta?.activity?.start_at">
                        <td>{{ item.causer }}</td>
                        <td>{{ item.created_at | date: 'longDate' }}</td>
                        <td>{{ item.old_value | date: 'longDate':'UTC' }}</td>
                        <td>{{ item.new_value | date: 'longDate':'UTC' }}</td>
                      </tr>
                    </tbody>
                  </table>
                </nb-accordion-item-body>
              </nb-accordion-item>
            </nb-accordion>
          </div>
        </div>
      </fieldset>

      <pip-plan-standards-form-group [plan]="plan" formGroupName="standards"></pip-plan-standards-form-group>
    </nb-card-body>
    <nb-card-footer class="d-flex justify-content-between">
      <button nbButton status="primary" ghost type="button" (click)="cancel.emit()">{{ cancelButton }}</button>
      <button nbButton status="primary" type="submit" [disabled]="!form.valid">{{ confirmButton }}</button>
    </nb-card-footer>
  </nb-card>
</form>
