import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, TemplateRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  Employee,
  EmployeesState,
  GetPlan,
  IEmployee,
  IEmployeeMedia,
  MediaModel,
  MediaType,
  PlansState,
  UpdateEmployee,
} from '@app/data';
import { NbDialogService } from '@nebular/theme';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'pip-attach-employee-id',
  templateUrl: './attach-employee-id.component.html',
  styleUrls: ['./attach-employee-id.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttachEmployeeIdComponent implements OnInit, OnChanges {
  @Input() public employee: IEmployee;

  public media$: Observable<IEmployeeMedia>;

  public form: FormGroup;
  public mediaType = MediaType;
  public mediaModel = MediaModel;

  constructor(private dialog: NbDialogService, private store: Store) {}

  ngOnInit() {}

  public ngOnChanges(): void {
    if (this.employee) {
      this.media$ = this.store.select(EmployeesState.employeeMedia(this.employee.id));
    }
  }

  public onClick(templateRef: TemplateRef<any>): void {
    this.initForm();
    this.dialog.open(templateRef);
  }

  public onSubmit(): void {
    const employee: IEmployee = {
      id: this.employee.id,
      type: this.employee.type,
      attributes: {
        ...this.form.value,
      },
    };

    this.store
      .dispatch(new UpdateEmployee(employee))
      .pipe(take(1))
      .subscribe(() => {
        const plan = this.store.selectSnapshot(PlansState.activePlan);

        if (plan) {
          this.store.dispatch(new GetPlan({ planId: plan.id }));
        }
      });
  }

  private initForm(): void {
    const { employee_id } = this.employee.attributes || Employee.createAttributes();

    this.form = new FormGroup({
      employee_id: new FormControl(employee_id, [Validators.required]),
    });
  }
}
