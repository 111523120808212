<div class="d-flex align-items-center">
  <h2>Overall Details</h2>
  <button *ngIf="canEdit" nbButton status="primary" size="tiny" outline class="ml-2" (click)="handleEdit(EditDialog)">
    Edit
  </button>
</div>
<div>
  <div class="form-group row">
    <dt class="col col-form-label label d-flex align-items-center">
      Effective Date&nbsp;
      <nb-icon
        icon="info-outline"
        nbTooltip="When the client began with the current Carrier (e.g. first Start Date with this Carrier)"
      ></nb-icon>
    </dt>
    <dd class="col-9">{{ plan?.attributes.effective_at | date: 'longDate' }}</dd>
  </div>
  <div class="form-group row">
    <dt class="col col-form-label label d-flex align-items-center">
      Renewal Date&nbsp;
      <nb-icon
        icon="info-outline"
        nbTooltip="Next renewal date calculated based on Renewal Months and the Start Date."
      ></nb-icon>
    </dt>
    <dd class="col-9"><pip-plan-renewal-date [plan]="plan"></pip-plan-renewal-date></dd>
  </div>
  <div class="form-group row">
    <dt class="col col-form-label label d-flex align-items-center">Next Renewal</dt>
    <dd class="col-9">
      {{ plan?.attributes.expire_at | date: 'longDate' }}
    </dd>
  </div>
  <div class="form-group row">
    <dt class="col col-form-label label d-flex align-items-center">
      Client Since&nbsp;
      <nb-icon icon="info-outline" nbTooltip="When the client started with Ingenious Insurance."></nb-icon>
    </dt>
    <dd class="col-9">{{ plan?.attributes.client_at | date: 'longDate' }}</dd>
  </div>
  <div class="form-group row">
    <dt class="col col-form-label label d-flex align-items-center">
      Inception Date&nbsp;
      <nb-icon icon="info-outline" nbTooltip="When the client first had insurance with any Carrier ever."></nb-icon>
    </dt>
    <dd class="col-9">{{ plan?.attributes.incepted_at | date: 'longDate' }}</dd>
  </div>

  <div class="form-group row">
    <dt class="col col-form-label label">Policy Number</dt>
    <dd class="col-9">{{ plan?.attributes.policy_number }}</dd>
  </div>
  <div class="form-group row">
    <dt class="col col-form-label label">Contract</dt>
    <dd class="col-9">
      <ng-container *ngIf="canUploadDocs; else downloadContract">
        <pip-media-uploader
          class="d-block w-100"
          [existingMedia]="planMedia?.Contract"
          [modelId]="plan?.id"
          [mediaType]="mediaType.Contract"
          [mediaModel]="mediaModel.Plan"
        ></pip-media-uploader>
      </ng-container>

      <ng-template #downloadContract>
        <a
          class="mr-1"
          *ngFor="let item of planMedia?.Contract"
          nbButton
          status="primary"
          [href]="item.links.self"
          download="true"
          >Download contract</a
        >
      </ng-template>
    </dd>
  </div>
  <div class="form-group row">
    <dt class="col col-form-label label">
      <span> Carrier </span>
    </dt>
    <dd class="col-9">
      <ng-container *ngIf="carrier">
        {{ carrier?.attributes.name }}
      </ng-container>
      <ng-container #selectCarrier>
        <button
          nbButton
          size="small"
          status="primary"
          outline
          class="ml-3"
          *ngIf="canEdit"
          (click)="handleSelectCarrierDialog(SelectCarrierDialog)"
        >
          Select Carrier
        </button>
      </ng-container>
    </dd>
  </div>
  <div class="form-group row">
    <dt class="col col-form-label label">Carrier Guide</dt>
    <dd class="col-9">
      <a
        *ngIf="carrier?.attributes.name && carrierMediaLinks?.CarrierGuide"
        [href]="carrierMediaLinks.CarrierGuide"
        download="true"
      >
        Download {{ carrier?.attributes.name }}'s carrier guide
      </a>
    </dd>
  </div>
  <div class="form-group row">
    <dt class="col col-form-label label">Carrier Online Access</dt>
    <dd class="col-9">
      <a *ngIf="carrier?.attributes?.online_access_url" [href]="carrier.attributes.online_access_url" target="_blank">
        {{ carrier?.attributes.name }} Login
      </a>
    </dd>
  </div>
  <div class="form-group row">
    <dt class="col col-form-label label">Contacts</dt>
    <dd class="col-9">
      <ng-container *ngIf="canUploadDocs; else downloadBenefitsTeam">
        <pip-media-uploader
          class="d-block w-100"
          [existingMedia]="planMedia?.BenefitsTeam"
          [modelId]="plan?.id"
          [mediaType]="mediaType.BenefitsTeam"
          [mediaModel]="mediaModel.Plan"
        ></pip-media-uploader>
      </ng-container>

      <ng-template #downloadBenefitsTeam>
        <a
          *ngIf="planMedia?.BenefitsTeam?.links?.self"
          nbButton
          status="primary"
          [href]="planMedia.BenefitsTeam.links.self"
          download="true"
        >
          Your Benefits Team
        </a>
      </ng-template>
    </dd>
  </div>
</div>
<!--  -->
<ng-template #EditDialog let-data let-ref="dialogRef">
  <form [formGroup]="data.form" (ngSubmit)="handleSubmit(data.form.value)">
    <nb-card status="basic">
      <nb-card-body>
        <fieldset>
          <legend class="subtitle">Overall Details</legend>
          <div class="form-group row">
            <div class="col">
              <label class="label d-flex align-items-center"
                >Effective date&nbsp;
                <nb-icon
                  icon="info-outline"
                  nbTooltip="When the client began with the current Carrier (e.g. first Start Date with this Carrier)"
                ></nb-icon
              ></label>
              <pip-plan-start-date [allowBackdate]="true" formControlName="effective_at"></pip-plan-start-date>
            </div>
          </div>
          <div class="form-group row">
            <div class="col">
              <label class="label d-flex align-items-center"
                >Start date&nbsp;
                <nb-icon
                  icon="info-outline"
                  nbTooltip="Beginning of the current plan (e.g. start of the most recent renewal)"
                ></nb-icon
              ></label>
              <pip-plan-start-date [allowBackdate]="true" formControlName="start_at"></pip-plan-start-date>
            </div>
          </div>
          <div class="form-group row">
            <label for="incepted_at" class="col col-form-label label d-flex align-items-center"
              >Inception Date&nbsp;
              <nb-icon
                icon="info-outline"
                nbTooltip="When the client first had insurance with any Carrier ever."
              ></nb-icon
            ></label>
            <div class="col-sm-7">
              <input type="date" nbInput fullWidth id="incepted_at" formControlName="incepted_at" />
            </div>
          </div>
          <div class="form-group row">
            <label for="client_at" class="col col-form-label label d-flex align-items-center"
              >Client Since&nbsp;
              <nb-icon icon="info-outline" nbTooltip="When the client started with Ingenious Insurance."></nb-icon
            ></label>
            <div class="col-sm-7">
              <input type="date" nbInput fullWidth id="client_at" formControlName="client_at" />
            </div>
          </div>
          <div class="form-group row">
            <label for="policy_number" class="col col-form-label label">Policy Number</label>
            <div class="col-sm-7">
              <input type="text" nbInput fullWidth id="policy_number" formControlName="policy_number" />
            </div>
          </div>
        </fieldset>
      </nb-card-body>
      <nb-card-footer class="d-flex justify-content-between">
        <button nbButton status="primary" ghost type="button" (click)="ref.close()">Close</button>
        <button nbButton status="primary" type="submit">Save</button>
      </nb-card-footer>
    </nb-card>
  </form>
</ng-template>

<ng-template #SelectCarrierDialog let-data let-ref="dialogRef">
  <form [formGroup]="data.form" (ngSubmit)="handleSelectCarrier(data.form.value.carrier)">
    <nb-card status="basic">
      <nb-card-body>
        <fieldset>
          <legend class="subtitle">Select Carrier</legend>

          <div class="form-group row">
            <nb-radio-group formControlName="carrier">
              <nb-radio *ngFor="let carrier of carriers" [value]="carrier">{{ carrier.attributes.name }}</nb-radio>
            </nb-radio-group>
          </div>
        </fieldset>
      </nb-card-body>
      <nb-card-footer class="d-flex justify-content-between">
        <button nbButton status="primary" ghost type="button" (click)="ref.close()">Close</button>
        <button nbButton status="primary" type="submit" [disabled]="data.form.invalid">Save</button>
      </nb-card-footer>
    </nb-card>
  </form>
</ng-template>
