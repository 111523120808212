import { ChangeDetectionStrategy, Component, Input, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { GetPlan, IncludeEmployees } from '@app/data';
import { MediaModel } from '@app/data/enums';
import { MediaService } from '@app/data/services';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { Actions, Store } from '@ngxs/store';
import { catchError, of, take } from 'rxjs';

@Component({
  selector: 'pip-import-employee-data',
  templateUrl: './import-employee-data.component.html',
  styleUrls: ['./import-employee-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImportEmployeeDataComponent implements OnInit {
  @Input() public planId: string;

  public form: FormGroup;
  public loading: boolean;
  public response: {
    errors: {
      title: string;
      detail: string;
    }[];
    meta: {
      validation_errors: string[];
    };
  };

  private dialogRef: NbDialogRef<any>;

  constructor(
    private store: Store,
    private actions$: Actions,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private dialog: NbDialogService,
    private mediaService: MediaService,
  ) {}

  public ngOnInit(): void {}

  public openModal(template: TemplateRef<any>): void {
    this.dialogRef = this.dialog.open(template);
  }

  public onSubmit(form: HTMLFormElement): void {
    this.loading = true;

    const formData = new FormData(form);
    formData.append('type', 'CarrierCensus');
    formData.append('model_id', this.planId);
    formData.append('model', MediaModel.Plan);

    this.mediaService
      .importEmployeeData(formData)
      .pipe(
        take(1),
        catchError((response) => {
          this.response = response?.error;
          this.loading = false;
          return of(response);
        }),
      )
      .subscribe((res) => {
        if (res.data.length) {
          this.dialogRef.close();
          // this.store.dispatch(new IncludeEmployees(res.data));
          this.store.dispatch(new GetPlan({ planId: this.planId }));
        }

        this.loading = false;
      });
  }
}
