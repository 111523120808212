import { Injectable } from '@angular/core';
import { QuoteStatus } from '@app/data/enums/quote-status.enum';
import {
  ICarrier,
  ICarrierMediaLinks,
  ICarrierQuoteDetail,
  IEmployee,
  IMedia,
  IPlan,
  IQuote,
  IQuoteItem,
  IRate,
  IUser,
  Relationship,
  RelationshipData,
  ResourceObject,
  ResourceObjects,
} from '@app/data/models';
import { QuotesService } from '@app/data/services';
import { RouterStateParams } from '@app/store';
import * as Utils from '@app/utils';
import { NbToastrService } from '@nebular/theme';
import { RouterState, RouterStateModel } from '@ngxs/router-plugin';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { DataType, MediaType } from '../../enums';
import { EmployeesState, EmployeesStateModel } from '../employees';
import { IncludeMedia, MediaState, MediaStateModel } from '../media';
import { PaymentInfosState, PaymentInfosStateModel } from '../payment-info';
import { GetPlan, IncludePlans, PlansState, PlansStateModel } from '../plans';
import { IncludeRates, RatesState, RatesStateModel } from '../rates';
import { IncludeUsers, UsersState, UsersStateModel } from '../users';
import { CreateQuote, GetQuote, GetQuotes, IncludeQuotes, UpdateQuote } from './quotes.actions';

export interface QuotesStateModel {
  entities: EntityMap<IQuote>;
  isLoading: boolean;
}

export const quotesStateDefaults: QuotesStateModel = {
  entities: {},
  isLoading: false,
};

@State<QuotesStateModel>({
  name: DataType.Quotes,
  defaults: quotesStateDefaults,
})
@Injectable()
export class QuotesState {
  constructor(private quoteService: QuotesService, public toastr: NbToastrService) {}

  /**
   * SELECTORS
   */

  @Selector()
  public static isLoading(state: QuotesStateModel): boolean {
    return state.isLoading;
  }

  @Selector()
  public static quotes(state: QuotesStateModel): IQuote[] {
    return Utils.entitiesToArray(state.entities);
  }

  @Selector([QuotesState.quotes, MediaState])
  public static selectedQuoteDocument(state: QuotesStateModel, quotes: IQuote[], media: MediaStateModel) {
    const quote = quotes.find((q) => q.attributes.selected);

    if (!quote) {
      return;
    }

    const document = media.entities[quote.attributes.selected_media_id];

    return document;
  }

  @Selector([QuotesState.quotes, UsersState, PlansState])
  public static quoteItemList(
    state: QuotesStateModel,
    quotes: IQuote[],
    users: UsersStateModel,
    plans: PlansStateModel,
  ): IQuoteItem[] {
    return quotes.map((quote) => {
      const currentPlanId = quote?.relationships?.plans && quote?.relationships?.plans?.data?.id;
      const currentPlan = plans.entities[currentPlanId];
      const planAttr = currentPlan?.attributes;
      const repRel: RelationshipData<IUser> = currentPlan?.relationships?.rep?.data;
      const rep = repRel && users.entities[repRel.id];

      return {
        id: quote?.id,
        company: planAttr.organization_name,
        advisors: repRel && [`${rep.attributes.first_name} ${rep.attributes.last_name}`],
        status: quote && quote.attributes && quote.attributes.status,
        requestedDate: quote && quote.attributes.created_at,
        dueDate: quote && quote.attributes.due_at,
        group: quote.status,
      };
    });
  }

  @Selector([QuotesState.quoteItemList])
  public static requestToQuoteItemLIst(state: QuotesStateModel, quoteItemList: IQuoteItem[]): IQuoteItem[] {
    return quoteItemList.filter((q) => q.group === 'RequestToQuote');
  }

  @Selector([QuotesState.quoteItemList])
  public static soldQuoteItemLIst(state: QuotesStateModel, quoteItemList: IQuoteItem[]): IQuoteItem[] {
    return quoteItemList.filter((q) => q.group === 'Sold');
  }

  @Selector([QuotesState.quoteItemList])
  public static deadQuoteItemLIst(state: QuotesStateModel, quoteItemList: IQuoteItem[]): IQuoteItem[] {
    return quoteItemList.filter((q) => q.group === 'Dead');
  }

  @Selector([RouterState, PlansState, MediaState, EmployeesState, RatesState, PaymentInfosState])
  public static activeQuote(
    state: QuotesStateModel,
    router: RouterStateModel<RouterStateParams>,
    plansState: PlansStateModel,
    mediaState: MediaStateModel,
    employeesState: EmployeesStateModel,
    ratesState: RatesStateModel,
    paymentInfosState: PaymentInfosStateModel,
  ): ICarrierQuoteDetail {
    const quoteId = router.state.params.quoteId;
    const quote = state.entities[quoteId];
    const planId = quote?.relationships?.plans?.data?.id;
    const plan = plansState.entities[planId];

    if (!quote || !plan) {
      return;
    }

    const planAttr = plan?.attributes;
    const planRel = plan?.relationships;
    const planMediaRel = planRel?.media?.data;
    const planMedia = this.getMedia(planMediaRel, mediaState.entities);
    const mediaRel = quote && quote.relationships.media && quote.relationships.media.data;
    const media = this.getMedia(mediaRel, mediaState.entities).filter((m) => !!m);

    const carrierQuoteDetail: ICarrierQuoteDetail = {
      id: quote?.id,
      administrator: planAttr?.administrator,
      companyInfo: (planAttr as any).organization_info, // @todo Fix plan model... company_info is now organization_info
      companyName: planAttr?.organization_name,
      employeeCount: planRel && planRel.employees?.total,
      employeeDownload: plan?.links && plan.links.employees,
      media,
      notes: quote?.attributes && quote.attributes.notes,
      planFeatures: planAttr?.features,
      planId: plan?.id,
      planLTD: planAttr?.ltd,
      planMedia,
      planOptions: planAttr?.options,
      planOptionsDownloadLink: plan?.links?.options,
      planRequests: planAttr?.requests,
      planSTD: planAttr?.std,
      planVirgin: planAttr?.virgin,
      status: quote?.attributes?.status,
      planEAP: planAttr?.eap,
      planVirtualCare: planAttr?.virtual_care,
      criticalIllness: planAttr?.critical_illness,
      healthSpendingAccount: planAttr?.health_spending_account,
      startDate: planAttr?.start_at,
      planStandards: planAttr?.standards,
      employees: (plan?.relationships?.employees as Relationship<IEmployee[]>).data.map((e) => {
        const employee = employeesState.entities[e.id];
        const eMedia =
          employee?.relationships?.media && this.getMedia(employee?.relationships?.media?.data, mediaState.entities);
        const eoi =
          eMedia && eMedia.filter((m) => !!m).find((m) => m.attributes.type === MediaType.EvidenceOfInsurability);

        return {
          ...employee,
          links: {
            ...employee.links,
            evidence_of_insurability: eoi?.links?.self,
          },
        };
      }),
      employeeLinks: plan?.relationships?.employees?.links,
      planAdminSignedAt: planAttr?.pa_signed_at,
      planAdminSignedBy: planAttr?.pa_signature,
      planAdminSignedProvince: planAttr?.pa_signature_province,
      advisorSignedAt: planAttr?.advisor_signed_at,
      advisorSignedBy: planAttr?.advisor_signature,
      carrier: plansState?.carriers[quote?.relationships?.carrier?.data.id],
      carrierSignedAt: quote?.attributes.signed_at,
      carrierSignedBy: quote?.attributes.signature,
      paAccess: planAttr?.pa_access,
      planStatus: planAttr?.status,
      planDeviations: planAttr?.deviations,
      predecessorPlan: plansState?.entities[plan.relationships?.predecessor?.data?.id],
      predecessorPlanRates: quote?.relationships?.predecessor_rates?.data?.map((r) => {
        const rate = ratesState.entities[r.id];

        if (!rate) {
          return;
        }

        const rMedia =
          rate?.relationships?.media?.data && this.getMedia(rate?.relationships?.media?.data, mediaState.entities);

        return {
          ...rate,
          media: {
            [MediaType.RateBenefitsBooklet]: rMedia?.find((m) => m?.attributes?.type === MediaType.RateBenefitsBooklet),
            [MediaType.RateWelcomePackage]: rMedia?.filter((m) => m?.attributes?.type === MediaType.RateWelcomePackage),
          },
        };
      }),
      planPaymentInfos: plan?.relationships?.payment_infos?.data?.map((paymentInfoRel) => {
        const paymentInfo = paymentInfosState.entities[paymentInfoRel.id];
        const piMedia = this.getMedia(paymentInfo?.relationships?.media?.data, mediaState.entities);

        return {
          ...paymentInfo,
          media: {
            [MediaType.VoidCheque]: piMedia?.find((m) => m?.attributes?.type === MediaType.VoidCheque),
          },
        };
      }),
    };

    return carrierQuoteDetail;
  }

  @Selector([PlansState.activePlan, PlansState.planCarriers])
  public static carriersToInvite(state: QuotesStateModel, plan: IPlan, allCarriers: ICarrier[]): ICarrier[] {
    const planQuotes =
      plan.relationships &&
      plan.relationships.quotes &&
      plan.relationships.quotes.data &&
      (plan.relationships.quotes.data as IQuote[]).map((q) => state.entities[q.id]);
    const currentCarrierIds = planQuotes.map((q) => q.relationships.carrier.data.id);
    const carriersToInvite = allCarriers.filter((c) => !currentCarrierIds.includes(c.id));

    return carriersToInvite.sort((a, b) => (a.attributes.name > b.attributes.name ? 1 : -1));
  }

  @Selector([PlansState.activePlan, PlansState])
  public static selectedCarrier(state: QuotesStateModel, plan: IPlan, plans: PlansStateModel): ICarrier {
    const quote =
      plan &&
      plan.relationships &&
      plan.relationships.quotes &&
      (plan.relationships.quotes.data as ResourceObjects)
        .map((q) => state.entities[q.id])
        .find((q) => q.attributes.selected);
    const carrier = quote && plans.carriers[quote.relationships.carrier.data.id];

    return carrier;
  }

  @Selector([QuotesState.selectedCarrier, MediaState])
  public static carrierMediaLinks(
    state: QuotesStateModel,
    carrier: ICarrier,
    media: MediaStateModel,
  ): ICarrierMediaLinks {
    const carrierMedia =
      carrier && (carrier.relationships.media.data as ResourceObjects).map((m) => media.entities[m.id]);

    const evidenceOfInsurability =
      carrierMedia && carrierMedia.find((m) => m.attributes.type === MediaType.EvidenceOfInsurability);
    const secureOnlineAccess =
      carrierMedia && carrierMedia.find((m) => m.attributes.type === MediaType.SecureOnlineAccess);
    const masterApplication =
      carrierMedia && carrierMedia.find((m) => m.attributes.type === MediaType.MasterApplication);
    const payorAuthorization =
      carrierMedia && carrierMedia.find((m) => m.attributes.type === MediaType.PayorAuthorization);
    const absentFromWork = carrierMedia && carrierMedia.find((m) => m.attributes.type === MediaType.AbsentFromWork);
    const salesTaxForm = carrierMedia && carrierMedia.find((m) => m.attributes.type === MediaType.SalesTaxForm);
    const trusteeAppointment =
      carrierMedia && carrierMedia.find((m) => m.attributes.type === MediaType.TrusteeAppointment);
    const carrierGuide = carrierMedia && carrierMedia.find((m) => m.attributes.type === MediaType.CarrierGuide);

    return {
      [MediaType.EvidenceOfInsurability]: evidenceOfInsurability && (evidenceOfInsurability.links.self as string),
      [MediaType.SecureOnlineAccess]: secureOnlineAccess && (secureOnlineAccess.links.self as string),
      [MediaType.MasterApplication]: masterApplication && (masterApplication.links.self as string),
      [MediaType.PayorAuthorization]: payorAuthorization && (payorAuthorization.links.self as string),
      [MediaType.AbsentFromWork]: absentFromWork && (absentFromWork.links.self as string),
      [MediaType.SalesTaxForm]: salesTaxForm && (salesTaxForm.links.self as string),
      [MediaType.TrusteeAppointment]: trusteeAppointment && (trusteeAppointment.links.self as string),
      [MediaType.CarrierGuide]: carrierGuide && (carrierGuide.links.self as string),
    };
  }

  /**
   * ACTIONS
   */

  @Action(IncludeQuotes)
  public includePlans(ctx: StateContext<QuotesStateModel>, action: IncludeQuotes) {
    ctx.setState(
      patch<QuotesStateModel>({
        entities: patch(Utils.createEntities(action.payload, 'id')),
      }),
    );
  }

  @Action(GetQuotes)
  public async getQuotes(ctx: StateContext<QuotesStateModel>, action: GetQuotes) {
    ctx.patchState({ isLoading: true });

    const res = await this.quoteService.getQuotes(action.payload.status).toPromise();

    const entities = res && res.data && res.data.map((q) => ({ ...q, status: action.payload.status }));

    ctx.setState(
      patch<QuotesStateModel>({
        entities: patch(Utils.createEntities(entities, 'id')),
        isLoading: false,
      }),
    );

    this.updateStores(ctx, res.included);
  }

  @Action(GetQuote)
  public async getQuote(ctx: StateContext<QuotesStateModel>, action: GetQuote) {
    ctx.patchState({ isLoading: true });

    const res = await this.quoteService.getQuote(action.payload.quoteId).toPromise();
    const quote = res.data;

    // Load the plan so that its media is included
    ctx.dispatch(new GetPlan({ planId: quote.relationships.plans.data.id }));

    ctx.setState(
      patch<QuotesStateModel>({
        entities: patch({ [quote.id]: quote }),
        isLoading: false,
      }),
    );

    this.updateStores(ctx, res.included);
  }

  @Action(CreateQuote)
  public async createQuote(ctx: StateContext<QuotesStateModel>, action: CreateQuote) {
    const { carrierId, planId, contacts } = action.payload;

    const res = await this.quoteService.createQuote(planId, carrierId, contacts).toPromise();
    const quote = res.data;

    ctx.setState(
      patch<QuotesStateModel>({
        entities: patch({ [quote.id]: quote }),
      }),
    );

    this.updateStores(ctx, res.included);
  }

  @Action(UpdateQuote)
  public async updateQuote(ctx: StateContext<QuotesStateModel>, action: UpdateQuote) {
    const res = await this.quoteService.updateQuote(action.payload.quote).toPromise();
    const quote = res.data;

    ctx.setState(
      patch<QuotesStateModel>({
        entities: patch({ [quote.id]: quote }),
      }),
    );

    if (res && quote) {
      quote.attributes.status === QuoteStatus.Submitted
        ? this.toastr.success(`Your quote(s) has been successfully submitted`, 'Quote(s) Successfully Submitted')
        : this.toastr.success(`Your quote(s) has been successfully updated`, 'Quote(s) Successfully Updated');
    }

    this.updateStores(ctx, res.included);
  }

  /**
   * HELPER METHODS
   */

  private updateStores(ctx: StateContext<QuotesStateModel>, included: ResourceObject[]) {
    const plans = included.filter((i) => i.type === DataType.Plans) as IPlan[];
    const advisors = included.filter((i) => i.type === DataType.Users) as IUser[];
    const media = included.filter((i) => i.type === DataType.Media) as IMedia[];
    const rates = included.filter((i) => i.type === DataType.Rates) as IRate[];
    const quotes = included.filter((i) => i.type === DataType.Quotes) as IQuote[];

    if (quotes.length) {
      ctx.dispatch(new IncludeQuotes(quotes));
    }

    if (plans.length) {
      ctx.dispatch(new IncludePlans(plans));
    }

    if (advisors.length) {
      ctx.dispatch(new IncludeUsers(advisors));
    }

    if (media.length) {
      ctx.dispatch(new IncludeMedia(media));
    }

    if (rates.length) {
      ctx.dispatch(new IncludeRates(rates));
    }
  }

  private static getAdvisors(advisorRel, userEntities: EntityMap<IUser>): string[] {
    return (
      advisorRel &&
      (advisorRel as RelationshipData<IUser>[]).map((advisor) => {
        const user = userEntities[advisor.id];
        return user && `${user.attributes.first_name} ${user.attributes.last_name}`;
      })
    );
  }

  private static getMedia(mediaRel, mediaEntities: EntityMap<IMedia>): IMedia[] {
    if (!mediaRel || !mediaEntities) {
      return;
    }

    return (mediaRel as RelationshipData<IMedia>[])?.map((media) => {
      return mediaEntities[media.id];
    });
  }

  private static filterPlanMedia(media: IMedia[]): IMedia[] {
    return media.filter((m) => {
      return m && (m.attributes.type === MediaType.Renewal || m.attributes.type === MediaType.BenefitsBooklet);
    });
  }
}
