import { Injectable } from '@angular/core';
import { RouterStateParams } from '@app/store';
import * as Utils from '@app/utils';
import { NbToastrService } from '@nebular/theme';
import { RouterState, RouterStateModel } from '@ngxs/router-plugin';
import { Action, createSelector, Selector, State, StateContext } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { tap } from 'rxjs/operators';
import { DataType, MediaType } from '../../enums';
import * as Data from '../../models';
import { EmployeesService } from '../../services/employees.service';
import { IncludeMedia } from '../media/media.actions';
import { MediaState, MediaStateModel } from '../media/media.state';
import { AddPlanEmployees, IncludePlans } from '../plans/plans.actions';
import { IncludeRates, RatesState, RatesStateModel } from '../rates';
import {
  CreateEmployee,
  CreateEmployeeSuccess,
  DeleteEmployee,
  EmployeeConfirmations,
  GetAuthUserEmployees,
  GetEmployee,
  GetUserEmployees,
  GetUserEmployeesSuccess,
  IncludeEmployees,
  UpdateEmployee,
} from './employees.actions';
import { PlansState, PlansStateModel } from '../plans';

export interface EmployeesStateModel {
  entities: EntityMap<Data.IEmployee>;
  loading: boolean;
}

export const employeesStateDefaults: EmployeesStateModel = {
  entities: {},
  loading: false,
};

@State<EmployeesStateModel>({
  name: DataType.Employees,
  defaults: employeesStateDefaults,
})
@Injectable()
export class EmployeesState {
  @Selector()
  public static employees(state: EmployeesStateModel): Data.IEmployee[] {
    return Utils.entitiesToArray(state.entities);
  }

  @Selector()
  public static loading(state: EmployeesStateModel): boolean {
    return state.loading;
  }

  @Selector([RouterState])
  public static activeEmployee(
    employees: EmployeesStateModel,
    router: RouterStateModel<RouterStateParams>,
  ): Data.IEmployee {
    return employees.entities[router.state.params.employeeId];
  }

  @Selector([EmployeesState.employees])
  public static defaultEmployee(state: EmployeesStateModel, employees: Data.IEmployee[]): Data.IEmployee {
    return employees[0];
  }

  @Selector([EmployeesState.activeEmployee, MediaState])
  public static activeEmployeeMedia(
    employees: EmployeesStateModel,
    employee: Data.IEmployee,
    media: MediaStateModel,
  ): Data.IEmployeeMedia {
    return EmployeesState.getEmployeeMedia(employee, media);
  }

  @Selector([EmployeesState.activeEmployee, RatesState])
  public static activeEmployeeRates(
    employees: EmployeesStateModel,
    employee: Data.IEmployee,
    ratesState: RatesStateModel,
  ): Data.IRate {
    const rate = ratesState.entities[employee.relationships.rates.data.id];
    return rate;
  }

  // Custom Media Selector
  public static employeeMedia(employeeId: string) {
    return createSelector([EmployeesState, MediaState], (employees: EmployeesStateModel, media: MediaStateModel) => {
      const employee = employees.entities[employeeId];
      return EmployeesState.getEmployeeMedia(employee, media);
    });
  }

  // Custom Rate Selector
  public static employeeRates(employeeId: string) {
    return createSelector([EmployeesState, RatesState], (employees: EmployeesStateModel, rates: RatesStateModel) => {
      const employee = employees.entities[employeeId];
      const employeeRates = rates.entities[employee.relationships.rates.data.id];

      return employeeRates;
    });
  }

  @Action(EmployeeConfirmations)
  public async employeeConfirmations(
    ctx: StateContext<EmployeesStateModel>,
    { payload: { confirmation, employeeId } }: EmployeeConfirmations,
  ) {
    ctx.patchState({ loading: true });
    const state = ctx.getState();
    const res = await this.employees.employeeConfirmation(employeeId, confirmation).toPromise();

    ctx.setState({
      ...state,
      loading: false,
      entities: { ...state.entities, ...Utils.createEntities(res ? [res.data] : [], 'id') },
    });

    this.updateStores(ctx, res.included);

    if (res && res.data) {
      this.toastr.success(
        `${res.data.attributes.first_name} ${res.data.attributes.last_name} was updated.`,
        'Update successful',
      );
    }
  }

  constructor(public employees: EmployeesService, public toastr: NbToastrService) {}

  @Action(GetAuthUserEmployees)
  public async getAuthUserEmployees(ctx: StateContext<EmployeesStateModel>, action: GetAuthUserEmployees) {
    ctx.patchState({ loading: true });

    const res = await this.employees.getAuthUserEmployees().toPromise();
    const employees = res.data;

    ctx.patchState({ entities: Utils.createEntities(employees, 'id'), loading: false });

    this.updateStores(ctx, res.included);

    ctx.dispatch(new GetUserEmployeesSuccess());
  }

  @Action(GetUserEmployees)
  public async getUserEmployees(ctx: StateContext<EmployeesStateModel>, action: GetUserEmployees) {
    ctx.patchState({ loading: true });

    const res = await this.employees.getUserEmployees(action.payload.userId).toPromise();
    const employees = res.data;

    ctx.patchState({ entities: Utils.createEntities(employees, 'id'), loading: false });

    this.updateStores(ctx, res.included);

    ctx.dispatch(new GetUserEmployeesSuccess());
  }

  @Action(IncludeEmployees)
  public includeUsers(ctx: StateContext<EmployeesStateModel>, action: IncludeEmployees) {
    ctx.setState(
      patch<EmployeesStateModel>({
        entities: patch(Utils.createEntities(action.payload, 'id')),
      }),
    );
  }

  @Action(GetEmployee)
  public async getEmployee(ctx: StateContext<EmployeesStateModel>, action: GetEmployee) {
    const employee = await this.employees.getEmployee(action.payload.employeeId).toPromise();
    const state = ctx.getState();

    ctx.setState({
      ...state,
      entities: { ...state.entities, ...Utils.createEntities([employee.data], 'id') },
    });

    // ctx.dispatch(new GetPlan({ planId: employee.data.relationships.plans.data.id }));

    const included =
      action.payload.updatePlan === false
        ? employee.included.filter((i) => i.type !== DataType.Plans)
        : employee.included;

    this.updateStores(ctx, included);
  }

  @Action(CreateEmployee)
  public createEmployee(ctx: StateContext<EmployeesStateModel>, action: CreateEmployee) {
    return this.employees.createEmployee(action.payload.employee, action.payload.planId).pipe(
      tap((res) => {
        const employee = res.data;

        ctx.setState(
          patch<EmployeesStateModel>({
            entities: patch({ [employee.id]: employee }),
          }),
        );

        ctx.dispatch([
          new AddPlanEmployees({ planId: action.payload.planId, employeeIds: [employee.id] }),
          new CreateEmployeeSuccess({ id: employee.id }),
        ]);
      }),
    );
  }

  @Action(UpdateEmployee)
  public updateEmployee(ctx: StateContext<EmployeesStateModel>, action: UpdateEmployee) {
    return this.employees.updateEmployee(action.payload).pipe(
      tap((res) => {
        const employee = res.data;

        ctx.setState(
          patch<EmployeesStateModel>({
            entities: patch({ [employee.id]: employee }),
          }),
        );

        this.toastr.success(
          `${employee.attributes.first_name} ${employee.attributes.last_name} was updated.`,
          'Update successful',
        );
      }),
    );
  }

  @Action(DeleteEmployee)
  public deleteEmployee(ctx: StateContext<EmployeesStateModel>, action: DeleteEmployee) {
    const id = action.payload;
    const employee = ctx.getState().entities[id];

    return this.employees.deleteEmployee(id).pipe(
      tap((res) => {
        this.toastr.success(
          `${employee.attributes.first_name} ${employee.attributes.last_name} was deleted.`,
          'Delete successful',
        );

        const { [id]: removed, ...entities } = ctx.getState().entities;

        ctx.patchState({
          entities,
        });
      }),
    );
  }

  private static getEmployeeMedia(employee: Data.IEmployee, media: MediaStateModel): Data.IEmployeeMedia {
    const employeeMedia =
      (employee.relationships.media.data as Data.RelationshipData<Data.IMedia>[])
        .filter((i) => media.entities[i.id])
        .map((i) => media.entities[i.id]) || [];
    return {
      [MediaType.EmployeeHealth]: employeeMedia.find((i) => i.attributes.type === MediaType.EmployeeHealth),
      [MediaType.EmployeeApplication]: employeeMedia.find((i) => i.attributes.type === MediaType.EmployeeApplication),
      [MediaType.EvidenceOfInsurability]: employeeMedia.find(
        (i) => i.attributes.type === MediaType.EvidenceOfInsurability,
      ),
      [MediaType.TrusteeAppointment]: employeeMedia.filter((i) => i.attributes.type === MediaType.TrusteeAppointment),
      [MediaType.EmployeeIdCard]: employeeMedia.find((i) => i.attributes.type === MediaType.EmployeeIdCard),
      [MediaType.BenefitsTeam]: employeeMedia.find((i) => i.attributes.type === MediaType.BenefitsTeam),
      [MediaType.RateWelcomePackage]: employeeMedia.filter((i) => i.attributes.type === MediaType.RateWelcomePackage),
      [MediaType.RateBenefitsBooklet]: employeeMedia.find((i) => i.attributes.type === MediaType.RateBenefitsBooklet),
    };
  }

  private updateStores(ctx: StateContext<EmployeesStateModel>, included: Data.ResourceObject[]) {
    const media = included && (included.filter((i) => i.type === DataType.Media) as Data.IMedia[]);
    const rates = included && (included.filter((i) => i.type === DataType.Rates) as Data.IRate[]);
    const plans = included && (included.filter((i) => i.type === DataType.Plans) as Data.IPlan[]);

    if (media && media.length) {
      ctx.dispatch(new IncludeMedia(media));
    }

    if (rates && rates.length) {
      ctx.dispatch(new IncludeRates(rates));
    }
    if (plans && plans.length) {
      ctx.dispatch(new IncludePlans(plans));
    }
  }
}
