import { State, Action, StateContext, Selector } from '@ngxs/store';
import * as Utils from '@app/utils';
import { IncludeRoles, GetRoles } from './roles.action';
import { patch } from '@ngxs/store/operators';
import { IRole } from '@app/data';
import { DataType } from '@app/data/enums';
import { RolesService } from '@app/data/services';
import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';

export interface RolesStateModel {
  entities: EntityMap<IRole>;
}

export const rolesStateDefaults: RolesStateModel = {
  entities: {},
};

@State<RolesStateModel>({
  name: DataType.Roles,
  defaults: rolesStateDefaults,
})
@Injectable()
export class RolesState {
  constructor(private rolesService: RolesService) {}

  @Selector()
  public static roles(state: RolesStateModel) {
    return Utils.entitiesToArray(state.entities);
  }

  @Action(GetRoles)
  public async getRoles(ctx: StateContext<RolesStateModel>, action: GetRoles) {
    const res = await this.rolesService.getRoles().toPromise();

    ctx.setState(
      patch<RolesStateModel>({
        entities: patch(Utils.createEntities(res.data, 'id')),
      }),
    );
  }

  @Action(IncludeRoles)
  public includeRoles(ctx: StateContext<RolesStateModel>, action: IncludeRoles): void {
    ctx.setState(
      patch<RolesStateModel>({
        entities: patch(Utils.createEntities(action.payload, 'id')),
      }),
    );
  }
}
