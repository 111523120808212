import { Language } from '@app/data/enums/language.enum';

export interface ICompanyInfo {
  industry: string;
  website: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  country: string;
  postal_code: string;
  language: Language;
}

export class CompanyInfo {
  public static create(): ICompanyInfo {
    return {
      industry: null,
      website: null,
      address1: null,
      address2: null,
      city: null,
      state: null,
      country: null,
      postal_code: null,
      language: Language.English,
    };
  }
}
