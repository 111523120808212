export enum BenefitsContinuation {
  'Zero' = 0,
  'Three' = 3,
  'Six' = 6,
  'Nine' = 9,
  'Twelve' = 12,
}

export const BenefitsContinuationDictionary: Record<BenefitsContinuation, string> = {
  [BenefitsContinuation.Zero]: '0',
  [BenefitsContinuation.Three]: '3',
  [BenefitsContinuation.Six]: '6',
  [BenefitsContinuation.Nine]: '9',
  [BenefitsContinuation.Twelve]: '12',
};

export const BenefitsContinuations = Object.entries(BenefitsContinuationDictionary).map(([value, label]) => ({
  value: parseInt(value),
  label,
}));
