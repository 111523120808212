import { ChangeDetectionStrategy, Component, Input, OnInit, TemplateRef } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
  DataType,
  DeletePaymentInfo,
  IPaymentInformation,
  IPaymentInformationAttributes,
  IPlan,
  MediaModel,
  MediaType,
  MediaTypeDictionary,
  PaymentInformation,
  UpdatePaymentInfo,
} from '@app/data';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { take } from 'rxjs';

@Component({
  selector: 'pip-payment-information-properties',
  templateUrl: './payment-information-properties.component.html',
  styleUrls: ['./payment-information-properties.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentInformationPropertiesComponent implements OnInit {
  @Input() public paymentInfo: IPaymentInformation;
  @Input() public plan: IPlan;
  @Input() public allowEdit = true;

  private dialogRef: NbDialogRef<any>;

  public mediaType = MediaType;
  public mediaModel = MediaModel;
  public mediaDictionary = MediaTypeDictionary;

  constructor(private store: Store, private dialog: NbDialogService, private actions$: Actions) {}

  public ngOnInit(): void {}

  public handleDelete(dialogRef: TemplateRef<any>): void {
    this.dialog
      .open(dialogRef)
      .onClose.pipe(take(1))
      .subscribe((shouldDelete) => {
        if (shouldDelete) {
          this.store.dispatch(new DeletePaymentInfo(this.paymentInfo.id));
        }
      });
  }

  public handleEdit(templateRef: TemplateRef<any>): void {
    const { attributes } = this.paymentInfo;
    const { account_number, institution_id, name, transit } = attributes || PaymentInformation.createAttributes();

    const form = new FormGroup({
      name: new FormControl(name),
      account_number: new FormControl(account_number),
      institution_id: new FormControl(institution_id),
      transit: new FormControl(transit),
    });

    this.dialogRef = this.dialog.open(templateRef, { context: { form }, hasScroll: false });
  }

  public onEdit(formValues: IPaymentInformationAttributes): void {
    const paymentInfo: IPaymentInformation = {
      id: this.paymentInfo.id,
      type: DataType.PaymentInformation,
      attributes: {
        ...formValues,
      },
    };

    this.actions$.pipe(ofActionSuccessful(UpdatePaymentInfo), take(1)).subscribe(() => this.dialogRef.close());

    this.store.dispatch(new UpdatePaymentInfo(paymentInfo));
  }
}
