import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ControlContainer } from '@angular/forms';

@Component({
  selector: 'pip-employee-dependent-formgroup',
  templateUrl: './employee-dependent-formgroup.component.html',
  styleUrls: ['./employee-dependent-formgroup.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class EmployeeDependentFormgroupComponent implements OnInit {
  constructor(public controlContainer: ControlContainer) {}

  public ngOnInit(): void {}
}
