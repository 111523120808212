import { ChangeDetectionStrategy, Component, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DeleteEmployee, IEmployee, IFieldStatuses, IPlan, IRate, PlanConfirmations, PlansState } from '@app/data';
import { PlanConfirmation } from '@app/data/enums/plan-confirmation.enum';
import { NbDialogService } from '@nebular/theme';
import { Navigate } from '@ngxs/router-plugin';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

@Component({
  selector: 'pip-quote-task-employees',
  templateUrl: './quote-task-employees.component.html',
  styleUrls: ['./quote-task-employees.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuoteTaskEmployeesComponent implements OnInit {
  public plan$: Observable<IPlan> = this.store.select(PlansState.activePlan);
  public rates$: Observable<IRate[]> = this.store.select(PlansState.activePlanRates);
  public employees$: Observable<IEmployee[]> = this.store.select(PlansState.activePlanEmployees);

  public isSubmittable$: Observable<boolean> = this.employees$.pipe(
    map(
      (employees) =>
        employees &&
        employees.every((employee) => Object.values(employee.meta.field_statuses).every((fs) => fs.complete)),
    ),
  );

  constructor(
    private store: Store,
    private dialog: NbDialogService,
    private route: ActivatedRoute,
    private actions$: Actions,
  ) {}

  public ngOnInit(): void {}

  public onUpdatePlan(plan: IPlan): void {
    this.actions$.pipe(ofActionSuccessful(PlanConfirmations), take(1)).subscribe(() => this.routeBack());

    this.store.dispatch(new PlanConfirmations({ planId: plan.id, confirmation: PlanConfirmation.Employees }));
  }

  public onCancel(): void {
    this.routeBack();
  }

  public onDelete({ id, first_name, last_name }, dialog: TemplateRef<any>): void {
    this.dialog
      .open(dialog, { context: `${first_name} ${last_name}` })
      .onClose.pipe(
        take(1),
        filter((shouldDelete) => shouldDelete),
      )
      .subscribe(() => this.store.dispatch([new DeleteEmployee(id)]));
  }

  public onInfo({ fs, email }: { fs: IFieldStatuses; email: string }, dialog: TemplateRef<any>): void {
    this.dialog.open(dialog, { context: { fs: Object.values(fs).filter((fs) => !fs.complete), email } });
  }

  private routeBack(): void {
    this.store.dispatch(new Navigate(['../'], null, { relativeTo: this.route }));
  }
}
