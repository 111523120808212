<div class="p-5" *ngIf="isLoading$ | async; else carrierDetail">
  <nb-spinner status="primary"></nb-spinner>
</div>

<ng-template #carrierDetail>
  <hr />
  <pip-carrier-document-detail
    [plan]="plan$ | async"
    [quote]="quote$ | async"
    [carrier]="carrier$ | async"
    [media]="media$ | async"
    [users]="users"
    [isUploading]="isUploading$ | async"
    [isDeleting]="isDeleting$ | async"
    [showEnrollCtrls]="showEnrollCtrls"
    [showDocCtrls]="showDocCtrls"
    (invite)="onCarrierInvite($event, confirmInviteDialog)"
    (decline)="onCarrierDecline($event, declineQuoteDialog)"
    (enroll)="onQuoteEnroll($event, confirmEnrollDialog)"
    (uploadMedia)="onUploadMedia($event)"
    (deleteMedia)="onDeleteMedia($event)"
  ></pip-carrier-document-detail>
</ng-template>

<ng-template #declineQuoteDialog let-data let-ref="dialogRef">
  <form [formGroup]="declineForm" (ngSubmit)="declineQuote(data.quote.id)">
    <nb-card status="basic">
      <nb-card-header>Decline {{ data.carrier.attributes.name }}'s quote</nb-card-header>
      <nb-card-body>
        <div class="form-group">
          <label class="label" for="notes">Notes</label>
          <textarea formControlName="notes" nbInput fullWidth></textarea>
        </div>
      </nb-card-body>
      <nb-card-footer>
        <button nbButton status="primary" type="submit">Submit</button>
        <button nbButton status="primary" ghost type="button" (click)="ref.close()">Cancel</button>
      </nb-card-footer>
    </nb-card>
  </form>
</ng-template>

<ng-template #confirmInviteDialog let-data let-ref="dialogRef">
  <nb-card status="basic">
    <nb-card-header>Confirm invite</nb-card-header>
    <nb-card-body>
      <p>Are you sure you want to re-invite this carrier?</p>
    </nb-card-body>
    <nb-card-footer>
      <button nbButton status="primary" type="button" (click)="ref.close(true)">Yes, re-invite</button>
      <button nbButton status="primary" ghost type="button" (click)="ref.close()">No, do not re-invite</button>
    </nb-card-footer>
  </nb-card>
</ng-template>

<ng-template #confirmEnrollDialog let-data let-ref="dialogRef">
  <nb-card status="basic">
    <nb-card-header>Confirm enrollment</nb-card-header>
    <nb-card-body>
      <p>Enroll with {{ (carrier$ | async)?.attributes.name }}</p>
      <form [formGroup]="data.form">
        <div class="form-group">
          <label for="selected_media_id" class="label">Select sold plan</label>
          <nb-select fullWidth name="selected_media_id" id="selected_media_id" formControlName="selected_media_id">
            <nb-option *ngFor="let option of data.options" [value]="option.id">{{ option.attributes.name }}</nb-option>
          </nb-select>
        </div>
        <div class="form-group">
          <label class="label">Start date</label>
          <pip-plan-start-date formControlName="start_at"></pip-plan-start-date>
        </div>
      </form>
    </nb-card-body>
    <nb-card-footer>
      <button [disabled]="!data.form.valid" nbButton status="primary" type="button" (click)="ref.close(true)">
        Enroll
      </button>
      <button nbButton status="primary" ghost type="button" (click)="ref.close()">Cancel</button>
    </nb-card-footer>
  </nb-card>
</ng-template>
